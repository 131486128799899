import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AsignateimageService } from '../../assign-images/assign-images.component.service';
import { ComponentsService } from '@app/shared';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { CoreState } from '@app/store';
import { DeleteVariantImagesFromSon, SetVariantImage } from '@app/store/unit-product-creation/actions';
import { ProductCategory } from '@app/secure/products/models/product-creation.model';
import { ColorService } from '@app/shared/util/ColorService';


@Component({
  selector: 'app-product-variant-register-form',
  templateUrl: './product-variant-register-form.component.html',
  styleUrls: ['./product-variant-register-form.component.scss']

})
export class ProductVariantRegisterFormComponent implements OnInit {
  @Input() variantForm: FormGroup;
  @Input() index: number;
  @Input() clothingSizes: any[];
  @Input() clothingColors: any[];
  @Output() deleteVariantForm: EventEmitter<number>;
  images: { id: string; url: string }[];
  colorSelected: any;

  constructor(
    private imagesService: AsignateimageService,
    private componentsService: ComponentsService,
    private translateService: TranslateService,
    private store: Store<CoreState>,
    private colorService:ColorService
  ) {
    this.deleteVariantForm = new EventEmitter<number>();
  }

  ngOnInit(): void {
    this.images = [
      {
        id: `variant-${this.index + 1}-image-1-${Date.now()}-${Math.random().toString()}`,
        url: this.variantForm.value.imageUrl1,
      },
      {
        id: `variant-${this.index + 1}-image-2-${Date.now()}-${Math.random().toString()}`,
        url: this.variantForm.value.imageUrl2,
      },
      {
        id: `variant-${this.index + 1}-image-3-${Date.now()}-${Math.random().toString()}`,
        url: this.variantForm.value.imageUrl3,
      },
      {
        id: `variant-${this.index + 1}-image-4-${Date.now()}-${Math.random().toString()}`,
        url: this.variantForm.value.imageUrl4,
      },
    ];
    this.colorNameControl.setValue(this.variantForm.value.colorName);
    this.colorSelectControl.setValue(this.colorService.getColorName((this.variantForm.value.colorCode) ));
    
  }

  onDeleteVarianteForm(index: number): void {
    this.deleteVariantForm.emit(index);
    this.deleteVariantImagesFromSon(index);
    this.variantForm.updateValueAndValidity();
  }

  onColorSelected(colorSelected: any): void {
    this.colorSelected = colorSelected;
    this.variantForm.get('colorCode').setValue(colorSelected.name);
  }

  checkImagesCount() {
    return !this.variantForm.controls.imageUrl1.value &&
      !this.variantForm.controls.imageUrl2.value &&
      !this.variantForm.controls.imageUrl3.value &&
      !this.variantForm.controls.imageUrl4.value &&
      this.variantForm.controls.imageUrl1.dirty &&
      this.variantForm.controls.imageUrl2.dirty &&
      this.variantForm.controls.imageUrl3.dirty &&
      this.variantForm.controls.imageUrl4.dirty
  }

  sendImage(imageData: { id: string; image: string }): void {
    const imageUrl = { UrlImage: imageData.image };
    this.imagesService.getvalidateImage(imageUrl).subscribe(res => {
      const responseData = JSON.parse(res.body).Data;
      const index = this.images.findIndex(i => i.id === imageData.id);
      this.images[index].url = responseData.Url;
      this.setVariantImage(responseData.Url);
      switch (index) {
        case 0:
          this.variantForm.get('imageUrl1').setValue(responseData.Url);
          break;
        case 1:
          this.variantForm.get('imageUrl2').setValue(responseData.Url);
          break;
        case 2:
          this.variantForm.get('imageUrl3').setValue(responseData.Url);
          break;
        case 3:
          this.variantForm.get('imageUrl4').setValue(responseData.Url);
          break;
      }
      const isError = responseData.Error;
      if (isError) {
        this.componentsService.openSnackBar(
          this.translateService.instant('secure.products.unit_product_creation.variant_image_invalid'),
          this.translateService.instant('actions.close'),
          5000
        );
      }
    });
  }

  setVariantImage(url: string) {
    this.store.dispatch(new SetVariantImage({
      sonIndex: this.index + 1,
      url
    }));
  }

  deleteVariantImagesFromSon(index: number) {
    this.store.dispatch(new DeleteVariantImagesFromSon(index + 1));
  }

  get colorNameControl(): FormControl {
    return this.variantForm.get('colorName') as FormControl;
  }
  get colorSelectControl(): FormControl {
    return this.variantForm.get('colorCode') as FormControl;
  }
}
