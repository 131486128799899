<section class="product-categorization" *ngIf="categories">
  <h2 class="unit-product-creation__title">
    {{ "secure.products.unit_product_creation.categorization.title" | translate }}
  </h2>
  <div class="product-categorization__search-form">
    <img src="assets/img/busqueda-categorias.svg" alt="Busqueda de categoria">
    <app-product-category-search
      [categories]="categories"
      (categorySelected)="onCategorySelected($event)"
    ></app-product-category-search>
  </div>
  <app-product-category-tree
    [categories]="categories"
    [navigationCategoriesSelected]="navigationCategoriesSelected"
    (categorySelected)="onCategoryFromTreeSelected($event)"
  ></app-product-category-tree>
</section>

<div class="unit-product-creation__actions" style="flex-direction: column">
  <button matStepperNext class="unit-product-creation__step-button unit-product-creation__button-continue">
    {{ "secure.products.unit_product_creation.continue_button_label" | translate }}
  </button>
  <button matStepperPrevious class="unit-product-creation__step-button unit-product-creation__button-back">
    {{ "secure.products.unit_product_creation.back_button_label" | translate }}
  </button>
</div>
