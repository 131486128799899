<div *ngIf="myform">
  <h2 mat-dialog-title>{{'secure.orders.download_order_modal.title' | translate}}
    <span *ngIf="!billingType && type === 1"> {{'secure.orders.orders' | translate}} </span>
    <span *ngIf="billingType"> {{'secure.orders.download_order_modal.title_3' | translate}} </span>
    <span *ngIf="!billingType && type === 2"> {{'secure.orders.order_list.product_detail.guides' | translate}} </span>
    {{'secure.orders.download_order_modal.title_4' | translate}}</h2>
  <mat-dialog-content>
    <form #f="ngForm" [formGroup]="myform" class="form-login">
      <div fxLayout="column" fxLayoutGap="20px">
        <div fxLayout="row" fxFlex="100">
          <mat-form-field fxFlex="100" class="input-style">
            <input name="email" formControlName="email" matInput [placeholder]="'secure.seller.register_seller.lb_email_form' | translate" type="email" required>

            <!-- dirty: si el campo cambia -->
            <button disabled="{{!f.form.dirty}}" matSuffix type="button" color="primary" mat-icon-button (click)="createForm()">
              <mat-icon [matTooltip]="'secure.offers.historical_admin.download_historical.tooltip_email' | translate" matTooltipPosition="below" matSuffix>
                autorenew
              </mat-icon>
            </button>
            <mat-error>{{'errors.email_required' | translate}}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions class="dialog-buttons">
    <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
    <button class="sc-button" mat-button [mat-dialog-close]="true">{{'actions.cancel_min' | translate}}</button>

    <button class="sc-button" *ngIf="type === 1" [disabled]="!f.form.valid" (click)="downloadOrders(myform)" mat-raised-button color="accent">
      <span *ngIf="!billingType">{{'secure.orders.download_order_modal.btn_send_order' | translate}}</span>
      <span *ngIf="billingType">{{'secure.orders.download_order_modal.btn_send_invoice' | translate}} </span>
    </button>
    <button class="sc-button" *ngIf="type === 2" [disabled]="!f.form.valid" (click)="downloadGuide(myform)" mat-raised-button color="accent">
      <span>{{'secure.orders.download_order_modal.btn_send_guide' | translate}}</span>
    </button>
  </mat-dialog-actions>
</div>