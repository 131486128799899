<div class="errorPage" fxFlex="row" *ngIf="false">
  <iframe
    src="https://vignette.wikia.nocookie.net/gemas/images/b/b1/Gear1.gif/revision/latest?cb=20151201164022&path-prefix=es"
    width="426" height="327" frameBorder="0"></iframe>
  <p>
    "Estamos trabajando para restablecer nuestros servicios, muchas
    gracias por tu paciencia."</p>
</div>

<div class="content-page" >
  <div class="content-tab" fxFlexAlign="center" fxLayout="row" fxFlex="450px" fxFlex.xs="90" fxFlex.sm="450px"
    fxFlex.md="450px" fxFlex.lg="450px" xFlex.xl="35">
    <div [@scaleEfect] fxFlex="100" class="tab-style login-content">
      <div class="title-login">
        <!-- <h1 class="title-card-form">Iniciar sesión</h1> -->
        <div class="logo-application"></div>
      </div>
      <mat-card class="card-login">
        <form #f="ngForm" [formGroup]="awscognitogroup" class="form-login" method="POST" action="#" role="form">
          <div fxLayout="column" fxLayoutGap="20px">
            <div fxFlex="100">
              <mat-form-field class="full-width input-style">
                <mat-icon matPrefix>person</mat-icon>
                <input id="input-username" name="email" formControlName="email" matInput
                  [placeholder]="'public.auth.login.ph_user' | translate" type="email" required maxlength="100">
                <mat-error>{{'errors.error_user_login' | translate}}</mat-error>
              </mat-form-field>
            </div>
            <div fxFlex="100">
              <mat-form-field class="full-width">
                <mat-icon matPrefix>https</mat-icon>
                <input id="input-password" name="password" formControlName="password" maxlength="25" matInput
                  [placeholder]="'public.auth.login.ph_psw' | translate" type="password" required length="40">
                <mat-error>{{'errors.error_psw_login' | translate}}</mat-error>
              </mat-form-field>
            </div>
            <div fxFlex="100" fxLayout="row" fxLayoutAlign="center center">
              <button id="login-button" [disabled]="!f.form.valid" (click)="onLogin()" type="submit" mat-raised-button
                color="primary">{{'actions.enter' | translate}}</button>
            </div>
            <div *ngIf="errorMessage!=null">
              <mat-error>{{ errorMessage }}</mat-error>
            </div>
            <div fxFlex="100" fxLayout="row" fxFlexAlign="center">
              <p>
                <a [routerLink]="['/' + consts.homeForgotPassword]"><i class="fa fa-fw fa-group"></i>
                  {{'public.auth.login.forgot_psw' | translate}}</a>
              </p>
            </div>
          </div>
        </form>
      </mat-card>
    </div>
  </div>
</div>
