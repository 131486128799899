<section>
  <div *ngIf="load" class="list-school-exito-backgroudColor">
    <app-list-seller-school *ngIf="!isAdmin"></app-list-seller-school>
    <!-- <div *ngIf="!isAdmin">
      </div> -->
    <app-list-admin-school *ngIf="isAdmin"></app-list-admin-school>
    <!-- <div *ngIf="isAdmin">
        <app-toolbar-tittle class="app-toolbar-title"[tittleBar]="'school.exito' |
          translate" [subtitleBar]="'school.exito.theme.list' | translate"></app-toolbar-tittle>
      </div> -->
  </div>
  <div class="list-school-exito-wait-moment-please" *ngIf="!load">
    {{'secure.products.create_product_unit.list_products.please_wait' | translate}}
  </div>
</section>