<div class="container" fxLayout="row wrap" fxLayoutAlign="center start">

  <div fxFlex="100" class="light-content">

    <!-- Toolbar de las opciones para la orden -->
    <mat-toolbar class="toolbar-orders-options-table" color="transparent">
      <mat-toolbar-row class="toolbar-options">

        <div class="normal-theme toolbar-options-table">

          <!-- Paginación de la tabla -->
          <div fxShow.xs="false" fxShow.sm="false" fxShow.md="true" fxShow.lg="true" fxShow.xl="true"
            fxLayout="row" fxLayoutAlign="center center">

            <!-- Input para subir archivo Excel -->

            <a *ngIf="getFunctionality(download)" download class="sc-default-button" id="download-file-button"
              (click)="openModalDownloadFormat()" mat-button>
              <mat-icon class="mat-18">file_download</mat-icon>
              {{'secure.offers.bulk_upload.bulk_upload.download_format_offer' | translate}}
            </a>

            <!-- Input para subir archivo Excel -->
            <input placeholder="file upload" hidden name="fileUploadOption" type="file" (change)="onFileChange($event)"
              multiple="false" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              #fileUploadOption>
            <button *ngIf="getFunctionality(load)" color="primary" class="sc-default-button" id="load-file-button"
              (click)="fileUploadOption.click()" mat-button>
              <mat-icon class="mat-18 ">file_upload</mat-icon>
              {{'secure.offers.bulk_upload.bulk_upload.upload_format' | translate}}
            </button>

          </div>
          <div fxShow.xs="true" fxShow.sm="true" fxShow.md="false" fxShow.lg="false" fxShow.xl="false"
            class="center-product-list" fxFlex="130px">
            <button id="dropdown-options-load-guide" class="button-toolbar" mat-button
              [matMenuTriggerFor]="toolbarOptions">
              <span>{{'secure.products.bulk_upload.options' | translate}}</span>
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu dense #toolbarOptions="matMenu">
              <button *ngIf="getFunctionality(load)" id="upload-file-guide-button" (click)="fileUploadOption.click()"
                mat-menu-item>
                <span>{{'secure.products.bulk_upload.upload_file' | translate}}</span>
              </button>
              <a (click)="openModalDownloadFormat()" download id="download-file-guide-button"
                 mat-menu-item>
                {{'secure.products.bulk_upload.download_format' | translate}}</a>
              <button *ngIf="fileName != ''" (click)="resetVariableUploadFile()" id="download-order-button"
                mat-menu-item>
                <span>{{'secure.products.bulk_upload.clear_current_data' | translate}}</span>
              </button>
              <button id="send-guide-button" (click)="sendJsonInformation()"
                [disabled]="countErrors != 0 || countRowUpload == 0" color="accent"
                mat-menu-item>{{'secure.products.bulk_upload.send_data' | translate}}
              </button>
            </mat-menu>
          </div>
          <!-- Paginación de la tabla -->

        </div>
      </mat-toolbar-row>
    </mat-toolbar>
    <!-- Toolbar de las opciones para la orden -->
  </div>


  <div *ngIf="!orderListLength" class="content-expansion-orders" fxFlex.xs="93%" fxFlex.sm="95%" fxFlex.md="30%"
    fxFlex.lg="30" fxFlex.xl="40">

    <!-- Componente para visualizar la lista de errores -->
    <app-table-errors [fileName]="fileName" [countRowUpload]="countRowUpload" [countErrors]="countErrors"
      [listLog]="listLog"></app-table-errors>
  </div>
  <!-- Tabla de órdenes -->
  <div *ngIf="!orderListLength" fxFlex.xs="93%" fxFlex.sm="95" fxFlex.md="60" fxFlex.lg="60" fxFlex.xl="50"
    class="content-table-orders">
    <!-- Componente para visualizar la lista de resultados obtenidos -->
    <app-table-load  [countErrors]="countErrors" [countRowUpload]="countRowUpload"></app-table-load>

  </div>

  <div fxFlex.xs="93%" fxFlex.sm="95" fxFlex.md="70" fxFlex.lg="60" fxFlex.xl="60" class="content-table-orders">
    <div [@detailExpand] class="orders-no-data" *ngIf="countRowUpload == 0">
      <div fxShow.xs="false" fxShow.sm="false" fxShow.md="true" fxShow.lg="true" fxShow.xl="true"
        class="content-icon-no-data">
        <mat-icon id="upload-file-guide-button" (click)="fileUploadOption.click()" class="icon-not-found"
          color="primary">backup
        </mat-icon>
      </div>
      <div class="content-information-no-data">
        <h4 class="no-margin">{{'secure.load_guide_page.load_guide.hi' | translate}}</h4>
        <p>{{'secure.load_guide_page.load_guide.body' | translate}}
          <a download id="download-file-guide-button" (click)="openModalDownloadFormat()"
            class="link-text">{{'secure.load_guide_page.load_guide.body_2' | translate}}
          </a>{{'secure.load_guide_page.load_guide.body_3' | translate}}
        </p>
        <p>
          <span class="link-text" id="upload-file-guide-button"
            (click)="fileUploadOption.click()">{{'secure.load_guide_page.load_guide.body_4' | translate}}
          </span> {{'secure.products.bulk_upload.text_select_import_type' | translate}}</p>
        <div class="alert-size">
          <mat-icon>warning</mat-icon>
          <span>{{'secure.load_guide_page.load_guide.warning_register' | translate}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
