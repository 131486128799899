import { Component, OnInit, AfterViewInit } from '@angular/core';
import { UserInformation } from '@app/shared';
import { UserParametersService } from '@app/core';
import { MatTabChangeEvent } from '@angular/material';
import { SetHeaderTitle } from '@app/store/configuration/actions';
import { Store } from '@ngrx/store';
import { CoreState } from '@app/store';


@Component({
  selector: 'app-tab-product',
  templateUrl: './tab-product.component.html',
  styleUrls: ['./tab-product.component.scss']
})
export class TabProductComponent implements OnInit, AfterViewInit {
  public user: UserInformation;
  showTabs = true;
  showButtonDownload: Boolean = true;
  isDrawerOpenedFlat = false;
  isLoading = true;


  constructor(
    public userParams: UserParametersService,
    private store: Store<CoreState>
  ) { }

  ngOnInit() {
    this.setProductTitle();
    this.getDataUser();
  }

  ngAfterViewInit() {
    if (this.showTabs && !this.isLoading) {
      const principalToolbar = document.getElementsByClassName('mat-tab-label');
      principalToolbar[0].classList.add('mat-tab-label-size');
      principalToolbar[1].classList.add('mat-tab-label-size');
    }
  }

  async getDataUser() {
    this.user = await this.userParams.getUserData();
    if (this.user.sellerProfile === 'seller') {
      this.showTabs = true;
    } else {
      this.showTabs = false;
    }
    this.isLoading = false;
  }

  onTabChanged(e: MatTabChangeEvent) {
    if (e.index !== 0) {
      this.showButtonDownload = false;
    } else {
      this.showButtonDownload = true;
    }
  }

  setProductTitle() {
    this.store.dispatch(new SetHeaderTitle({
      title: 'module.Productos',
      subtitle: 'menu.Listado de productos',
      description: ''
    }));
  }

  toggleDrawerOpened(event: any) {
    this.isDrawerOpenedFlat = event;
  }

}
