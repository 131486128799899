import { Component, Inject, Input, OnDestroy, ViewChild } from '@angular/core';
import { ListProductService } from '@app/secure/products/list-products/list-products.service';
import { LoadingService, ModalService } from '@app/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { ModalComponent } from '../modal/modal.component';
import { ListService } from '@app/secure/offers/list/list.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnDestroy {
  private limitSub: Subscription;
  private selectAllSub: Subscription;
  private confirmDialogSub: Subscription;
  private afterCloseDialogRef: Observable<any>;
  private _fulfillmentView: boolean;
  public products: any[] = [];
  public currentPage: number = 1;
  public gridView: boolean = true;
  public pagesNumber: number;
  public productCheckArr: FormArray;
  public limit: FormControl = new FormControl('30');
  public selectAll: FormControl = new FormControl(false);

  @ViewChild(ModalComponent, { static: false }) private modalComponent: ModalComponent;

  @Input()
  set fulfillmentView(value: boolean) {
    this._fulfillmentView = value;
    this.products = [];
    this.selectAll.setValue(false, { emitEvent: false });
    this.currentPage = 1;
    value ? this.getFulfillmentProducts() : this.getOffers();
  }

  get fulfillmentView(): boolean {
    return this._fulfillmentView;
  }

  constructor(
    private modalService: ModalService,
    private productsService: ListProductService,
    private offerService: ListService,
    private loadingService: LoadingService,
    private formBuilder: FormBuilder,
    @Inject(DOCUMENT) private doc: Document
  ) {
    this.subscribeToLimit();
    this.subscribeToSelectAll();
  }

  private getOffers(): void {
    this.loadingService.viewSpinner();
    const params = {
      currentPage: this.currentPage,
      ean: null,
      limit: this.limit.value,
      pluVtex: null,
      product: null,
      sellerSku: null,
      reference: null,
      stock: null
    };

    this.offerService.getOffers(params).subscribe((result: any) => {
      if (result && result.body !== undefined) {
        const response = result.body.data;
        this.products = response.sellerOfferViewModels;
        this.pagesNumber = Math.ceil(response.total / this.limit.value);
        this.initializeProductChecks();
      } else {
        this.modalService.showModal('errorService');
      }
      this.loadingService.closeSpinner();
      this.scrollToTop();
    }, error => {
      this.loadingService.closeSpinner();
    });
  }

  private getFulfillmentProducts(): void {
    this.loadingService.viewSpinner();
    const urlParams2 = `?&page=${this.currentPage - 1}&limit=${this.limit.value}`;

    this.productsService.getFulfillmentProductList(urlParams2).subscribe((result: any) => {
      if (result && result.data !== undefined) {
        this.products = result.data.list;
        this.pagesNumber = Math.ceil(result.data.total / this.limit.value);
        this.initializeProductChecks();
      } else {
        this.modalService.showModal('errorService');
      }
      this.loadingService.closeSpinner();
      this.scrollToTop();
    }, error => {
      this.loadingService.closeSpinner();
    });
  }

  private scrollToTop(): void {
    const el = this.doc.getElementById('scroll-restoring-element');
    el.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
  }

  private markFulfillmentProducts(): void {
    const productRequests: Observable<any>[] = this.eansToRequests();
    forkJoin(productRequests).subscribe((result) => {
      if (result) {
        this.selectAll.setValue(false, { emitEvent: false });
        this.initializeProductChecks();
        this.loadingService.closeSpinner();
      }
    }, error => {
      this.modalService.showModal('errorService');
      this.loadingService.closeSpinner();
    });
  }

  private unmarkFulfillmentProducts(): void {
    const productRequests: Observable<any>[] = this.eansToRequests();
    forkJoin(productRequests).subscribe((result) => {
      if (result) {
        this.selectAll.setValue(false, { emitEvent: false });
        this.getFulfillmentProducts();
      }
    }, error => {
      this.modalService.showModal('errorService');
      this.loadingService.closeSpinner();
    });
  }

  private initializeProductChecks(): void {
    this.productCheckArr = new FormArray([]);
    this.products.forEach(({ ean }) => {
      this.productCheckArr.push(
        this.formBuilder.group({
          state: new FormControl(false),
          ean: new FormControl(ean, Validators.required)
        })
      )
    });
  }

  private eansToRequests(): Observable<any>[] {
    const requestArray: Observable<any>[] = [];

    (this.productCheckArr.controls as FormGroup[]).forEach(({ value }) => {
      if (value.state) {
        requestArray.push(this._fulfillmentView
          ? this.productsService.unmarkFulfillmentProduct([value.ean])
          : this.productsService.markFulfillmentProduct([value.ean]));
      }
    });

    return requestArray;
  }

  private subscribeToLimit(): void {
    this.limitSub = this.limit.valueChanges.subscribe((_) => {
      this.currentPage = 1;
      this.selectAll.setValue(false, { emitEvent: false });
      this._fulfillmentView ? this.getFulfillmentProducts() : this.getOffers();
    });
  }

  private subscribeToSelectAll(): void {
    this.selectAllSub = this.selectAll.valueChanges.subscribe((state) => {
      this.selectAllProducts(state);
    });
  }

  private subscribeToConfirmDialog(): void {
    this.confirmDialogSub = this.afterCloseDialogRef.subscribe((confirm) => {
      if (confirm) {
        this.loadingService.viewSpinner();
        this._fulfillmentView ? this.unmarkFulfillmentProducts() : this.markFulfillmentProducts();
        this.confirmDialogSub.unsubscribe();
      }
    });
  }

  private selectAllProducts(state: boolean): void {
    (this.productCheckArr.controls as FormGroup[]).forEach(({ controls }) => controls.state.setValue(state));
  }

  public changeViewMode(event): void {
    this.gridView = event;
  }

  public changePage(event): void {
    this.currentPage = event;
    this._fulfillmentView ? this.getFulfillmentProducts() : this.getOffers();
  }

  public confirmProducts(): void {
    this.afterCloseDialogRef = this.modalComponent.showModal(this._fulfillmentView ? 'remove' : 'add');
    this.subscribeToConfirmDialog();
  }

  public getProdCheckFormControl(index: number): FormControl {
    return (this.productCheckArr.controls[index] as FormGroup).controls.state as FormControl;
  }

  public enableConfirm(): boolean {
    return (this.productCheckArr.controls as FormGroup[]).some(({ value }) => value.state);
  }

  ngOnDestroy(): void {
    this.limitSub.unsubscribe();
    this.selectAllSub.unsubscribe();
    this.confirmDialogSub && this.confirmDialogSub.unsubscribe();
  }
}
