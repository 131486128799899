<div class="content-modal">
  <div class="title-page">
    <h2 class="sc-title mat-h2">{{'secure.orders.in_devolution.action_report_novelty.placeholder_denied' | translate}}</h2>
    <a id="close-finish-send-order" [matTooltip]="'actions.close' | translate" mat-icon-button matTooltipPosition="below" mat-button>
      <mat-icon (click)="onNoClick()">close</mat-icon>
    </a>
  </div>
  <form class="full-width form-devolution" #f="ngForm" [formGroup]="myform">
    <mat-dialog-content class="full-width">

      <mat-form-field class="full-width">
        <mat-select required formControlName="reason" [placeholder]="'secure.orders.in_devolution.action_report_novelty.placeholder_denied' | translate">
          <mat-option *ngFor="let reasonReject of reasonRejection" [value]="reasonReject.idMotivoSolicitudReversion">
            {{ reasonReject.nombreMotivoSolicitudReversion }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="full-width">
        <textarea formControlName="observation" matInput [placeholder]="'secure.seller.list.observation' | translate"></textarea>
      </mat-form-field>

    </mat-dialog-content>

    <mat-dialog-actions class="button-content">

      <button class="sc-button" (click)="clearForm()" mat-button>
        <mat-icon>clear_all</mat-icon>
        {{'filters.clear' | translate}}
      </button>
      <button class="sc-button" [disabled]="!f.form.valid" (click)="reportNovelty(myform)" type="submit" mat-raised-button
              color="primary">{{'actions.send' | translate}}
      </button>
    </mat-dialog-actions>
  </form>
</div>
