<div class="case-list-content">
  <mat-accordion class="case-list-expandable">
    <mat-expansion-panel [expanded]="isOpen"
      (opened)="opened.emit(data); this.panelOpenState = true; read = true; showDetail.emit(panelOpenState)"
      (closed)="panelOpenState = false; showDetail.emit(panelOpenState)">
      <mat-expansion-panel-header>
        <div [ngClass]="read === true ? '' : 'bold-text'" class="case-list-detail" fxLayout fxLayout.xs="row"
          fxLayoutAlign="center center" fxLayoutGap="10px" fxLayoutGap.xs="0">
          <div class="case-list-detail--item-detail" fxFlex="5%" fxLayoutAlign="center center" class="no-select">
            <span [ngClass]="
                !panelOpenState
                  ? 'item-detail-arrow'
                  : 'item-detail-arrow--active'
              ">
              <mat-icon>keyboard_arrow_up</mat-icon>
            </span>
          </div>

          <div class="case-list-detail--item-detail" fxFlex="10%" id="dpbox-case-id-{{index}}">
            {{ data.caseNumber }}
          </div>
          <div class="case-list-detail--item-detail" fxFlex="15%" id="dpbox-case-number-{{index}}">
            {{ data.orderNumber }}
          </div>
          <div class="case-list-detail--item-detail noneMobile" fxFlex="30%" id="dpbox-case-detail-{{index}}">
            <p>{{ data.reasonDetail }}</p>
          </div>
          <div class="case-list-detail--item-detail noneMobile" fxFlex="15%" id="dpbox-case-date-{{index}}">
            {{ data.createDate | dateNoGtm | date }}
          </div>
          <div class="case-list-detail--item-detail noneMobile" fxFlex="10%" id="dpbox-case-status-{{index}}">
            {{ data.status }}
          </div>
          <div class="case-list-detail--item-detail noneMobile" fxFlex="20%" id="dpbox-case-reason-{{index}}">
            <div [ngClass]="data.attachments && data.attachments.length && data.attachments.length > 0 ? 'flexAttach': ''">
              <mat-icon id="dpbox-attachment-icon-{{index}} "
              *ngIf="data.attachments && data.attachments.length && data.attachments.length > 0">
              attachment
            </mat-icon>
            <p>{{ data.lastPostDesc }}</p>
            </div>
          </div>

          <div class="case-list-detail--item-detail" fxFlex="20%" id="dpbox-case-time-{{index}}">
            <p>{{ data.responseTime }} {{'secure.parametize.support.hours' | translate}}</p>
          </div>
        </div>
      </mat-expansion-panel-header>

      <ng-container *ngFor="let item of items">
        <ng-container [ngTemplateOutlet]="item.tpl"></ng-container>
      </ng-container>
    </mat-expansion-panel>
  </mat-accordion>
</div>
