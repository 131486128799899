import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DownloadModalErrorVtexComponent } from './download-modal-error-vtex/download-modal-error-vtex.component';
import { SetHeaderTitle } from '@app/store/configuration/actions';
import { Store } from '@ngrx/store';
import { CoreState } from '@app/store';

@Component({
  selector: 'app-report-errors-vtex',
  templateUrl: './report-errors-vtex.component.html',
  styleUrls: ['./report-errors-vtex.component.scss']
})
export class ReportErrorsVtexComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    private store: Store<CoreState>
  ) { }

  ngOnInit() {
    this.setConfigurationTitle();
  }

  openModalDownloadReportErrorsVtex() {
    const dialogRef = this.dialog.open(DownloadModalErrorVtexComponent, {
      data: {
      },
    });
    dialogRef.afterClosed().subscribe(result => { });
  }

  setConfigurationTitle() {
    this.store.dispatch(new SetHeaderTitle({
      title: 'module.Reportes',
      subtitle: 'menu.Reporte de errores en VTEX',
      description: ''
    }));
  }
}

