<div>
  <div fxLayout="row" class="padding-10">
    <div fxFlex="95">
      <span class="modal-export-to-reclaim-font-weight ">{{'secure.parametize.support_claims-filter.modal-export.titleExportReclaim' | translate}} </span><span>|</span><span
        class="modal-export-to-reclaim-color-text">
        ({{email}})</span>
    </div>
    <div fxFlex="5">
      <mat-icon class="mat-18 close-modal modal-export-to-reclaim-cursor" (click)="onNoClick()"
        [matTooltip]="'actions.close' | translate">clear
      </mat-icon>
    </div>
  </div>
  <mat-divider></mat-divider>

  <div mat-dialog-content class="">
    <form #filterForm="ngForm" [formGroup]="form">
      <div fxLayout="row" class="modal-export-to-reclaim-margin-text-search-seller" *ngIf="isAdmin">
        <div>
          <span>{{'secure.parametize.support_claims-filter.modal-export.searchSeller' | translate}}</span>
        </div>
      </div>
      <div fxLayout="row wrap" class="modal-export-to-reclaim-margin-left-component-search-seller" *ngIf="isAdmin">
        <div fxFlex.md="75" fxFlex.lg="75" fxFlex.xl="75" fxFlex.xs="90" fxFlex.sm="90">
          <div fxLayout="column">
            <div>
              <app-search-seller [loadSpinner]="false" [emitModal]="true" [clearSearch]="clearSearch"
                [widthComplete]="true"></app-search-seller>
            </div>
          </div>
        </div>
        <div fxFlex.md="20" fxFlex.lg="20" fxFlex.xl="20" fxFlex.xs="5" fxFlex.sm="5"
          class="text-aling-end modal-export-to-reclaim-add-seller-btn" (click)="IsClickEnable?saveKeyword():null">
          <mat-icon matSuffix [matTooltip]="'secure.parametize.port.btn_add_port' | translate"
            class="material-icons color-icon-add-seller vertical-align-top">
            add_circle_outline
          </mat-icon>
          <span class="color-text-add-seller" fxShow.xs="false" fxShow.sm="false" fxShow.lg="true"
            fxShow.xl="true">{{'secure.parametize.support_claims-filter.modal-export.addSeller' | translate}}</span>
        </div>
      </div>
      <div fxLayout="row wrap" class="modal-export-to-reclaim-action-padding" *ngIf="isAdmin">
        <div fxFlex.md="15" fxFlex.lg="15" fxFlex.xl="15" fxFlex.xs="100" fxFlex.sm="95"
          class="modal-export-to-reclaim-margin-content-action-5">
          <mat-checkbox formControlName="importAll" class="dialog-accept" (click)="clearSellerSearch(!valueCheck)">
          </mat-checkbox>
          <span class="modal-export-to-reclaim-text-export-text">{{'secure.parametize.support_claims-filter.modal-export.exportAll' | translate}}</span>
        </div>
        <div fxFlex.md="15" fxFlex.lg="15" fxFlex.xl="15" fxFlex.xs="100" fxFlex.sm="95"
          class="modal-export-to-reclaim-margin-content-action-5" (click)="showFilter()">
          <mat-icon class="mat-18 vertical-align-top">filter_list</mat-icon>
          <span class="modal-export-to-reclaim-text">{{'secure.parametize.support_claims-filter.modal-export.applyFilter' | translate}}</span>
        </div>
        <div fxFlex.md="15" fxFlex.lg="15" fxFlex.xl="15" fxFlex.xs="100" fxFlex.sm="95"
          class="modal-export-to-reclaim-margin-content-action-5" (click)="showEmailSend()">
          <mat-icon class="mat-18 vertical-align-top modal-export-to-reclaim-color-text modal-export-to-reclaim-cursor">
            email</mat-icon>
          <span class="modal-export-to-reclaim-text">{{'secure.parametize.support_claims-filter.modal.export.editEmail' | translate}}</span>
        </div>
      </div>
      <div fxLayout="column" class="modal-export-to-reclaim-container-email" *ngIf="isShowEmail || !isAdmin">
        <div class="modal-export-to-reclaim-margin-bottom-16" (click)="showEmailSend()">
          <span class="modal-export-to-reclaim-text-send-email">{{'secure.parametize.support_claims-filter.modal-export.text-message-send-email' | translate}}</span>
        </div>
        <div class="modal-expot-to-reclaim-margin-left-5">
          <mat-form-field class="half-width modal-export-to-reclaim-width-100">
            <input matInput placeholder="Correo electrónico" class="modal-export-to-reclaim-text-uppercase"
              formControlName="email" [errorStateMatcher]="matcher">
            <mat-error *ngIf="form.get('email').hasError('required')">{{'errors.required' | translate}}</mat-error>
            <mat-error *ngIf="form.get('email').hasError('email')">{{'errors.format_invalidate' | translate}}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row wrap" *ngIf="isShowFilter || !isAdmin" class="modal-export-to-reclaim-container-input-filter">
        <div fxFlex.md="18" fxFlex.lg="18" fxFlex.xl="18" fxFlex.xs="100" fxFlex.sm="100">
          <mat-form-field color="primary" class="modal-export-to-reclaim-width-90">
            <input id="sinceDate-input"
              [placeholder]="translateService.instant('secure.parametize.support_claims-filter.start-date')"
              [(ngModel)]="filter.DateInit" formControlName="dateInitial" name="dateInitial" [readonly]="true" matInput
              [matDatepicker]="dateInitial">
            <mat-datepicker-toggle matPrefix [for]="dateInitial"></mat-datepicker-toggle>
            <mat-datepicker touchUi="true" startView="year" #dateInitial></mat-datepicker>
          </mat-form-field>
        </div>
        <div fxFlex.md="18" fxFlex.lg="18" fxFlex.xl="18" fxFlex.xs="100" fxFlex.sm="100">
          <mat-form-field color="primary" class="modal-export-to-reclaim-width-90">
            <input id="untilDate-input"
              [placeholder]="translateService.instant('secure.parametize.support_claims-filter.end-date')"
              [(ngModel)]="filter.DateEnd" formControlName="dateFinal" name="dateFinal" [readonly]="true"
              [min]="filter.DateInit" [max]="rangeDateMax" matInput [matDatepicker]="dateFinal">
            <mat-datepicker-toggle matPrefix [for]="dateFinal"></mat-datepicker-toggle>
            <mat-datepicker touchUi="true" startView="year" #dateFinal></mat-datepicker>
          </mat-form-field>
        </div>
        <div fxFlex.md="18" fxFlex.lg="18" fxFlex.xl="18" fxFlex.xs="100" fxFlex.sm="100">
          <mat-form-field class="filter-form--field" class="modal-export-to-reclaim-width-90">
            <mat-select [placeholder]="translateService.instant('secure.parametize.support_claims-filter.last_post')"
              name="lastPost" formControlName="lastPost" [(ngModel)]="valuePost">
              <mat-option *ngFor="let post of lastPost" value="{{post.valuePost}}">
                {{post.name | translate}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxFlex.md="18" fxFlex.lg="18" fxFlex.xl="18" fxFlex.xs="100" fxFlex.sm="100">
          <mat-form-field class="filter-form--field" class="modal-export-to-reclaim-width-90">
            <mat-select [placeholder]="translateService.instant('secure.parametize.support_claims-filter.status')"
              name="status" [(ngModel)]="value" formControlName="status">
              <mat-option *ngFor="let state of options" value="{{state.code}}">
                {{state.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxFlex.md="25" fxFlex.lg="25" fxFlex.xl="25" fxFlex.xs="100" fxFlex.sm="100">
          <mat-form-field class="filter-form--field w-100">
            <mat-select id="suport-modal-input-reason" formControlName="classification"
            [placeholder]="'secure.parametize.support_drop-down-reason-detail' | translate">
            <ng-container *ngFor="
                let item of getClassification;
                let i = index
              ">
              <mat-option [value]="item.idMatrix">
                <span id="{{ item.category }} - {{ i }}">
                  {{
                    item.category != null
                      ? item.category
                      : "Sin título"
                  }}
                </span>
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>  
        </div>
      </div>
      <div fxLayout="row" class="modal-export-to-reclaim-container-scroll-seller" *ngIf="isAdmin">
        <div class="modal-export-to-reclaim-chips-seller">
          <div class="modal-export-to-reclaim-margin-top-20">
            <mat-chip-list>
              <mat-chip *ngFor="let key of keywords; let i = index" (removed)="deleteKeywork(i)">
                {{key}}
                <mat-icon class="material-icons" matChipRemove>cancel</mat-icon>
              </mat-chip>
            </mat-chip-list>
          </div>
          <div *ngIf="keywords.length === 0 && valueCheck === false" class="modal-export-to-reclaim-margin-top-50">
            <span>{{'secure.parametize.support_claims-filter.modal-export.not-select-seller' | translate}}</span>
          </div>
          <div *ngIf="valueCheck === true && keywords.length === 0" class="modal-export-to-reclaim-margin-top-50">
            <span class="modal-export-to-reclaim-font-weight ">{{'secure.parametize.support_claims-filter.modal-export.export-all' | translate}}</span>
          </div>
        </div>
      </div>
    </form>
  </div>
  <mat-divider></mat-divider>
  <mat-dialog-actions align="end">
    <button mat-button (click)="onNoClick()">{{'actions.cancel'| translate}}</button>
    <button type="button" mat-raised-button color="primary" (click)="sendExportReclain()"
      [disabled]="disabledButton && !valueCheck || !filterForm.form.valid">{{'actions.accept' | translate}}</button>
  </mat-dialog-actions>
</div>
