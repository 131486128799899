<div class="ticket-content" *ngIf="case">
  <div class="ticket-content__item ticket-detail ticket-detail--col">

    <div fxHide.gt-xs="true">
      
      <label>{{'secure.parametize.support_drop-down-reason-detail' | translate}}</label>
      <p>{{case.reasonDetail}}</p>

      <label>{{'secure.parametize.support_drop-down-creation-date' | translate}}</label>
      <p>{{case.createDate | date: 'dd/MM/yyyy'}}</p>

      <label>{{'secure.parametize.support_drop-down-status' | translate}}</label>
      <p>{{case.status}}</p>

      <label>{{'secure.parametize.support_drop-down-last-reply' | translate}}</label>
      <p>{{ case.lastPostDesc }}</p>

    </div>

    <app-basic-card class="ticket-content--bottom-space"
      [title]=" translateService.instant('secure.parametize.support_claims.description')"
      [description]="case.description"></app-basic-card>
  </div>

  
  <button fxHide.gt-xs="true" class="btn-no-bg" mat-stroked-button color="primary" type="button" (click)="showThumbnail(case)">
    {{'secure.parametize.support.see.product' | translate}} 
  </button>

  <div class="ticket-content__item ticket-detail--col">
    <br>
    <span class="ticket-content__title ticket-content--bottom-space">
      {{'secure.parametize.support_claims.tracing' | translate}}
    </span>

    <app-conversation>
      <ng-container *ngIf="!case.followLast || case.followLast.length == 0">
        <app-conversation-message *itemConversation class="sh-case-detail--full-width" style="opacity: 0.7;"
          [message]="'secure.parametize.support_claims.no-tracing'| translate"></app-conversation-message>
      </ng-container>

      <ng-container *ngFor="let follow of case.followLast; let i = index">
        <app-conversation-message *itemConversation style="width: 100%" [name]="follow.userName"
          [message]="follow.description" [date]="follow.createDate" [configuration]="{theme: 'BASIC'}"
          [caseId]="case.caseNumber">
        </app-conversation-message>
      </ng-container>
    </app-conversation>

    <div class="ticket-content__controls ticket-content__controls--left" *ngIf="!disableButtonAnswer">
      <button  (click)="goToListCancelOrders()"  *ngIf="case.buttonReversion"
        class="btn-no-bg marginButtonRedirect" mat-stroked-button color="primary" type="button"
        [ngClass]="disabledClass ? 'ticket-content__button--secondary-disabled' : ''"
        id="case-summary-reply-btn-to-list-cancel{{case.caseNumber}}">
        {{'secure.parametize.support_claims.reply-to-cancel' | translate}}
      </button>

      <button [disabled]="disabled" (click)="openResponseDialog()"
        class="btn-no-bg" mat-stroked-button color="primary" type="button"
        [ngClass]="disabledClass ? 'ticket-content__button--secondary-disabled' : ''"
        id="case-summary-reply-btn-{{case.caseNumber}}">
        {{'secure.parametize.support_claims.reply-btn' | translate}}
      </button>
      
    </div>
  </div>

  <span (click)="redirecToDetail(case.id)" class="ticket-content__item ticket-content__title ticket-content__item--full-width
  ticket-content__title--underline ticket-content__title--center ticket-content__title--pointer
  ticket-content--no-select" id="case-summary-view-detail-{{case.caseNumber}}">
    {{'secure.parametize.support_claims.view-detail' | translate}}
  </span>
</div>