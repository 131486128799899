<div class="categorization-breadcrumb">
  <h2
    class="categorization-breadcrumb__selection"
    *ngIf="navigationCategoryNodeLeaf"
  >
    {{ navigationCategoryNodeLeaf.Name }}
  </h2>

  <ul class="categorization-breadcrumb__list">
    <li
      *ngFor="let category of navigationCategoriesSelected; let i = index"
      class="categorization-breadcrumb__item"
    >
      <span
        class="categorization-breadcrumb__icon material-symbols-outlined"
        [class.categorization-breadcrumb__icon--last]="isLastBreadcrumb(i)"
      >
        chevron_right
      </span>
      <button
        class="categorization-breadcrumb__button"
        (click)="handleNavigateCategory(category)"
        [class.categorization-breadcrumb__button--last]="isLastBreadcrumb(i)"
        [disabled]="isLastBreadcrumb(i)"
      >
        {{ category.Name }}
      </button>
    </li>
  </ul>
</div>
