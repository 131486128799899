<mat-drawer-container style="height: 80%;" class="drawer-container" [hasBackdrop]="true">
  <mat-drawer #drawer [mode]="'over'" [position]="'end'" class="example-sidenav" [disableClose]="true">
  </mat-drawer>
  <div fxLayout="column">
    <div fxLayout="column" class="downloadReports">
      <div class="icon-face">
        <button mat-button (click)="openModalDownloadReportOffert()">
          <mat-icon class="material-icons">cloud_download</mat-icon>
        </button>

      </div>
      <label class="icon-face-text">
        Da clic al icono para descargar un reporte completo de las ofertas cargadas en su versión actualizada.
      </label>
    </div>
  </div>
</mat-drawer-container>