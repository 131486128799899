<h2 mat-dialog-title>{{'secure.billing.send_payment_information' | translate}}</h2>
<mat-dialog-content>

  <form #f="ngForm" [formGroup]="myform" class="form-login">
    <div fxLayout="column" fxLayoutGap="20px">
      <div fxLayout="row" fxFlex="100">
        <mat-form-field fxFlex="100" class="input-style">

          <!-- Email imput -->
          <input
            name="email"
            formControlName="email"
            matInput [placeholder]="'secure.seller.register_seller.lb_email_form' | translate"
            type="email"
            id="emailPago"
            required>
          <!-- /Email imput -->

          <!-- Clean email input -->
          <button
            disabled="{{!f.form.dirty}}"
            matSuffix
            type="button"
            color="primary"
            mat-icon-button
            (click)="createForm()">            
            <mat-icon [matTooltip]="'secure.offers.historical_admin.download_historical.tooltip_email' | translate" matTooltipPosition="below" matSuffix>
              autorenew
            </mat-icon>
          </button>
          <!-- Clean email input -->
          
          <!-- Error message -->
          <mat-error>{{'errors.blank_email' | translate}}</mat-error>
          <!-- /Error message -->

        </mat-form-field>
      </div>
    </div>
  </form>

</mat-dialog-content>
<mat-dialog-actions class="dialog-buttons">

  <!-- Cancel button -->
  <button class="sc-button" mat-button [mat-dialog-close]="true">{{'actions.cancel' | translate}}</button>
  <!-- /Cancel button -->

  <!-- Send historical button -->
  <button
    class="sc-button"
    [disabled]="!f.form.valid"
    (click)="downloadPay(myform)"
    mat-raised-button color="accent">{{'secure.billing.send_payment' | translate}}
  </button>
  <!-- /Send historical button -->

</mat-dialog-actions>
