import { Component, OnInit, ViewChild } from '@angular/core';
import { CoreState } from '@app/store';
import { SetHeaderTitle } from '@app/store/configuration/actions';
import { Store } from '@ngrx/store';
import { ModalComponent } from './modal/modal.component';

@Component({
  selector: 'app-enrollment',
  templateUrl: './enrollment.component.html',
  styleUrls: ['./enrollment.component.scss']
})
export class EnrollmentComponent implements OnInit {
  public tabIndex: number = 0;

  constructor(private store: Store<CoreState>) { }

  ngOnInit() {
    this.setOrdersTitle();
  }

  private setOrdersTitle() {
    this.store.dispatch(new SetHeaderTitle({
      title: 'Fulfillment',
      subtitle: 'Inscripción productos',
      description: 'Gestiona los productos que deseas incluir como fulfillment'
    }));
  }

  public changeTab(index: number): void {
    this.tabIndex = index;
  }
}
