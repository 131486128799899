<div class="product-size-slider" *ngIf="shippingSizeOptions">
  <ng-container *ngFor="let option of shippingSizeOptions; let i = index;">
    <div
      role="button"
      class="product-size-slider__option"
      [class.product-size-slider__option--selected]="isOptionSelected(i)"
      (click)="onSelectOption(option, i)"
    >
      <span class="material-symbols-outlined">{{ option.icon }}</span>
    </div>
    <div class="product-size-slider__range"
         [class.product-size-slider__range--selected]="isRangeSelected(i)"
    ></div>
  </ng-container>
</div>
