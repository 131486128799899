<div class="sh-case-detail">
  <div class="sh-case-detail__item ticket-detail ticket-detail--col">

    <div fxHide.gt-xs="true">

      <label>{{'secure.parametize.support_drop-down-reason-detail' | translate}}</label>
      <p>{{case.reasonDetail}}</p>

      <label>{{'secure.parametize.support_drop-down-creation-date' | translate}}</label>
      <p>{{case.createDate | date: 'dd/MM/yyyy'}}</p>

      <label>{{'secure.parametize.support_drop-down-status' | translate}}</label>
      <p>{{case.status}}</p>

      <label>{{'secure.parametize.support_drop-down-last-reply' | translate}}</label>
      <p>{{ case.lastPostDesc }}</p>

    </div>


    <app-basic-card class="sh-case-detail--bottom-space"
      [title]="translateService.instant('secure.parametize.support_claims.description')"
      [description]="case.description">
    </app-basic-card>
  </div>

  <button fxHide.gt-xs="true" class="btn-no-bg" mat-stroked-button color="primary" type="button"
    (click)="showThumbnail(case)">
    {{'secure.parametize.support.see.product' | translate}}
  </button>

  <div class="sh-case-detail__item ticket-detail ticket-detail--col" *ngIf="case.descriptionSolution">
    <br>
    <app-basic-card class="sh-case-detail--bottom-space"
      [title]="translateService.instant('secure.parametize.support_claims-details.detail-of-solution')"
      [description]="case.descriptionSolution"></app-basic-card>
  </div>

  <div class="sh-case-detail__attachments">
    <br>
    <div class="sh-case-detail__title">
      <mat-icon class="sh-case-detail__icon">
        attach_file
      </mat-icon>
      <span>{{'secure.parametize.support_claims-details.attachments' | translate}}</span>
    </div>
    <div class="sh-case-detail__attachments-files">
      <div class="">
        <a *ngFor="let attach of case.attachments; let i = index" id="attach-{{ i }}" target="_blank"
          href="{{attach.url}}" class="attachment-link">
          {{ attach.name }}
        </a>
      </div>
    </div>
  </div>

  <div class="sh-case-detail__item ticket-detail--col">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <span class="sh-case-detail__title sh-case-detail--bottom-space">
        {{'secure.parametize.support_claims.tracing'|translate}}
      </span>

      <div class="titleTimeReply" fxShow.xs="false" fxShow.sm="false">
        {{'secure.parametize.support_drop-down-time-reply-seller'|translate}}
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between start">
      <app-conversation class="classConversation">
        <div *ngIf="!case.follow || case.follow.length == 0">
          <app-conversation-message *itemConversation class="sh-case-detail--full-width" style="opacity: 0.7;"
            [message]="translateService.instant('secure.parametize.support_claims.no-tracing')">
          </app-conversation-message>
        </div>

        <div *ngFor="let follow of case.follow; let i = index">
          <app-conversation-message *itemConversation class="sh-case-detail--full-width" [name]="follow.userName"
            [message]="follow.description" [date]="follow.createDate" (replyEvent)="openResponseDialog()"
            [disabledClass]="isClosed" [configuration]="{reply: i == 0, theme: 'ALTER'}" [caseId]="case.caseNumber">
          </app-conversation-message> 
        </div>
      </app-conversation>

      <div class="chip_hours" fxShow.xs="false" fxShow.sm="false">
        <span >
          {{case.responseTime}} {{'secure.parametize.support.hours' | translate}}
        </span>
      </div>

    </div>

  </div>
</div>
