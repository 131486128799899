<div class="delete-module">

  <div fxLayout="row" fxLayoutAlign="end end"><mat-icon class="material-icons close" (click)="close()"> close </mat-icon></div>

  <div fxLayout="row" fxLayoutAlign="center center">
    <div class="container-icon" fxLayout="column" fxLayoutAlign="center center">
      <mat-icon class="material-icons">
        delete
      </mat-icon>
    </div>
  </div>

  <h2 class="center-text bold" mat-dialog-title>
    {{'school.exito.delete.item.module.title' | translate}}
  </h2>

  
  <div mat-dialog-content class="form-container">

      <p>{{'school.exito.delete.item.module.description' | translate}}</p>

    </div>

    <div mat-dialog-actions class="mat-buttons" fxLayout="row" fxLayoutAlign="center center">
      <button mat-stroked-button class="btn-transparent" type="button" id="btn-delete-submodule" color="primary" (click)="close()">{{'actions.cancel' | translate}}</button>
      <button class="uppercase" id="btn-delete-submodule" mat-raised-button color="primary"(click)="deleteSubModule()" >{{'actions.delete' | translate}}</button>
    </div>

</div>