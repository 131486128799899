<header class="header">
  <div class="header__title">
    <h1 class="title-container">
      <span class="title-container__title" *ngIf="title">{{ title | translate | titlecase }}</span>
      <span class="title-container__subtitle" *ngIf="subtitle">| {{ subtitle | translate | titlecase}}</span>
    </h1>
    <p class="section-description" *ngIf="description && showDescription">
      {{ description | translate }}
      <!-- Añadir el ícono de la X de google fonts -->
      <mat-icon class="close-icon" (click)="toggleDescription(false)">close</mat-icon>
      <span class="material-symbols-rounded close-icon" (click)="toggleDescription(false)">close</span>
    </p>
  </div>

  <app-floating-header class="app-floating-header"></app-floating-header>
</header>