<mat-drawer-container class="example-container" autosize>
  <mat-drawer #drawer [mode]="'over'" [position]="'end'" class="example-sidenav" [disableClose]="true">

    <form #f="ngForm" [formGroup]="myform" class="search-form light-content">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div class="filter-header">
          <span class="filter-header--title">
            {{'secure.orders.bulk.billing.name_filter_billing'| translate}}
          </span>
        </div>
        <button id="closeMenuApplication" type="button" color="primary" [matTooltip]="'actions.close' | translate"
          matTooltipPosition="below" mat-icon-button mat-button (click)="drawer.toggle()">
          <mat-icon>clear</mat-icon>
        </button>
      </div>
      <div fxLayout="column" fxLayoutAlign="space-between" fxLayout.xs="column">
        <!-- PaymentDateInitial -->
        <mat-form-field id="paymentDate-input-search-order" color="accent">
          <input id="input-filter-paymentDateInitial" [max]="myform.value.paymentDateFinal"
            formControlName="paymentDateInitial"
            [placeholder]="'core.shell.search_order_menu.search_billing_form.initial_date' | translate" matInput
            [matDatepicker]="paymentDateInitial">
          <mat-datepicker-toggle id="paymentDate-Perfix" matPrefix [for]="paymentDateInitial"></mat-datepicker-toggle>
          <mat-datepicker touchUi="true" startView="year" #paymentDateInitial></mat-datepicker>
        </mat-form-field>

        <!-- PaymentDateFinal -->
        <mat-form-field id="paymentDate-input-search-order" color="accent">
          <input id="input-filter-paymentDateFinal" [min]="myform.value.paymentDateInitial"
            formControlName="paymentDateFinal"
            [placeholder]="'core.shell.search_order_menu.search_billing_form.final_date' | translate" matInput
            [matDatepicker]="paymentDateFinal">
          <mat-datepicker-toggle id="paymentDate-Perfix" matPrefix [for]="paymentDateFinal"></mat-datepicker-toggle>
          <mat-datepicker touchUi="true" startView="year" #paymentDateFinal></mat-datepicker>
        </mat-form-field>

        <!-- BillingNumber -->
        <mat-form-field id="billingNumber-input-search-order" class="example-full-width" color="accent">
          <mat-icon matPrefix color="accent">format_list_bulleted</mat-icon>
          <input id="input-filter-billingNumber" formControlName="billingNumber" type="text" matInput
            [placeholder]="'core.shell.search_order_menu.search_billing_form.invoice_number' | translate">
        </mat-form-field>

        <!-- OrderNumber -->
        <mat-form-field id="orderNumber-input-search-order" class="example-full-width" color="accent">
          <mat-icon matPrefix color="accent">format_list_bulleted</mat-icon>
          <input id="input-filter-orderNumber" formControlName="orderNumber" type="text" pattern="[0-9.,]+" matInput
            [placeholder]="'secure.parametize.support_drop-down-order-numer' | translate" (keyup.enter)="saveKeyword()">
          <mat-icon matSuffix
            [matTooltip]="'core.shell.search_order_menu.search_billing_form.tooltip_pedido' | translate"
            [ngClass]="invalidOrder ? 'disabledIconAddKeyCategoryFilter': ''"
            class="material-icons add-keyword-icon vertical-align-top" (click)="saveKeyword()">
            add_circle_outline
          </mat-icon>
        </mat-form-field>
        <div class="chip-list-billingOrder">
          <mat-chip-list #chipList>
            <mat-chip *ngFor="let key of keywords; let i = index" (removed)="deleteKeywork(i)">
              {{key}}
              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
          </mat-chip-list>
        </div>
      </div>


      <div class="orders-search-option normal-theme">
        <button id="clear-filter-button" class="sc-button" mat-button type="button" (click)="clearForm()">
          <mat-icon>clear_all</mat-icon>
          {{'filters.clear' | translate}}
        </button>
        <button id="apply-filter-button" [disabled]="!f.form.valid || !f.form.touched" class="sc-button"
          mat-raised-button color="primary" type="button" (click)="drawer.toggle() && filterOrder()">
          {{'actions.apply' | translate}}
        </button>
      </div>
    </form>
  </mat-drawer>
</mat-drawer-container>



<div class="billing">
  <div class="billing__header">
    <!-- Toolbar para los dropdown de filtro de estado para las órdenes -->
    <!-- Toolbar de las opciones para los pagos -->
    <app-toolbar-search-pagination #toolbarOptions [showBtn]="false" [btnFilter]="false"
      (paginationListOrdens)="paginations($event)" [billingType]="true" [Typeprofile]="typeProfile"
      [informationToForm]="informationToForm" [downloadBillingPay]="false" [limitPaginate]="[50, 100, 200]"
      [lengthOrder]="length" (OnGetOrdersList)="getOrdersList($event)"
      (OnChangeSizeOrderTable)="changeSizeOrderTable($event)">
    </app-toolbar-search-pagination>
    <div class="filterBilling">
      <button type="button" mat-button (click)="openModalDownloadBillPay()">
        <mat-icon>file_download</mat-icon>
        <small class="sub-title-filter">{{'actions.download' | translate}}</small>
      </button>
      <button type="button" mat-button (click)="drawer.toggle()">
        <mat-icon>filter_list</mat-icon>
        <small class="sub-title-filter">{{'filters.filter' | translate}}</small>
      </button>
    </div>
    <div class="center-product-list-2">
      <button id="more-options-for-search-orden" class="margin-toolbar-aling" mat-icon-button *ngIf="!billingType"
        [matMenuTriggerFor]="toolbarOptions">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #toolbarOptions="matMenu">
        <div class="options-dropdwon">
          <button id="close-more-options-for-search-orden" color="primary" mat-icon-button matSuffix>
            <mat-icon>close</mat-icon>
          </button>
        </div>
        <button id="search-order-button" mat-menu-item (click)="drawer.toggle()">
          <mat-icon>filter_list</mat-icon>
          <span class="acction-text">{{'filters.filter' | translate}}</span>
        </button>
        <button [disabled]="orderListLength" class="acction-text" (click)="openModalDownloadBillPay()"
          id="download-order-button" mat-menu-item>
          <mat-icon>file_download</mat-icon>
          <span>{{'actions.download' | translate}}</span>
        </button>
      </mat-menu>
    </div>

    <!-- Toolbar de las opciones para los pagos -->
  </div>

  <!-- Tabla de pagos -->
  <div fxFlex="100" class="content-table-orders">

    <!-- matSort: Permite filtrar la tabla por los titulos de las columnas -->
    <mat-table #table [dataSource]="dataSource" matSort>
      <!-- expand Column -->
      <ng-container matColumnDef="expand">
        <mat-header-cell fxFlex.lg="50px" fxFlex="70px" *matHeaderCellDef mat-sort-header>
        </mat-header-cell>
        <mat-cell fxFlex="70px" fxFlex.lg="50px" *matCellDef="let element;let index=index">
          <button id="expand-more-{{index}}" mat-icon-button>
            <mat-icon class="expand" style="font-weight: bold;" color="primary">expand_more</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <!-- bill Column -->
      <ng-container matColumnDef="bill">
        <mat-header-cell fxFlex.lt-md="calc(50% - 180px)" fxFlex.xs="calc(80% - 180px)" fxFlex="20%" *matHeaderCellDef
          mat-sort-header>
          <span class="title-table-header">{{'secure.billing.invoice-note' | translate}}</span>
        </mat-header-cell>
        <mat-cell fxFlex.lt-md="calc(50% - 180px)" fxFlex.xs="calc(80% - 180px)" fxFlex="20%" *matCellDef="let element">
          <p class="sc-text-bold-order">{{ element.billingNumber }}</p>
        </mat-cell>
      </ng-container>

      <!-- numberOrder Column -->
      <ng-container matColumnDef="numberOrder">
        <mat-header-cell fxFlex.lt-md="calc(50% - 180px)" fxShow.xs="false" fxFlex="20%" *matHeaderCellDef
          mat-sort-header>
          <span class="title-table-header">{{'secure.billing.order_number' | translate}}</span>
        </mat-header-cell>
        <mat-cell fxFlex.lt-md="calc(50% - 180px)" fxShow.xs="false" fxFlex="20%" *matCellDef="let element">
          <p class="sc-text-normal bold">{{ element.orderNumber || '------' }}</p>
        </mat-cell>
      </ng-container>

      <!-- orderPayment Column -->
      <ng-container matColumnDef="orderPayment">
        <mat-header-cell fxShow.xs="false" [ngClass]="isInternational ? 'hiddenColumn' : 'showColumn'" fxShow.sm="false"
          fxShow.lg="true" fxShow.xl="true" fxShow="true" fxFlex.sm="120px" fxFlex.md="15%" fxFlex.lg="15%"
          fxFlex.xs="50%" *matHeaderCellDef mat-sort-header>
          <span class="title-table-header">{{'secure.billing.payment_number' | translate}}</span>
        </mat-header-cell>
        <mat-cell fxShow.xs="false" [ngClass]="isInternational ? 'hiddenColumn' : 'showColumn'" fxShow.sm="false"
          fxShow.lg="true" fxShow.xl="true" fxShow="true" fxFlex.sm="120px" fxFlex.md="15%" fxFlex.lg="15%"
          fxFlex.xs="50%" *matCellDef="let element">
          <p class="sc-text-normal bold">{{ element.payOrderNumber || '------' }}</p>
        </mat-cell>
      </ng-container>

      <!-- concept Column -->
      <ng-container matColumnDef="concept">
        <mat-header-cell fxShow.xs="false" fxShow.sm="false" fxShow.lg="true" fxShow.xl="true" fxShow="true"
          fxFlex.sm="120px" fxFlex.md="15%" fxFlex.lg="15%" fxFlex.xs="50%" *matHeaderCellDef mat-sort-header>
          <span class="title-table-header">{{'secure.billing.concept' | translate}}</span>
        </mat-header-cell>
        <mat-cell fxShow.xs="false" fxShow.sm="false" fxShow.lg="true" fxShow.xl="true" fxShow="true" fxFlex.sm="120px"
          fxFlex.md="15%" fxFlex.lg="15%" fxFlex.xs="50%" *matCellDef="let element">
          {{ 'secure.billing.'+ element.concept | translate}}
          <p class="sc-text-normal"></p>
        </mat-cell>
      </ng-container>

      <!-- paymentDate Column -->
      <ng-container matColumnDef="paymentDate">
        <mat-header-cell fxShow.xs="false" [ngClass]="isInternational ? 'hiddenColumn' : 'showColumn'" fxShow.sm="false"
          fxShow.lg="true" fxShow.xl="true" fxShow="true" fxFlex.sm="120px" fxFlex.md="15%" fxFlex.lg="15%"
          fxFlex.xs="50%" *matHeaderCellDef mat-sort-header>
          <span class="title-table-header">{{'secure.billing.payment_date' | translate}}</span>
        </mat-header-cell>
        <mat-cell fxShow.xs="false" [ngClass]="isInternational ? 'hiddenColumn' : 'showColumn'" fxShow.sm="false"
          fxShow.lg="true" fxShow.xl="true" fxShow="true" fxFlex.sm="120px" fxFlex.md="15%" fxFlex.lg="15%"
          fxFlex.xs="50%" *matCellDef="let element">
          <p class="sc-date-content-table">{{ element.paymentDate | date }} </p>
        </mat-cell>
      </ng-container>

      <!-- transfer request date Column -->
      <ng-container matColumnDef="transferRequestDate">
        <mat-header-cell fxShow.xs="false" [ngClass]="isInternational ? 'showColumn' : 'hiddenColumn'" fxShow.sm="false"
          fxShow.lg="true" fxShow.xl="true" fxShow="true" fxFlex.sm="120px" fxFlex.md="15%" fxFlex.lg="15%"
          fxFlex.xs="50%" *matHeaderCellDef mat-sort-header>
          <span class="title-table-header text-aling-right">{{'secure.billing.transfer_request_date' | translate}}
          </span>
        </mat-header-cell>
        <mat-cell fxShow.xs="false" [ngClass]="isInternational ? 'showColumn' : 'hiddenColumn'" fxShow.sm="false"
          fxShow.lg="true" fxShow.xl="true" fxShow="true" fxFlex.sm="120px" fxFlex.md="15%" fxFlex.lg="15%"
          fxFlex.xs="50%" *matCellDef="let element">
          <p class="sc-date-content-table">{{ element.transferRequestDate | date }} </p>
        </mat-cell>
      </ng-container>

      <!-- commission Column -->
      <ng-container matColumnDef="commission">
        <mat-header-cell fxShow.lt-lg="false" fxFlex="10%" *matHeaderCellDef mat-sort-header>
          <span class="title-table-header">{{'shared.commission' | translate}}</span>
        </mat-header-cell>
        <mat-cell fxShow.lt-lg="false" fxFlex="10%" *matCellDef="let element">
          <p class="sc-text-normal"
            [ngClass]="element.concept !== 'Nota crédito' ? 'sc-commission-text' : 'sc-valueToPay-text'">
            {{ element.commission | currency:'COP' }}</p>
        </mat-cell>
      </ng-container>

      <!-- Iva Column -->
      <ng-container matColumnDef="iva">
        <mat-header-cell fxShow.lt-lg="false" fxFlex="10%" [ngClass]="isInternational ? 'hiddenColumn' : 'showColumn'"
          *matHeaderCellDef mat-sort-header>
          <span class="title-table-header">{{'secure.billing.iva' | translate}}</span>
        </mat-header-cell>
        <mat-cell fxShow.lt-lg="false" fxFlex="10%" [ngClass]="isInternational ? 'hiddenColumn' : 'showColumn'"
          *matCellDef="let element">
          <p class="sc-text-normal"
            [ngClass]="element.concept !== 'Nota crédito' ? 'sc-commission-text' : 'sc-valueToPay-text'">
            {{ element.iva | currency:'COP' }}</p>
        </mat-cell>
      </ng-container>

      <!-- valueToPay Column -->
      <ng-container matColumnDef="valueToPay">
        <mat-header-cell fxShow.lt-md="false" fxFlex="15%" *matHeaderCellDef mat-sort-header>
          <span class="title-table-header">{{'secure.billing.amount_pay' | translate}}</span>
        </mat-header-cell>
        <mat-cell fxShow.lt-md="false" fxFlex="15%" *matCellDef="let element">
          <p class="sc-text-normal"
            [ngClass]="element.concept === 'Nota crédito' ? 'sc-commission-text' : 'sc-valueToPay-text'">
            {{ element.billingTotal | currency:'COP' }}
          </p>
          <p class="sc-text-normal" *ngIf="element.concept === billingConcepts.logisticsExito">
            ------
          </p>
        </mat-cell>
      </ng-container>

      <!-- ImprimirRecibo Column -->
      <ng-container matColumnDef="detailOrder">
        <mat-header-cell class="center-product-list" fxShow="true" fxShow.gt-sm="false" fxFlexFill.xs *matHeaderCellDef>
          <span class="title-table-header">{{'shared.actions' | translate}}</span>
        </mat-header-cell>
        <mat-cell fxShow="true" fxShow.gt-sm="false" fxFlexFill.xs *matCellDef="let element;let index=index;">
          <div class="center-product-list">
            <button id="dropdown-button-order-{{index}}" color="primary" (click)="stopPropagation($event)"
              class="sc-button-toolbar button-toolbar" mat-button [matMenuTriggerFor]="orderOptions">
              <mat-icon matPrefix>arrow_drop_down</mat-icon>
              <span>{{'shared.actions' | translate}}</span>
            </button>
            <mat-menu #orderOptions="matMenu">
              <button id="viewDetail-button-order-{{index}}" fxShow.xs="true" fxShow.sm="true" fxShow.gt-lg="false"
                fxShow="false" (click)="openModalDetailOrder(element)" mat-menu-item>
                <mat-icon>more_vert</mat-icon>
                <span>{{'actions.show_detail' | translate}}</span>
              </button>
            </mat-menu>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

      <mat-row id="order-{{index}}" *matRowDef="let index=index;let row; columns: displayedColumns;" matRipple
        class="element-row personal-row-table" [cdkDetailRow]="row" [cdkDetailRowTpl]="tpl">
      </mat-row>
    </mat-table>

    <div [@detailExpand] class="orders-no-data" *ngIf="noData && !activeSearch">
      <mat-icon class="icon-not-found" color="primary">info</mat-icon>
      <p>{{'secure.billing.no_payment' | translate}}
      </p>
      <h3 fxShow.xs="false" fxShow.sm="true" fxShow.md="true" fxShow.lg="true" fxShow.xl="true" fxShow="true"
        class="sc-title title-toolbar">
        {{'secure.billing.refresh_payment' | translate}}
      </h3>

    </div>
    <div [@detailExpand] class="orders-no-data" *ngIf="noData && activeSearch">
      <mat-icon class="icon-not-found" color="primary">info</mat-icon>
      <p>{{'secure.billing.no_payment' | translate}}
      </p>
      <h2 fxShow.xs="false" fxShow.sm="true" fxShow.md="true" fxShow.lg="true" fxShow.xl="true" fxShow="true"
        class="sc-title title-toolbar">
        {{'secure.billing.no_data_admin' | translate}}
      </h2>
    </div>
  </div>

  <ng-template #tpl let-element>
    <div class="content-detail-order mat-row " [@detailExpand] style="overflow: hidden ">
      <div fxFlex="100" fxLayout="column">
        <!-- Product Information -->
        <app-billing-products-order [isInternational]="isInternational"
          *ngIf="element.concept === billingConcepts.marketplaceSale || element.concept === billingConcepts.noteCredit"
          [order]="element">
        </app-billing-products-order>
        <!-- Detail fulfillment -->
        <app-billing-fulfillment-detail *ngIf="element.concept === billingConcepts.logisticsExito" [data]="element">
        </app-billing-fulfillment-detail>
      </div>
    </div>
  </ng-template>

</div>