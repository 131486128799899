import { Action } from '@ngrx/store';

const NAMESPACE = 'USER MODULES';
const withNamespace = (action: String) => `${NAMESPACE} - ${action}`;

export const Types = {
  FetchModulesDone: withNamespace('Fetch modules done'),
  GetSellerData: withNamespace('Get seller data')
};

export class FetchModulesDone implements Action {
  readonly type = Types.FetchModulesDone;
  constructor(public payload: Array<any>) { }
}

export class GetSellerData implements Action {
  readonly type = Types.GetSellerData;
  constructor(public payload: any) { }
}

export type ActionsUnion = FetchModulesDone;
