<div class="cont-product">

  <div class="info-product" fxShow.xs="true" fxShow="false">
    <div class="subtitle divider-two">
      <h3>{{productsExpanded.name}}</h3>
      <p class="title divider-two">{{'secure.offers.list.list.EAN_offert' | translate}} {{productsExpanded.ean}}</p>
    </div>
  </div>

  <div class="cont-images">
    <div class="smallImages">
      <!-- for que contiene el arreglo de las imagenes -->
      <mat-card class="image-card" *ngFor="let img of images">
        <ng-container *ngIf="img.max">
          <!-- cargo la url de la imagen en el src y el evento click q llama la funcion con el parametro de la img grande -->
          <div (click)="changeImage(img.max, img)" class="imageXs" [ngClass]=" (imageMax == img.max) ? 'activeImage' : ''">
            <img mat-card-image [src]="img.max" alt="X" [ngClass]=" (imageMax == img.max) ? 'border-gray' : ''">
          </div>
        </ng-container>
      </mat-card>
    </div>
    <div>

      <div class="imageXl" *ngIf="showImage">
        <img [src]="imageMax" alt="Imagen no disponible">
      </div>

      <div class="video imageXl" *ngIf="productsExpanded.hasVideoUrl && showVideo">
        aquii: {{productsExpanded.videoUrl}}
        <iframe width="480" height="315" [src]="productsExpanded.videoUrl | safe: 'resourceUrl'" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>

    </div>
  </div>

  <mat-divider vertical="true"></mat-divider>
  <div class="info-product" fxLayout="column" fxLayoutAlign="space-around none">
    <div class="subtitle divider-two" fxShow.xs="false">
      <div fxFlex fxLayout="row wrap" fxLayoutAlign="center start"
        class="max-height-list-product expanded-product-margin-max-height">
        <div fxFlex>
          <h3>{{'secure.offers.list.list.EAN_offert' | translate}} {{productsExpanded.ean}}</h3>
          <h3>{{'secure.dashboard.table.top.title.SKU' | translate}}: {{productsExpanded.pluVtex}}</h3>
        </div>
        <div fxFlex fxLayoutAlign="end center" class="expanded-product-height-edit-icon" *ngIf="productsExpanded">
          <button class="button-edit" type="button" color="primary" matTooltip="Editar"
            *ngIf="editPermission && productsExpanded.isMarketplace" matTooltipPosition="below" mat-icon-button
            mat-button (click)="editProduct(productsExpanded)">
            <mat-icon class="flexbox align-center justify-center">edit</mat-icon>
          </button>
          <button *ngIf="isAdmin && deletePermission" class="button-edit" type="button" color="primary"
            matTooltip="Eliminar" matTooltipPosition="below" mat-icon-button mat-button
            (click)="deleteProduct(productsExpanded)">
            <mat-icon class="flexbox align-center justify-center">delete</mat-icon>
          </button>
        </div>
      </div>
    </div>
 
    <div>
      <p class="title divider-two">{{productsExpanded.name}}</p>
    </div>
    <div *ngIf="productsExpanded.reference !=='' && productsExpanded.reference !== null">
      {{'secure.products.table_load_product.parent_reference' | translate}}: {{productsExpanded.reference}}
    </div> 
    <div class="divider-one">
      <p> <span
          class="color-gray">{{'secure.products.create_product_unit.list_products.expanded_product.creation_date' | translate}}:</span>
        {{productsExpanded.createdDate}}</p>
      <span class="separator"> | </span>
      <p> <span
          class="color-gray">{{'secure.products.create_product_unit.list_products.expanded_product.modification_date' | translate}}:</span>
        {{productsExpanded.updatedDate}}</p>
    </div>

    <div class="text"> {{'shared.category' | translate}}: <span class="color-gray"></span> <label>
        {{productsExpanded.category}} </label></div>
    <div class="text">{{'shared.brand' | translate}}:<span class="color-gray"></span> <label> {{productsExpanded.brand}}
      </label></div>
    <div class="divider-one"> {{'secure.products.create_product_unit.basic_information.shipping_size' | translate}}:
      <span class="color-gray"></span> <label> {{productsExpanded.shipping_size}} </label></div>


    <div class="text" fxLayout="row">
      <div *ngIf="productsExpanded.colour.length !== 0">
        <i class="material-icons"> color_lens </i>
        {{'secure.products.create_product_unit.list_products.expanded_product.color' | translate}}:
        <div class="color-product" [ngStyle]="{'background': '#'+productsExpanded.colour}"></div>
      </div>
      <div *ngIf="productsExpanded.colour.length !== 0">
        <i class="material-icons"> style </i>
        {{'secure.products.create_product_unit.list_products.expanded_product.size' | translate}}:
        <label> {{productsExpanded.size}}</label>
      </div>
    </div>

    <div class="text">
      <i class="material-icons"> shopping_basket </i>
      {{'secure.products.create_product_unit.list_products.expanded_product.packaging_form' | translate}}:
      <label>
        {{'secure.products.create_product_unit.list_products.expanded_product.height' | translate}}-{{productsExpanded.package_height}}cm
        {{'secure.products.create_product_unit.list_products.expanded_product.width' | translate}}-{{productsExpanded.package_width}}cm
        {{'secure.products.create_product_unit.list_products.expanded_product.length' | translate}}-{{productsExpanded.package_length}}cm
        {{'secure.products.create_product_unit.list_products.expanded_product.weight' | translate}}-{{productsExpanded.package_weight}}kg
      </label>
    </div>

    <div class="text">
      <i class="material-icons"> local_shipping </i>
      {{'secure.products.create_product_unit.list_products.expanded_product.products' | translate}}
      <label>{{'secure.products.create_product_unit.list_products.expanded_product.height' | translate}}-{{productsExpanded.item_height}}cm
        {{'secure.products.create_product_unit.list_products.expanded_product.width' | translate}}-{{productsExpanded.item_width}}cm
        {{'secure.products.create_product_unit.list_products.expanded_product.length' | translate}}-{{productsExpanded.item_length}}cm
        {{'secure.products.create_product_unit.list_products.expanded_product.weight' | translate}}-{{productsExpanded.item_weight}}kg
      </label>
    </div>

    <div class="best-offer" fxLayout="row" fxLayoutAlign="space-between center"
      *ngIf="productsExpanded.bestOffer !== '0.00'">
      <p>{{'secure.products.create_product_unit.list_products.expanded_product.best_offer' | translate}}</p>
      <p [innerHTML]="productsExpanded.bestOffer | currency: '$' : 'symbol' : '1.2-2'"
        *ngIf="productsExpanded.bestOffer !== '0.00'">
      </p>
    </div>
  </div>
</div>

<mat-expansion-panel hideToggle="true" class="ofert-expanded" *ngIf="offerPermission"
  [disabled]="productsExpanded.availableToOffer === false">
  <mat-expansion-panel-header>
    <mat-panel-title class="title-ofert" fxLayout="row" fxLayoutAlign="center center">
      <div *ngIf="productsExpanded.availableToOffer === true" fxLayout="row" fxLayoutAlign="space-around center">
        <mat-icon matPrefix>add</mat-icon>
        <h3>
          {{'secure.products.create_product_unit.list_products.expanded_product.add_offer' | translate}}
        </h3>
      </div>
      <div *ngIf="productsExpanded.availableToOffer === false" fxLayout="row" fxLayoutAlign="space-around center">
        <mat-icon matPrefix>add_alert</mat-icon>
        <h3>
          {{'secure.products.create_product_unit.list_products.expanded_product.product_not_ready_offer' | translate}}
        </h3>
        <mat-icon matPrefix>add_alert</mat-icon>
      </div>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <app-ofert-expanded-product [productsExpanded]="productsExpanded" *ngIf="applyOffer" class="ofertProductExpanded"
    [applyOffer]="applyOffer"></app-ofert-expanded-product>
</mat-expansion-panel>

<div class="expanded-information-product">
  <mat-tab-group>
    <mat-tab [label]="'secure.products.create_product_unit.list_products.expanded_product.description' | translate">
      <p class="description-product" [innerHtml]="productsExpanded.description"></p>
    </mat-tab>
    <mat-tab [label]="'secure.products.create_product_unit.list_products.expanded_product.keywords' | translate"
      class="flex">
      <div class="keywords-products" *ngFor="let keywords of productsExpanded.keyword">
        <mat-chip>{{keywords}}</mat-chip>
      </div>
    </mat-tab>

    <mat-tab [label]="'secure.products.create_product_unit.list_products.expanded_product.specifications' | translate">
      <span class="no-specs" *ngIf="!productsExpanded.features  || !productsExpanded.features.length">
        {{'secure.products.create_product_unit.list_products.expanded_product.no_more_spedifications' | translate}}
      </span>
      <div class="container">
        <div class="container-sub" *ngFor="let spec of productsExpanded.features">
          <div class="container-specs_title">
            {{spec.key}}
          </div>
          <div class="container-specs_value">
            {{spec.value}}
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<div class="expanded-information-product2">
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{'shared.descripcion' | translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-form-field>
        <p class="description-product" [innerHtml]="productsExpanded.description"></p>
        <input matInput placeholder="Age">
      </mat-form-field>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{'secure.products.create_product_unit.list_products.expanded_product.keywords' | translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="keywords-products" *ngFor="let keywords of productsExpanded.keyword">
        <mat-chip>{{keywords}}</mat-chip>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{'secure.products.create_product_unit.list_products.expanded_product.specifications' | translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <span *ngIf="!productsExpanded.features  || !productsExpanded.features.length">
        {{'secure.products.create_product_unit.list_products.expanded_product.no_more_spedifications' | translate}}
      </span>
      <div class="container">
        <div class="container-sub" *ngFor="let spec of productsExpanded.features">
          <div class="container-specs_title">
            {{spec.key}}
          </div>
          <div class="container-specs_value">
            {{spec.value}}
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
