import {Component, Output, EventEmitter, ViewChild, ElementRef} from '@angular/core';

@Component({
  selector: 'app-upload-product-image-url',
  templateUrl: './upload-product-image-url.component.html',
  styleUrls: ['./upload-product-image-url.component.scss']
})
export class UploadProductImageUrlComponent {
  @ViewChild('imageUrlInput', {static: false}) imageUrlInput!: ElementRef;
  @Output() uploadImageUrl: EventEmitter<string>;
  imageUrl: string;

  constructor() {
    this.uploadImageUrl = new EventEmitter<string>();
  }

  onImageUrl(): void {
    this.imageUrl = this.imageUrlInput.nativeElement.value;
  }
  sendImage(): void {
    this.uploadImageUrl.emit(this.imageUrl);
    this.imageUrl = '';
    this.imageUrlInput.nativeElement.value = '';
  }
}
