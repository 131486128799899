<section class="floating-header" (clickOutside)="onClickedOutside()">
    <div class="floating-header__options">
        <button (click)="toggleMenu();onClickedOutside()" class="option-button option-button__menu">
            <span class="material-symbols-rounded option-button__icon">menu</span>
        </button>
        <button (click)="openMenu(1)" class="option-button option-button__language"
            [matTooltip]="('profile.language' | translate)" [matTooltipDisabled]="menuOpened === 1"
            [ngClass]="{'active':menuOpened === 1}" matTooltipClass="white-text">
            <span class="material-symbols-rounded option-button__icon">translate</span>
            <span class="material-symbols-rounded option-button__arrow"
                [ngClass]="{'up-direction':menuOpened === 1}">keyboard_arrow_down</span>
        </button>
        <button (click)="openMenu(2)" class="option-button" [ngClass]="{'active':menuOpened === 2}"
            [matTooltip]="('profile.notifications' | translate)" [matTooltipDisabled]="menuOpened === 2"
            matTooltipClass="white-text">
            <span class="option-button__dot" *ngIf="showNotificationDot"></span>
            <span class="material-symbols-rounded option-button__icon">notifications</span>
            <span class="material-symbols-rounded option-button__arrow"
                [ngClass]="{'up-direction':menuOpened === 2}">keyboard_arrow_down</span>
        </button>
        <button (click)="openMenu(3)" class="option-button {{currentStateClass}}"
            [ngClass]="{'active':menuOpened === 3}" [disabled]="user.Profile !== 'seller' || user.Status === 'Disable'"
            [matTooltip]="('shared.status' | translate)" [matTooltipDisabled]="menuOpened === 3"
            matTooltipClass="white-text">
            <span class="option-button__dot-state"></span>
            <span class="option-button__text">
                {{ currentState | translate }}
            </span>
            <span class="material-symbols-rounded option-button__arrow"
                *ngIf="user.Profile === 'seller' && user.Status !== 'Disable'"
                [ngClass]="{'up-direction':menuOpened === 3}">keyboard_arrow_down</span>
        </button>
        <button (click)="openMenu(4)" class="option-button option-button--image"
            [ngClass]="{'active':menuOpened === 4,'seller': user.Profile === 'seller'}"
            [matTooltip]="('secure.aws_cognito.profile.profile' | translate)" [matTooltipDisabled]="menuOpened === 4"
            matTooltipClass="white-text" #tooltip="matTooltip">
            <span class="initials-username">
                {{ shortName }}
            </span>
            <span class="material-symbols-rounded option-button__arrow"
                [ngClass]="{'up-direction':menuOpened === 4}">keyboard_arrow_down</span>
        </button>
    </div>

    <div class="floating-header__menu" [ngClass]="{'opened':menuOpened}">
        <ng-container [ngSwitch]="menuOpened">
            <app-select-language *ngSwitchCase="1" class="floating-header__menu-language"></app-select-language>
            <app-notification-list *ngSwitchCase="2"></app-notification-list>
            <app-profile-status *ngSwitchCase="3" (closeMenu)="onClickedOutside()"></app-profile-status>
            <app-profile-menu *ngSwitchCase="4" (closeMenu)="onClickedOutside()"></app-profile-menu>
        </ng-container>
    </div>

</section>