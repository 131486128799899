<section *ngIf="status" class="modal">
  <mat-dialog-content class="modal__content">
    <header class="modal__header">
      <h3>{{ title }}</h3>
    </header>
    <p>{{ message }}</p>
  </mat-dialog-content>
  <mat-dialog-actions class="modal__actions">
    <button mat-button mat-dialog-close class="modal__actions__cancel">Cancelar</button>
    <button mat-button [mat-dialog-close]="true" class="modal__actions__confirm">Confirmar</button>
  </mat-dialog-actions>
</section>