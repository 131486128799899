<form #f="ngForm" [formGroup]="myForm" fxLayoutGap="10px" fxLayout="row wrap" fxFlex.xs="100%" fxLayoutGap="10px" fxFlex.sm="100%"
  fxFlex.gt-sm="100%">
  <div fxFlex.gt-sm="250px" fxFlex.xs="200px">
    <div class="center-product-list">
      <mat-form-field fxFlex.gt-sm="230px" fxFlex.xs="200px" class="select-send-options sc-text-normal" id="quantity-product" color="accent"  class="send-guide">
        <mat-select name="Transporter" required formControlName="Transporter" [placeholder]="'secure.orders.order_list.product_order.conveyor'| translate">
          <mat-option *ngFor="let trans of carries" [value]="trans.name">{{trans.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div fxFlex.gt-sm="230px" fxFlex.xs="200px">
    <div class="center-product-list">
      <mat-form-field fxFlex.gt-sm="230px" fxFlex.xs="200px">
        <input required name="Guide" class="textarea-guide" formControlName="Guide" matInput [placeholder]="'secure.orders.order_list.product_detail.guide' | translate" />
        <!-- <textarea required name="Guide" class="textarea-guide" formControlName="Guide" matInput [placeholder]="'secure.orders.order_list.product_detail.guide' | translate"></textarea> -->
        <mat-error>{{'errors.error_guide' | translate}}</mat-error>
      </mat-form-field>
    </div>

  </div>

  <div fxFlex.gt-sm="80px" fxFlex.xs="120px">
    <div class="center-product-list">
      <button type="submit" [disabled]="!f.form.valid || order.sendAllProduct" (click)="sendProduct(product,myForm)" class="full-button sc-button"
        mat-raised-button color="accent">
        {{'actions.send'| translate}}
      </button>
    </div>
  </div>
</form>
