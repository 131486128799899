import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';


@Component({
  selector: 'app-product-color-picker',
  templateUrl: './product-color-picker.component.html',
  styleUrls: ['./product-color-picker.component.scss']
})
export class ProductColorPickerComponent implements OnInit {
   @Input() colorNameControl: FormControl;
  @Input() colorSelectControl: FormControl;
  @Input() clothingColors: any[];
  @Output() colorSelected: EventEmitter<string>;
  optionSelected: any;

  constructor() {
    this.colorSelected = new EventEmitter<string>();
  }

  ngOnInit(): void {
     const initialColor = this.clothingColors.find(color => color.name === this.colorSelectControl.value);
    if (initialColor) {
      this.optionSelected = initialColor;
    }
  }

  selectColor(optionSelected: any): void {
    this.optionSelected = optionSelected;
    this.colorSelected.emit(this.optionSelected);
  }

  isSelected(option: any): boolean {
    return this.optionSelected && this.optionSelected.name === option.name;
  }

  isMulticolor(colorPickerOption: any): boolean {
    return colorPickerOption.name === 'MultiColor';
  }
}