<mat-form-field class="register-ports-field full-width" style="width:100% !important;"
  [formGroup]="validateFormRegister">
  <mat-select [placeholder]="'secure.seller.register_seller.lb_postal_ports' | translate"
    formControlName="portsFormControl" id="register-ports" required>
    <mat-option *ngIf="listPorts.length === 0" value="" [innerHTML]="'No hay puertos asociados a este país'">
    </mat-option>
    <mat-option *ngFor='let port of listPorts' [value]="port.Id" [innerHTML]="port.Name" (click)="setDataPort(port)">
    </mat-option>
  </mat-select>
  <mat-error id="error-ports-required" *ngIf="validateFormRegister.get('portsFormControl').hasError('required')"
    [innerHTML]="'Este campo es requerido.'"></mat-error>
</mat-form-field>
