import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-pager',
  templateUrl: './pager.component.html',
  styleUrls: ['./pager.component.scss']
})
export class PagerComponent {
  private _pagesNumber: number;
  public pagesArr: Array<number>;
  public ellipsisPager: boolean;

  @Input()
  set pagesNumber(value: number) {
    this._pagesNumber = value;
    this.ellipsisPager = value > 5;
    this.pagesArr =  Array.from({length: this.ellipsisPager ? 3 : value}, (_, i) => i + 1);
  }

  get pagesNumber(): number {
    return this._pagesNumber;
  }

  @Input() currentPage: number;

  @Output() pageEmitter: EventEmitter<number> = new EventEmitter();
  
  public changePage(index: number): void {
    if (index !== this.currentPage) {
      this.currentPage = index;
      this.ellipsisPager && this.updatePagesArray();
      this.pageEmitter.emit(index);
    }
  }
  
  public prevPage(): void {
    if (this.currentPage !== 1) {
      this.currentPage -= 1;
      this.ellipsisPager && this.updatePagesArray();
      this.pageEmitter.emit(this.currentPage);
    }
  }
  
  public nextPage(): void {
    if (this.currentPage !== this.pagesNumber) {
      this.currentPage += 1;
      this.ellipsisPager && this.updatePagesArray();
      this.pageEmitter.emit(this.currentPage);
    }
  }

  private updatePagesArray(): void {
    this.pagesArr = this.currentPage < this._pagesNumber - 4
    ? Array.from({length: 3}, (_, i) => i + this.currentPage)
    : Array.from({length: 5}, (_, i) => i + this._pagesNumber - 4);
  }
}
