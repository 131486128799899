<div class="stepper">
  <header class="stepper__bar">
    <button
      *ngFor="let step of steps; let i = index;"
      class="step"
      [class.step--active]="selectedIndex === i"
      [class.step--completed]="selectedIndex > i"
      (click)="onClick(i)"
    >
      <div class="step__index"
      [class.step__index--active]="selectedIndex === i"
      [class.step__index--completed]="selectedIndex > i"
      >
      {{ i + 1 }}
    </div>
      <div class="step__label"
      [class.step__label--active]="selectedIndex === i"
      [class.step__label--completed]="selectedIndex > i"
      >{{ step.label }}</div>
    </button>
  </header>
  <main class="stepper__content">
    <ng-container [ngTemplateOutlet]="selected.content"></ng-container>
  </main>
</div>
