import { Component, OnInit, Input, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AngularEditorConfig } from '@root/node_modules/@kolkov/angular-editor/lib/config';
import { debounceTime, distinctUntilChanged } from '@root/node_modules/rxjs/operators';
import { BasicInformationService } from '@secure/products/create-product-unit/basic-information/basic-information.component.service';
import { LoadingService } from '@app/core';
import { ValidationRegularExpression } from '../product-basic-information.component';
import { EanServicesService } from '@secure/products/create-product-unit/validate-ean/ean-services.service';
import { ProductCategory } from '@app/secure/products/models/product-creation.model';

@Component({
  selector: 'app-product-basic-information-form',
  templateUrl: './product-basic-information-form.component.html',
  styleUrls: ['./product-basic-information-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductBasicInformationFormComponent implements OnInit {
  @Input() basicInformationForm: FormGroup;
  @Input() validationRegularExpresions: ValidationRegularExpression[];
  @Input() BrandsRegex: any;
  @Input() isEditing: boolean;
  @Input() category: ProductCategory = {} as any;
  @ViewChild('keywordInput', { static: false }) inputElement: ElementRef<HTMLInputElement>;
  nameControl: FormControl = new FormControl(null, [Validators.required, Validators.minLength(1)]);
  keyWordsControl: FormControl = new FormControl(null);

  keywords: string[];
  measurementUnits = [
    { value: 'Gramo', label: 'Gramo' },
    { value: 'Mililitro', label: 'Mililitro' },
    { value: 'Metro', label: 'Metro' },
    { value: 'Unidad', label: 'Unidad' },
  ];
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    // placeholder: this.languageService.instant('secure.products.create_product_unit.basic_information.write_description_here'),
    translate: 'no',
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ]
  };
  showSearchBrand = true;
  isManual = false;
  // Brands Variables
  brands = [];
  filterBrands = [];
  validateEanSonExist: any;

  combos = [];

  constructor(private basicInformationService: BasicInformationService,
    private loadingService: LoadingService,
    private serviceEanSon: EanServicesService) {
  }

  ngOnInit(): void {
    this.getBrands();
    this.handleIsComboChange();
    if (this.isEditing) {
      this.checkBrandValidation();
    }
  }

  getBrands(): void {
    this.loadingService.viewSpinner();
    this.basicInformationService.getActiveBrands().subscribe(brands => {
      const initialBrands = brands.Data.Brands;
      this.brands = initialBrands.sort((a, b) => {
        if (a.Name > b.Name) {
          return 1;
        }
        if (a.Name < b.Name) {
          return -1;
        }
        return 0;
      });
      this.handleBrandChange();
      this.loadingService.closeSpinner();
    });
  }

  checkKeywords() {
    this.basicInformationForm.get('keywordsValue').setValue(null);
    if (this.basicInformationForm.get('keywords').value.length) {
      this.basicInformationForm.get('keywordsValue').clearValidators();
    } else {
      this.basicInformationForm.get('keywordsValue').setValidators(Validators.required);
    }
    this.basicInformationForm.get('keywordsValue').updateValueAndValidity();
  }

  saveKeyword(keyword: string): void {
    const keywords = this.basicInformationForm.get('keywords') as FormControl;
    keywords.setValue([...keywords.value, keyword]);
    this.inputElement.nativeElement.value = null;
    this.checkKeywords();
  }

  deleteKeyword(keywordIndex: number): void {
    const keywords = this.basicInformationForm.get('keywords') as FormControl;
    const keywordsCopy = [...keywords.value];
    keywordsCopy.splice(keywordIndex, 1);
    keywords.setValue([...keywordsCopy]);
    this.checkKeywords();
  }

  onSelectSize(size: number): void {
    const shippingSize = this.basicInformationForm.get('shippingSize') as FormControl;
    shippingSize.setValue(size);
  }

  toggleBrandInput() {
    this.showSearchBrand = !this.showSearchBrand;
    this.isManual = !this.isManual;
    this.setValidateBrands();
  }

  setValidateBrands() {
    const brand = this.basicInformationForm.get('brand') as FormControl;
    brand.reset();
    brand.setErrors(null);
    this.checkBrandValidation();
  }

  checkBrandValidation() {
    const brand = this.basicInformationForm.get('brand') as FormControl;
    if (this.isManual) {
      brand.setValidators([Validators.required, Validators.pattern(this.BrandsRegex.brandsName)]);
    } else {
      brand.setValidators([
        Validators.required, Validators.pattern(this.getRegularExpresion('brandProduct'))
      ]);
    }
  }

  handleBrandChange(): void {
    const brandControl = this.basicInformationForm.get('brand') as FormControl;
    brandControl.valueChanges.pipe(distinctUntilChanged(), debounceTime(300)).subscribe(val => {
      if (!this.isManual) {
        if (!!val && val.length >= 2) {
          this.filterBrands = this.brands.filter(brand => brand.Name.toString().toLowerCase().includes(val.toLowerCase()));
          const exist = this.filterBrands.find(brand => brand.Name === val);
          if (!exist) {
            brandControl.setErrors({ pattern: true });
          } else {
            brandControl.setErrors(null);
          }
        } else if (!val) {
          this.filterBrands = [];
          brandControl.setErrors({ required: true });
        } else {
          brandControl.setErrors({ pattern: true });
        }
      } else {
        if (!val) {
          this.filterBrands = [];
          brandControl.setErrors({ required: true });
        }
      }
    });
  }

  getRegularExpresion(field: string): string {
    return this.validationRegularExpresions.find(regularExpresionData => regularExpresionData.Identifier === field).Value;
  }

  handleIsComboChange(): void {
    const isCombo = this.basicInformationForm.get('isCombo') as FormControl;
    const eanCombo = this.basicInformationForm.get('eanCombo') as FormControl;
    isCombo.valueChanges.subscribe((isSelected: boolean) => {
      if (isSelected) {
        eanCombo.enable();
      } else {
        eanCombo.reset();
        eanCombo.disable();
      }
    });
  }

  addEanCombo() {
    const eanCombo = this.basicInformationForm.get('eanCombo') as FormControl;
    if (!!eanCombo.value && eanCombo.valid && !this.combos.includes(eanCombo.value)) {
      this.loadingService.viewSpinner();
      this.serviceEanSon.validateEan(eanCombo.value).subscribe(res => {
        // Validar si la data es un booleano para validar si existe el Ean del hijo
        this.validateEanSonExist = (res['data']);
        if (this.validateEanSonExist) {
          this.combos.push(eanCombo.value);
          eanCombo.setValue(this.combos);
          eanCombo.reset();
        } else {
          eanCombo.setErrors({ existBD: true });
        }
        this.loadingService.closeSpinner();
      });
    } else {
      eanCombo.setErrors({ exist: true });
    }
  }
}
