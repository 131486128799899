import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material';
import { DownloadModalOffertReportComponent } from './download-modal-offert-report/download-modal-offert-report.component';
import { Logger } from '@app/core';
import { SetHeaderTitle } from '@app/store/configuration/actions';
import { Store } from '@ngrx/store';
import { CoreState } from '@app/store';

const log = new Logger('ReportOffertComponent');

@Component({
  selector: 'app-report-offert',
  templateUrl: './report-offert.component.html',
  styleUrls: ['./report-offert.component.scss']
})
export class ReportOffertComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    private store: Store<CoreState>
  ) { }

  ngOnInit() {
    this.setConfigurationTitle();
  }

  openModalDownloadReportOffert(): void {
    const dialogRef = this.dialog.open(DownloadModalOffertReportComponent, {
      data: {
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      log.info('The modal detail order was closed');
    });
  }

  setConfigurationTitle() {
    this.store.dispatch(new SetHeaderTitle({
      title: 'module.Reportes',
      subtitle: 'menu.Reporte de ofertas',
      description: ''
    }));
  }

}

