<div>
  <div fxLayout="row" class="padding-10">
    <div fxFlex="95">
      <span class="modal-load-agreement-font-weight">{{'menu.Cargar Acuerdos' |
        translate}} </span>
    </div>
    <div fxFlex="5">
      <mat-icon class="mat-18 close-modal modal-load-agreement-cursor"
        (click)="onNoClick()"
        [matTooltip]="'actions.close' | translate">clear
      </mat-icon>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div mat-dialog-content class="modal-load-agreement-content">
    <form #fomrLoadAgreement="ngForm" [formGroup]="form">
      <div>
        <div class="modal-load-agreement-padding-content">
          <div class="modal-load-agreement-padding-margin-content"><span class="modal-load-agreement-content-text-type">{{'secure.seller.contracts.load-agreement.select-option-load'| translate}}</span></div>
          <div>
            <mat-radio-group aria-label="Seleccione una opción"
              formControlName="typeAgreement">
              <mat-radio-button value=2 class="modal-load-agreement-text-option-type-agreement">{{'secure.seller.contracts.load-agreement.agreement'| translate}}</mat-radio-button>
              <mat-radio-button value=1 class="modal-load-agreement-text-option-type-agreement">{{'secure.seller.contracts.load-agreement.Attached'| translate}}</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="modal-load-agreement-content-load-file-padding">
          <span class="modal-load-agreement-text-option-type-agreement">{{'secure.seller.contracts.load-agreement.upload_file'| translate}}</span>
        </div>
        <div fxLayout="row" class="load-file-container">
          <div fxFlex="100" class="text-aling-center-modal-load">
            <div class="inline-block">
              <div ngfDrop selectable="1" [(validDrag)]="validComboDrag"
                [(files)]="files" [(file)]="file"
                [maxSize]="maxSize" accept="application/pdf"
                [(lastInvalids)]="lastInvalids" [(dragFiles)]="dragFiles"
                class="well my-drop-zone" fxLayout="column" id="pdf"
                fxLayoutAlign="center center"
                [class.invalid-drag]="validComboDrag===false"
                [class.valid-drag]="validComboDrag"
                (filesChange)="lastFileAt=getDate(); validateOneFile()">
                <strong
                  *ngIf="!files.length && !lastInvalids?.length">{{'shared.components.load_file.text1'
                  | translate}}</strong>
                <strong *ngIf="files?.length && !lastInvalids?.length">{{files[files.length
                  - 1].name}} -
                  {{ files[files.length - 1].size/1024/1024 | number:'.2' }} MB</strong>
                <strong *ngIf="lastInvalids?.length && lastInvalids.length ? validateFormatInvalidate(lastInvalids): null">{{'shared.components.load_file.text2'
                  | translate}}</strong>
                <mat-icon class="material-icons" *ngIf="!files.length &&
                  !lastInvalids?.length">
                  attach_file
                </mat-icon>
                <mat-icon class="material-icons check" *ngIf="files.length &&
                  !lastInvalids?.length">
                  check_circle
                </mat-icon>
                <mat-icon class="material-icons error"
                  *ngIf="lastInvalids?.length">
                  not_interested
                </mat-icon>
                <div class="button-fake" fxLayout="column" fxLayoutAlign="center
                  center">
                  <span class="modal-load-agreement-text-btn-file-margin-top">
                    {{'shared.components.load_file.text3' | translate}}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-load-agreement-content-description">
          <mat-form-field class="field" class="modal-load-agreement-content-description-input">
            <input matInput
              [placeholder]="'secure.parametize.support_claims.description' |
              translate"
              formControlName="description">
            <mat-error *ngIf="form.get('description').hasError('required')">{{'errors.required'
              | translate}}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
  <mat-divider></mat-divider>
  <mat-dialog-actions align="end">
    <button mat-button (click)="onNoClick()">{{'actions.cancel'| translate}}</button>
    <button type="button" mat-raised-button color="primary"
      [disabled]="!fomrLoadAgreement.form.valid || uploadAgreementBtn"
      (click)="sendImportAgreement()">{{'secure.seller.contracts.load-agreement.import_agreement' | translate}} </button>
  </mat-dialog-actions>
</div>
