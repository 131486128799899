<section id="section-historical-filter-offers" class="flexbox flex-wrap">

  <form novalidate #f="ngForm" [formGroup]="historicalFilterForm"
    (ngSubmit)="historical.historicalFilter(historicalFilterForm)" class="flexbox flex-wrap justify-center">

    <!-- Clear form button -->
    <article class="flexbox align-center justify-between">
      <span>{{'secure.offers.historical_admin.components.filter.title' | translate}}</span>
      <button id="closeMenuApplication" type="button" color="primary"
        [matTooltip]="'secure.offers.historical_admin.components.filter.tooltip_close' | translate"
        matTooltipPosition="below" mat-icon-button mat-button (click)="toggleMenu()">
        <mat-icon>clear</mat-icon>
      </button>
    </article>
    <!-- Clear /form button -->

    <!-- Input date initial -->
    <mat-form-field color="primary">
      <input id="sinceDate-input"
        [placeholder]="'secure.offers.historical_admin.components.filter.placeholder_initial_date' | translate"
        formControlName="dateInitial" name="dateInitial" [readonly]="true" matInput [matDatepicker]="dateInitial"
        (dateChange)="setMaxDateRange();validateFinalRange()" (click)="openDateInitial()">
      <mat-datepicker-toggle matPrefix [for]="dateInitial"></mat-datepicker-toggle>
      <mat-datepicker touchUi="true" startView="year" #dateInitial></mat-datepicker>
    </mat-form-field>
    <!-- /Input date initial -->

    <!-- Input date final -->
    <mat-form-field color="primary">
      <input id="untilDate-input"
        [placeholder]="'secure.offers.historical_admin.components.filter.placeholder_final_date' | translate"
        formControlName="dateFinal" name="dateFinal" [readonly]="true" [min]="historicalFilterForm.value.dateInitial"
        [max]="rangeDateMax" matInput [matDatepicker]="dateFinal" (click)="openDateFinal()">
      <mat-datepicker-toggle matPrefix [for]="dateFinal"></mat-datepicker-toggle>
      <mat-datepicker touchUi="true" startView="year" #dateFinal></mat-datepicker>
      <mat-error *ngIf="rangeError">{{'errors.error_date' | translate}}</mat-error>
      <mat-error *ngIf="historicalFilterForm.controls.dateFinal.hasError('matDatepickerMin')">
        {{'secure.products.create_product_unit.list_products.ending_date' | translate}}
      </mat-error>
    </mat-form-field>
    <!-- /Input date final -->

    <!-- Input EAN -->
    <mat-form-field>
      <input matInput [placeholder]="'secure.offers.historical_admin.components.filter.EAN' | translate"
        formControlName="ean" matInput>
      <mat-hint></mat-hint>
      <mat-error *ngIf="historicalFilterForm.get('ean').hasError('pattern')">{{'errors.invalid_EAN' | translate}}
      </mat-error>
    </mat-form-field>
    <!-- /Input EAN -->

    <!-- Filter action button -->
    <article class="container-buttons flexbox align-center justify-between">
      <button mat-stroked-button color="primary" type="reset" (click)="clearAndSubmit()">{{'filters.clear' | translate}}</button>
      <button mat-flat-button color="primary" type="submit"
        [disabled]="!f.form.valid">{{'filters.filter' | translate}}
      </button>
    </article>
    <!-- /Filter action button -->

  </form>

</section>
