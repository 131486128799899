import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatTooltip } from '@angular/material';
import { UserParametersService } from '@app/core/aws-cognito';
import { UserInformation } from '@app/shared';
import { BreakpointService } from '@app/shared/services/breakpoint.service';
import { UtilsService } from '@app/shared/services/utils.service';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { Store, select } from '@ngrx/store';
import { CoreState } from '@app/store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-floating-header',
  templateUrl: './floating-header.component.html',
  styleUrls: ['./floating-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FloatingHeaderComponent implements OnInit, OnDestroy {
  menuOpened: number = 0;
  shortName: string = 'NN';
  user: any = {};
  currentState: string = '';
  currentStateClass: string = 'enable';
  showNotificationDot: boolean = false;
  notification$: Subscription = new Subscription();
  userData$: Subscription = new Subscription();

  constructor(
    private utilsService: UtilsService,
    private matDialog: MatDialog,
    private store: Store<CoreState>
  ) { }

  ngOnInit() {
    this.getDataUser();
    this.getNotifications();
  }

  openMenu(menu: number) {
    if (this.menuOpened === menu) {
      this.menuOpened = 0;
      return;
    }
    this.menuOpened = menu;
  }

  getDataUser() {
    this.userData$ = this.store
      .pipe(select(state => state.configuration.userData))
      .subscribe(
        userData => {
          if (userData) {
            this.user = userData;
            this.shortName = this.utilsService.getShortName(userData.Name);
            this.getCurrentState(userData);
          }
        }
      );
  }

  toggleMenu() {
    this.matDialog.open(SidebarComponent, {
      position: { left: '0' },
      height: '100vh',
      panelClass: 'pannel-class__sidebar-menu',
      backdropClass: ''
    })
  }

  getNotifications() {
    this.notification$ = this.store
      .pipe(select(state => state.notification))
      .subscribe(
        notificationState => {
          this.showNotificationDot = !!(notificationState.unreadDevolutions || notificationState.unreadPendings);
        }
      );
  }

  getCurrentState(userData: any) {
    const statesDictionary = {
      enable: 'profile.status.enabled',
      disable: 'profile.status.disabled',
      vacations: 'profile.status.vacations'
    }
    this.currentStateClass = userData.Status.toLocaleLowerCase();
    this.currentState = statesDictionary[userData.Status.toLocaleLowerCase()];
  }

  ngOnDestroy(): void {
    this.notification$.unsubscribe();
    this.userData$.unsubscribe();
  }

  onClickedOutside() {
    this.menuOpened = 0;
  }

}
