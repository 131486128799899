<div class="edit-module">

  <div fxLayout="row" fxLayoutAlign="end end"><mat-icon class="material-icons close" (click)="close()"> close </mat-icon></div>

  <div fxLayout="row" fxLayoutAlign="center center">
    <div class="container-icon" fxLayout="column" fxLayoutAlign="center center">
      <mat-icon class="material-icons">
        edit
      </mat-icon>
    </div>
  </div>

  <h2 class="center-text bold" mat-dialog-title>
    {{'school.exito.edit.module.title' | translate}}
  </h2>

  <p>{{'school.exito.edit.module.description' | translate}}</p>

  <form [formGroup]="formEditModule" class="form-add" (ngSubmit)="editModule()">

    <div mat-dialog-content class="form-container">

      <div fxLayout="row" class="w-100" fxLayout.xs="column" fxLayoutAlign="space-between start">
        
        <div class="container-form" fxLayout="column">
          
          <mat-form-field class="input-add">
            <input matInput formControlName="moduleName" [placeholder]="'school.exito.edit.module.name.module' | translate"  (keydown)="changeValue()">
            <mat-error *ngIf="formEditModule.get('moduleName').hasError('required')">{{'errors.required' | translate}}</mat-error>
          </mat-form-field>
          
        </div>
      </div>

      <!-- CONTAINER BUTTON TO ADD NEW SPECIFICATION GROUP -->

    </div>

    <div mat-dialog-actions class="mat-buttons" fxLayout="row" fxLayoutAlign="center center">
      <button  mat-stroked-button class="btn-transparent" type="button" id="btn-edit-module" color="primary" (click)="close()">{{'actions.cancel' | translate}}</button>
      <button class="uppercase" id="btn-edit-module" type="submit" mat-raised-button color="primary" [disabled]="!formEditModule.valid || changeValueEdit">{{'actions.edit' | translate}}</button>
    </div>

  </form>

</div>