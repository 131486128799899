import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FulfillmentRoutingModule } from './fulfillment.routing';
import { EnrollmentComponent } from './enrollment/enrollment.component';
import { TabsComponent } from './enrollment/tabs/tabs.component';
import { FiltersComponent } from './enrollment/filters/filters.component';
import { MaterialModule } from '@app/material.module';
import { ProductListComponent } from './enrollment/product-list/product-list.component';
import { ProductCardComponent } from './enrollment/product-card/product-card.component';
import { PagerComponent } from './enrollment/pager/pager.component';
import { ModalComponent } from './enrollment/modal/modal.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    FulfillmentRoutingModule,
    MaterialModule,
    ReactiveFormsModule,
    SharedModule
  ],
  declarations: [
    EnrollmentComponent,
    TabsComponent,
    FiltersComponent,
    ProductListComponent,
    ProductCardComponent,
    PagerComponent,
    ModalComponent
  ],
  entryComponents: [
    ModalComponent
  ]
})
export class FulfillmentModule { }
