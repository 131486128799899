<section *ngIf="pagesNumber && currentPage" class="pager">
  <button
    class="pager__chevron-button"
    [class.pager__chevron-button--disabled]="currentPage === 1"
    (click)="prevPage()"
  >
    <span class="material-symbols-outlined">
      chevron_left
    </span>
  </button>

  <button
    *ngFor="let page of pagesArr"
    class="pager__button"
    [class.pager__button--active]="page === currentPage"
    [class.pager__button--disabled]="currentPage === page"
    (click)="changePage(page)"
  >
    {{ page }}
  </button>

  <ng-container *ngIf="ellipsisPager && pagesArr.length === 3">
    <span>...</span>
    <button
      class="pager__button"
      [class.pager__button--active]="currentPage === pagesNumber"
      [class.pager__button--disabled]="currentPage === pagesNumber"
      (click)="changePage(pagesNumber)"
    >
      {{ pagesNumber }}
    </button>
  </ng-container>
  <button
    class="pager__chevron-button"
    [class.pager__chevron-button--disabled]="currentPage === pagesNumber"
    (click)="nextPage()"
  >
    <span class="material-symbols-outlined">
      chevron_right
    </span>
  </button>
</section>