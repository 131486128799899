<div *ngIf="formListPending">
  <h2 mat-dialog-title *ngIf="filtersList && filtersList.typeReport === 1">
    {{'secure.orders.list-cancels.tab1-title-download' | translate}}</h2>
  <h2 mat-dialog-title *ngIf="filtersList && filtersList.typeReport === 2">
    {{'secure.orders.list-cancels.tab2-title-download' | translate}}</h2>

  <mat-dialog-content>
    <form #f="ngForm" [formGroup]="formListPending" class="form-login">
      <div fxLayout="column" fxLayoutGap="20px">
        <div fxLayout="row" fxFlex="100">
          <mat-form-field fxFlex="100" class="input-style">
            <input name="email" formControlName="email" matInput
              [placeholder]="'secure.seller.register_seller.lb_email_form' | translate" type="email" required>

            <!-- dirty: si el campo cambia -->
            <button disabled="{{!f.form.dirty}}" matSuffix type="button" color="primary" mat-icon-button
              (click)="createForm()">
              <mat-icon [matTooltip]="'secure.offers.historical_admin.download_historical.tooltip_email' | translate"
                matTooltipPosition="below" matSuffix>
                autorenew
              </mat-icon>
            </button>
            <mat-error>{{'errors.email_required' | translate}}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
  </mat-dialog-content>

  <div mat-dialog-actions class="footer-dialog">
    <button mat-button [mat-dialog-close]="'false'" mat-stroked-button color="primary"
      (click)="onNoClick()">{{'actions.cancel_min' | translate}}</button>
    <button mat-button [mat-dialog-close]="'true'" mat-raised-button color="primary" [disabled]="!f.form.valid"
      *ngIf="filtersList && filtersList.typeReport === 1"
      (click)="sendExportDevolutionList()">{{'actions.accept' | translate}}</button>
    <button mat-button [mat-dialog-close]="'true'" mat-raised-button color="primary" [disabled]="!f.form.valid"
      *ngIf="filtersList && filtersList.typeReport === 2"
      (click)="sendExportDevolutionHistoric()">{{'actions.accept' | translate}}</button>
  </div>
</div>
