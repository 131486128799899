<div class="sh-conversation">
  <ng-container *ngFor="let item of items">
    <div class="sh-conversation__flow">
      <div
        class="sh-conversation__flow--decoration sh-conversation__flow--node"
      ></div>
      <div style="width: calc(100% - 42px)">
        <ng-container [ngTemplateOutlet]="item.tpl"></ng-container>
      </div>
    </div>
  </ng-container>
</div>
