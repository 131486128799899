<div fxLayout="row" fxLayoutAlign="space-evenly stretch" >
  <p class="type-text">
    {{'secure.offers.list.list.modal_desactive_1' | translate}}{{response.count}} {{'secure.offers.list.list.modal_desactive_2' | translate}}?
  </p>
</div>

<div mat-dialog-actions class="footer-dialog">
  <button mat-button [mat-dialog-close]="'false'" mat-stroked-button color="primary" (click)="onNoClick()">{{'actions.cancel_min' | translate}}</button>
  <button mat-button [mat-dialog-close]="'true'" mat-raised-button color="primary" (click)="onNoClickOk()">{{'actions.accept' | translate}}</button>
</div>
