import { CdkStepper } from '@angular/cdk/stepper';
import { Component } from '@angular/core';

@Component({
  selector: 'app-unit-product-creation-stepper',
  templateUrl: './unit-product-creation-stepper.component.html',
  styleUrls: ['./unit-product-creation-stepper.component.scss'],
  providers: [
    { provide: CdkStepper, useExisting: UnitProductCreationStepperComponent },
  ],
})
export class UnitProductCreationStepperComponent extends CdkStepper {
  onClick(index: number): void {
    this.selectedIndex = index;
  }
}
