<div class="content-page">
  <div class="content-tab" fxFlexAlign="center" fxLayout="row" fxFlex="450px" fxFlex.xs="90" fxFlex.sm="450px" fxFlex.md="450px"
    fxFlex.lg="450px" xFlex.xl="35">
    <div [@scaleEfect] fxFlex="100" class="tab-style forgot-password-content">
      <div class="title-forgot-password">
        <div class="logo-application"></div>
      </div>
      <mat-card class="card-forgot-password">
        <form #f="ngForm"
              [formGroup]="awscognitogroup"
              class="form-forgot-password"
              method="POST"
              action="#"
              role="form">
          <div fxLayout="column" fxLayoutGap="20px">
            <div fxFlex="100">
              <mat-form-field class="full-width input-style">
                <mat-icon matPrefix>email</mat-icon>
                <input id="signupEmail"
                       name="email"
                       formControlName="email"
                       matInput
                       [placeholder]="'secure.seller.register_seller.lb_email_form' | translate"
                       type="email"
                       required
                       maxlength="50">
                <mat-error>{{'errors.error_user_login'| translate}}</mat-error>
              </mat-form-field>
            </div>
            <div fxFlex="100" fxLayout="row">
              <button id="nextPage"
                      [disabled]="!f.form.valid"
                      (click)="onNext()"
                      type="submit"
                      mat-raised-button
                      fxFlex="100" color="primary">{{'actions.recover_password' | translate}}</button>
            </div>
            <div *ngIf="errorMessage!=null">
              <mat-error>{{ errorMessage }}</mat-error>
            </div>
          </div>
        </form>
      </mat-card>
    </div>
  </div>
</div>
