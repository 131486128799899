<mat-drawer-container class="drawer-container list-sellers" [hasBackdrop]="true">
  <mat-drawer #drawer [mode]="'over'" [position]="'end'" class="example-sidenav">
    <!-- Formulario para realizar filtro de listado de vendedores -->
    <form class="basic-info" #createfilterSeller="ngForm" [formGroup]="filterSeller" class="seller-filter-sidenav">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <h3>{{'secure.seller.list.seller_filter' | translate}}</h3>
        <button id="closeMenuApplication" type="button" color="primary" [matTooltip]="'actions.close' | translate"
          matTooltipPosition="below" mat-icon-button mat-button (click)="drawer.toggle()">
          <mat-icon>clear</mat-icon>
        </button>
      </div>

      <div fxLayout="column" fxLayoutAlign="space-between" fxLayoutAlign.xs="center center" fxLayout.xs="column">
        <mat-form-field color="primary">
          <input matInput [placeholder]="'secure.seller.list.id' | translate" formControlName="id"
            [errorStateMatcher]="matcher" (matChipInputTokenEnd)="add($event)" [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
          <mat-hint></mat-hint>
          <mat-error *ngIf="filterSeller.get('id').hasError('pattern')">{{'errors.invalid_id' | translate}}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input matInput [placeholder]="'secure.seller.list.seller_name' | translate" formControlName="sellerName"
            [errorStateMatcher]="matcher" (matChipInputTokenEnd)="add($event)" [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
          <mat-error *ngIf="filterSeller.get('sellerName').hasError('trim')">{{'errors.invalid_name' |
            translate}}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input matInput [placeholder]="'secure.seller.list.nit' | translate" formControlName="nit"
            [errorStateMatcher]="matcher" (matChipInputTokenEnd)="add($event)" [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
          <mat-hint></mat-hint>
          <mat-error *ngIf="filterSeller.get('nit').hasError('pattern')">{{'errors.invalid_nit' |
            translate}}</mat-error>
        </mat-form-field>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <button mat-stroked-button color="primary" (click)="cleanFilter()">{{'filters.clear' | translate}}</button>
          <button mat-flat-button color="primary" type="submit"
            (click)="drawer.toggle() && filterListSeller()">{{'filters.filter' | translate}}</button>
        </div>

      </div>
    </form>
  </mat-drawer>
  <mat-drawer-content>

    <div class="height-full" fxLayout="column">
      <mat-toolbar class="seller-list-toolbar">
        <button type="button" mat-button (click)="drawer.toggle()">
          <mat-icon>filter_list</mat-icon>
          <small class="sub-title-filter">{{'filters.filter' | translate}}</small>
        </button>
      </mat-toolbar>
      <!-- Resultado Filtro -->
      <div class="example-chip-list">
        <mat-chip-list #chipList>
          <mat-chip *ngFor="let listFilterSeller of listFilterSellers" [selectable]="selectable" [removable]="removable"
            (removed)="remove(listFilterSeller)">
            {{listFilterSeller.name}}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip>
          <input [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" disabled>
        </mat-chip-list>
      </div>
      <!-- SELLER CONTAINER LIST-->
      <div class="list-container" *ngIf="canRead">
        <!-- SELLER HEADER LIST-->
        <div id="header-list" class="header-list" fxLayout="row" fxLayoutAlign="space-around center" fxHide.xs>
          <div id="id" class="label-container id">
            <label class="label-list">{{'secure.seller.list.id' | translate}}
            </label>
          </div>
          <div id="nit" class="label-container nit">
            <label class="label-list">{{'secure.seller.list.nit' | translate}}
            </label>
          </div>
          <div id="name" class="label-container name">
            <label class="label-list">{{'secure.seller.list.seller_name' | translate}}
            </label>
          </div>
          <div id="status" class="label-container status">
            <label class="label-list">{{'secure.seller.list.status' | translate}}
            </label>
          </div>
          <div id="vacations" class="label-container vacations">
            <label class="label-list">{{'secure.seller.list.vacations' | translate}}
            </label>
          </div>
        </div>

        <!-- SELLER BODY LIST -->
        <div *ngFor="let seller of sellerList; let i = index " [ngClass]="{'background-bar' : seller.block}">
          <mat-progress-bar mode="indeterminate" *ngIf="seller.block"></mat-progress-bar>
          <!-- SELLER HEADER LIST-->
          <div id="body-list" class="header-list">
            <div id="id" class="label-container id">
              <button mat-icon-button [matTooltip]="'secure.seller.list.tooltip_manage' | translate"
                class="btn__visibility" (click)="redirectToSeller(seller.IdSeller)" fxShow [fxShow.xs]="false"
                *ngIf="canVisualize">
                <mat-icon class="material-icons">
                  visibility
                </mat-icon>
              </button>
              <p class="title-list">{{'secure.seller.list.nit' | translate}}
              </p>
              <label class="label-result">
                {{seller.IdSeller}}
              </label>
            </div>
            <div id="nit" class="label-container nit">
              <p class="title-list">{{'secure.seller.list.nit' | translate}}
              </p>
              <label class="label-result">
                {{seller.Nit}}
              </label>
            </div>
            <div id="name" class="label-container name">
              <p class="title-list">{{'secure.seller.list.seller_name' | translate}}
              </p>
              <label class="label-result">
                {{seller.Name}}
              </label>
            </div>
            <div id="status" class="label-container status">
              <button mat-icon-button id="btn-enabled-seller"
                [matTooltip]="'secure.seller.list.tooltip_enable' | translate"
                (click)="changeSellerStatus(seller, 'enabled', i)"
                [ngClass]="seller.Status == 'Enable' ? 'enabledSellerButton' : 'button--disabled' "
                [disabled]="!seller.Status || seller.Status == 'Enable'">
                <mat-icon aria-label="active status of seller">
                  check
                </mat-icon>
              </button>
              <button mat-icon-button id="btn-disabled-seller"
                [matTooltip]="'secure.seller.list.tooltip_disable' | translate"
                (click)="changeSellerStatus(seller, 'disabled', i)"
                [ngClass]="seller.Status == 'Disable' ? 'warnColor' : 'button--disabled' "
                [disabled]="!seller.Status || seller.Status == 'Disable'">
                <mat-icon aria-label="inactive status of seller">
                  block
                </mat-icon>
              </button>
              <button mat-icon-button id="btn-vacation-seller"
                [matTooltip]="'secure.seller.list.tooltip_vacations' | translate"
                (click)="changeSellerStatus(seller, 'vacation', i)"
                [ngClass]="seller.Status == 'Vacations' ? 'primaryColor' : 'button--disabled' "
                [disabled]=" !seller.Status || seller.Status == 'Disable'">
                <mat-icon aria-label="vacation status of seller">
                  local_airport
                </mat-icon>
              </button>
            </div>
            <div id="vacations" class="label-container vacations"
              [ngClass]="{noneVacations: seller.Status == 'Disable' }">
              <p class="title-list">{{'secure.seller.list.vacations' | translate}}
              </p>
              <label class="label-result flex-end-center"
                *ngIf="!!seller.StartVacations && !!seller.EndVacations; else ProgramDate">
                <div>
                  <strong>{{'secure.seller.list.start_date' | translate}}: </strong>{{seller.StartVacations}}<br />
                  <strong>{{'secure.seller.list.end_date' | translate}}: </strong>{{seller.EndVacations}}
                </div>
                <mat-icon *ngIf="canCancelVacation" (click)="sendToOpenCancelVacationDialog(i)">
                  delete
                </mat-icon>
              </label>
              <ng-template #ProgramDate>
                <label class="label-result">{{'secure.seller.list.program_vacation' | translate}}
                </label>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>


<ng-template #dialogContent *ngIf="needFormStates$ | async as status">
  <ng-container *ngIf="status.status == 'vacation'">
    <form [formGroup]="statusForm" class="full-width space-between">
      <mat-form-field class="half-width">
        <input matInput id="inputInitialDate" [matDatepicker]="intialPicker" [min]="tomorrow" [readonly]="true"
          [placeholder]="'secure.seller.list.start_date' | translate" formControlName="StartDateVacation"
          (click)="openPicker(1)" (dateInput)="startDate()">
        <mat-datepicker-toggle matSuffix [for]="intialPicker"></mat-datepicker-toggle>
        <mat-datepicker #intialPicker disabled="false"></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="half-width">
        <input matInput id="inputEndDate" [matDatepicker]="endPicker" [readonly]="true" [min]="endvacationStart"
          [placeholder]="'secure.seller.list.end_date' | translate" formControlName="EndDateVacation"
          (click)="openPicker(2)" [disabled]="enableEndVacation">
        <mat-datepicker-toggle matSuffix [for]="endPicker" [disabled]="enableEndVacation"></mat-datepicker-toggle>
        <mat-datepicker #endPicker disabled="false" [disabled]="enableEndVacation"></mat-datepicker>
      </mat-form-field>

    </form>
  </ng-container>
  <ng-container *ngIf="status.status == 'disabled'">
    <form [formGroup]="statusForm">
      <mat-form-field class="full-width">
        <input matInput [placeholder]="'secure.seller.list.reason' | translate" id="inputReason" autocomplete="false"
          formControlName="Reasons">
        <mat-error *ngIf="reason.hasError('maxlength')">{{'errors.reason_max_length' | translate }}</mat-error>
        <mat-error *ngIf="reason.hasError('required') || reason.hasError('trim')">{{'errors.required' | translate
          }}</mat-error>
      </mat-form-field>
      <mat-form-field class="full-width">
        <textarea matInput [placeholder]="'secure.seller.list.observation' | translate" id="inputObservation"
          autocomplete="false" formControlName="Observations"></textarea>
        <mat-hint></mat-hint>
        <mat-error *ngIf="observation.hasError('maxlength')">{{'errors.observation_max_length' | translate
          }}</mat-error>
        <mat-error *ngIf="observation.hasError('required') || observation.hasError('trim')">{{'errors.required' |
          translate }}</mat-error>
      </mat-form-field>
    </form>
  </ng-container>
</ng-template>