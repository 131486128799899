<div class="content-order my-content">
  <div fxFlex class="container" fxLayout="row wrap" fxLayoutAlign="center
    start">
    <div fxFlex="100" class="light-content">
      <app-toolbar-search-pagination [informationToForm]="informationToForm" #toolbarOptions [lengthOrder]="length"
        [limitPaginate]="[50, 100, 150]" [btnFilter]="btnFilter" [showBtn]="false" 
        (filterGerenic)="toggleFilterReportPaymentSummary()" (paginationListOrdens)="paginations($event)"
        [pageIndexChange]="indexPage">
      </app-toolbar-search-pagination>
    </div>
    <div fxLayout="row wrap" class="payment-summary-component-width-padding-20">
      <div fxFlex="70">
        <div class="payment-summary-component-padding-bottom-15">
          <span class="payment-summary-component-text-color-total-summary">{{'secure.dispersion.summaryPayment.valueTotalPay'| translate}}:
             {{totalPayValue | currency: 'USD' : 'code' : '1.0-0'  }}</span>
        </div>
        <div>
          <span class="payment-summary-component-text-color-total-summary">{{'secure.dispersion.summaryPayment.allSeller' | translate}}:
            {{totalSeller}}</span>
        </div>
      </div>
      <div fxFlex="30">
        <div class="payment-summary-component-text-aling-end">
          <button mat-stroked-button color="primary"
            (click)="btnDispersion()" [disabled]= "disabledBtnDispersion">{{'secure.dispersion.summaryPayment.dispersar'| translate}}</button>
        </div>
      </div>
    </div>
    <div class="payment-summary-component-width-complete">
      <div fxFlex.xs="93%" fxFlex.sm="95" fxFlex.md="97" fxFlex.lg="97" fxFlex.xl="97" class="payment-summary-component-width-complete">
        <mat-table #table [dataSource]="dataSource" matSort class="payment-summary-component-width-complete">
          <ng-container matColumnDef="check">
            <mat-header-cell class='marginListAdminTotal'  fxShow.lg="true" fxShow.xs="false" fxShow.sm="false" fxShow.lg="true" fxShow.xl="true" fxShow="true"
              fxShow.xl="true" fxShow="true" fxFlex.lg="10" fxFlex.sm="10" fxFlex.md="10" fxFlex.xs="5" fxFlex.xl=""
              *matHeaderCellDef>
              <mat-checkbox [disabled]= "allPaymentSummary.length === 0 ? true : false " (change)="$event ? masterToggle(statusAllCheck, dataSource) :
            null" [checked]="selection.hasValue()"
                [indeterminate]="selection.hasValue() && !isAllSelectedCurrent">
                {{'actions.all'| translate}}
              </mat-checkbox>
            </mat-header-cell>
            <mat-cell class='marginListAdminTotal' fxShow.xs="true" fxShow.sm="true" fxShow.lg="true" fxShow.xl="true"
              fxShow="true" fxFlex.lg="10" fxFlex.sm="10" fxFlex.md="10" fxFlex.xs="5" fxFlex.xl=""
              *matCellDef="let row; let element">
              <mat-checkbox (click)="$event.stopPropagation()"
                (change)="changeStatus(row,$event.checked); changeStatusDispersionBySeller(row, !element.excluded);"
                [checked]="!element.excluded"></mat-checkbox>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="cutoffDate">
            <mat-header-cell class='marginListAdminTotal' fxShow.xs="false" fxShow.sm="false" fxShow.md="true" fxShow.lg="true"
              fxShow.xl="true" fxShow="true" fxFlex.lg="15" fxFlex.sm="15" fxFlex.md="15" fxFlex.xs="30" fxFlex.xl=""
              *matHeaderCellDef>
              <span class="title-table-header"> {{'secure.dispersion.summaryPayment.cutofDate' | translate}}</span>
            </mat-header-cell>
            <mat-cell class='marginListAdminTotal' fxShow.xs="false" fxShow.sm="false" fxShow.lg="true" fxShow.xl="true"
              fxShow="true" fxFlex.lg="15" fxFlex.sm="15" fxFlex.md="15" fxFlex.xs="30" fxFlex.xl=""
              *matCellDef="let element">
              <p class="sc-text-normal">{{element.cutOffDate}}</p>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="idSeller">
            <mat-header-cell class='marginListAdminTotal' fxShow.xs="true" fxShow.sm="true" fxShow.lg="true"
              fxShow.xl="true" fxShow="true" fxFlex.lg="15" fxFlex.sm="15" fxFlex.md="15" fxFlex.xs="30" fxFlex.xl=""
              *matHeaderCellDef>
              <span class="title-table-header"> {{'secure.dispersion.summaryPayment.idSeller' | translate}}</span>
            </mat-header-cell>
            <mat-cell class='marginListAdminTotal' fxShow.xs="true" fxShow.sm="true" fxShow.lg="true" fxShow.xl="true"
              fxShow="true" fxFlex.lg="15" fxFlex.sm="15" fxFlex.md="15" fxFlex.xs="30" fxFlex.xl=""
              *matCellDef="let element">
              <p class="sc-text-normal">{{element.sellerId}}</p>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="seller">
            <mat-header-cell class='marginListAdminTotal' fxShow.xs="false" fxShow.sm="false" fxShow.lg="true" 
              fxShow.xl="true" fxShow="true" fxFlex.lg="15" fxFlex.sm="15" fxFlex.md="15" fxFlex.xs="30" fxFlex.xl=""
              *matHeaderCellDef>
              <span class="title-table-header">{{'secure.dispersion.summaryPayment.seller' | translate}}</span>
            </mat-header-cell>
            <mat-cell class='marginListAdminTotal' fxShow.xs="false" fxShow.sm="false" fxShow.lg="true" fxShow.xl="true"
              fxShow="true" fxFlex.lg="15" fxFlex.sm="15" fxFlex.md="15" fxFlex.xs="30" fxFlex.xl=""
              *matCellDef="let element">
              <p class="sc-text-normal">{{element.sellerName}}</p>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="quantityOrders">
            <mat-header-cell class='marginListAdminTotal' fxShow.xs="false" fxShow.sm="false" fxShow.lg="true"
              fxShow.xl="true" fxShow="true" fxFlex.lg="15" fxFlex.sm="15" fxFlex.md="15" fxFlex.xs="30" fxFlex.xl=""
              *matHeaderCellDef>
              <span
                class="title-table-header payment-summary-component-aling-column-center">{{'secure.dispersion.summaryPayment.quantityOrders' | translate}}</span>
            </mat-header-cell>
            <mat-cell class='marginListAdminTotal' fxShow.xs="false" fxShow.sm="false" fxShow.lg="true" fxShow.xl="true"
              fxShow="true" fxFlex.lg="15" fxFlex.sm="15" fxFlex.md="15" fxFlex.xs="30" fxFlex.xl=""
              *matCellDef="let element">
              <p class="sc-text-normal payment-summary-component-aling-column-center">
                {{element.ordersQuantity}}
              </p>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="idInternalCode">
            <mat-header-cell class='marginListAdminTotal' fxShow.xs="true" fxShow.sm="true" fxShow.lg="true"
              fxShow.xl="true" fxShow="true" fxFlex.lg="15" fxFlex.sm="15" fxFlex.md="15" fxFlex.xs="30" fxFlex.xl=""
              *matHeaderCellDef>
              <span class="title-table-header payment-summary-component-aling-column-center">
                {{'secure.dispersion.summaryPayment.idCodeInternal' | translate}}</span>
            </mat-header-cell>
            <mat-cell class='marginListAdminTotal' fxShow.xs="true" fxShow.sm="true" fxShow.lg="true" fxShow.xl="true"
              fxShow="true" fxFlex.lg="15" fxFlex.sm="15" fxFlex.md="15" fxFlex.xs="30" fxFlex.xl=""
              *matCellDef="let element">
              <p class="sc-text-normal payment-summary-component-aling-column-center">{{element.internalPaymentId}}</p>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="description">
            <mat-header-cell class='marginListAdminTotal' fxShow.xs="false" fxShow.sm="false" fxShow.lg="true"
              fxShow.xl="true" fxShow="true" fxFlex.lg="15" fxFlex.sm="15" fxFlex.md="15" fxFlex.xs="30" fxFlex.xl=""
              *matHeaderCellDef>
              <span class="title-table-header"> {{'secure.dispersion.summaryPayment.description' | translate}}</span>
            </mat-header-cell>
            <mat-cell class='marginListAdminTotal' fxShow.xs="false" fxShow.sm="false" fxShow.lg="true" fxShow.xl="true"
              fxShow="true" fxFlex.lg="15" fxFlex.sm="15" fxFlex.md="15" fxFlex.xs="30" fxFlex.xl=""
              *matCellDef="let element">
              <p class="sc-text-normal">{{element.description}}</p>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="totalToPay">
            <mat-header-cell class='marginListAdminTotal' fxShow.xs="true" fxShow.sm="true" fxShow.lg="true"
              fxShow.xl="true" fxShow="true" fxFlex.lg="15" fxFlex.sm="15" fxFlex.md="15" fxFlex.xs="30" fxFlex.xl=""
              *matHeaderCellDef>
              <span
                class="title-table-header payment-summary-component-aling-column-right">{{'secure.dispersion.summaryPayment.totalPay' | translate}}
              </span>
            </mat-header-cell>
            <mat-cell class='marginListAdminTotal' fxShow.xs="true" fxShow.sm="true" fxShow.lg="true" fxShow.xl="true"
              fxShow="true" fxFlex.lg="15" fxFlex.sm="15" fxFlex.md="15" fxFlex.xs="30" fxFlex.xl=""
              *matCellDef="let element">
              <p class="sc-text-normal payment-summary-component-aling-column-right">
                {{element.amount | currency: 'USD' : 'code' : '1.0-0'}}</p>
            </mat-cell>
          </ng-container>



          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row id="order-number-{{index}}" *matRowDef="let index=index; let
          row; columns: displayedColumns;" class="element-row personal-row-table" [cdkDetailRow]="row"
            [cdkDetailRowTpl]="tpl">
          </mat-row>
        </mat-table>
      </div>
    </div>
    <div *ngIf="!showTable" class="payment-summary-component-container-wait-moment-please">
      <div>
        <span> {{'secure.products.create_product_unit.list_products.please_wait' | translate}}</span>
      </div>
    </div>
    <div *ngIf="showTable && allPaymentSummary.length === 0" class="payment-summary-component-without-register">
     <span>No se encontraron registros</span>
    </div>
  </div>
</div>


<mat-sidenav-container>
  <mat-sidenav #sidenavSearchPaymentSummary mode="over" class="container-filter" fxFlex.sm="90" fxFlex.xs="90"
    fxFlex.md="45" fxFlex.xl="45" fxFlex.lg="45" position="end" opened="{{stateSideNavOrder}}">

    <div fxLayout="row" fxLayoutAlign="start center" class="payment-summary-component-height-70">
      <div fxFlex="80">
        <div class="payment-summary-component-margin-left-10">
          {{informationToForm.title_for_search | translate}}
        </div>
      </div>
      <div fxFlex="20" class="toolbar-title-search payment-summary-component-height-90">
        <button mat-icon-button color="primary" class="payment-summary-component-100-size-container"  (click)="toggleFilterReportPaymentSummary()" [matTooltip]="'actions.close' | translate" matTooltipPosition="below" >
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div fxLayout="row">
      <div fxFlex>

        <form #createfilterPaymentSummary="ngForm" [formGroup]="filterPaymentSummary"
          class="payment-summary-component-margin-top-40">
          <div class="payment-summary-component-height-470-filter">
            <mat-form-field class="classDatapicker  payment-summary-component-input-generic">
              <input matInput [matDatepicker]="picker"  placeholder="Fecha de corte" formControlName="cutOffDate">
              <mat-error *ngIf="filterPaymentSummary.get('cutOffDate').hasError('pattern')">
                {{'errors.format_invalidate' | translate}}
              </mat-error>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker disabled="false"></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="field payment-summary-component-input-generic">
              <input matInput placeholder="ID vendedor" formControlName="sellerId">
              <mat-error *ngIf="filterPaymentSummary.get('sellerId').hasError('pattern')">
                {{'errors.format_invalidate' | translate}}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="field payment-summary-component-input-generic">
              <input matInput placeholder="Id código interno" formControlName="internalIdPayment">
              <mat-error *ngIf="filterPaymentSummary.get('internalIdPayment').hasError('pattern')">
                {{'errors.format_invalidate' | translate}}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="field payment-summary-component-input-generic">
              <input matInput placeholder="Monto" formControlName="amount">
              <mat-error *ngIf="filterPaymentSummary.get('amount').hasError('pattern')">
                {{'errors.format_invalidate' | translate}}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="payment-summary-component-text-aling-center">
            <div>
              <button class="sc-button payment-summary-component-btn-clear-form" mat-button type="button"
                (click)="clearForm()">
                <mat-icon>clear_all</mat-icon>
                {{'filters.clear' | translate}}
              </button>
              <button class="sc-button" mat-raised-button color="primary"
                [disabled]="!createfilterPaymentSummary.form.valid" type="button"
                (click)="apllyFilterPaymentSummary(filterPaymentSummary.value)">
                {{'actions.apply' | translate}}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </mat-sidenav>
</mat-sidenav-container>
