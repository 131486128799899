import { Injectable } from "@angular/core";
import { CategoryModel } from "../categorization/list/category.model";
import { SearchService } from "../categorization/search.component.service";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";


@Injectable()
export class ProductCategorizationTreeBuilderService {
  listCategories: any
  constructor(private searchService: SearchService) { }

  public getCategoriesTree(): Observable<CategoryModel[]> {
    return this.searchService.getCategories()
      .pipe(map((result: any) => {
        if (result.status === 200 && result.body.body) {
          const body = JSON.parse(result.body.body);
          this.listCategories = body.Data;
          this.showOnlyWithSon();
          return this.listCategories[0].Son.filter(cat => cat.Name === 'Nacionales');
        } else {
          return [];
        }
      }));
  }

  public showOnlyWithSon(): void {
    if (this.listCategories && this.listCategories.length) {
      this.organizedCategoriesList(this.listCategories);
      for (let i = 0; i < this.listCategories.length; i++) {
        if (this.listCategories[i].IdParent || !this.listCategories[i].Son.length) {
          this.listCategories.splice(i, 1);
          i--;
        }
      }
    }
  }

  private organizedCategoriesList(list: any): CategoryModel[] {
    const categories: CategoryModel[] = [];
    for (let i = 0; i < list.length; i++) {
      this.createCategorySonObject(list[i]);
      for (let j = 0; j < list.length; j++) {
        if (list[i].Id === list[j].IdParent) {
          list[j].Show = false;
          list[i].Son.push(list[j]);
          list[j].touched = true;
        }
      }
      if (list[i].IdParent === null) {
        categories.push(this.getCategoryMap(list[i]));
      }

    }
    return categories;
  }

  private createCategorySonObject(model: any): void {
    if (!model.Son) {
      model.Son = [];
    }
  }

  private getCategoryMap(model: any): CategoryModel {
    let son = null;
    if (model.Son && model.Son.length) {
      son = model.Son;
    }
    return new CategoryModel(model.Name, model.IdParent, model.ProductType, son, model.Id);
  }
}
