<mat-drawer-container class="drawer-container brands-container" [hasBackdrop]="true">
  <mat-drawer #drawer [mode]="'over'" [position]="'end'" class="example-sidenav" [disableClose]="true">
    <form class="basic-info" [formGroup]="filterBrands" class="brands-filter-sidenav">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <h3>{{'secure.parametize.brands.title_filter_brand' | translate}}</h3>
        <button id="closeMenuApplication" type="button" color="primary" [matTooltip]="'actions.close' | translate"
          matTooltipPosition="below" mat-icon-button mat-button (click)="drawer.toggle()">
          <mat-icon>clear</mat-icon>
        </button>
      </div>
      <div fxLayout="column" fxLayoutAlign="space-between" fxLayout.xs="column">
        <mat-form-field color="primary">
          <input matInput [placeholder]="'shared.id' | translate" formControlName="filterBrandsId"
            [errorStateMatcher]="matcher">
          <mat-hint></mat-hint>
          <mat-error *ngIf="filterBrands.get('filterBrandsId').hasError('pattern')">{{'errors.invalid_id'}}</mat-error>
        </mat-form-field>
        <mat-form-field color="primary">
          <input matInput [placeholder]="'secure.parametize.brands.txt_brand_name' | translate"
            style="text-transform: uppercase;" formControlName="filterBrandsName" [errorStateMatcher]="matcher">
          <mat-hint></mat-hint>
          <mat-error *ngIf="filterBrands.get('filterBrandsName').hasError('pattern')">
            {{'errors.brand_invalidate' | translate}}</mat-error>
        </mat-form-field>
        <div fxLayout="row" fxLayoutAlign="space-between center" class="buttonsSend">
          <button mat-stroked-button color="primary" type="button" (click)="cleanAllFilter()">{{'filters.clear' |
            translate}}</button>
          <button mat-flat-button color="primary" type="submit" [disabled]="filterBrands && filterBrands.invalid"
            (click)="filterApply(drawer)">{{'filters.filter' | translate}}</button>
        </div>
      </div>
    </form>
  </mat-drawer>

  <mat-toolbar class="tree-toolbar">
    <div class="normal-theme toolbar-options-table" fxLayout="row" fxLayoutAlign="end center">
      <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
        (page)="pageEvent = changePaginatorBrands($event)"></mat-paginator>
    </div>
    <button type="button" mat-button (click)="drawer.toggle()">
      <mat-icon>filter_list</mat-icon>
      <small class="sub-title-filter">{{'filters.filter' | translate}}</small>
    </button>
  </mat-toolbar>
  <div fxLayout="column">
    <div class="listBrands">
      <div fxLayout="row" fxLayoutAlign="space-between start" class="headerActions">
        <div class="brands-label-filter">
          <div class="example-chip-list">
            <mat-chip-list #chipList>
              <mat-chip *ngFor="let productsFilter of listFilterBrands" [selectable]="selectable"
                [removable]="removable" (removed)="remove(productsFilter)">
                {{productsFilter.name}}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
              </mat-chip>
              <input [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" disabled>
            </mat-chip-list>
          </div>
        </div>
        <div class="brands-button-add">
          <div id="addButton" class="container-group-add-button">
            <input placeholder="file upload" id="uploadFile" hidden name="fileUploadOption" type="file"
              (change)="onFileChange($event)" multiple="false"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              #fileUploadOption>
            <button mat-button mat-stroked-button *ngIf="canCreate && canRead" (click)="upsetBrands()"
              id="addUnitBrand">
              <strong>{{'secure.parametize.brands.btn_add_brand'| translate}}</strong>
            </button>
            <button mat-button mat-stroked-button (click)="fileUploadOption.click()" id="addMassiveBrand"
              color="primary"><strong>{{'secure.parametize.brands.btn_add_brand_massive' | translate}}</strong></button>
          </div>
          <div>
            <a href="{{urlDownloadFile}}" target="_parent">
              <button color="primary" class="sc-default-button" id="download-file-button" mat-button>
                Descargar formato
              </button>
            </a>
          </div>
        </div>
      </div>
      <table matSort (matSortChange)="sortData($event)" class="table mat-table">
        <tr class="mat-header-row">
          <th id="" style="font-weight: inherit; font-size: 14px;"
            class="mat-header-cell cdk-column-position mat-column-position mat-style-tr">{{'shared.id' | translate}}
          </th>
          <th id="" style="font-weight: inherit; font-size: 14px;"
            class="mat-header-cell cdk-column-position mat-column-position mat-style-tr">
            {{'secure.parametize.brands.txt_brand_name'| translate}}</th>
          <th id="" style="font-weight: inherit; font-size: 14px;"
            class="mat-header-cell cdk-column-position mat-column-position mat-style-tr">{{'shared.status' | translate}}
          </th>
          <th id="" style="font-weight: inherit; font-size: 14px;"
            class="mat-header-cell cdk-column-position mat-column-position mat-style-tr">
            {{'shared.actions' | translate}}</th>
        </tr>
        <tr style="height: 56px;" *ngFor="let brand of sortedData" class="mat-row">
          <td class="mat-cell mat-style-td">{{brand.Id}}</td>
          <td class="mat-cell mat-style-td">{{brand.Name}}</td>
          <td class="mat-cell mat-style-td">
            <section class="example-section">
              <mat-slide-toggle class="example-margin" [color]="color" id="id_slide_toggle_{{brand.Id}}"
                (click)="changeStatusBrands($event, brand)"
                [matTooltip]="brand.Status == true ? ('actions.disable' | translate) : ('actions.enable'| translate)"
                [checked]=brand.Status>
              </mat-slide-toggle>
            </section>
          </td>
          <td class="mat-cell mat-style-td">
            <button mat-icon-button [matTooltip]="'actions.edit' | translate" *ngIf="canUpdate"
              (click)="upsetBrands(brand)">
              <mat-icon class="edit-profile material-icons">create</mat-icon>
            </button>
          </td>
        </tr>
      </table>
      <div *ngIf="!sortedData" class="brands-table-null">
        <label class="">
          {{'secure.parametize.brands.txt_no_brands'| translate}}
        </label>
      </div>
    </div>
    <div *ngIf="!canRead" class="brands-not-permission">
      <div class="brands-permission">
        <label>{{'secure.parametize.brands.txt_no_permissions_view'| translate}}</label>
      </div>
      <div class="brands-permission brands-icon-permission">
        <mat-icon class="material-icons">
          visibility_off
        </mat-icon>
      </div>
    </div>
  </div>
</mat-drawer-container>
<ng-template #dialogContent>
  <form [formGroup]="form" class="full-width brands-modal-margin">
    <div class="brands-input-spinner">
      <mat-form-field class="half-width">
        <input matInput [placeholder]="'secure.parametize.brands.txt_brand_name'| translate"
          style="text-transform: uppercase;" formControlName="nameBrands" [errorStateMatcher]="matcher"
          (blur)="validateExist($event)" required>
        <mat-error *ngIf="form.get('nameBrands').hasError('required')">{{'errors.required' | translate}}</mat-error>
        <mat-error *ngIf="form.get('nameBrands').hasError('pattern')">{{'errors.format_invalidate' | translate}}
        </mat-error>
      </mat-form-field>
      <mat-spinner [diameter]="20" *ngIf="showSpinner"></mat-spinner>
    </div>
    <div class="brands-position-option">
      <button type="button" mat-button color="primary" (click)="onNoClick()">{{'actions.cancel' | translate}}</button>
      <button type="submit" mat-raised-button color="primary" class="brands-position-option-cancel"
        (click)="confirmation()" [disabled]="form && form.invalid || validateExit">{{'actions.accept' |
        translate}}</button>
    </div>
  </form>
</ng-template>