<div class="container-error">

  <div class="name-file-upload">
    <p>{{'secure.products.table_errors_product.selected_file' | translate}}:</p>
    <b>{{ fileName}}</b>
  </div>
  <mat-expansion-panel expanded="true" [@detailExpand] class="list-information-table card-err-process"
                        *ngIf="listLog.length != 0">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="title-expansion-panel-table">
          <mat-icon>warning</mat-icon>
          <span>{{countErrors}}</span>
          <b>{{'errors.errors_detected' | translate}}</b>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="error-list-content">

      <mat-nav-list dense>
        <mat-list-item>
          <p class="wrap" matLine>{{'errors.error_guide_upload' | translate}}.</p>
        </mat-list-item>
        <mat-list-item id="error-list-guide-button" (click)="loadGuide.selectErrorLog(item)"
                       *ngFor="let item of listLog">
          <mat-icon class="icon-color-error">warning</mat-icon>
          <p matLine *ngIf="item.type == 'NumberFormat'">{{'secure.products.table_errors_product.column_data' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.column' | translate}} #{{ item.columna }}</span> {{'secure.products.table_errors_product.for' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.row' | translate}} #{{item.fila}}</span> {{'secure.load_guide_page.table_errors.numeric' | translate}}</p>
          <p matLine *ngIf="item.type == 'dateNotFound'">{{'secure.load_guide_page.table_errors.missinf_info' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.column' | translate}} #{{ item.columna }}</span> {{'secure.products.table_errors_product.for' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.row' | translate}} #{{item.fila}}</span>
          </p>
        </mat-list-item>
      </mat-nav-list>
    </div>
  </mat-expansion-panel>

  <mat-card class="card-ok-process" *ngIf="countErrors == 0">
    <div class="succes-data">
      <mat-icon>check</mat-icon>
      {{'secure.load_guide_page.table_errors.ok' | translate}}
    </div>
    <mat-card-actions>
      <button id="finish-send-guide-button" (click)="loadGuide.sendJsonInformation()"
              [disabled]="countErrors != 0 || countRowUpload == 0"
              mat-raised-button class="sc-default-button full-width" color="accent">{{'secure.products.bulk_upload.send_data' | translate}}
      </button>
    </mat-card-actions>
  </mat-card>
</div>
