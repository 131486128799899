<div fxLayout="row wrap">
  <div fxFlex="100" class="modal-contact-perfil-text-align-end">
    <mat-icon class="mat-18 modal-contact-perfil-icon-close-modal"
      [matTooltip]="'actions.close' |
      translate" (click)="close()">close</mat-icon>
  </div>
</div>
<div fxLayout="row wrap" *ngIf="!success">
  <div fxFlex="100" class="modal-contact-perfil-text-align-center">
    <span class="modal-contact-perfil-font-size-font-bold">{{'secure.profiel.edit_contact'
      | translate}}</span>
  </div>
</div>
<div *ngIf="form && !success">
  <div fxLayout="row wrap" class="modal-contact-perfil-width-100">
    <form #contactForm="ngForm" [formGroup]="form"
      class="modal-contact-perfil-width-100">
      <div fxLayout="row wrap" class="modal-contact-perfil-padding-20-40-width">
        <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="50" fxFlex.lg="50"
        fxFlex.xl="50" fxFlex="50" class="modal-contact-perfil-padding-right-20">
          <mat-form-field class="modal-contact-perfil-width-100">
            <mat-label>{{'secure.profiel.responsible_area' | translate}}</mat-label>
            <mat-select formControlName="translate" [(value)]="translate">
              <mat-option *ngFor="let area of data.arrayListArea"
                [value]="area.Traduction">
                {{area.Traduction}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="50" fxFlex.lg="50"
        fxFlex.xl="50" fxFlex="50" class="modal-contact-perfil-padding-right-20">
          <mat-form-field
            class="modal-contact-perfil-width-100">
            <input matInput
              [placeholder]="'secure.profiel.contact_name_surname'| translate"
              class="modal-export-to-reclaim-text-uppercase"
              [errorStateMatcher]="matcher" formControlName="contactName">
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row wrap" class="modal-contact-perfil-width-padding">
        <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="50" fxFlex.lg="50"
        fxFlex.xl="50" fxFlex="50" class="modal-contact-perfil-padding-right-20">
          <mat-form-field
            class="modal-contact-perfil-width-100">
            <input matInput [placeholder]="'secure.profiel.position' |
              translate"
              class="modal-export-to-reclaim-text-uppercase"
              [errorStateMatcher]="matcher" formControlName="role">
          </mat-form-field>
        </div>
        <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="50" fxFlex.lg="50"
        fxFlex.xl="50" fxFlex="50" class="modal-contact-perfil-padding-right-20">
          <mat-form-field
            class="modal-contact-perfil-width-100">
            <input matInput
              [placeholder]="'secure.seller.register_seller.lb_email_form' |
              translate"
              class="modal-export-to-reclaim-text-uppercase"
              [errorStateMatcher]="matcher" formControlName="email">
              <mat-error *ngIf="form.get('email').hasError('pattern')">{{'errors.format_invalidate'
                | translate}}
              </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row wrap" class="modal-contact-perfil-width-padding">
        <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="50" fxFlex.lg="50"
        fxFlex.xl="50" fxFlex="50" class="modal-contact-perfil-padding-right-20">
          <mat-form-field 
            class="modal-contact-perfil-width-100">
            <input matInput [placeholder]="'secure.profiel.cellPhone' |
              translate"
              (focusout)="validateOutFocus_CellPhone()"
              class="modal-export-to-reclaim-text-uppercase"
              [errorStateMatcher]="matcher" formControlName="cellPhone"
              maxlength="12">
            <mat-error *ngIf="form.get('cellPhone').hasError('pattern')">{{'errors.format_invalidate'
              | translate}}
            </mat-error>
            <mat-error *ngIf="form.get('cellPhone').hasError('required')">{{'errors.required'
              | translate}}</mat-error>
          </mat-form-field>
        </div>
        <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="50" fxFlex.lg="50"
        fxFlex.xl="50" fxFlex="50" class="modal-contact-perfil-padding-right-20">
          <mat-form-field 
            class="modal-contact-perfil-width-100">
            <input matInput [placeholder]="'secure.profiel.phone_number' |
              translate"
              class="modal-export-to-reclaim-text-uppercase"
              (focusout)="validateOutFocus_Phone()"
              [errorStateMatcher]="matcher" formControlName="phone"
              maxlength="30">
            <mat-error *ngIf="form.get('phone').hasError('pattern')">{{'errors.format_invalidate'
              | translate}}
            </mat-error>
            <mat-error *ngIf="form.get('phone').hasError('required')">{{'errors.required'
              | translate}}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
  <div fxLayout="row wrap" class="modal-contact-perfil-width-padding"
    fxLayoutAlign="center center"
    class="modal-contact-perfil-padding-top-30">
    <div fxFlex.xs="50" fxFlex.sm="50" fxFlex.md="50" fxFlex.lg="50"
      fxFlex.xl="50" fxFlex="50">
      <button mat-stroked-button color="primary"
        class="modal-contact-perfil-margin-transform-uppercase" mat-button
        (click)="close()">{{'actions.cancel' | translate}}</button>
    </div>
    <div fxFlex.xs="50" fxFlex.sm="50" fxFlex.md="50" fxFlex.lg="50"
      fxFlex.xl="50" fxFlex="50">
      <button mat-raised-button
        class="modal-contact-perfil-text-transform-uppercase"
        [disabled]="!contactForm.form.valid"
        color="primary" mat-button
        (click)="validContact()">{{'secure.profiel.edit_contact' | translate}}</button>
    </div>
  </div>
</div>
<div *ngIf="!form && !success"
  class="modal-contact-perfil-text-align-center-padding-top">
  <div class="modal-contact-perfil-margin-left-47">
    <mat-spinner class="processCharge" [diameter]="40"></mat-spinner>
  </div>
  <div class="modal-contact-perfil-padding-top-20">
    <span>
      {{'secure.products.create_product_unit.list_products.please_wait' |
      translate}}
    </span>
  </div>
</div>

<div *ngIf="success">
  <div class="modal-contact-perfil-text-align-center">
    <div class="modal-contact-perfil-container-icon-check">
      <mat-icon class="material-icons modal-contact-perfil-icons-white-margin">
        check
      </mat-icon>
    </div>
    <div class="modal-contact-perfil-padding-top-bottom">
      <span class="modal-contact-perfil-font-size-weight">
        {{'secure.profiel.modal.message.text'| translate}}
      </span>
    </div>
    <div>
      <div>
        <button mat-raised-button color="primary"
          class="modal-contact-perfil-text-transform-uppercase" mat-button
          (click)="close()">{{'actions.accept' | translate}}</button>
      </div>
    </div>
  </div>
</div>