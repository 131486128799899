<form class="form-signin" method="POST" action="#" role="form">
    <div class="form-group">
        <h2>Crear una cuenta</h2>
    </div>
    <div *ngIf="errorMessage!=null" class="alert alert-danger">
        {{ errorMessage }}
    </div>
    <div class="form-group">
        <label class="control-label" for="signupName">Nombre</label>
        <input id="signupName" type="text" maxlength="50" class="form-control"
               [(ngModel)]="registrationUser.name" [ngModelOptions]="{standalone: true}">
    </div>
    <div class="form-group">
        <label class="control-label" for="signupEmail">Email</label>
        <input id="signupEmail" type="email" maxlength="50" class="form-control"
               [(ngModel)]="registrationUser.email" [ngModelOptions]="{standalone: true}">
    </div>
    <div class="form-group">
        <label class="control-label" for="signupPhone">Tel&eacute;fono</label>
        <input id="signupPhone" type="text" maxlength="13" class="form-control"
               [(ngModel)]="registrationUser.phone_number" [ngModelOptions]="{standalone: true}">
    </div>
    <div class="form-group">
        <label class="control-label" for="signupPassword">Contrase&ntilde;a</label>
        <input id="signupPassword" type="password" maxlength="25" class="form-control"
               placeholder="at least 8 characters" length="40" [(ngModel)]="registrationUser.password"
               [ngModelOptions]="{standalone: true}">
    </div>
    <div class="form-group">
        <button (click)="onRegister()" id="signupSubmit" type="submit" class="btn btn-info btn-block">
            Crea tu cuenta
        </button>
    </div>
    <hr>
    <p>Ya tienes una cuenta? <a [routerLink]="['/home/login']"> <i class="fa fa-fw fa-lock"></i>Entrar</a></p>
</form>

