import { RoutesConst } from '@app/shared';

/**
 * Clase con la estructura de datos del modulo.
 *
 * @export
 * @class ModuleModel
 */
export class ModuleModel {
    NameModule: string;
    ShowModule: boolean;
    NameModuleBack: string;
    Menus: MenuModel[];

    /**
     * Cuanto se crea una instancia del modelo de modulos.
     * @param {string} nameModule Nombre del modulo.
     * @param {boolean} showModule Variable para mostrar o no el modulo. Usada en el menu lateral y horizontal.
     * @param {string} nameModuleBack Nombre del modulo en back o guardado en base de datos.
     * @param {MenuModel[]} menus Lista de menus que posee el modulo.
     * @memberof ModuleModel
     */
    constructor(
        nameModule: string,
        showModule: boolean,
        nameModuleBack: string,
        menus: MenuModel[]) {
        this.NameModule = nameModule;
        this.ShowModule = showModule;
        this.NameModuleBack = nameModuleBack;
        this.Menus = menus;
    }
}

/**
 * Clase con la estructura de datos del menu.
 *
 * @export
 * @class MenuModel
 */
export class MenuModel {
    NameMenu: string;
    ShowMenu: boolean;
    NameMenuBack: string;
    ProfileType: number;
    Functionalities: FunctionalitiesModel[];
    UrlRedirect: string;
    Id: string;
    ShowMenuProduction = true;

    /**
     * Cuanto se crea una instancia del modelo de menus.
     * @param {string} nameMenu Nombre del menu.
     * @param {boolean} showMenu Variable para mostrar o no el menu. usado en el menu lateral y horizontal, o para ingresar a las componentes.
     * @param {string} nameMenuBack Nombre del menu en back para mapearlo.
     * @param {number} profileType Tipo del perfil (mirar constante de ProfileTypes).
     * @param {FunctionalitiesModel[]} functionalities Lista de funcionalidades.
     * @param {string} urlRedirect Url que muestra el componente (mirar constante de RoutesConst).
     * @param {string} [id] id del menu.
     * @param {string} [showMenuProduction] Mostrar en produccion.
     * @memberof MenuModel
     */
    constructor(
        nameMenu: string,
        showMenu: boolean,
        nameMenuBack: string,
        profileType: number,
        functionalities: FunctionalitiesModel[],
        urlRedirect: string,
        id?: string,
        showMenuProduction?: boolean
    ) {
        this.NameMenu = nameMenu;
        this.ShowMenu = showMenu;
        this.NameMenuBack = nameMenuBack;
        this.ProfileType = profileType;
        this.Functionalities = functionalities;
        this.UrlRedirect = urlRedirect;
        this.Id = id;
        this.ShowMenuProduction = showMenuProduction === false ? false : true;
    }
}

/**
 * Clase con la estructura de datos de las funcionalidades.
 *
 * @export
 * @class MenuModel
 */
export class FunctionalitiesModel {
    NameFunctionality: string;
    ShowFunctionality: boolean;
    nameFunctionalityBack: string;

    /**
     * Cuanto se crea una instancia del modelo de funcionalidades.
     * @param {string} nameFunctionality Nombre de la funcionalidad.
     * @param {boolean} showFunctionality Mostrar o no la funcionalidad en los componentes.
     * @param {string} nameFunctionalityBack Nombre de la funcionalidad en back para mapearlo
     * @memberof FunctionalitiesModel
     */
    constructor(
        nameFunctionality: string,
        showFunctionality: boolean,
        nameFunctionalityBack: string
    ) {
        this.NameFunctionality = nameFunctionality;
        this.ShowFunctionality = showFunctionality;
        this.nameFunctionalityBack = nameFunctionalityBack;
    }
}

// 1, Administrador 0. Vendedor.
export const ProfileTypes = {
    Vendedor: 0,
    Administrador: 1
};

const showAll = false;


/**
 * Actualizado: 29-01-2019 - lecheverry. 01-03-2019 jbanguera
 * @version 1.1 (Creación del archivo).
 *
 * Para incluir un menu en el modulo de ordenes o funcionalidad en algun menu ya creado. por favor actualizar este objeto la version y fechas.
 *
 * Modulo de ordenes que posee menus:
 * 1. Todas.
 * 2. Por enviar.
 * 3. Enviadas.
 * 4. Cargar guias
 * 5. Facturación electronica.
 */
export const orderModule = 'Órdenes'
export const productAndOffers = 'Productos y ofertas'
export const claimsAndReturns = 'Reclamaciones y Cancelaciones'
export const finances = 'Finanzas'
export const seller = 'Vendedor'
export const configuration = 'Configuración'
export const pedidos = 'Pedidos'
 export const allName = 'Todas';
export const toSendName = 'Por enviar';
export const sendedName = 'Enviadas';
export const onlineBillName = 'Factura Electrónica';
export const dashboardName = 'Dashboard';
export const guideChargesName = 'Carga masiva de Guías';
export const bulkLoadBilling = 'Carga masiva de Facturas';
export const freightQuoter = 'Cotizador de flete';
export const billingSeller = 'Facturación vendedor';
export const readFunctionality = 'Consultar';
export const downloadFunctionality = 'Descargar';
export const updateFunctionality = 'Editar';
export const loadFunctionality = 'Cargar';
export const createFunctionality = 'Crear';
export const visualizeFunctionality = 'Visualizar';
export const enableFunctionality = 'Habilitar';
export const disableFunctionality = 'Deshabilitar';
export const vacationFunctionality = 'Vacaciones';
export const cancelVacacionFunctionality = 'Cancelar Vacaciones';
export const deleteFunctionality = 'Eliminar';
export const attachmentFunctionality = 'Adjuntar';
export const sendFunctionality = 'Enviar';
export const marketFuncionality = 'Marcar';
export const offerFuncionality = 'Ofertar';
export const idSended = '170';
export const idToSend = '35';
export const acceptFuncionality = 'Aceptar';
export const refuseFuncionality = 'Rechazar';
export const editException = 'Editar Excepción';
export const readException = 'Consultar excepción';

export const offersModule = 'OFERTAS';
export const offerListName = 'Listado de Ofertas';
export const offerListNameAdmin = 'Listado de Ofertas';
export const bulkLoadName = 'Carga Masiva de Ofertas';
export const bulkLoadHistoryName = 'Histórico de Carga de Ofertas';
export const bulkLoadHistoryNameAdmin = 'Histórico de Carga de ofertas';
export const citiesCoverageName = 'Ciudades con Cobertura';

export const productsModule = 'PRODUCTOS';
export const unitProductCreation = 'Creación unitaria de producto';
export const bulkLoadProductName = 'Creación Masiva de producto'; 
export const moderateName = 'Moderación de productos';
export const listProductsName = 'Listado de productos';
export const pendingApproval = 'Pendientes de aprobación';
export const claimListName = 'Listado De Reclamaciones';


export const fulfillmentModuleName = 'Fulfillment';
export const fulfillmentSupplyOrdersName = 'Ordenes abastecimiento';
export const fulfillmentProductRegistrationName = 'Inscripción Productos';
export const fulfillmentInventoryName = 'Inventario';
export const fulfillmentOffersName = 'Ofertas';
export const fulfillmentOrdersName = 'Pedidos';
export const fulfillmentReportsName = 'Reportes';

export const devolutionsModule = 'DEVOLUCIONES';
export const pendingName = 'Solicitudes pendientes';
export const devolutionName = 'En devolución';
export const validationName = 'En validación';
export const historicDevolution = 'Historico de cancelaciones';
export const listCancelOrders = 'Listado de cancelaciones';

export const billingModule = 'FACTURACIÓN';
export const paidName = 'Detalle de Pagos';
export const paymentSummary = 'Resumen de Pagos';

export const sellerModule = 'VENDEDORES';
export const registerName = 'Registrar Nuevo';
export const administrateName = 'Administrar';
export const agreementName = 'Acuerdo';
export const sellerListName = 'Listado de Vendedores';
export const uploadAgreement = 'Cargar Acuerdos';
export const manageAgreement = 'Administrar Acuerdos';

export const calificationModule = 'CALIDAD'
export const listCalification = 'Calificación de Vendedores'
export const qualityIndicators = 'Indicadores de calidad';

export const reportModule = 'REPORTES'
export const reportOffertAdmin = 'Reporte de ofertas'
export const reportErrorsVtex = 'Reporte de errores en VTEX'
export const reportCommission = 'Reporte de comisiones'
export const reportDispersion = 'Reporte cobros pendientes MPI'
export const fraudNotification = 'Notificación de Fraudes'
export const sellerContacts = 'Reporte contactos vendedor' 
export const reportOrders = 'Reporte de órdenes';

export const quoteName = 'Cotizador de flete';
export const transportName = 'transportadora';
export const zonesName = 'zonas';
export const specsName = 'Especificaciones';
export const assignCommision = 'Asignar comisión';
export const profileName = 'Perfiles';
export const categoryName = 'Árbol de categorías';
export const brandName = 'Marcas';
export const portName = 'Parametrizar Centros de Acopio';
export const exception = 'Excepción de comisión';
export const sizeName = 'Tallas';
export const advertisement = 'Anuncios';
export const notifications = 'Notificación';
export const dispersionModule = 'DISPERSION'
export const summaryPaymentAdmin = 'Resumen de pagos'
export const detailPaymentAdmin = 'Detalle de dispersión';

export const documentModule = 'Documentación'
export const apiName = 'API'
export const apiAngularName = 'App angular'
export const apiAdminName = 'API'
export const apiAngularAdminName = 'App angular'
export const agreementNameSeller = 'Acuerdos aprobados';

// ----------------------------------INICIO: NUEVOS MÓDULOS-----------------------------------------------------------

const Fulfillment = new ModuleModel(fulfillmentModuleName, showAll, fulfillmentModuleName.toLowerCase(), [
    // 1. Órdenes abastecimiento (Rol administrador - Consultar).
    new MenuModel(fulfillmentSupplyOrdersName, showAll, fulfillmentSupplyOrdersName.toLowerCase(), ProfileTypes.Administrador, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar.
    ], RoutesConst.sellerCenterFulfillmentOrders),
    // 2. Inventario (Rol administrador - Consultar).
    new MenuModel(fulfillmentInventoryName, showAll, fulfillmentInventoryName.toLowerCase(), ProfileTypes.Administrador, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar.
    ], RoutesConst.sellerCenterFulfillment),
    // 3. Pedidos (Rol administrador - Consultar).
    new MenuModel(fulfillmentOrdersName, showAll, fulfillmentOrdersName.toLowerCase(), ProfileTypes.Administrador, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar.
    ], RoutesConst.sellerCenterFulfillment),
    // 4. Reportes (Rol administrador - Consultar).
    new MenuModel(fulfillmentReportsName, showAll, fulfillmentReportsName.toLowerCase(), ProfileTypes.Administrador, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar.
    ], RoutesConst.sellerCenterFulfillment),
    // 5. Inscripción de productos (Rol vendedor - Consultar).
    new MenuModel(fulfillmentProductRegistrationName, showAll, fulfillmentProductRegistrationName.toLowerCase(), ProfileTypes.Vendedor, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar.
    ], RoutesConst.sellerCenterFulfillmentEnrollment),
    // 6. Órdenes abastecimiento (Rol vendedor - Consultar).
    new MenuModel(fulfillmentSupplyOrdersName, showAll, fulfillmentSupplyOrdersName.toLowerCase(), ProfileTypes.Vendedor, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar.
    ], RoutesConst.sellerCenterFulfillmentOrders),
    // 7. Ofertas (Rol vendedor - Consultar).
    new MenuModel(fulfillmentOffersName, showAll, fulfillmentOffersName.toLowerCase(), ProfileTypes.Vendedor, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar.
    ], RoutesConst.sellerCenterFulfillment),
    // 8. Pedidos (Rol vendedor - Consultar).
    new MenuModel(fulfillmentOrdersName, showAll, fulfillmentOrdersName.toLowerCase(), ProfileTypes.Vendedor, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar.
    ], RoutesConst.sellerCenterFulfillment),
    // 9. Reportes (Rol vendedor - Consultar).
    new MenuModel(fulfillmentReportsName, showAll, fulfillmentReportsName.toLowerCase(), ProfileTypes.Vendedor, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar.
    ], RoutesConst.sellerCenterFulfillment),
]);

const ProductAndOffers = new ModuleModel(productAndOffers, showAll, productAndOffers.toLowerCase(), [
    // 1. Creación unitaria de producto (Rol vendedor - Agregar, Editar).
    new MenuModel(unitProductCreation, showAll, unitProductCreation.toLowerCase(), ProfileTypes.Vendedor, [
        new FunctionalitiesModel(createFunctionality, showAll, createFunctionality), // Agregar
        new FunctionalitiesModel(updateFunctionality, showAll, updateFunctionality), // Editar
    ], RoutesConst.sellerCenterIntCreateUnutaryProduct),
    // 2. Creación masiva de producto (Rol vendedor - Cargar).
    new MenuModel(bulkLoadProductName, showAll, bulkLoadProductName.toLowerCase(), ProfileTypes.Vendedor, [
        new FunctionalitiesModel(loadFunctionality, showAll, loadFunctionality), // Cargar
    ], RoutesConst.sellerCenterIntProductBulkLoad),
    // 3. Creación masiva de producto (Rol administrador - Cargar).
    new MenuModel(bulkLoadProductName, showAll, bulkLoadProductName.toLowerCase(), ProfileTypes.Administrador, [
        new FunctionalitiesModel(loadFunctionality, showAll, loadFunctionality), // Cargar
    ], RoutesConst.sellerCenterIntProductBulkLoad),
    // 4. Creación unitaria de producto (Rol administrador - Agregar, Editar).
    new MenuModel(unitProductCreation, showAll, unitProductCreation.toLowerCase(), ProfileTypes.Administrador, [
        new FunctionalitiesModel(createFunctionality, showAll, createFunctionality), // Agregar
        new FunctionalitiesModel(updateFunctionality, showAll, updateFunctionality), // Editar
    ], RoutesConst.sellerCenterIntCreateUnutaryProduct),
    // 5. Listado de productos (Rol vendedor - Consultar, Editar, Ofertar).
    new MenuModel(listProductsName, showAll, listProductsName.toLowerCase(), ProfileTypes.Vendedor, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar.
        new FunctionalitiesModel(updateFunctionality, showAll, updateFunctionality), // Editar
        new FunctionalitiesModel(offerFuncionality, showAll, offerFuncionality), // Ofertar
    ], RoutesConst.sellerCenterIntListProducts),
    // 6. Listado de productos (Rol vendedor - Consultar, Editar, Eliminar).
    new MenuModel(listProductsName, showAll, listProductsName.toLowerCase(), ProfileTypes.Administrador, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar.
        new FunctionalitiesModel(updateFunctionality, showAll, updateFunctionality), // Editar
        new FunctionalitiesModel(deleteFunctionality, showAll, deleteFunctionality), // Eliminar
    ], RoutesConst.sellerCenterIntListProducts),
    // 7. Pendientes de aprobación (Rol vendedor - Consultar, Editar).
    new MenuModel(pendingApproval, showAll, pendingApproval.toLowerCase(), ProfileTypes.Vendedor, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar.
        new FunctionalitiesModel(updateFunctionality, showAll, updateFunctionality), // Editar
    ], RoutesConst.sellerCenterIntPendingProducts),
    // 8. Moderación de productos (Rol vendedor - Cargar).
    new MenuModel(moderateName, showAll, moderateName.toLowerCase(), ProfileTypes.Administrador, [
        new FunctionalitiesModel(loadFunctionality, showAll, loadFunctionality), // Cargar
    ], RoutesConst.sellerCenterProductModerationBulkLoad),
    // 9. Listado de ofertas (Rol vendedor - Consultar, Editar).
    new MenuModel(offerListName, showAll, offerListName.toLowerCase(), ProfileTypes.Vendedor, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar.
        new FunctionalitiesModel(updateFunctionality, showAll, updateFunctionality), // Editar
    ], RoutesConst.sellerCenterIntOfersList),
    // 10. Listado de ofertas (Rol administrador - Consultar).
    new MenuModel(offerListNameAdmin, showAll, offerListNameAdmin.toLowerCase(), ProfileTypes.Administrador, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar.
    ], RoutesConst.sellerCenterIntOfersListAdmin),
    // 11. Carga masiva de ofertas (Rol vendedor - Cargar).
    new MenuModel(bulkLoadName, showAll, bulkLoadName.toLowerCase(), ProfileTypes.Vendedor, [
        new FunctionalitiesModel(loadFunctionality, showAll, loadFunctionality), // Cargar
    ], RoutesConst.sellerCenterIntOferBulkLoad),
    // 12. Histórico de cargas de ofertas (Rol vendedor - Consultar, Descargar).
    new MenuModel(bulkLoadHistoryName, showAll, bulkLoadHistoryName.toLowerCase(), ProfileTypes.Vendedor, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar.
        new FunctionalitiesModel(downloadFunctionality, showAll, downloadFunctionality), // Descargar.
    ], RoutesConst.sellerCenterIntOferHistoricalBulkLoad),
    // 13. Histórico de cargas de ofertas (Rol vendedor - Consultar, Descargar).
    new MenuModel(bulkLoadHistoryNameAdmin, showAll, bulkLoadHistoryNameAdmin.toLowerCase(), ProfileTypes.Administrador, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar.
        new FunctionalitiesModel(downloadFunctionality, showAll, downloadFunctionality), // Descargar.
    ], RoutesConst.sellerCenterIntOferHistoricalBulkLoadAdmin),
]);


const ClaimsAndReturns = new ModuleModel(claimsAndReturns, showAll, claimsAndReturns.toLowerCase(), [
    // 1. Listado De Reclamaciones (Rol vendedor - Consultar).
    new MenuModel(claimListName, showAll, claimListName.toLowerCase(), ProfileTypes.Vendedor, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar
    ], RoutesConst.sellerCenterCases),
    // 2. Listado De Reclamaciones (Rol Administrador - Consultar).
    new MenuModel(claimListName, showAll, claimListName.toLowerCase(), ProfileTypes.Administrador, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar
    ], RoutesConst.sellerCenterCases),
    // 3. Listado de cancelaciones Administrador
    new MenuModel(listCancelOrders, showAll, listCancelOrders.toLowerCase(), ProfileTypes.Administrador, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar.
    ], RoutesConst.sellerCenterListCancelOrders),
    // 4. Listado de cancelaciones vendedor
    new MenuModel(listCancelOrders, showAll, listCancelOrders.toLowerCase(), ProfileTypes.Vendedor, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar.
        new FunctionalitiesModel(acceptFuncionality, showAll, acceptFuncionality), // Aceptar.
        new FunctionalitiesModel(refuseFuncionality, showAll, refuseFuncionality), // Rechazar.
    ], RoutesConst.sellerCenterListCancelOrders)
]);

const Finances = new ModuleModel(finances, showAll, finances.toLowerCase(), [
    // 1. Resumen de pagos (Rol vendedor - Consultar).
    new MenuModel(paymentSummary, showAll, paymentSummary.toLowerCase(), ProfileTypes.Vendedor, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar
    ], RoutesConst.sellerCenterIntPaymentSummary),
    // 2. Detalle de pagos (Rol vendedor - Consultar).
    new MenuModel(paidName, showAll, paidName.toLowerCase(), ProfileTypes.Vendedor, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar
    ], RoutesConst.sellerCenterIntBillingPayments),
    // 3. Facturación vendedor (Rol administrador - Consultar).
    new MenuModel(billingSeller, showAll, billingSeller.toLowerCase(), ProfileTypes.Administrador, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar
        new FunctionalitiesModel(downloadFunctionality, showAll, downloadFunctionality), // Descargar.
    ], RoutesConst.sellerCenterIntBillingPayments),
    // 4. Factura electrónica (Rol administrador - Consultar, Descargar).
    new MenuModel(onlineBillName, showAll, onlineBillName.toLowerCase(), ProfileTypes.Administrador, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar
        new FunctionalitiesModel(downloadFunctionality, showAll, downloadFunctionality), // Descargar.
    ], RoutesConst.sellerCenterIntOrderBillingOrders),
]);

const Seller = new ModuleModel(seller, showAll, seller.toLowerCase(), [
    // 1. Registrar Nuevo (Rol Administrador - Crear)
    new MenuModel(registerName, showAll, registerName.toLowerCase(), ProfileTypes.Administrador, [
        new FunctionalitiesModel(createFunctionality, showAll, createFunctionality) // Crear
    ], RoutesConst.sellerCenterIntSellerRegister),
    // 2. Administrar (Rol Administrador - Crear)
    new MenuModel(administrateName, showAll, administrateName.toLowerCase(), ProfileTypes.Administrador, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar
        new FunctionalitiesModel(updateFunctionality, showAll, updateFunctionality) // Editar
    ], RoutesConst.sellerCenterIntSellerManage),
    // 3. Acuerdos (Rol Administrador - Consultar, Visualizar)
    new MenuModel(agreementName, showAll, agreementName.toLowerCase(), ProfileTypes.Administrador, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar
        new FunctionalitiesModel(visualizeFunctionality, showAll, visualizeFunctionality), // Visualizar
    ], RoutesConst.sellerCenterIntSellerAgreements),
    // 4. Administar acuerdos -contratos- anexos (Rol Administrador - Consultar, Visualizar, Habilitar, Cargar)
    new MenuModel(manageAgreement, showAll, manageAgreement.toLowerCase(), ProfileTypes.Administrador, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar
        new FunctionalitiesModel(visualizeFunctionality, showAll, visualizeFunctionality), // Visualizar
        new FunctionalitiesModel(enableFunctionality, showAll, enableFunctionality), // Habilitar
        new FunctionalitiesModel(loadFunctionality, showAll, loadFunctionality) // Cargar
    ], RoutesConst.sellerCenterIntManageAgreement),
    // 5. Reporte contactos vendedor (Rol Administrador - Consultar)
    new MenuModel(sellerContacts, showAll, sellerContacts.toLowerCase(), ProfileTypes.Administrador, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar.
    ], RoutesConst.sellerCenterSellerContact),
    // 6. Calificación de vendedores (Rol Administrador - Consultar)
    new MenuModel(listCalification, showAll, listCalification.toLowerCase(), ProfileTypes.Administrador, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar.
    ], RoutesConst.sellerCenterIntListCalification),
    // 7. Indicadores de calidad (Rol Administrador - Consultar)
    new MenuModel(qualityIndicators, showAll, qualityIndicators.toLowerCase(), ProfileTypes.Administrador, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar.
    ], RoutesConst.sellerCenterIntConsultIndicators),
    // 8. Cargar acuerdos -contratos- anexos (Rol Administrador - Consultar, Visualizar, Habilitar, Deshabilitar, Cargar)
    new MenuModel(uploadAgreement, showAll, uploadAgreement.toLowerCase(), ProfileTypes.Administrador, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar
        new FunctionalitiesModel(visualizeFunctionality, showAll, visualizeFunctionality), // Visualizar
        new FunctionalitiesModel(enableFunctionality, showAll, enableFunctionality), // Habilitar
        new FunctionalitiesModel(disableFunctionality, showAll, disableFunctionality), // Deshabilitar
        new FunctionalitiesModel(loadFunctionality, showAll, loadFunctionality) // Cargar
    ], RoutesConst.sellerCenterIntUploadAgreement),
    // 9. Listado de Vendedores (Rol Administrador - Consultar, Visualizar, Habilitar, Deshabilitar, vacaciones, Cancelar vacaciones)
    new MenuModel(sellerListName, showAll, sellerListName.toLowerCase(), ProfileTypes.Administrador, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar
        new FunctionalitiesModel(visualizeFunctionality, showAll, visualizeFunctionality), // Visualizar
        new FunctionalitiesModel(enableFunctionality, showAll, enableFunctionality), // Habilitar
        new FunctionalitiesModel(disableFunctionality, showAll, disableFunctionality), // Deshabilitar
        new FunctionalitiesModel(vacationFunctionality, showAll, vacationFunctionality), // vacaciones
        new FunctionalitiesModel(cancelVacacionFunctionality, showAll, cancelVacacionFunctionality) // Cancelar vacaciones
    ], RoutesConst.sellerCenterIntSellerList),
]);

const Configuration = new ModuleModel(configuration, showAll, configuration.toLowerCase(), [
    // 1. Árbol de categorías
    new MenuModel(categoryName, showAll, categoryName.toLowerCase(), ProfileTypes.Administrador, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar.
        new FunctionalitiesModel(updateFunctionality, showAll, updateFunctionality), // Editar.
        new FunctionalitiesModel(createFunctionality, showAll, createFunctionality), // Crear.
        new FunctionalitiesModel(deleteFunctionality, showAll, deleteFunctionality) // Eliminar.,
    ], RoutesConst.sellerCenterIntCategoryTree),
    // 2. Especificaciones.
    new MenuModel(specsName, showAll, specsName.toLowerCase(), ProfileTypes.Administrador, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar
        new FunctionalitiesModel(deleteFunctionality, showAll, deleteFunctionality), // Eliminar
        new FunctionalitiesModel(updateFunctionality, showAll, updateFunctionality), // Editar
        new FunctionalitiesModel(createFunctionality, showAll, createFunctionality) // Agregar
    ], RoutesConst.sellerCenterIntParamSpecs),
    // 3. Marcas.
    new MenuModel(brandName, showAll, brandName.toLowerCase(), ProfileTypes.Administrador, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar.
        new FunctionalitiesModel(updateFunctionality, showAll, updateFunctionality), // Editar.
        new FunctionalitiesModel(createFunctionality, showAll, createFunctionality) // Agregar.
    ], RoutesConst.sellerCenterIntParamBrand),
    // 4. Tallas
    new MenuModel(sizeName, showAll, sizeName.toLowerCase(), ProfileTypes.Administrador, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar.
        new FunctionalitiesModel(updateFunctionality, showAll, updateFunctionality), // Editar.
        new FunctionalitiesModel(createFunctionality, showAll, createFunctionality), // Agregar.
        new FunctionalitiesModel(deleteFunctionality, showAll, deleteFunctionality) // Eliminar.
    ], RoutesConst.sellerCenterIntParamSize),
    // 5. Perfiles.
    new MenuModel(profileName, showAll, profileName.toLowerCase(), ProfileTypes.Administrador, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar.
        new FunctionalitiesModel(updateFunctionality, showAll, updateFunctionality), // Editar.
        new FunctionalitiesModel(createFunctionality, showAll, createFunctionality) // Agregar.
    ], RoutesConst.sellerCenterIntSellerProfiles),
    // 6. Asignar Comisión.
    new MenuModel(assignCommision, showAll, assignCommision.toLowerCase(), ProfileTypes.Administrador, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar.
        new FunctionalitiesModel(updateFunctionality, showAll, updateFunctionality), // Editar.,
        new FunctionalitiesModel(readException, showAll, readException), // Consultar excepcion.
        new FunctionalitiesModel(editException, showAll, editException), // Editar excepción.,
    ], RoutesConst.sellerCenterIntOferTreeCategory),
    // 7. Excepción de comisión
    new MenuModel(exception, showAll, exception.toLowerCase(), ProfileTypes.Administrador, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar.
        new FunctionalitiesModel(updateFunctionality, showAll, updateFunctionality), // Editar.
        new FunctionalitiesModel(deleteFunctionality, showAll, deleteFunctionality) // Eliminar.
    ], RoutesConst.sellerCenterIntException),
    // 8. Anuncios
    new MenuModel(notifications, showAll, notifications.toLowerCase(), ProfileTypes.Administrador, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar.
        new FunctionalitiesModel(updateFunctionality, showAll, updateFunctionality), // Editar.
        // new FunctionalitiesModel(createFunctionality, showAll, createFunctionality) // Crear.
        new FunctionalitiesModel(deleteFunctionality, showAll, deleteFunctionality) // Eliminar.
    ], RoutesConst.sellerCenterIntNotification),
    // 9. Cotizador administrador.
    new MenuModel(quoteName, showAll, quoteName.toLowerCase(), ProfileTypes.Administrador, [
        new FunctionalitiesModel(`${readFunctionality} ${transportName}`, showAll, `${readFunctionality} ${transportName}`), // Consultar transportadora.
        new FunctionalitiesModel(`${createFunctionality} ${transportName}`, showAll, `${createFunctionality} ${transportName}`), // Crear transportadora.
        new FunctionalitiesModel(`${updateFunctionality} ${transportName}`, showAll, `${updateFunctionality} ${transportName}`), // Editar transportadora.
        new FunctionalitiesModel(`${deleteFunctionality} ${transportName}`, showAll, `${deleteFunctionality} ${transportName}`), // Eliminar transportador.
        new FunctionalitiesModel(`${readFunctionality} ${zonesName}`, showAll, `${readFunctionality} ${zonesName}`), // Consultar zonas.
        new FunctionalitiesModel(`${createFunctionality} ${zonesName}`, showAll, `${createFunctionality} ${zonesName}`), // Crear zonas.
        new FunctionalitiesModel(`${updateFunctionality} ${zonesName}`, showAll, `${updateFunctionality} ${zonesName}`), // Editar zonas.
        new FunctionalitiesModel(`${deleteFunctionality} ${zonesName}`, showAll, `${deleteFunctionality} ${zonesName}`) // Eliminar zonas.
    ], RoutesConst.sellerCenterIntOfferQuoting),
    // 10. Parametrizar Centros de Acopio
    new MenuModel(portName, showAll, portName.toLowerCase(), ProfileTypes.Administrador, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar.
        new FunctionalitiesModel(updateFunctionality, showAll, updateFunctionality), // Editar.
        new FunctionalitiesModel(createFunctionality, showAll, createFunctionality) // Crear.
    ], RoutesConst.sellerCenterIntPort),
]);

const Reports = new ModuleModel(reportModule, showAll, reportModule.toLowerCase(), [
    // 1. Reporte de ofertas
    new MenuModel(reportOffertAdmin, showAll, reportOffertAdmin.toLowerCase(), ProfileTypes.Administrador, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar.
        new FunctionalitiesModel(downloadFunctionality, showAll, downloadFunctionality) // Descargar
    ], RoutesConst.sellerCenterIntOfferReportOffert),
    // 2. Reporte de órdenes
    new MenuModel(reportOrders, showAll, reportOrders.toLowerCase(), ProfileTypes.Administrador, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar.
        new FunctionalitiesModel(downloadFunctionality, showAll, downloadFunctionality) // Descargar
    ], RoutesConst.sellerCenterIntOfferReportOrder),
    // 3. Reporte de comisiones
    new MenuModel(reportCommission, showAll, reportCommission.toLowerCase(), ProfileTypes.Administrador, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar.
        new FunctionalitiesModel(downloadFunctionality, showAll, downloadFunctionality) // Descargar
    ], RoutesConst.sellerCenterIntReportsCommission),
    // 4. Notificación de Fraudes
    new MenuModel(fraudNotification, showAll, fraudNotification.toLowerCase(), ProfileTypes.Administrador, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar.
        new FunctionalitiesModel(downloadFunctionality, showAll, downloadFunctionality) // Descargar
    ], RoutesConst.sellerCenterFraudNotification),
    // 5. Reporte de errores en VTEX
    new MenuModel(reportErrorsVtex, showAll, reportErrorsVtex.toLowerCase(), ProfileTypes.Administrador, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar.
        new FunctionalitiesModel(downloadFunctionality, showAll, downloadFunctionality) // Descargar
    ], RoutesConst.sellerCenterIntReportsErrorsVtex),
    // 6. Reporte cobros pendientes MPI
    new MenuModel(reportDispersion, showAll, reportDispersion.toLowerCase(), ProfileTypes.Administrador, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar.
        new FunctionalitiesModel(downloadFunctionality, showAll, downloadFunctionality) // Descargar
    ], RoutesConst.sellerCenterIntReportsDispersion),
]);
// ----------------------------------FIN: NUEVOS MÓDULOS-----------------------------------------------------------


const OrdersModule = new ModuleModel(orderModule, showAll, orderModule.toLowerCase(), [
    // 1. Pedidos (Rol vendedor - Consultar, Descargar).
    new MenuModel(pedidos, showAll, pedidos.toLowerCase(), ProfileTypes.Vendedor, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar.
        new FunctionalitiesModel(downloadFunctionality, showAll, downloadFunctionality), // Descargar.
        new FunctionalitiesModel(attachmentFunctionality, showAll, attachmentFunctionality), // Adjuntar.
        new FunctionalitiesModel(sendFunctionality, showAll, sendFunctionality), // Enviar.
        new FunctionalitiesModel(marketFuncionality, showAll, marketFuncionality) // Marcar.
    ], RoutesConst.sellerCenterOrders),
    // 2. Pedidos (Rol administrador - Consultar)
    new MenuModel(pedidos, showAll, pedidos.toLowerCase(), ProfileTypes.Administrador, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar.
        new FunctionalitiesModel(visualizeFunctionality, showAll, visualizeFunctionality), // Visualizar.
    ], RoutesConst.sellerCenterOrders),
    // 3. Por enviar (Rol vendedor - Consultar, Descargar).
    new MenuModel(toSendName, showAll, toSendName.toLowerCase(), ProfileTypes.Vendedor, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar.
        new FunctionalitiesModel(downloadFunctionality, showAll, downloadFunctionality), // Descargar.
        new FunctionalitiesModel(attachmentFunctionality, showAll, attachmentFunctionality), // Adjuntar.
        new FunctionalitiesModel(sendFunctionality, showAll, sendFunctionality), // Enviar.
        new FunctionalitiesModel(marketFuncionality, showAll, marketFuncionality) // Marcar.
    ], RoutesConst.sellerCenterIntOrdersState + '/' + idToSend, idToSend),
    // 4. Enviadas (Rol vendedor - Consultar, Descargar).
    new MenuModel(sendedName, showAll, sendedName.toLowerCase(), ProfileTypes.Vendedor, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar.
        new FunctionalitiesModel(downloadFunctionality, showAll, downloadFunctionality), // Descargar.
        new FunctionalitiesModel(attachmentFunctionality, showAll, attachmentFunctionality), // Adjuntar.
        new FunctionalitiesModel(sendFunctionality, showAll, sendFunctionality), // Enviar.
        new FunctionalitiesModel(marketFuncionality, showAll, marketFuncionality) // Marcar.
    ], RoutesConst.sellerCenterIntOrdersState + '/' + idSended, idSended),
    // 5. Carga masiva de Guías (Cargar, Descargar).
    new MenuModel(guideChargesName, showAll, guideChargesName.toLowerCase(), ProfileTypes.Vendedor, [
        new FunctionalitiesModel(loadFunctionality, showAll, loadFunctionality), // Cargar
        new FunctionalitiesModel(downloadFunctionality, showAll, downloadFunctionality), // Descargar.
    ], RoutesConst.sellerCenterIntOrderLoadGuide),
    // 6. Carga masiva de Facturas (Cargar).
    new MenuModel(bulkLoadBilling, showAll, bulkLoadBilling.toLowerCase(), ProfileTypes.Vendedor, [
        new FunctionalitiesModel(loadFunctionality, showAll, loadFunctionality) // Cargar
    ], RoutesConst.sellerCenterIntBulkLoadBilling),
    // 7. Ciudades con Cobertura
    new MenuModel(citiesCoverageName, showAll, citiesCoverageName.toLowerCase(), ProfileTypes.Vendedor, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar
        new FunctionalitiesModel(updateFunctionality, showAll, updateFunctionality) // Editar
    ], RoutesConst.sellerCenterIntOfferCitiesCoverage),
    // 8. Cotizador (Vendedor solo nacional)
    new MenuModel(quoteName, showAll, quoteName.toLowerCase(), ProfileTypes.Vendedor, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar
        new FunctionalitiesModel(deleteFunctionality, showAll, deleteFunctionality), // Eliminar
        new FunctionalitiesModel(updateFunctionality, showAll, updateFunctionality), // Editar
        new FunctionalitiesModel(createFunctionality, showAll, createFunctionality) // Agregar
    ], RoutesConst.sellerCenterIntOfferQuoting)
]);

/**
 * Actualizado: 29-01-2019 - lecheverry.
 * @version 1.0 (Creación del archivo).
 *
 * Para incluir un menu en el modulo de documentación o funcionalidad en algun menu ya creado. por favor actualizar este objeto la version y fechas.
 *
 * Modulo de documentación que posee menus:
 * 1. Api administrador.
 * 2. App Angular administrador.
 * 3. Api vendedor.
 * 4. App Angular vendedor.
 * 5. Listado de acuerdos/anexos vendedor
 */

const DocumentModule = new ModuleModel(documentModule, showAll, documentModule.toLowerCase(), [
    // 1. Api administrador.
    new MenuModel(apiName, showAll, apiName.toLowerCase(), ProfileTypes.Administrador, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality) // Consultar.
    ], 'https://developers.exito.com/'),
    // 2. App Angular administrador.
    new MenuModel(apiAngularName, showAll, apiAngularName.toLowerCase(), ProfileTypes.Administrador, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality) // Consultar.
    ], 'http://sellercenter.frontdoc.exito.com.co.s3-website-us-east-1.amazonaws.com/'),
    // 3. Api vendedor.
    new MenuModel(apiAdminName, false, apiAdminName.toLowerCase(), ProfileTypes.Vendedor, [
        new FunctionalitiesModel(readFunctionality, false, readFunctionality) // Consultar.
    ], 'https://developers.exito.com/'),
    // 4. App Angular vendedor.
    new MenuModel(apiAngularAdminName, false, apiAngularAdminName.toLowerCase(), ProfileTypes.Vendedor, [
        new FunctionalitiesModel(readFunctionality, false, readFunctionality) // Consultar.
    ], 'http://sellercenter.frontdoc.exito.com.co.s3-website-us-east-1.amazonaws.com/'),
    // 5. Listado de acuerdos/anexos vendedor
    new MenuModel(agreementNameSeller, showAll, agreementNameSeller.toLowerCase(), ProfileTypes.Vendedor, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar
        new FunctionalitiesModel(visualizeFunctionality, showAll, visualizeFunctionality), // Visualizar
        new FunctionalitiesModel(downloadFunctionality, showAll, downloadFunctionality) // Descargar
    ], RoutesConst.sellerCenterIntSellerAgreements),
]);

const DispersionModule = new ModuleModel(dispersionModule, showAll, dispersionModule.toLowerCase(), [
    new MenuModel(summaryPaymentAdmin, showAll, summaryPaymentAdmin.toLowerCase(), ProfileTypes.Administrador, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar.
    ], RoutesConst.sellerCenterIntDispersionSummary),
    new MenuModel(detailPaymentAdmin, showAll, detailPaymentAdmin.toLowerCase(), ProfileTypes.Administrador, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar.
    ], RoutesConst.sellerCenterIntDispersionDetail)
]);

export const schoolExitoModule = 'Escuela Exito', listSchoolExito = 'Escuela Exito'; 
const SchoolExitoModule = new ModuleModel(schoolExitoModule, showAll, schoolExitoModule.toLowerCase(), [
    new MenuModel(listSchoolExito, showAll, listSchoolExito.toLowerCase(), ProfileTypes.Administrador, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar.
        new FunctionalitiesModel(visualizeFunctionality, showAll, visualizeFunctionality),
    ], RoutesConst.sellerCenterIntSchoolExito),
    new MenuModel(listSchoolExito, showAll, listSchoolExito.toLowerCase(), ProfileTypes.Vendedor, [
        new FunctionalitiesModel(readFunctionality, showAll, readFunctionality), // Consultar.
        new FunctionalitiesModel(visualizeFunctionality, showAll, visualizeFunctionality),
    ], RoutesConst.sellerCenterIntSchoolExito)
]);

export const Modules = [
    // Fulfillment,
    OrdersModule,
    ProductAndOffers,
    ClaimsAndReturns,
    Finances,
    Seller,
    Configuration,
    Reports,
    DocumentModule,
    // DispersionModule, 
    SchoolExitoModule
]; // Lista de modelo, menus a mostrar.