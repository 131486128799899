<section class="product-specifications">
  <h2 class="unit-product-creation__title">
    {{ "secure.products.unit_product_creation.specifications.title" | translate }}
  </h2>
  <p class="unit-product-creation__description">
    {{ "secure.products.unit_product_creation.specifications.description" | translate }}
  </p>
</section>
<div class="specification-container" *ngIf="chargeList">
  <p *ngIf="category" class="unit-product-creation__description">
    {{ "secure.products.unit_product_creation.specifications.category" | translate }}:
    <strong>{{category.CategoryName }}</strong>
  </p>
  {{validForm(specsForm.invalid)}}
  <form name="specsForm" #specsForm="ngForm">
    <div *ngIf="isLoad">
      <mat-spinner [diameter]="40" class="margin-loading"></mat-spinner>
      <p class="loading-waiting">{{'secure.products.create_product_unit.list_products.please_wait' | translate}}</p>
    </div>
    <div *ngIf="!isLoad && isShow">
      <mat-spinner [diameter]="30" class="margin-loading"></mat-spinner>
      <p class="loading-waiting">{{'secure.products.create_product_unit.list_products.please_wait' | translate}}</p>
    </div>

    <ng-container *ngIf="!isLoad">
      <article *ngFor="let specification of specificationsGroups; let i = index" class="specification-item">
        <h3 class="specification-title">
          <span
            [matTooltip]="'secure.products.create_product_unit.specifications.group_mandatory_specifications' | translate">
            {{specification.Name}}
          </span>
        </h3>
        <div class="specification-group" *ngIf="specification.Sons.length">
          <div *ngFor="let spec of specification.Sons; let e = index" class="specification-son">
            <mat-form-field *ngIf="!spec.List.length" class="specification-input">
              <input [required]="spec.Required" matInput [placeholder]="spec.Name"
                (keyup.enter)="specificationChange(spec, i, e)" [name]="specification.Label + e"
                [id]="specification.Label + e" [formControl]="specificationForm.get('specs'+spec.Id)"
                (change)="specificationChange(spec, i, e)" pattern="^[\w\W\s\d]{1,500}$">
              <mat-error *ngIf="specificationForm.get('specs'+spec.Id).errors">
                {{showError(spec.Id)}}
              </mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="spec.List.length" class="specification-input">
              <mat-select [required]="spec.Required" [placeholder]="spec.Name" [id]="specification.Label + e"
                [formControl]="specificationForm.get('specs'+spec.Id)"
                (selectionChange)="specificationChange(spec, i, e)">
                <mat-option *ngFor="let item of spec.List" [value]="item.displayName">
                  {{item.displayName}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="specificationForm.get('specs'+spec.Id).errors">
                {{showError(spec.Id)}}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </article>
    </ng-container>
  </form>
</div>
<div class="unit-product-creation__actions">
  <button matStepperPrevious class="unit-product-creation__step-button unit-product-creation__button-back">
    {{ "secure.products.unit_product_creation.back_button_label" | translate }}
  </button>
  <button matStepperNext (click)="checkForm()"
    class="unit-product-creation__step-button unit-product-creation__button-continue">
    {{ "secure.products.unit_product_creation.continue_button_label" | translate }}
  </button>
</div>

<div fxLayout="row" class="new-specs-title" *ngIf="ShowSpecTitle" fxHide.xs>
  <p>
    {{'secure.products.create_product_unit.specifications.specifications' | translate}}
  </p>
  <p>
    {{'shared.name' | translate}}
  </p>
</div>

<div *ngFor="let spec of specificationListToAdd; let i = index">
  <div fxLayout="row" fxLayout.xs="column" class="new-specs" *ngIf="spec.Show">
    <p fxShow.xs fxShow="false">
      {{'secure.products.create_product_unit.specifications.specifications' | translate}}
    </p>
    <span>
      {{spec.Name}}
    </span>
    <span fxHide.xs>
      {{spec.Value}}
    </span>
    <button mat-icon-button (click)="removeSpecification(i)" fxHide.xs>
      <mat-icon class="material-icons">
        delete
      </mat-icon>
    </button>
    <div fxShow.xs fxShow="false" fxLayout="column">
      <p>
        {{spec.Name}}
      </p>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <span>
          {{spec.Value}}
        </span>
        <button mat-icon-button (click)="removeSpecification(i)">
          <mat-icon class="material-icons">
            delete
          </mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>