import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material';
import { UserParametersService } from '@app/core';
import { UserInformation } from '@app/shared';
import { CoreState } from '@app/store';
import { SetHeaderTitle } from '@app/store/configuration/actions';
import { select, Store } from '@ngrx/store';

@Component({
  selector: 'app-list-cancels-orders',
  templateUrl: './list-cancels-orders.component.html',
  styleUrls: ['./list-cancels-orders.component.scss']
})
export class ListCancelsOrdersComponent implements OnInit {

  public user: UserInformation;
  showTabs = true;
  devolution: number;

  constructor(
    public userParams: UserParametersService,
    private store: Store<CoreState>
  ) { }

  ngOnInit() {
    this.getDataUser();
    this.store
      .pipe(select(state => state.notification))
      .subscribe(
        notificationState => {
          this.devolution = notificationState.unreadDevolutions;
        }
      );
  }

  async getDataUser() {
    this.user = await this.userParams.getUserData();
    if (this.user.sellerProfile === 'seller') {
      this.showTabs = true;
      this.setReturnTitle('menu.Cancelaciones');
    } else {
      this.showTabs = false;
      this.setReturnTitle('menu.Listado de cancelaciones');
    }
  }

  setReturnTitle(subtitle: string) {
    this.store.dispatch(new SetHeaderTitle({
      title: 'menu.Cancelaciones',
      subtitle,
      description: ''
    }));
  }

}
