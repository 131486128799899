import { Component, EventEmitter, OnInit, Output } from '@angular/core';

interface tab {
  name: string,
  isActive: boolean
}

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent {
  public tabs: tab[] = [
    {
      name: 'Mis productos',
      isActive: true
    },
    {
      name: 'Productos fulfillment',
      isActive: false
    }
  ]
  
  @Output() tabChangeEmitter: EventEmitter<number> = new EventEmitter<number>();

  public onTabClick(index: number): void {
    this.tabs.forEach(tab => {
      tab.isActive = false;        
    });
    this.tabs[index].isActive = true;
    this.tabChangeEmitter.emit(index);
  }
}
