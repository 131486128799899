import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent {
  @Input() gridView: boolean = true;
  @Input() limit: FormControl;
  @Input() selectAll: FormControl;
  @Input() fulfillmentView: boolean;
  @Input() enableConfirm: boolean;
  @Output() viewModeEmitter: EventEmitter<boolean> = new EventEmitter();
  @Output() confirmProductsEmitter: EventEmitter<void> = new EventEmitter();

  public emitViewMode(gridView: boolean): void {
    this.gridView = gridView;
    this.viewModeEmitter.emit(gridView);
  }

  public confirmProducts(): void {
    this.confirmProductsEmitter.emit();
  }
}
