<!-- Template para el contenido principal. -->
<ng-template #mainContentTpl>
  <p>
      {{'secure.products.bulk_load_product_moderation.text_may_upload_product' | translate}}
    <a (click)="requestMail()" class="link-text" [ngClass]="{'block-link' : !getFunctionality(load)}">{{'secure.products.bulk_load_product_moderation.download_the_format' | translate}}
    </a>{{'secure.products.bulk_load_product_moderation.text_file_may_update' | translate}}
  </p>
  <p>
    <span class="link-text" (click)="bulkLoadCmp.fileUploadInputClick()"  [ngClass]="{'block-link' : !getFunctionality(load)}">{{'secure.products.bulk_upload.text_select_import' | translate}}</span> {{'secure.products.bulk_upload.text_select_import_type' | translate}}
  </p>
</ng-template>

<!-- Componente que construye la mayor parte de la vista. -->
<app-bulk-load #bulkLoadCmp [config]="config" [disabledLoad]='disabledLoad' (event)="manageEvents($event)"></app-bulk-load>

