<h3 class="unit-product-creation__subtitle">
  {{ "secure.products.unit_product_creation.basic_information.variant.color_picker_title" | translate }}
  <span *ngIf="optionSelected" class="color-picker__color-selected">
    ({{ "secure.products.unit_product_creation.basic_information.variant.color_picker_selected" | translate }}
    : {{ optionSelected.name }})
  </span>
</h3>
<article class="color-picker">
  <div class="color-picker__option" *ngFor="let colorPickerOption of clothingColors"
    (click)="selectColor(colorPickerOption)" [class.color-picker__option--selected]="isSelected(colorPickerOption)">
    <div class="color-picker__color"
      [style.background]="!isMulticolor(colorPickerOption) ? '#' + colorPickerOption.code : 'linear-gradient(180deg, #E9E9E9 0%, #FF7307 9%, #EEFF00 21%, #2DF81F 31%, #2AF8B2 44%, #00AEFB 60%, #A000FB 73%, #FC2AA8 85%, #FF0707 100%)'">
    </div>
    <h5 class="color-picker__name">{{ colorPickerOption.name }}</h5>
  </div>
</article>
<div class="unit-product-creation__form-field">
  <label class="unit-product-creation__form-label">
    {{ "secure.products.unit_product_creation.basic_information.variant.color_name_label" | translate }}*
  </label>
  <mat-form-field floatLabel="never">
    <input matInput type="text" [formControl]="colorNameControl"
      [placeholder]="'secure.products.unit_product_creation.basic_information.variant.color_name_placeholder' | translate">
    <mat-error *ngIf="colorNameControl.hasError('required')">
      {{ "errors.error_required" | translate }}
    </mat-error>
    <mat-error *ngIf="colorNameControl.hasError('pattern')">
      {{ 'secure.products.create_product_unit.basic_information.specific_name_invalid' | translate }}
    </mat-error>
  </mat-form-field>
</div>