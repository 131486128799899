<div class="tree-content" style="margin-top:34px;">
  <div class="header-tree-categories">
    <h1>{{currentStoreSelect.Name}}</h1>
  </div>

  <!-- Componente empleado cuando no se posee una tienda seleccionada -->
  <app-no-content *ngIf="currentStoreSelect.Name === ''"></app-no-content>

  <!-- <app-exception-brand *ngIf="informationForTreeIsLoad == true && currentStoreSelect" [currentStoreSelect]="currentStoreSelect.IdSeller"></app-exception-brand> -->

  <!-- Componente que se visualiza cuando se ha indicado una tienda para consultar -->
  <app-tree-component [updateFunction]="updateFunction" *ngIf="informationForTreeIsLoad == true"  [arbol]="arbol" (currentTreeOutput)="receiveDataTree($event)"></app-tree-component>

  <!-- Componente loading -->
  <div *ngIf="showLoading == true" class="spinner-component">
    <mat-spinner></mat-spinner>
  </div>
</div>
