<form #f="ngForm" [formGroup]="myform" class="search-form light-content">
  <mat-form-field id="sinceDate-input-search-order" color="accent">
    <input id="sinceDate-input" [max]="myform.value.dateOrderFinal" formControlName="dateOrderInitial"
      [placeholder]="'core.shell.search_order_menu.search_order_form.from' | translate" matInput
      [matDatepicker]="dateOrderInitial">
    <mat-datepicker-toggle id="sinceDate-Perfix" matPrefix [for]="dateOrderInitial"></mat-datepicker-toggle>
    <mat-datepicker touchUi="true" startView="year" #dateOrderInitial disabled="false"></mat-datepicker>
  </mat-form-field>

  <mat-form-field id="untilDate-input-search-order" color="accent">
    <input id="untilDate-input" [min]="myform.value.dateOrderInitial" formControlName="dateOrderFinal"
      [placeholder]="'core.shell.search_order_menu.search_order_form.to' | translate" matInput
      [matDatepicker]="dateOrderFinal">
    <mat-datepicker-toggle id="untilDate-Prefix" matPrefix [for]="dateOrderFinal">
    </mat-datepicker-toggle>
    <mat-datepicker touchUi="true" startView="year" #dateOrderFinal disabled="false"></mat-datepicker>
  </mat-form-field>

  <mat-form-field id="chanelOrder-input-search-order" class="example-full-width" color="accent">
    <mat-icon matPrefix color="accent">device_hub</mat-icon>
    <mat-select id="select-chanelOrder" formControlName="idChannel"
      [placeholder]="'core.shell.search_order_menu.search_envios_exito_form.filter_channel' | translate">
      <mat-option>{{'core.shell.search_order_menu.search_envios_exito_form.none' | translate}}</mat-option>
      <mat-option value="11">{{'core.shell.search_order_menu.search_envios_exito_form.exito' | translate}}
      </mat-option>
      <mat-option value="13">{{'core.shell.search_order_menu.search_envios_exito_form.carulla' | translate}}
      </mat-option>
      <mat-option value="16">{{'core.shell.search_order_menu.search_envios_exito_form.digital_catalog' | translate}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field id="status-input-search-order" [ngClass]="{'hidden': state === 170 || state === 35}"
    class="example-full-width" color="accent">
    <mat-icon matPrefix color="accent">sort</mat-icon>
    <mat-select id="select-status" formControlName="idStatusOrder"
      [placeholder]="'core.shell.search_order_menu.search_envios_exito_form.filter_status' | translate">
      <mat-option *ngFor="let status of listOrderStatus" value="{{status.idStatusOrder}}">
        {{status.statusOrder}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="example-full-width" color="accent">
    <mat-icon matPrefix color="accent">format_list_bulleted</mat-icon>
    <input id="orderNumber-input-search-order" formControlName="orderNumber" type="text" pattern="[0-9.]+" matInput
      [placeholder]="'core.shell.search_order_menu.search_envios_exito_form.filter_by_number' | translate">
  </mat-form-field>

  <mat-form-field class="example-full-width" color="accent">
    <mat-icon matPrefix color="accent">person_pin</mat-icon>
    <input id="identificationCard-input-search-order" formControlName="identificationCard" type="text" pattern="[0-9.]+"
      matInput [placeholder]="'core.shell.search_order_menu.search_envios_exito_form.filter_by_id' | translate">
  </mat-form-field>
  <section class="example-section normal-theme" *ngIf="typeProfiel == 0">
    <mat-slide-toggle color="primary" id="processedOrder-input-search-order" formControlName="processedOrder">
      {{'core.shell.search_order_menu.search_order_form.only_markerd_order' | translate}}
    </mat-slide-toggle>
  </section>
  <section class="example-section normal-theme">
    <mat-slide-toggle color="primary" id="noBills-input-search-order" formControlName="noBills">
      {{'core.shell.search_order_menu.search_order_form.ordersWithoutInvoice' | translate}}
    </mat-slide-toggle>
  </section>

  <div class="orders-search-option normal-theme">
    <button id="clear-filter-button" class="sc-button" mat-button (click)="clearForm()">
      <mat-icon>clear_all</mat-icon>
      {{'filters.clear' | translate}}
    </button>
    <button id="apply-filter-button" [disabled]="!f.form.valid || !f.form.touched" class="sc-button" mat-raised-button
      color="primary" type="submit" (click)="filterOrder(myform)">{{'actions.apply' | translate}}
    </button>
  </div>
</form>
