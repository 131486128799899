import { Component } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {
  public status: string;
  public message: string;
  public title: string;

  constructor(public dialog: MatDialog) { }

  /**
   * showModal método para mostrar el modal.
   *
   * @param status
   * @memberof ModalComponent
   */
  public showModal(status: string): Observable<any> {
    let dataModal: any;
    const dialogRef = this.dialog.open(ModalComponent, {
      height: '185px',
      width: '655px'
    });
    switch (status) {
      case 'add':
        dataModal = {
          'status': 'add',
          'message': 'Luego podrás ir a ordenes de abastecimiento a gestionar la entrega de tus productos en el centro de almacenamiento.',
          'title': '¿Confirmas activar estos productos a fulfillment?'
        };
        break;
      case 'remove':
        dataModal = {
          'status': 'remove',
          'message': 'Al desactivar los productos ya no podrás administrarlos en fulfillment. Si cambias de opinión vuélvelos a seleccionar desde Mis productos.',
          'title': '¿Estás seguro que deseas desactivar estos productos de fulfillment?'
        };
        break;
    }
    dialogRef.componentInstance.status = dataModal.status;
    dialogRef.componentInstance.message = dataModal.message;
    dialogRef.componentInstance.title = dataModal.title;

    return dialogRef.afterClosed();
  }
}
