<div class="light-content">
  <mat-toolbar color="primary" class="toolbar-component" [class.in-detail]="inDetail">

    <div class="toolbar-options flexbox align-center justify-between view-mobile" [class.in-detail]="inDetail">
      <button class="btn-filter" mat-icon-button (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>

    <div class="toolbar-options menu flexbox flex-wrap align-center view-desktop" [class.in-detail]="inDetail">

      <div class="seller-search search-bar">
        <app-search-seller class="listAdmin" *ngIf="getFunctionality(read)" [isFullSearch]="false"></app-search-seller>
      </div>

      <div *ngIf="isEnabled" class="options-toolbar flexbox align-center justify-end hidde-in-detail">

        <mat-form-field class="select-page-size">
          <mat-select id="select-number-page-size"
            placeholder="{{'secure.offers.list.components.toolbar.page_products' | translate}}"
            [(ngModel)]="dataPaginate.limit" (ngModelChange)="changeSize()">
            <mat-option value="30">30</mat-option>
            <mat-option value="60">60</mat-option>
            <mat-option value="120">120</mat-option>
            <mat-option value="600">600</mat-option>
          </mat-select>
        </mat-form-field>

        <p class="pagination-num" [innerHTML]="currentPage + ' - ' + numberPages"></p>

        <button class="btn-paginator" mat-icon-button mat-button (click)="changePage('prev')"
          [disabled]="currentPage <= 1 ">
          <mat-icon>keyboard_arrow_left</mat-icon>
        </button>

        <button class="btn-paginator" mat-icon-button mat-button (click)="changePage('next')"
          [disabled]="currentPage >= numberPages">
          <mat-icon>keyboard_arrow_right</mat-icon>
        </button>

        <button class="btn-filter" mat-icon-button mat-button (click)="toggleMenu()">
          <mat-icon>filter_list</mat-icon>
          <small class="sub-title-filter">{{'filters.filter' | translate}}</small>
        </button>

      </div>
    </div>
  </mat-toolbar>
</div>