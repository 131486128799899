<section class="product-card" [class.product-card--details-mode]="!gridView">
  <app-input-checkbox
    [id]="product.ean + 'ProductCheckbox'"
    [control]="checkControl"
  ></app-input-checkbox>
  <header class="product-card__header">
    <picture class="product-card__header__picture">
      <img
        src="{{product.imageUrl || product.urlImage}}"
        alt="Imagen del producto">
    </picture>
  </header>
  <div class="product-card__details">
    <h4>{{product.name}}</h4>
    <div class="product-card__details__data">
      <p>
        <span>EAN:</span>
        {{product.ean}}
      </p>
      <p>
        <span>PLU:</span>
        {{product.pluVtex}}
      </p>
    </div>
  </div>
</section>