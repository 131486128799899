<div class="upload-file" appDragAndDrop (fileDropped)="onUploadFile($event)">
  <input class="upload-file__input"
         type="file"
         #fileInput
         [id]="'upload-file-' + imageData.id"
         [accept]="allowedFileTypes"
         (change)="onUploadFile($event.target.files)">
  <label [for]="'upload-file-' + imageData.id">
    <div class="upload-file__label" *ngIf="!imageData.url">
      <span class="upload-file__icon material-symbols-outlined">add</span>
      <span>{{ "secure.products.unit_product_creation.images_and_video.upload_file_label" | translate }}</span>
    </div>
    <img *ngIf="imageData.url" class="upload-file__image" [src]="imageData.url" alt="">
  </label>
</div>

