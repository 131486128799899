<form #f="ngForm" [formGroup]="myform" class="search-form light-content">
  <!-- PaymentDateInitial -->
  <mat-form-field id="paymentDate-input-search-order" color="accent">
    <input id="input-filter-paymentDateInitial" [max]="myform.value.paymentDateFinal"
      formControlName="paymentDateInitial" [placeholder]="'core.shell.search_order_menu.search_billing_form.initial_date' | translate" matInput
      [matDatepicker]="paymentDateInitial">
    <mat-datepicker-toggle id="paymentDate-Perfix" matPrefix [for]="paymentDateInitial"></mat-datepicker-toggle>
    <mat-datepicker touchUi="true" startView="year" #paymentDateInitial></mat-datepicker>
  </mat-form-field>

  <!-- PaymentDateFinal -->
  <mat-form-field id="paymentDate-input-search-order" color="accent">
    <input id="input-filter-paymentDateFinal" [min]="myform.value.paymentDateInitial" formControlName="paymentDateFinal"
      [placeholder]="'core.shell.search_order_menu.search_billing_form.final_date' | translate" matInput [matDatepicker]="paymentDateFinal">
    <mat-datepicker-toggle id="paymentDate-Perfix" matPrefix [for]="paymentDateFinal"></mat-datepicker-toggle>
    <mat-datepicker touchUi="true" startView="year" #paymentDateFinal></mat-datepicker>
  </mat-form-field>

  <!-- BillingNumber -->
  <mat-form-field id="billingNumber-input-search-order" class="example-full-width" color="accent">
    <mat-icon matPrefix color="accent">format_list_bulleted</mat-icon>
    <input id="input-filter-billingNumber" formControlName="billingNumber" type="text" pattern="[0-9.,]+" matInput
      [placeholder]="'core.shell.search_order_menu.search_billing_form.invoice_number' | translate">
  </mat-form-field>

  <div class="orders-search-option normal-theme">
    <button id="clear-filter-button" class="sc-button" mat-button type="button" (click)="clearForm()">
      <mat-icon>clear_all</mat-icon>
      {{'filters.clear' | translate}}
    </button>
    <button id="apply-filter-button" [disabled]="!f.form.valid" class="sc-button" mat-raised-button color="primary"
      type="submit" >
      {{'actions.apply' | translate}}
    </button>
  </div>
</form>
