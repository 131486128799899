import { Component, OnInit } from '@angular/core';
import { CoreState } from '@app/store';
import { Store, select } from '@ngrx/store';

@Component({
  selector: 'app-uploaded-images',
  templateUrl: './uploaded-images.component.html',
  styleUrls: ['./uploaded-images.component.scss']
})
export class UploadedImagesComponent implements OnInit {

  nImages: number = 8;
  showNImages: number = this.nImages;
  showMore: boolean = true;
  uploadedImages = []

  constructor(
    private store: Store<CoreState>
  ) { }

  ngOnInit() {
    this.getVariantImages();
  }

  toggleShowMore() {
    this.showMore = !this.showMore;
    this.showNImages = this.showMore ? this.nImages : this.uploadedImages.length;
  }

  getVariantImages() {
    this.store
      .pipe(select(state => state.unitProductCreation.images))
      .subscribe((images) => {
        this.uploadedImages = images.sort((a, b) => a.sonIndex > b.sonIndex ? 1 : -1);
      });
  }

  getImageName(url: string) {
    return url.split('/').slice(-1);
  }

}
