<div>
  <div fxLayout="row" class="list-order-component-container-list-orders">
    <div fxFlex="11"></div>
    <div fxFlex="15">
      <span class="list-order-component-text-column">
        {{'secure.orders.order' | translate}}
      </span>
    </div>
    <div fxFlex="15" fxShow.xs="false" fxShow.sm="false" fxShow.md="true" fxShow.lg="true" fxShow.xl="true">
      <span class="list-order-component-text-column">
        {{'secure.orders.order_list.detail_modal.order_date' | translate}}
      </span>
    </div>
    <div fxFlex="15" fxShow.xs="false" fxShow.sm="false" fxShow.md="true" fxShow.lg="true" fxShow.xl="true">
      <span class="list-order-component-text-column">
        {{'secure.orders.order_list.detail_modal.deadline' | translate}}
      </span>
    </div>
    <div fxFlex="20">
      <span class="list-order-component-text-column">
        {{'shared.status' | translate}}
      </span>
    </div>
    <div fxFlex="15">
      <span class="list-order-component-text-column">
        {{'secure.orders.order_list.detail_modal.channel' | translate}}
      </span>
    </div>
    <div fxFlex="5" class="actions-header">
      <span class="list-order-component-text-column">
        {{'shared.actions' | translate}}
      </span>
    </div>
    <div fxFlex="4"></div>
  </div>
  <ng-container *ngFor="let item of items" >
    <ng-container [ngTemplateOutlet]="item.tpl"></ng-container>
  </ng-container>
</div>