<div class="content-order my-content">

  <div fxFlex class="container" fxLayout="row wrap" fxLayoutAlign="center start">
    <div fxFlex="100" class="light-content">
      <app-toolbar-search-pagination [isClear]="isClear" [informationToForm]="informationToForm" [state]="lastState"
        #toolbarOptions (OnGetOrdersList)="getOrdersList($event)" (paginationListOrdens)="paginations($event)"
        (OnChangeSizeOrderTable)="changeSizeOrderTable($event)" [showBtn]="false" [btnFilter]="false" [Typeprofile]="3" [lengthOrder]="length"
        [orderListLength]="true" [limitPaginate]="[50, 100, 150]" (filterCalifications)="toggleFilterPorts()">
      </app-toolbar-search-pagination>
    </div>

    <div class="container-port-main">
      <div fxLayout="column" class="container-width-100">
        <div fxLayout="row">
          <div class="port-component-label-filter">
            <div class="example-chip-list"></div>
          </div>
          <div class="port-component-button-add">
            <div id="addButton" class="container-group-add-button">
              <button mat-button (click)="upsetPort(1, null)">
                <mat-icon class="material-icons">
                  control_point
                </mat-icon>
                {{'secure.parametize.port.btn_add_port'| translate}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Tabla de órdenes -->
    <div fxFlex.xs="93%" fxFlex.sm="95" fxFlex.md="97" fxFlex.lg="97" fxFlex.xl="97"
      class="port-component-content-table-center-collection">
      <mat-table #table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="expand">
          <mat-header-cell fxFlex.lg="60px" fxFlex="70px" fxShow.xs="false" fxShow.sm="false" fxShow.gt-lg="false"
            fxShow="true" fxFlex.xl="250px" *matHeaderCellDef>
          </mat-header-cell>
          <mat-cell fxFlex="70px" fxFlex.lg="60px" fxShow.xs="false" fxShow.sm="false" fxShow.gt-lg="false"
            fxShow="true" *matCellDef="let element;let index=index">
            <button id="expand-row-number-{{index}}" disabled mat-icon-button>
              <mat-icon class="font-weight" color="primary">expand_more</mat-icon>
            </button>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="name">
          <mat-header-cell fxShow.xs="true" fxShow.sm="true" fxShow.lg="true" fxShow.xl="true" fxShow="true"
            fxFlex.lg="20" fxFlex.sm="20" fxFlex.md="20" fxFlex.xs="20" fxFlex.xl="" *matHeaderCellDef>
            <span class="title-table-header">{{'shared.name' | translate}}</span>
          </mat-header-cell>
          <mat-cell fxShow.xs="true" fxShow.sm="true" fxShow.lg="true" fxShow.xl="true" fxShow="true" fxFlex.lg="20"
            fxFlex.sm="20" fxFlex.md="20" fxFlex.xs="20" fxFlex.xl="" *matCellDef="let element">
            <p class="sc-text-normal">{{element.Name}}</p>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="address">
          <mat-header-cell fxShow.xs="false" fxShow.sm="false" fxShow.lg="true" fxShow.xl="true" fxShow="true"
            fxFlex.lg="40" fxFlex.sm="40" fxFlex.md="40" fxFlex.xs="30" fxFlex.xl="" *matHeaderCellDef>
            <span class="title-table-header">{{'secure.parametize.port.address' | translate}}</span>
          </mat-header-cell>
          <mat-cell fxShow.xs="false" fxShow.sm="false" fxShow.lg="true" fxShow.xl="true" fxShow="true" fxFlex.lg="40"
            fxFlex.sm="40" fxFlex.md="40" fxFlex.xs="30" fxFlex.xl="" *matCellDef="let element">
            <p class="sc-text-normal" class="container-margin-right-20">
              <span *ngIf="element.Address" class="text-font-weight">{{element.Address}}</span>
              <span *ngIf="element.PostalCode" class="text-font-weight">, {{element.PostalCode}}</span>
              <span *ngIf="!element.PostalCode" class="color-text-empty">, (No tiene Código Postal)</span>
              <span *ngIf="element.City" class="text-font-weight">, {{element.City}}</span>
              <span *ngIf="!element.City" class="color-text-empty">, (No tiene ciudad)</span>
              <span *ngIf="element.Province" class="text-font-weight">, {{element.Province}}</span>
              <span *ngIf="!element.Province" class="color-text-empty">, (No tiene Provincia/Estado)</span>
              <span *ngIf="element.CountryName" class="text-font-weight">, {{element.CountryName}}
                <span *ngIf="element.CountryIso2" class="text-font-weight">({{element.CountryIso2}})</span>
                <span *ngIf="!element.CountryIso2" class="color-text-empty">(No tiene)</span>
              </span>
              <span *ngIf="!element.CountryName" class="color-text-empty">, (No tiene País)</span>
            </p>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="phone">
          <mat-header-cell fxShow.xs="false" fxShow.sm="false" fxShow.lg="true" fxShow.xl="true" fxShow="true"
            fxFlex.lg="15" fxFlex.sm="15" fxFlex.md="15" fxFlex.xs="30" fxFlex.xl="" *matHeaderCellDef>
            <span class="title-table-header">{{'secure.parametize.port.phone' | translate}}</span>
          </mat-header-cell>
          <mat-cell fxShow.xs="false" fxShow.sm="false" fxShow.lg="true" fxShow.xl="true" fxShow="true" fxFlex.lg="15"
            fxFlex.sm="15" fxFlex.md="15" fxFlex.xs="30" fxFlex.xl="" *matCellDef="let element">
            <p class="sc-text-normal">{{element.Phone}}</p>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="action">
          <mat-header-cell class="port-component-text-aling" fxShow.xs="true" fxShow.sm="true" fxShow.lg="true"
            fxShow.xl="true" fxShow="true" fxFlex.lg="15" fxFlex.sm="15" fxFlex.md="15" fxFlex.xs="30" fxFlex.xl=""
            *matHeaderCellDef>
            <span class="title-table-header">{{'secure.parametize.port.action' | translate}}</span>
          </mat-header-cell>
          <mat-cell fxShow.xs="true" fxShow.sm="true" fxShow.lg="true" fxShow.xl="true" fxShow="true" fxFlex.lg="15"
            fxFlex.sm="15" fxFlex.md="15" fxFlex.xs="30" fxFlex.xl="" *matCellDef="let element"
            class="port-component-text-aling">
            <mat-icon class="mat-18 port-component-color-icon-edit color-icon-edi"
              (click)="upsetPort(2, element)">edit</mat-icon>
          </mat-cell>
        </ng-container>

        <!-- Creación de las filas para la tabla -->
        <mat-header-row *matHeaderRowDef="displayedColumns "></mat-header-row>
        <mat-row id="order-number-{{index}}" *matRowDef="let index=index; let row; columns: displayedColumns;" matRipple
          class="element-row personal-row-table" [cdkDetailRow]="row" [cdkDetailRowTpl]="tpl">
        </mat-row>
      </mat-table>

    </div>
    <div [@detailExpand] class="orders-no-data" *ngIf="length == 0">
      <mat-icon class="icon-not-found" color="primary">info</mat-icon>
      <p>{{'secure.parametize.port.no_found_port' | translate}}
      </p>
    </div>
    <!-- Fila desplegable para la tabla -->
    <ng-template #tpl let-element>
      <div class="content-detail-order mat-row  overFlow-Hidden" [@detailExpand]>
        <div fxLayout="row wrap" class="container-port-padding-button">
          <div fxFlex.lg="40" fxFlex.sm="100" fxFlex.md="40" fxFlex.xs="100" fxFlex.xl="40">
            <div fxLayout="column" class="container-port-details">
              <div class="container-port-padding"><span
                  class="port-title-text-detail">{{'secure.parametize.port.tariff' | translate}}: </span> <span
                  class="port-result-text-detail">{{element.Tariff}}</span></div>
              <div class="container-port-padding"><span
                  class="port-title-text-detail">{{'secure.parametize.port.shippingCost' | translate}}: </span> <span
                  class="port-result-text-detail">{{element.ShippingCost}}</span></div>
              <div class="container-port-padding"><span
                  class="port-title-text-detail">{{'secure.parametize.port.tariffByKg' | translate}}: </span> <span
                  class="port-result-text-detail">{{element.NegotiatedShippingCost}}</span></div>
              <div class="container-port-padding"><span
                  class="port-title-text-detail">{{'secure.parametize.port.insurance_freight' | translate}}: </span>
                <span class="port-result-text-detail">{{element.InsuranceFreight}}</span></div>
              <div class="container-port-padding"><span
                  class="port-title-text-detail">{{'secure.parametize.port.preparation' | translate}}: </span> <span
                  class="port-result-text-detail">{{element.Preparation}}</span></div>
              <div class="container-port-padding"><span
                  class="port-title-text-detail">{{'secure.parametize.port.national_transportation' | translate}}:
                </span> <span class="port-result-text-detail">{{element.NationalTransport}}</span></div>
              <div class="container-port-padding"><span
                  class="port-title-text-detail">{{'secure.parametize.port.insurance_CIF' | translate}}: </span> <span
                  class="port-result-text-detail">{{element.InsuranceCif}}</span></div>

            </div>
          </div>
          <div fxFlex.lg="60" fxFlex.sm="100" fxFlex.md="60" fxFlex.xs="100" fxFlex.xl="60">
            <div fxLayout="row">
              <div class="container-title-collection-center">Paises a los que aplica</div>
            </div>
            <div fxLayout="row wrap" class="max-height-overflox-y">
              <div fxFlex="50" *ngFor="let country of element.Country"
                class="port-result-text-detail width-and-padding">{{country}}</div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<mat-sidenav-container>
  <mat-sidenav #sidenavSearchOrder mode="over" position="end" opened="{{stateSideNavOrder}}">
    <mat-card class="content-profile port-list-93-height">
      <mat-nav-list class="light-content">
        <mat-toolbar color="primary" class="toolbar-title-search">
          <h4 class="title-toolbar width-container-text">{{'secure.parametize.port.search_port' | translate}}</h4>
          <button color="accent" class="position-end" [matTooltip]="'actions.close' | translate"
            matTooltipPosition="below" mat-icon-button mat-button (click)="toggleFilterPorts()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-toolbar>
      </mat-nav-list>
      <mat-card-content>
        <form class="example-container" #createfilterPort="ngForm" [formGroup]="filterPort"
          class="seller-filter-sidenav">
          <div>
            <div>
              <div class="port-list-padding-container">
                <div class="port-component-text-filter">
                  <span>Use el filtro para buscar los puertos asociados al país seleccionado</span>
                </div>
                <div class="port-list-padding-left">
                  <mat-form-field class="field container-width-100">
                    <input matInput [placeholder]="'secure.parametize.port.country' | translate"
                      [matAutocomplete]="auto" formControlName="countryFilter">
                    <mat-autocomplete #auto="matAutocomplete">
                      <mat-option *ngFor="let country of filterCountryFilter" [value]="country.CountryName">
                        {{country.CountryName}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="port-component-paddin-100">
              <div class="orders-search-option normal-theme calification-list-filter-btn-clear">
                <button class="sc-button" mat-button type="button" (click)="clearFormFilter()">
                  <mat-icon>clear_all</mat-icon>
                  {{'filters.clear' | translate}}
                </button>
                <button [disabled]="!createfilterPort.form.valid || !createfilterPort.form.touched" class="sc-button"
                  mat-raised-button color="primary" type="submit" (click)="getFilterPort(filterPort.value)">
                  {{'actions.apply' | translate}}
                </button>
              </div>
            </div>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </mat-sidenav>
</mat-sidenav-container>

<ng-template #dialogContent>
  <div fxLayout="row" class="padding-10">
    <div fxFlex="90">
      <div *ngIf="data && data.typeModal === 1 ">
        {{'secure.parametize.port.addPort' | translate}}
      </div>
      <div *ngIf="data && data.typeModal === 2 ">
        {{'secure.parametize.port.editPort' | translate}}
      </div>
    </div>
    <div fxFlex="10" class="text-aling-end">
      <mat-icon class="mat-18 close-modal" (click)="onNoClick()" [matTooltip]="'actions.close' | translate">clear
      </mat-icon>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div mat-dialog-content>
    <form #f="ngForm" [formGroup]="formPort" (ngSubmit)="saveColletionCenter()" class="padding-31px">
      <div fxLayout="row wrap">
        <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="100" fxFlex.lg="100" fxFlex.xl="100"
          class="port-component-container-header">
          <span class="port-component-text-title">
            <mat-icon class="mat-18 vertical-align-and-color-icon">assignment</mat-icon>
            Información Centro de Acopio
          </span>
        </div>
        <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="50" fxFlex.lg="50" fxFlex.xl="50"
          class="port-component-aling-padding-left">
          <mat-form-field class="field port-component-width-90">
            <input matInput [placeholder]="'shared.name' | translate" formControlName="name">
            <mat-error *ngIf="formPort.get('name').hasError('required')">{{'errors.required' | translate}}
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="50" fxFlex.lg="50" fxFlex.xl="50">
          <mat-form-field class="port-component-width-95">
            <input formControlName="phone" matInput [placeholder]="'shared.phone' | translate">
            <mat-error *ngIf="formPort.get('phone').hasError('pattern')">{{'errors.format_invalidate' | translate}}
            </mat-error>
            <mat-error *ngIf="formPort.get('phone').hasError('required')">{{'errors.required' | translate}}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row wrap" class="margin-top-11">
        <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="50" fxFlex.lg="50" fxFlex.xl="50"
          class="port-component-aling-padding-left">
          <mat-form-field class="field port-component-width-90">
            <input matInput [placeholder]="'secure.parametize.port.country' | translate" [matAutocomplete]="auto"
              formControlName="nameCountry">
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let countryAddress of filterCountryAddress" [value]="countryAddress.CountryName">
                {{countryAddress.CountryName}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="50" fxFlex.lg="50" fxFlex.xl="50">
          <mat-form-field class="port-component-width-95">
            <input formControlName="countryIso2" matInput class="text-transform-uppercase"
              [placeholder]="'secure.parametize.port.prefix' | translate">
            <mat-error *ngIf="formPort.get('countryIso2').hasError('pattern')">
              {{'errors.format_invalidate' | translate}}
            </mat-error>
            <mat-error *ngIf="formPort.get('countryIso2').hasError('required')">{{'errors.required' | translate}}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row wrap" class="margin-top-11">
        <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="50" fxFlex.lg="50" fxFlex.xl="50"
          class="port-component-aling-padding-left">
          <mat-form-field class="port-component-width-95">
            <input formControlName="city" matInput [placeholder]="'secure.parametize.port.city' | translate">
            <mat-error *ngIf="formPort.get('city').hasError('pattern')">{{'errors.format_invalidate' | translate}}
            </mat-error>
            <mat-error *ngIf="formPort.get('city').hasError('required')">{{'errors.required' | translate}}</mat-error>
          </mat-form-field>
        </div>
        <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="50" fxFlex.lg="50" fxFlex.xl="50">
          <mat-form-field class="port-component-width-95">
            <input formControlName="province" matInput [placeholder]="'secure.parametize.port.province' | translate">
            <mat-error *ngIf="formPort.get('province').hasError('pattern')">{{'errors.format_invalidate' | translate}}
            </mat-error>
            <mat-error *ngIf="formPort.get('province').hasError('required')">{{'errors.required' | translate}}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row wrap" class="margin-top-11">
        <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="50" fxFlex.lg="50" fxFlex.xl="50"
          class="port-component-aling-padding-left">
          <mat-form-field class="port-component-width-95">
            <input formControlName="postalCode" matInput
              [placeholder]="'secure.parametize.port.postalCode' | translate">
            <mat-error *ngIf="formPort.get('postalCode').hasError('required')">{{'errors.required' | translate}}
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="50" fxFlex.lg="50" fxFlex.xl="50">
          <mat-form-field class="port-component-width-95">
            <input formControlName="address" matInput [placeholder]="'secure.parametize.port.address' | translate">
            <mat-error *ngIf="formPort.get('address').hasError('required')">{{'errors.required' | translate}}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row wrap">
        <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="100" fxFlex.lg="100" fxFlex.xl="100"
          class="port-component-container-header">
          <span class="port-component-text-title">
            <mat-icon class="mat-18 vertical-and-color">local_shipping</mat-icon>
            Flete
          </span>
        </div>
        <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="50" fxFlex.lg="50" fxFlex.xl="50"
          class="port-component-aling-padding-left">
          <mat-form-field class="port-component-width-95">
            <input formControlName="insuranceFreight" matInput
              [placeholder]="'secure.parametize.port.insurance_freight' | translate">
            <mat-error *ngIf="formPort.get('insuranceFreight').hasError('pattern')">
              {{'secure.parametize.port.error_format_five_decimal' | translate}}</mat-error>
            <mat-error *ngIf="formPort.get('insuranceFreight').hasError('required')">{{'errors.required' | translate}}
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="50" fxFlex.lg="50" fxFlex.xl="50">
          <mat-form-field class="port-component-width-95">
            <input formControlName="preparation" matInput
              [placeholder]="'secure.parametize.port.preparation' | translate">
            <mat-error *ngIf="formPort.get('preparation').hasError('pattern')">
              {{'secure.parametize.port.error_format_two_decimal' | translate}}</mat-error>
            <mat-error *ngIf="formPort.get('preparation').hasError('required')">{{'errors.required' | translate}}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row wrap">
        <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="50" fxFlex.lg="50" fxFlex.xl="50"
          class="port-component-aling-padding-left">
          <mat-form-field class="port-component-width-95">
            <input formControlName="shippingCost" matInput
              [placeholder]="'secure.parametize.port.shippingCost' | translate">
            <mat-error *ngIf="formPort.get('shippingCost').hasError('pattern')">
              {{'secure.parametize.port.error_format_two_decimal' | translate}}</mat-error>
            <mat-error *ngIf="formPort.get('shippingCost').hasError('required')">{{'errors.required' | translate}}
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="50" fxFlex.lg="50" fxFlex.xl="50">
          <mat-form-field class="port-component-width-95">
            <input formControlName="nationalTransport" matInput
              [placeholder]="'secure.parametize.port.national_transportation' | translate">
            <mat-error *ngIf="formPort.get('nationalTransport').hasError('pattern')">
              {{'secure.parametize.port.error_format_two_decimal' | translate}}</mat-error>
            <mat-error *ngIf="formPort.get('nationalTransport').hasError('required')">{{'errors.required' | translate}}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row wrap">
        <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="100" fxFlex.lg="100" fxFlex.xl="100"
          class="port-component-container-header">
          <span class="port-component-text-title">
            <mat-icon class="mat-18 vertical-and-color">local_shipping</mat-icon>
            Paises a los que aplica
          </span>
        </div>
        <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="100" fxFlex.lg="100" fxFlex.xl="100"
          class="port-component-aling-padding-left">
          <mat-form-field class="field port-component-width-95">
            <input matInput [placeholder]="'secure.parametize.port.country' | translate" [matAutocomplete]="autoApply"
              formControlName="country" (keyup.enter)="saveKeyword()">
            <mat-autocomplete #autoApply="matAutocomplete">
              <mat-option *ngFor="let country of filterCountryApply" [value]="country.CountryName">
                {{country.CountryName}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-icon matSuffix [matTooltip]="'secure.parametize.port.btn_add_port' | translate"
            class="material-icons add-keyword-icon vertical-align-top" (click)="saveKeyword()">
            add_circle_outline
          </mat-icon>
        </div>
      </div>
      <div fxLayout="row" fxLayout.xs="column"
        [ngClass]="validateKey ? 'port-componente-container-scroll-hide' : 'port-component-container-scroll-country-apply'">
        <div class="port-component-container-chips-country">
          <mat-chip-list>
            <mat-chip *ngFor="let key of keywords; let i = index" (removed)="deleteKeywork(i)">
              {{key}}
              <mat-icon class="material-icons" matChipRemove>cancel</mat-icon>
            </mat-chip>
          </mat-chip-list>
        </div>
      </div>
      <div fxLayout="row wrap" class="container-margin-top-10">
        <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="100" fxFlex.lg="100" fxFlex.xl="100"
          class="port-component-container-header">
          <span class="port-component-text-title">
            <mat-icon class="mat-18 vertical-and-color">security</mat-icon>
            CIF
          </span>
        </div>
        <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="50" fxFlex.lg="50" fxFlex.xl="50"
          class="port-component-aling-padding-left">
          <mat-form-field class="port-component-width-95">
            <input formControlName="insuranceCif" matInput
              [placeholder]="'secure.parametize.port.insurance_CIF' | translate">
            <mat-error *ngIf="formPort.get('insuranceCif').hasError('pattern')">
              {{'secure.parametize.port.error_format_five_decimal' | translate}}</mat-error>
            <mat-error *ngIf="formPort.get('insuranceCif').hasError('required')">{{'errors.required' | translate}}
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="50" fxFlex.lg="50" fxFlex.xl="50">
          <mat-form-field class="port-component-width-95">
            <input formControlName="negotiatedShippingCost" matInput
              [placeholder]="'secure.parametize.port.tariffByKg' | translate">
            <mat-error *ngIf="formPort.get('negotiatedShippingCost').hasError('pattern')">
              {{'secure.parametize.port.error_format_five_decimal' | translate}}</mat-error>
            <mat-error *ngIf="formPort.get('negotiatedShippingCost').hasError('required')">
              {{'errors.required' | translate}}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row wrap">
        <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="100" fxFlex.lg="100" fxFlex.xl="100"
          class="port-component-container-header">
          <span class="port-component-text-title">
            <mat-icon class="mat-18 vertical-and-color">account_balance</mat-icon>
            Impuestos
          </span>
        </div>
        <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="50" fxFlex.lg="50" fxFlex.xl="50"
          class="port-component-aling-padding-left">
          <mat-form-field class="port-component-width-95">
            <input formControlName="tariff" matInput [placeholder]="'secure.parametize.port.tariff' | translate">
            <mat-error *ngIf="formPort.get('tariff').hasError('pattern')">
              {{'secure.parametize.port.error_format_five_decimal' | translate}}
            </mat-error>
            <mat-error *ngIf="formPort.get('tariff').hasError('required')">{{'errors.required' | translate}}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
  <mat-divider></mat-divider>
  <mat-dialog-actions align="end">
    <button mat-button (click)="onNoClick()">{{'actions.cancel'| translate}}</button>
    <button type="button" mat-raised-button color="primary" [disabled]="!f.form.valid || validateKey || validateCountry"
      (click)="savePort()">{{'actions.accept' | translate}}</button>
  </mat-dialog-actions>
</ng-template>
