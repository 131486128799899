<section class="notification-list">
    <ng-container
        *ngIf="!notificationList.length && !pendingDevolution && !pendingClaims; else notificationListTemplate">
        <p class="floating-header__section-title floating-header__section-title--center">
            {{ 'profile.notifications.no-notifications' | translate}}
        </p>
    </ng-container>
    <ng-template #notificationListTemplate>
        <p class="floating-header__section-title">
            {{ 'profile.notification-list' | translate}}
        </p>
        <div class="notification-list__group" [ngClass]="{'show-more':showMore}">
            <a class="notification" routerLink="/securehome/seller-center/ordenes/listado-cancelaciones"
                *ngIf="pendingDevolution">
                <span class="notification__dot-container">
                    <span class="notification__dot"></span>
                    <span class="material-symbols-rounded notification__icon">notifications</span>
                </span>
                <p class="notification__description">
                    {{ 'profile.you-have' | translate }}
                    {{ pendingDevolution }} {{ 'profile.pending-cancellations' | translate }}
                </p>
            </a>
            <a class="notification" routerLink="/securehome/seller-center/support-center" *ngIf="pendingClaims">
                <span class="notification__dot-container">
                    <span class="notification__dot"></span>
                    <span class="material-symbols-rounded notification__icon">notifications</span>
                </span>
                <p class="notification__description">
                    {{ 'profile.you-have' | translate }}
                    {{ pendingClaims }} {{ 'profile.pending-claims' | translate }}
                </p>
            </a>
            <!-- Para mostrar más notificaciones -->
            <!-- <a class="notification" [routerLink]="notification.url"
                *ngFor="let notification of notificationList | slice : 0 : sliceLimit">
                <span class="notification__dot-container">
                    <span class="notification__dot"></span>
                    <span class="material-symbols-rounded notification__icon">notifications</span>
                </span>
                <p class="notification__description">
                    {{ notification.description }}
                </p>
            </a> -->
        </div>
        <p class="notification-list__show-more" *ngIf="notificationList.length > 3">
            <button class="show-more__button" (click)="toggleShowMore()">
                <ng-container *ngIf="showMore; else showMoreTemplate">
                    {{ 'profile.notifications.show-less' | translate }}
                </ng-container>
                <ng-template #showMoreTemplate>
                    {{ 'profile.notifications.show-more' | translate }}
                </ng-template>
            </button>
        </p>
    </ng-template>
</section>