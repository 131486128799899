<mat-sidenav-container>
  <app-toolbar [isEnabled]="isEnabled" [sidenav]="sidenav" [inDetail]="inDetail" [currentPageInput]="currentPage"
    [numberPages]="numberPages"></app-toolbar>

  <section id="section-list-offers" class="flexbox justify-start  flex-wrap" *ngIf="listAdminOffer && !viewDetailOffer">

    <aside class="filters flexbox flex-wrap align-center" *ngIf="filterActive && ((paramData.product && paramData.product !== null && paramData.product !== undefined && paramData.product !== '') ||
      (paramData.ean && paramData.ean !== null && paramData.ean !== undefined && paramData.ean !== '') ||
      (paramData.sellerSku && paramData.sellerSku !== null && paramData.sellerSku !== undefined && paramData.sellerSku !== '') ||
      (paramData.reference && paramData.reference !== null && paramData.reference !== undefined && paramData.reference !== '') ||
      (paramData.stock && paramData.stock !== null && paramData.stock !== undefined && paramData.stock !== ''))">
      <span class="filter-tittle">{{'secure.offers.list.list.filter_answer' | translate}}</span>

      <div class="filter-desc first flexbox align-center justify-between"
        *ngIf="paramData.product && paramData.product !== null && paramData.product !== undefined && paramData.product !== ''">
        <span [innerHTML]="paramData.product"></span>
        <span class="icon flexbox align-center justify-center" (click)="removeFilter('filterProduct')">
          <mat-icon>add_circle</mat-icon>
        </span>
      </div>

      <div class="filter-desc flexbox align-center justify-between"
        *ngIf="paramData.ean && paramData.ean !== null && paramData.ean !== undefined && paramData.ean !== ''">
        <span [innerHTML]="paramData.ean"></span>
        <span class="icon flexbox align-center justify-center" (click)="removeFilter('filterEan')">
          <mat-icon>add_circle</mat-icon>
        </span>
      </div>

      <div class="filter-desc flexbox align-center justify-between"
        *ngIf="paramData.pluVtex && paramData.pluVtex !== null && paramData.pluVtex !== undefined && paramData.pluVtex !== ''">
        <span [innerHTML]="paramData.pluVtex"></span>
        <span class="icon flexbox align-center justify-center" (click)="removeFilter('filterPluVtex')">
          <mat-icon>add_circle</mat-icon>
        </span>
      </div>

      <div class="filter-desc flexbox align-center justify-between"
        *ngIf="paramData.sellerSku && paramData.sellerSku !== null && paramData.sellerSku !== undefined && paramData.sellerSku !== ''">
        <span [innerHTML]="paramData.sellerSku"></span>
        <span class="icon flexbox align-center justify-center" (click)="removeFilter('filterSellerSku')">
          <mat-icon>add_circle</mat-icon>
        </span>
      </div>

      <div class="filter-desc flexbox align-center justify-between"
        *ngIf="paramData.reference && paramData.reference !== null && paramData.reference !== undefined && paramData.reference !== ''">
        <span [innerHTML]="paramData.reference"></span>
        <span class="icon flexbox align-center justify-center" (click)="removeFilter('filterReference')">
          <mat-icon>add_circle</mat-icon>
        </span>
      </div>

      <div class="filter-desc flexbox align-center justify-between"
        *ngIf="paramData.stock && paramData.stock !== null && paramData.stock !== undefined && paramData.stock !== ''">
        <span
          [innerHTML]="paramData.stock == '1' ? ['secure.offers.list.list.with_stock' | translate] : paramData.stock == 0 ? ['secure.offers.list.list.with_out_stock' | translate] : '' "></span>
        <span class="icon flexbox align-center justify-center" (click)="removeFilter('filterStock')">
          <mat-icon>add_circle</mat-icon>
        </span>
      </div>
    </aside>

    <!--list admin offers -->
    <aside class="card-offer" *ngFor="let item of listAdminOffer" (click)="openDetailOffer(item)">

      <div class="container-card-img flexbox align-center justify-center">
        <img [src]="item.imageUrl" alt="">
        <div class="sold-out flexbox justify-center align-center" *ngIf="item.stock === 0 || !item.stock">
          <div class="flexbox justify-center align-center">
            <p [innerHTML]="'secure.offers.list.list.with_out_stock' | translate"></p>
          </div>
        </div>
      </div>

      <div class="container-desc-card flexbox flex-wrap align-center">
        <h3 [innerHTML]="['secure.offers.list.list.EAN_offert' | translate] + item.ean"></h3>
        <h3 [innerHTML]="['secure.dashboard.table.top.title.SKU' | translate] + ': ' + item.pluVtex"></h3>
        <p *ngIf="item.discountPrice > 0" [innerHTML]="item.price | currency: '$' : 'symbol' : '1.2-2'"
          class="price-strikethrough"></p>
        <p [innerHTML]="item.discountPrice > 0 ? (item.discountPrice | currency: '$' : 'symbol' : '1.2-2') : (item.price | currency: '$' : 'symbol' : '1.2-2')"
          class="price"></p>
        <p [innerHTML]="item.name" class="name"></p>
        <p [ngClass]="{'sold-out-text' : item.stock === 0 || !item.stock}"
          [innerHTML]="item.stock > 0 ? (item.stock | number) + ['secure.offers.list.list.units' | translate] : ['secure.offers.list.list.with_out_stock' | translate]"
          class="stock"></p>
      </div>

      <footer class="container-last-modification flexbox align-center">
        <p
          [innerHTML]="['secure.offers.list.components.detail_offer.last_modify' | translate] + '<strong>' + (item.lastUpdate | date : 'dd/MM/yyyy') + '</strong>'">
        </p>
      </footer>
      <footer class="container-last-modification mobile flexbox align-center">
        <p
          [innerHTML]="['secure.offers.list.components.detail_offer.last_mod' | translate] + '<strong>' + (item.lastUpdate | date : 'dd/MM/yyyy') + '</strong>'">
        </p>
        <p [ngClass]="{'sold-out-text' : item.stock === 0 || !item.stock}"
          [innerHTML]="item.stock > 0 ? (item.stock | number) + ['secure.offers.list.list.units' | translate] : ['secure.offers.list.list.with_out_stock' | translate]">
        </p>
      </footer>
    </aside>
    <!-- list admin offers -->

  </section>

  <!-- Message for not offers -->
  <section *ngIf="!listAdminOffer || listAdminOffer.length === 0 || listAdminOffer.length === null"
    class="no-content-component">
    <div class="icon-sentiment-dissatisfied">
      <mat-icon>sentiment_dissatisfied</mat-icon>
    </div>
    <p [innerHTML]="'secure.offers.list.list.without_offert' | translate"></p>
  </section>
  <!-- Message for not offers -->

  <section *ngIf="viewDetailOffer && viewDetailOffer == true">
    <app-detail-offer [dataOffer]="dataOffer"></app-detail-offer>
  </section>

  <!-- Filter sidenav component -->
  <mat-sidenav #sidenav mode="over" position="end">
    <app-listAdmin-filter [sidenav]="sidenav" [filterRemoved]="filterRemove"></app-listAdmin-filter>
  </mat-sidenav>
  <!-- /Filter sidenav component -->

</mat-sidenav-container>
