<section class="profile-menu">
    <header class="profile-menu__header">
        <span class="header__initial-letters" [ngClass]="{'seller':cognitoUser.sellerProfile === 'seller'}">
            {{ shortName }}
        </span>
        <div class="header__name-and-rol">
            <p class="header__name">
                {{ user.Name | titlecase }}
            </p>
            <p class="header__rol" [ngClass]="{'seller':cognitoUser.sellerProfile === 'seller'}">
                {{ cognitoProfile | translate }}
                <strong>| ID: {{ user.IdSeller }}</strong>
            </p>
        </div>
    </header>
    <div class="profile__button-group">
        <mat-expansion-panel class="profile__button-dropdown hide-in-desktop">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span class="material-symbols-rounded profile__button-icon">translate</span>
                    {{'profile.language' | translate}}
                </mat-panel-title>
                <span class="material-symbols-rounded expand-arrow">keyboard_arrow_down</span>
            </mat-expansion-panel-header>
            <app-select-language [hideLabel]="true"></app-select-language>
        </mat-expansion-panel>
        <a (click)="emitClose()" [routerLink]="['/' + routesConst?.sellerCenterIntSchoolExito]"
            class="profile__button-link">
            <span class="material-symbols-rounded profile__button-icon">school</span>
            {{ 'menu.Escuela Exito' | translate }}
        </a>
        <button class="profile__button-link" (click)="openDialogSupport();emitClose();"
            *ngIf="cognitoUser.sellerProfile === 'seller'">
            <span class="material-symbols-rounded profile__button-icon">contact_support</span>
            {{'core.shell.header.support' | translate}}
        </button>
    </div>
    <div class="profile__logout">
        <a class="profile__logout-button" [routerLink]="['/' + routesConst?.sellerCenterLogout]">
            {{ 'profile.logout' | translate }}
        </a>
    </div>
</section>