<div class="content-table">
  <div fxFlex="100%" class="toolbar-paginate">
    <mat-paginator class="paginator-style orders-paginate" itemsPerPageLabel="Items por pagina" #paginator
      [pageSize]="5" [length]="100" [pageSizeOptions]="[5, 10, 20]"></mat-paginator>
    <button fxShow.xs="false" fxShow.sm="false" fxShow.md="true" fxShow.lg="true" fxShow.xl="true"
      style="margin-left:20px" (click)="bulkLoad.resetVariableUploadFile()" id="clear-guide-button" color="primary"
      mat-raised-button>
      <span>{{'secure.offers.bulk_upload.table_load.title_offer_charge' | translate}}</span>
    </button>
  </div>

  <mat-expansion-panel class="card-err-process" [expanded]="countErrors > 0">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="title-expansion-panel-table">
          <mat-icon>error</mat-icon>
          <span [innerHTML]="countRowUpload - 1"></span>
          <b>{{'secure.products.table_load_product.records_found' | translate}}</b>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container-table-errors">
      <table mat-table #table [dataSource]="bulkLoad.dataSource">

        <!-- EAN Column -->
        <ng-container matColumnDef="EAN" sticky>
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>{{'secure.offers.historical_admin.components.filter.EAN' | translate}}</p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorEan == true }" *matCellDef="let element">
            <div class="information" *ngIf="element.EAN != undefined && element.EAN != ''">
              <p class="sc-text-normal">{{element.EAN}}</p>
            </div>
            <div class="information" *ngIf="element.EAN == undefined || element.EAN == ''">
              <p class="text-error"> {{'secure.products.table_load_product.mandatory_data' | translate}}
              </p>
            </div>
          </td>
        </ng-container>

        <!-- Stock Column -->
        <ng-container matColumnDef="Stock">
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>{{'secure.offers.historical_admin.historical_admin.stock' | translate}}</p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorStock == true }" *matCellDef="let element">
            <div class="information" *ngIf="element.Stock != undefined && element.Stock != ''">
              <p class="sc-text-normal">{{element.Stock}}</p>
            </div>
            <div class="information" *ngIf="element.Stock == undefined || element.Stock == ''">
              <p class="text-error"> {{'secure.products.table_load_product.mandatory_data' | translate}}
              </p>
            </div>
          </td>
        </ng-container>

        <!-- Price Column -->
        <ng-container matColumnDef="Price">
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>{{'secure.offers.historical_admin.historical_admin.price' | translate}}</p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorPrice == true }" *matCellDef="let element">
            <div class="information" *ngIf="element.Price != undefined && element.Price != ''">
              <p class="sc-text-normal">{{element.Price}}</p>
            </div>
            <div class="information" *ngIf="element.Price == undefined || element.Price == ''">
              <p class="text-error"> {{'secure.products.table_load_product.mandatory_data' | translate}}
              </p>
            </div>
          </td>
        </ng-container>

        <!-- DiscountPrice Column -->
        <ng-container matColumnDef="DiscountPrice">
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>{{'secure.offers.historical_admin.historical_admin.discount_price' | translate}}</p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorDiscountPrice == true }" *matCellDef="let element">
            <div class="information" *ngIf="element.DiscountPrice != undefined && element.DiscountPrice != ''">
              <p class="sc-text-normal">{{element.DiscountPrice}}</p>
            </div>
            <div class="information" *ngIf="element.DiscountPrice == undefined || element.DiscountPrice == ''">
              <p class="text-error"> -
              </p>
            </div>
          </td>
        </ng-container>

        <!-- AverageFreightCost Column -->
        <ng-container matColumnDef="AverageFreightCost">
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>{{'secure.offers.list.components.detail_offer.averageFreightCost' | translate}}</p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorAverageFreightCost == true }"
            *matCellDef="let element">
            <div class="information"
              *ngIf="element.AverageFreightCost != undefined && element.AverageFreightCost != ''">
              <p class="sc-text-normal">{{element.AverageFreightCost}}</p>
            </div>
            <div class="information"
              *ngIf="element.AverageFreightCost == undefined || element.AverageFreightCost == ''">
              <p class="text-error"> -
              </p>
            </div>
          </td>
        </ng-container>

        <!-- PromiseDelivery Column -->
        <ng-container matColumnDef="PromiseDelivery">
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>{{'secure.offers.historical_admin.historical_admin.promise' | translate}}</p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorPromiseDelivery == true }" *matCellDef="let element">
            <div class="information" *ngIf="element.PromiseDelivery != undefined && element.PromiseDelivery != ''">
              <p class="sc-text-normal">{{element.PromiseDelivery}}</p>
            </div>
            <div class="information" *ngIf="element.PromiseDelivery == undefined || element.PromiseDelivery == ''">
              <p class="text-error"> -
              </p>
            </div>
          </td>
        </ng-container>

        <!-- IsFreeShipping Column -->
        <ng-container matColumnDef="IsFreeShipping">
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>{{'secure.offers.historical_admin.historical_admin.free_shipping' | translate}}</p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorIsFreeShipping == true }" *matCellDef="let element">
            <div class="information" *ngIf="element.IsFreeShipping != undefined && element.IsFreeShipping != ''">
              <p class="sc-text-normal">{{element.IsFreeShipping}}</p>
            </div>
            <div class="information" *ngIf="element.IsFreeShipping == undefined || element.IsFreeShipping == ''">
              <p class="text-error"> -
              </p>
            </div>
          </td>
        </ng-container>

        <!-- IsEnviosExito Column -->
        <ng-container matColumnDef="IsEnviosExito">
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>{{'secure.offers.historical_admin.historical_admin.shiping_exito' | translate}}</p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorIsEnviosExito == true }" *matCellDef="let element">
            <div class="information" *ngIf="element.IsEnviosExito != undefined && element.IsEnviosExito != ''">
              <p class="sc-text-normal">{{element.IsEnviosExito}}</p>
            </div>
            <div class="information" *ngIf="element.IsEnviosExito == undefined || element.IsEnviosExito == ''">
              <p class="text-error"> -
              </p>
            </div>
          </td>
        </ng-container>

        <!-- IsFreightCalculator Column -->
        <ng-container matColumnDef="IsFreightCalculator">
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>{{'secure.offers.historical_admin.historical_admin.freight_calculator' | translate}}</p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorIsFreightCalculator == true }"
            *matCellDef="let element">
            <div class="information"
              *ngIf="element.IsFreightCalculator != undefined && element.IsFreightCalculator != ''">
              <p class="sc-text-normal">{{element.IsFreightCalculator}}</p>
            </div>
            <div class="information"
              *ngIf="element.IsFreightCalculator == undefined || element.IsFreightCalculator == ''">
              <p class="text-error"> -
              </p>
            </div>
          </td>
        </ng-container>

        <!-- Warranty Column -->
        <ng-container matColumnDef="Warranty">
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>{{'secure.offers.historical_admin.historical_admin.warranty' | translate}}</p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorWarranty == true }" *matCellDef="let element">
            <div class="information" *ngIf="element.Warranty != undefined && element.Warranty != ''">
              <p class="sc-text-normal">{{element.Warranty}}</p>
            </div>
            <div class="information" *ngIf="element.Warranty == undefined || element.Warranty == ''">
              <p class="text-error"> -
              </p>
            </div>
          </td>
        </ng-container>

        <!-- IsLogisticsExito Column -->
        <ng-container matColumnDef="IsLogisticsExito">
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>{{'secure.offers.historical_admin.historical_admin.exito_logistic' | translate}}</p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorIsLogisticsExito == true }" *matCellDef="let element">
            <div class="information" *ngIf="element.IsLogisticsExito != undefined && element.IsLogisticsExito != ''">
              <p class="sc-text-normal">{{element.IsLogisticsExito}}</p>
            </div>
            <div class="information" *ngIf="element.IsLogisticsExito == undefined || element.IsLogisticsExito == ''">
              <p class="text-error"> -
              </p>
            </div>
          </td>
        </ng-container>

        <!-- IsUpdatedStock Column -->
        <ng-container matColumnDef="IsUpdatedStock">
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>{{'secure.offers.list.components.detail_offer.update_stock' | translate}}</p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorIsUpdatedStock == true }" *matCellDef="let element">
            <div class="information" *ngIf="element.IsUpdatedStock != undefined && element.IsUpdatedStock != ''">
              <p class="sc-text-normal">{{element.IsUpdatedStock}}</p>
            </div>
            <div class="information" *ngIf="element.IsUpdatedStock == undefined || element.IsUpdatedStock == ''">
              <p class="text-error"> -
              </p>
            </div>
          </td>
        </ng-container>


        <!-- EanCombo Column -->
        <ng-container matColumnDef="EanCombo">
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>{{'secure.offers.bulk_upload.table_load.ean_combo' | translate}}
              </p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorEanCombo == true }" *matCellDef="let element">
            <div class="information" *ngIf="element.EanCombo != undefined && element.EanCombo != ''">
              <p class="sc-text-normal">{{element.EanCombo}}</p>
            </div>
            <div class="information" *ngIf="element.EanCombo == undefined || element.EanCombo == ''">
              <p class="text-error"> -
              </p>
            </div>
          </td>
        </ng-container>

        <!-- SellerSku Column -->
        <ng-container matColumnDef="SellerSku">
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>{{'secure.offers.bulk_upload.table_load.sellerSku' | translate}}
              </p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorSellerSku == true }" *matCellDef="let element">
            <div class="information" *ngIf="element.SellerSku != undefined && element.SellerSku != ''">
              <p class="sc-text-normal">{{element.SellerSku}}</p>
            </div>
            <div class="information" *ngIf="element.SellerSku == undefined || element.SellerSku == ''">
              <p class="text-error"> -
              </p>
            </div>
          </td>
        </ng-container>

            <!-- Referencia padre Column -->
            <ng-container matColumnDef="Reference">
              <th mat-header-cell *matHeaderCellDef>
                <div class="information th">
                  <p>{{'secure.products.table_load_product.parent_reference' | translate}}
                  </p>
                </div>
              </th>
              <td mat-cell [ngClass]="{'selected-error': element.errorReference == true }" *matCellDef="let element">
                <div class="information" *ngIf="element.Reference != undefined && element.Reference != ''">
                  <p class="sc-text-normal">{{element.Reference}}</p>
                </div>
                <div class="information" *ngIf="element.Reference == undefined || element.Reference == ''">
                  <p class="text-error"> -
                  </p>
                </div>
              </td>
            </ng-container>


        <!-- Cantidad en combo Column -->
        <ng-container matColumnDef="ComboQuantity">
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>{{'secure.offers.bulk_upload.table_load.quantity_combo' | translate}}
              </p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorComboQuantity == true }" *matCellDef="let element">
            <div class="information" *ngIf="element.ComboQuantity != undefined && element.ComboQuantity != ''">
              <p class="sc-text-normal">{{element.ComboQuantity}}</p>
            </div>
            <div class="information" *ngIf="element.ComboQuantity == undefined || element.ComboQuantity == ''">
              <p class="text-error"> -
              </p>
            </div>
          </td>
        </ng-container>

        <!--Tipo de moneda -->
        <ng-container matColumnDef="Currency">
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>{{'secure.offers.historical_admin.historical_admin.currency' | translate}}</p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorCurrency == true }" *matCellDef="let element">
            <div class="information" *ngIf="element.Currency != undefined && element.Currency != ''">
              <p class="sc-text-normal">{{element.Currency}}</p>
            </div>
            <div class="information" *ngIf="element.Currency == undefined || element.Currency == ''">
              <p class="text-error"> -
              </p>
            </div>
          </td>
        </ng-container>

        <!--Direccion de recogida -->
        <ng-container matColumnDef="Address">
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>{{'secure.offers.bulk_upload.table_load.address' | translate}}</p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorAddress == true }" *matCellDef="let element">
            <div class="information" *ngIf="element.Address != undefined && element.Address != ''">
              <p class="sc-text-normal">{{element.Address}}</p>
            </div>
            <div class="information" *ngIf="element.Address == undefined || element.Address == ''">
              <p class="text-error"> -
              </p>
            </div>
          </td>
        </ng-container>

        <!--Ciudad de recogida -->
        <ng-container matColumnDef="DaneCode">
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>{{'secure.offers.bulk_upload.table_load.dane_code' | translate}}</p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorDaneCode == true }" *matCellDef="let element">
            <div class="information" *ngIf="element.DaneCode != undefined && element.DaneCode != ''">
              <p class="sc-text-normal">{{element.DaneCode}}</p>
            </div>
            <div class="information" *ngIf="element.DaneCode == undefined || element.DaneCode == ''">
              <p class="text-error"> -
              </p>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="Periodicity">
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>{{'secure.offers.bulk_upload.table_load.Periodicity' | translate}}</p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorPeriodicity == true }" *matCellDef="let element">
            <div class="information" *ngIf="element.Periodicity != undefined && element.Periodicity != ''">
              <p class="sc-text-normal">{{element.Periodicity}}</p>
            </div>
            <div class="information" *ngIf="element.Periodicity == undefined || element.Periodicity == ''">
              <p class="text-error"> -
              </p>
            </div>
          </td>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

        <mat-row [ngClass]="{'personal-row': row.errorRow == true}" id="order-{{row.EAN}}"
          *matRowDef="let index=index;let row; columns: displayedColumns;"
          class="personal-row element-row personal-row-tabl ">
        </mat-row>
      </table>
    </div>

  </mat-expansion-panel>

</div>
