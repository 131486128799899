<!-- Web Desing -->
<div>
  <mat-toolbar class="tree-toolbar">
    <div class="normal-theme toolbar-options-table flexbox justify-center align-center">
      <!-- Componente para realizar la busqueda de una tienda -->
      <form #getBillings="ngForm" [formGroup]="billingGroup" class="search-store form-store">
        <div class="input-search-container">
          <mat-icon class="icon-search" matPrefix>search</mat-icon>
          <input matInput [placeholder]="'secure.orders.billing_orders.txt_serch_order' | translate"
            class="search-input" formControlName="billingOrderCtrl" (keyup.enter)="chargeBillingOrders()"
            autocomplete="off" *ngIf="getFunctionality(read)">
        </div>
      </form>
    </div>
  </mat-toolbar>
</div>
<div class="list-container">
  <div *ngIf="!sellerData && sellerData !== null">
    <div class="icon-face">
      <mat-icon>mms</mat-icon>
    </div>
    <p class="icon-face-text">{{'secure.orders.billing_orders.txt_no_order' | translate}}</p>
  </div>

  <div *ngIf="sellerData === null">
    <div class="icon-face">
      <mat-icon>insert_drive_file</mat-icon>
    </div>
    <p class="icon-face-text">{{'secure.orders.billing_orders.txt_no_invoices' | translate}}</p>
  </div>


  <div *ngIf="sellerData" class="agreement">
    <div class="agreement-title">
      <p>{{'secure.orders.order' | translate}}: {{billingGroup.controls.billingOrderCtrl.value}}</p>
    </div>
    <div *ngFor="let billing of keysBilling; let i = index" fxLayout="row" class="list-agreement">
      <div fxLayoutAlign="start center" fxLayout="row" class="width-80">
        <mat-icon class="material-icons" class="file-agreement">
          insert_drive_file
        </mat-icon>
        <p>{{billing.sellerName}} {{billing.billAtachedDate}}</p>
      </div>
      <button mat-icon-button (click)="downloadPDF(billing)" *ngIf="false">
        <mat-icon class="material-icons">
          get_app
        </mat-icon>
      </button>
      <button mat-icon-button (click)="viewPDF(billing)" *ngIf="getFunctionality(download)">
        <mat-icon class="material-icons">
          visibility
        </mat-icon>
      </button>
    </div>
  </div>
</div>