<div class="title-page">
  <div class="title">
    <h2 class="sc-title mat-h2">
      {{ "secure.parametize.support_modal.title" | translate }}
    </h2>
    <h4 class="description-title mat-h4">
      {{ "secure.parametize.support_modal.description" | translate }}
    </h4>
  </div>

  <a id="close-finish-send-order" [matTooltip]="'actions.close_menu' | translate" mat-icon-button
    matTooltipPosition="below" mat-button (click)="onNoClick()">
    <mat-icon>close</mat-icon>
  </a>
</div>

<form #f="ngForm" [formGroup]="myform" *ngIf="myform">
  <div fxFlex="100" fxLayoutGap.md="50px" fxLayoutGap.lg="50px" fxLayoutGap.xl="50px" fxLayoutAlign="center start"
    fxLayout="row wrap" class="example-form">

    <div fxFlex="45" fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="40" fxFlex.lg="45" fxFlex.xl="45">

      <mat-form-field class="example-full-width">
        <input readonly id="suport-modal-input-nit" required matInput formControlName="nit"
          [placeholder]="'secure.seller.list.nit' | translate" />
        <mat-icon matPrefix class="icon-support-modal">assignment_ind</mat-icon>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input readonly id="suport-modal-input-account" required matInput formControlName="account"
          [placeholder]="'secure.products.table_load_product.seller' | translate" />
        <mat-icon matPrefix class="icon-support-modal">account_balance</mat-icon>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input id="suport-modal-input-contact" required matInput formControlName="contact"
          [placeholder]="'secure.seller.register_seller.lb_contact_form' | translate" />

        <mat-icon matPrefix class="icon-support-modal">import_contacts</mat-icon>
        <mat-icon color="primary" matSuffix [matTooltip]="'secure.parametize.support_modal.tooltip_case' | translate"
          matTooltipPosition="below">help_outline</mat-icon>

        <mat-error *ngIf="myform.get('contact').hasError('required')">
          {{"secure.products.create_product_unit.basic_information.input_mandatory" | translate}}
        </mat-error>

        <mat-error *ngIf="myform.get('contact').hasError('pattern')">
          {{"secure.parametize.support_modal.invalid_fiel" | translate}}
        </mat-error>

      </mat-form-field>

      <mat-form-field class="example-full-width">
        <mat-icon matPrefix class="icon-support-modal">email</mat-icon>
        <input id="suport-modal-input-emailContact" required matInput formControlName="emailContact"
          [placeholder]="'secure.parametize.support_modal.contact_mail' | translate" />
        <mat-icon color="primary" matSuffix [matTooltip]="'secure.parametize.support_modal.email_contact' | translate"
          matTooltipPosition="below">help_outline</mat-icon>
        <mat-error *ngIf="myform.get('emailContact').hasError('email')">
          {{"secure.parametize.support_modal.error_email" | translate}}
        </mat-error>
      </mat-form-field>

    </div>

    <div fxFlex="45" fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="40" fxFlex.lg="45" fxFlex.xl="45">
      <div *ngIf="omsCategories.length == 0; else classificationField" class="loading-box">
        <mat-icon matPrefix class="icon-support-modal">list</mat-icon>
        <div class="loading-box__loading-animation">
          <mat-spinner color="accent" [diameter]="24"></mat-spinner>
        </div>
        <mat-icon color="primary" matSuffix matTooltipPosition="below"
          [matTooltip]="'secure.parametize.support_modal.classification_tips' | translate">
          help_outline
        </mat-icon>
      </div>

      <ng-template #classificationField>
        <mat-form-field id="suport-modal-input-reason-content" class="example-full-width" required>
          <mat-icon matPrefix class="icon-support-modal">list</mat-icon>
          <mat-select id="suport-modal-input-reason" formControlName="classification"
            [placeholder]="'secure.parametize.support_modal.classification' | translate">
            <ng-container *ngFor="
                let item of getClassification(omsCategories) | async;
                let i = index
              ">
              <mat-option [value]="item.classification" (click)="onClickClassificationOption(item)">
                <span id="{{ item.classification }} - {{ i }}">
                  {{
                    item.classification != null
                      ? item.classification
                      : "Sin título"
                  }}
                </span>
              </mat-option>
            </ng-container>
          </mat-select>
          <mat-icon color="primary" matSuffix matTooltipPosition="below"
            [matTooltip]="'secure.parametize.support_modal.classification_tips' | translate">
            help_outline
          </mat-icon>
        </mat-form-field>
      </ng-template>

      <mat-form-field id="suport-modal-input-category-content" class="example-full-width"
        *ngIf="scCategories.length > 0 && scCategories[0].category && scCategories">
        <mat-icon matPrefix class="icon-support-modal">list</mat-icon>
        <mat-select id="suport-modal-input-reason1" formControlName="category" required="true"
          [placeholder]="'secure.parametize.support_modal.subClassification' | translate">
          <ng-container *ngFor="let item of scCategories; let i = index">
            <mat-option [value]="item.category ? item.category : 'null'" (click)="onClickCategoryOption(item)"
              *ngIf="item.category">
              <span id="{{ item.category }}-{{ i }}">
                {{ item.category }}
              </span>
            </mat-option>
          </ng-container>
        </mat-select>

        <mat-icon color="primary" matSuffix matTooltipPosition="below"
          [matTooltip]="'secure.parametize.support_modal.category_tips' | translate">
          help_outline
        </mat-icon>
      </mat-form-field>

      <ng-container *ngIf="scSubcategories.length > 0 && scSubcategories">
        <mat-form-field id="suport-modal-input-subCategory-content" class="example-full-width"
          *ngIf="scSubcategories.length > 0 && scSubcategories">
          <mat-icon matPrefix class="icon-support-modal">list</mat-icon>
          <mat-select id="suport-modal-input-subCategory" required="true" formControlName="subCategory"
            [placeholder]="'secure.parametize.support_modal.detail' | translate">
            <ng-container *ngFor="let item of scSubcategories; let i = index">
              <mat-option [value]="item.subcategory ? item.subcategory : 'null'"
                (click)="onClickSubcategoryOption(item)" *ngIf="item.subcategory">
                <span id="{{ item.subcategory }}-{{ i }}" *ngIf="item.subcategory">
                  {{ item.subcategory }}
                </span>
              </mat-option>
            </ng-container>
          </mat-select>

          <mat-icon color="primary" matSuffix
            [matTooltip]="'secure.parametize.support_modal.subcategory_tips' | translate" matTooltipPosition="below">
            help_outline
          </mat-icon>
        </mat-form-field>
      </ng-container>

      <mat-form-field class="example-full-width" id="suport-modal-input-typeOfRequirement-content"
        *ngIf="scReasonTypes.length > 0 && scReasonTypes" required>
        <mat-icon matPrefix class="icon-support-modal">assignment</mat-icon>
        <mat-select id="suport-modal-input-typeOfRequirement" formControlName="typeOfRequirement"
          [placeholder]="'secure.parametize.support_modal.type_case' | translate">
          <ng-container *ngFor="let item of scReasonTypes; let i = index">
            <mat-option [value]="item.value">
              <span id="{{ item }}-{{ i }}">
                {{ item.value }}
              </span>
            </mat-option>
          </ng-container>
        </mat-select>
        <mat-icon color="primary" matSuffix [matTooltip]="'secure.parametize.support_modal.list_type_case' | translate"
          matTooltipPosition="below">help_outline</mat-icon>
        <mat-error *ngIf="myform.get('typeOfRequirement').hasError('required')">
          {{"secure.products.create_product_unit.basic_information.input_mandatory"| translate}}
        </mat-error>
      </mat-form-field>


      <ng-container *ngIf="scRequiered && scRequiered.length > 0">
        <div *ngFor="let field of scRequiered; let i=index">
          <mat-form-field class="example-full-width">

            <input id="{{field.name}}-{{i}}" matInput [required]="field.requiered" formControlName="{{field.name}}"
              placeholder="{{field.placeHolder}}">

            <mat-error *ngIf="!myform.get(field.name).hasError('fields')">
              {{"secure.products.create_product_unit.basic_information.input_mandatory" | translate}}
            </mat-error>

          </mat-form-field>
        </div>
      </ng-container>

      <mat-form-field class="example-full-width">
        <mat-icon matPrefix class="icon-support-modal">comment</mat-icon>
        <textarea id="suport-modal-textarea-description" required formControlName="description" matInput
          maxlength="2000" [placeholder]="'shared.descripcion' | translate">
        </textarea>

        <mat-icon color="primary" matSuffix
          [matTooltip]="'secure.parametize.support_modal.description_tips' | translate" matTooltipPosition="below">
          help_outline
        </mat-icon>

        <mat-error *ngIf="myform.get('description').hasError('required')">
          {{"secure.products.create_product_unit.basic_information.input_mandatory" | translate}}
        </mat-error>

        <mat-error *ngIf="myform.get('description').hasError('maxlength')">
          {{ "secure.parametize.support_modal.limit_field" | translate }}
        </mat-error>

        <mat-error *ngIf="myform.get('description').hasError('pattern')">
          {{ "secure.parametize.support_modal.field_invalid" | translate }}
        </mat-error>
      </mat-form-field>

    </div>

    <mat-dialog-actions fxFlex="100" class="footer-dialog normal-theme dialog-buttons">
      <div>
        <app-upload-button [validations]="validations" [showGeneralError]="true"
          [generalMessageError]="languageService.instant('secure.parametize.support_claims-upload-general-error')"
          (fileChange)="onFileChange($event)">
          {{'secure.parametize.support_claims-upload-btn.title' | translate}}
        </app-upload-button>
      </div>

      <div class="class-tutorial" fxLayout="row" fxLayoutAlign="space-between center">
        <mat-icon>video_library</mat-icon>
        <a href="https://www.youtube.com/channel/UCxCSSIsUl92fe7iqKH4nqPg/videos?view_as=subscriber" rel="noopener"
          target="_blank" id="id-color-tutorial">
          {{ "secure.parametize.support_modal.tutorial" | translate }}</a>
      </div>

      <button id="suport-modal-button-close-modal" class="sc-button" (click)="clearForm()" mat-button>
        <mat-icon>clear_all</mat-icon>
        {{ "filters.clear" | translate }}
      </button>

      <button id="suport-modal-button-sendInformation" class="sc-button" [disabled]="!f.form.valid"
        (click)="sendSupportMessage(myform)" type="submit" mat-raised-button color="primary">
        {{ "actions.send" | translate }}
      </button>
    </mat-dialog-actions>
  </div>
</form>
