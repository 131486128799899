<mat-card class="content-profile">

  <mat-nav-list class="light-content">
    <mat-toolbar color="primary" class="toolbar-title-search">
      <h4 class="title-toolbar">{{informationToForm.title_for_search | translate}}</h4>
      <button color="accent" [matTooltip]="'actions.close' | translate" matTooltipPosition="below" mat-icon-button mat-button (click)="toggleMenu()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </mat-nav-list>
  <mat-card-content>

    <app-search-order-form [informationToForm]="informationToForm" [paginator]="paginator" [state]="state" [typeProfiel]="typeProfiel" [idSeller]="idSeller" *ngIf="informationToForm.type_form == 'orders'"></app-search-order-form>

    <app-search-billing-form [informationToForm]="informationToForm" *ngIf="informationToForm.type_form == 'billing'"></app-search-billing-form>

    <app-search-pending-devolution-form [paginator]="paginator" [idSeller]="idSeller" [informationToForm]="informationToForm" *ngIf="informationToForm.type_form == 'pending-devolution'"></app-search-pending-devolution-form>

    <app-search-envios-exito-form [informationToForm]="informationToForm" *ngIf="informationToForm.type_form == 'envios-exito'"></app-search-envios-exito-form>

    <app-search-historical-devolution-form [idSeller]="idSeller" [informationToForm]="informationToForm" *ngIf="informationToForm.type_form === 'historical-devolution'"></app-search-historical-devolution-form>

    <app-search-fraud-notification-form [informationToForm]="informationToForm" [paginator]="paginator" [typeProfiel]="typeProfiel" [idSeller]="idSeller" *ngIf="informationToForm.type_form == 'fraud-notification'"></app-search-fraud-notification-form>

  </mat-card-content>
</mat-card>
