<section class="unit-product-creation product-media">
  <h2 class="unit-product-creation__title">
    {{ "secure.products.unit_product_creation.images_and_video.title" | translate }}
  </h2>
  <ng-container *ngIf="productType === 'Technology'">
    <p class="unit-product-creation__description">
      {{ "secure.products.unit_product_creation.images_and_video.description" | translate }}
    </p>
    <h3 class="unit-product-creation__subtitle">
      {{ "secure.products.unit_product_creation.images_and_video.upload_images.recommendations" | translate }}
    </h3>
    <ul class="unit-product-creation__list">
      <li class="unit-product-creation__list-item">
        <span class="unit-product-creation__list-icon material-symbols-outlined">check_circle</span>
        <span
          [innerHTML]="'secure.products.unit_product_creation.images_and_video.upload_images.recommendations.list_item_1' | translate : {imagesCount:5}"></span>
      </li>
      <li class="unit-product-creation__list-item">
        <span class="unit-product-creation__list-icon material-symbols-outlined">check_circle</span>
        <span
          [innerHTML]="'secure.products.unit_product_creation.images_and_video.upload_images.recommendations.list_item_2' | translate"></span>
      </li>
    </ul>
    <div class="product-media__images">
      <div class="product-media__upload-images">
        <app-upload-product-image-file *ngFor="let image of images" [imageData]="image"
          (uploadImage)="onUploadImageFile($event)"></app-upload-product-image-file>
      </div>
    </div>
    <app-upload-product-image-url (uploadImageUrl)="onUploadImageUrl($event)"></app-upload-product-image-url>
  </ng-container>

  <ng-container *ngIf="productType === 'Clothing'">
    <app-uploaded-images></app-uploaded-images>
  </ng-container>

  <app-upload-product-video-url [videoUrl]="videoUrl"
    (uploadVideo)="onUploadVideo($event)"></app-upload-product-video-url>
  <div class="unit-product-creation__actions">
    <button matStepperPrevious class="unit-product-creation__step-button unit-product-creation__button-back">
      {{ "secure.products.unit_product_creation.back_button_label" | translate }}
    </button>
    <button (click)="productCreationCompletedEmit()"
      class="unit-product-creation__step-button unit-product-creation__button-continue">
      {{ "secure.products.create_product_unit.component_process.save" | translate }}
    </button>
  </div>

</section>