<div class="upload-video">
  <h3 class="unit-product-creation__subtitle">
    {{ "secure.products.unit_product_creation.images_and_video.upload_video.title" | translate }}
  </h3>
  <p class="unit-product-creation__description">
    {{ "secure.products.unit_product_creation.images_and_video.upload_video.description" | translate }}
  </p>
  <ul class="unit-product-creation__list">
    <li class="unit-product-creation__list-item">
      <span class="unit-product-creation__list-icon material-symbols-outlined">check_circle</span>
      {{ "secure.products.unit_product_creation.images_and_video.upload_video.list_item_1" | translate }}
    </li>
    <li class="unit-product-creation__list-item">
      <span class="unit-product-creation__list-icon material-symbols-outlined">check_circle</span>
      {{ "secure.products.unit_product_creation.images_and_video.upload_video.list_item_2" | translate }}
    </li>
  </ul>
  <div class="upload-video__form">
    <mat-form-field floatLabel="never" class="upload-video__form-field">
      <input matInput type="text"
             [placeholder]="'secure.products.unit_product_creation.images_and_video.upload_video.url_input_placeholder' | translate"
             [formControl]="videoIdInput">
    </mat-form-field>
  </div>

  <div *ngIf="videoId" style="width: fit-content; margin: 0 auto">
    <youtube-player width="630" height="300" [videoId]="videoId">
    </youtube-player>
  </div>
  <div *ngIf="!videoId" class="upload-video__video-placeholder">
    <span class="material-symbols-outlined">play_arrow</span>
  </div>
</div>

