<div class="content-tree">
  <div class="header-tree-component">
    <p>{{'shared.name' | translate}}</p>
    <p>{{'shared.commission' | translate}}</p>
  </div>
  <mat-tree #tree [dataSource]="dataSource" [treeControl]="treeControl">
    <mat-tree-node class="tree-col" *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
      <div fxLayout="row" fxFlex="100%">
        <div fxLayout="row" fxFlex="100%">
          <p class="tree-child-col" [innerHTML]="node.filename"></p>
        </div>
        <div class="badge-tree-cateogry" matBadgePosition="before">
          <app-input-commision [updateFunction]="updateFunction" [inputValue]="node" [currenTree]="current_tree"></app-input-commision>
          <p class="sing-input">%</p>
        </div>
      </div>
    </mat-tree-node>

    <mat-tree-node class="tree-col" *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
      <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.filename">
        <mat-icon class="mat-icon-rtl-mirror">
          {{treeControl.isExpanded(node) ? 'remove' : 'add'}}
        </mat-icon>
      </button>
      <div fxLayout="row" fxFlex="100%">
        <div fxLayout="row" fxFlex="100%">
          <p class="tree-title-col" [innerHTML]="node.filename"></p>
          <p>{{node.comision}}</p>
        </div>
        <div class="badge-tree-cateogry" matBadgePosition="before">
          <app-input-commision [updateFunction]="updateFunction" [inputValue]="node" [currenTree]="current_tree"></app-input-commision>
          <p class="sing-input">%</p>
        </div>
      </div>
    </mat-tree-node>
  </mat-tree>
</div>

