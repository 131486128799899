<form class="product-basic-information__form" [formGroup]="basicInformationForm" *ngIf="brands">

  <div class="unit-product-creation__form-field">
    <label class="unit-product-creation__form-label">
      {{ "secure.products.unit_product_creation.basic_information.form.product_name_label" | translate }}
    </label>
    <mat-form-field floatLabel="never">
      <input matInput type="text"
        [placeholder]="'secure.products.unit_product_creation.basic_information.form.product_name_placeholder' | translate"
        formControlName="name" (blur)="basicInformationForm.get('name').markAsDirty()">
      <mat-error *ngIf=" basicInformationForm.get('name').hasError('required')">
        {{ "errors.error_required" | translate }}
      </mat-error>
      <mat-error *ngIf="basicInformationForm.get('name').hasError('pattern')">
        {{ "errors.error_format_invalid" | translate }}
      </mat-error>
    </mat-form-field>
  </div>

  <div class="unit-product-creation__form-field">
    <label class="unit-product-creation__form-label">
      {{ "secure.products.unit_product_creation.basic_information.form.category_label" | translate }}
    </label>
    <mat-form-field floatLabel="never">
      <input matInput type="text"
        [placeholder]="'secure.products.unit_product_creation.basic_information.form.category_placeholder' | translate"
        formControlName="category" (blur)="basicInformationForm.get('category').markAsDirty()">
    </mat-form-field>
  </div>

  <div class="unit-product-creation__form-field">
    <label class="unit-product-creation__form-label">
      {{ "secure.products.unit_product_creation.basic_information.form.brand_label" | translate }}
    </label>
    <mat-form-field floatLabel="never" *ngIf="showSearchBrand">
      <input matInput type="text" [matAutocomplete]="autocomplete"
        [placeholder]="'secure.products.unit_product_creation.basic_information.form.brand_placeholder' | translate"
        formControlName="brand" (blur)="basicInformationForm.get('brand').markAsDirty()">
      <mat-autocomplete #autocomplete="matAutocomplete">
        <mat-option *ngFor="let brand of filterBrands" [value]="brand.Name">
          {{ brand.Name }}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="basicInformationForm.get('brand').hasError('required')">
        {{ "errors.error_required" | translate }}
      </mat-error>
      <mat-error *ngIf="basicInformationForm.get('brand').hasError('pattern')">
        {{ "errors.error_format_invalid" | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field floatLabel="never" *ngIf="!showSearchBrand">
      <input matInput [placeholder]="'shared.brand' | translate" formControlName="brand"
        (blur)="basicInformationForm.get('brand').markAsDirty()">
      <mat-error *ngIf="basicInformationForm.get('brand').hasError('required')">
        {{ "errors.error_required" | translate }}
      </mat-error>
      <mat-error *ngIf="basicInformationForm.get('brand').hasError('pattern')">
        {{ "errors.error_format_invalid" | translate }}
      </mat-error>
    </mat-form-field>
    <mat-checkbox class="unit-product-creation__checkbox" color="primary" (click)="toggleBrandInput()">
      {{ "secure.products.unit_product_creation.basic_information.form.brand_checkbox_label" | translate }}
    </mat-checkbox>
  </div>

  <div class="unit-product-creation__form-field">
    <label class="unit-product-creation__form-label">
      {{ "secure.products.unit_product_creation.basic_information.form.shipping_size_label" | translate }}*
    </label>
    <div class="product-basic-information__size-slider">
      <app-product-size-slider (selectOption)="onSelectSize($event)"></app-product-size-slider>
    </div>
    <p class="custom-form__error">
      <mat-error
        *ngIf="basicInformationForm.get('shippingSize').errors && basicInformationForm.get('shippingSize').dirty">
        {{ "errors.error_required" | translate }}
      </mat-error>
    </p>
  </div>


  <div class="unit-product-creation__form-field">
    <label class="unit-product-creation__form-label">
      {{ "secure.products.unit_product_creation.basic_information.form.packing_dimensions_label" | translate }}*
    </label>
    <div class="product-basic-information__group" formGroupName="packingDimensions">
      <mat-form-field floatLabel="never" class="product-basic-information__group-item">
        <input matInput type="text"
          [placeholder]="'secure.products.unit_product_creation.basic_information.form.height_placeholder' | translate"
          formControlName="height" (blur)="basicInformationForm.get('packingDimensions.height').markAsDirty()">
        <mat-error *ngIf="basicInformationForm.get('packingDimensions.height').hasError('pattern')">
          {{ "errors.error_format_invalid" | translate }}
        </mat-error>
        <mat-error *ngIf="basicInformationForm.get('packingDimensions.height').hasError('required')">
          {{ "errors.error_required" | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field floatLabel="never" class="product-basic-information__group-item">
        <input matInput type="text"
          [placeholder]="'secure.products.unit_product_creation.basic_information.form.long_placeholder' | translate"
          formControlName="long" (blur)="basicInformationForm.get('packingDimensions.long').markAsDirty()">
        <mat-error *ngIf="basicInformationForm.get('packingDimensions.long').hasError('required')">
          {{ "errors.error_required" | translate }}
        </mat-error>
        <mat-error *ngIf="basicInformationForm.get('packingDimensions.long').hasError('pattern')">
          {{ "errors.error_format_invalid" | translate }}
        </mat-error>

      </mat-form-field>
      <mat-form-field floatLabel="never" class="product-basic-information__group-item">
        <input matInput type="text"
          [placeholder]="'secure.products.unit_product_creation.basic_information.form.width_placeholder' | translate"
          formControlName="width" (blur)="basicInformationForm.get('packingDimensions.width').markAsDirty()">
        <mat-error *ngIf="basicInformationForm.get('packingDimensions.width').hasError('required')">
          {{ "errors.error_required" | translate }}
        </mat-error>
        <mat-error *ngIf="basicInformationForm.get('packingDimensions.width').hasError('pattern')">
          {{ "errors.error_format_invalid" | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field floatLabel="never" class="product-basic-information__group-item">
        <input matInput type="text"
          [placeholder]="'secure.products.unit_product_creation.basic_information.form.weight_placeholder' | translate"
          formControlName="weight" (blur)="basicInformationForm.get('packingDimensions.weight').markAsDirty()">
        <mat-error *ngIf="basicInformationForm.get('packingDimensions.weight').hasError('required')">
          {{ "errors.error_required" | translate }}
        </mat-error>
        <mat-error *ngIf="basicInformationForm.get('packingDimensions.weight').hasError('pattern')">
          {{ "errors.error_format_invalid" | translate }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="unit-product-creation__form-field">
    <label class="unit-product-creation__form-label">
      {{ "secure.products.unit_product_creation.basic_information.form.product_dimensions_label" | translate }}*
    </label>
    <div class="product-basic-information__group" formGroupName="productDimensions">
      <mat-form-field floatLabel="never" class="product-basic-information__group-item">
        <input matInput type="text"
          [placeholder]="'secure.products.unit_product_creation.basic_information.form.height_placeholder' | translate"
          formControlName="height" (blur)="basicInformationForm.get('productDimensions.height').markAsDirty()">
        <mat-error *ngIf="basicInformationForm.get('productDimensions.height').hasError('required')">
          {{ "errors.error_required" | translate }}
        </mat-error>
        <mat-error *ngIf="basicInformationForm.get('productDimensions.height').hasError('pattern')">
          {{ "errors.error_format_invalid" | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field floatLabel="never" class="product-basic-information__group-item">
        <input matInput type="text"
          [placeholder]="'secure.products.unit_product_creation.basic_information.form.long_placeholder' | translate"
          formControlName="long" (blur)="basicInformationForm.get('productDimensions.long').markAsDirty()">
        <mat-error *ngIf="basicInformationForm.get('productDimensions.long').hasError('required')">
          {{ "errors.error_required" | translate }}
        </mat-error>
        <mat-error *ngIf="basicInformationForm.get('productDimensions.long').hasError('pattern')">
          {{ "errors.error_format_invalid" | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field floatLabel="never" class="product-basic-information__group-item">
        <input matInput type="text"
          [placeholder]="'secure.products.unit_product_creation.basic_information.form.width_placeholder' | translate"
          formControlName="width" (blur)="basicInformationForm.get('productDimensions.width').markAsDirty()">
        <mat-error *ngIf="basicInformationForm.get('productDimensions.width').hasError('required')">
          {{ "errors.error_required" | translate }}
        </mat-error>
        <mat-error *ngIf="basicInformationForm.get('productDimensions.width').hasError('pattern')">
          {{ "errors.error_format_invalid" | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field floatLabel="never" class="product-basic-information__group-item">
        <input matInput type="text"
          [placeholder]="'secure.products.unit_product_creation.basic_information.form.weight_placeholder' | translate"
          formControlName="weight" (blur)="basicInformationForm.get('productDimensions.weight').markAsDirty()">
        <mat-error *ngIf="basicInformationForm.get('productDimensions.weight').hasError('required')">
          {{ "errors.error_required" | translate }}
        </mat-error>
        <mat-error *ngIf="basicInformationForm.get('productDimensions.weight').hasError('pattern')">
          {{ "errors.error_format_invalid" | translate }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="unit-product-creation__form-field">
    <label class="unit-product-creation__form-label unit-product-creation__form-label--hidden">
      Unidad de medida*
    </label>
    <mat-form-field floatLabel="never">
      <mat-label>
        {{ "secure.products.unit_product_creation.basic_information.form.measurement_unit_label" | translate }}
      </mat-label>
      <mat-select formControlName="measurementUnit" (blur)="basicInformationForm.get('measurementUnit').markAsDirty()">
        <mat-option *ngFor="let measurementUnit of measurementUnits" [value]="measurementUnit.value">
          {{ measurementUnit.label }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="basicInformationForm.get('measurementUnit').hasError('required')">
        {{ "errors.error_required" | translate }}
      </mat-error>
    </mat-form-field>
  </div>

  <div class="unit-product-creation__form-field">
    <label class="unit-product-creation__form-label">
      {{ "secure.products.unit_product_creation.basic_information.form.conversion_factor_label" | translate }}
    </label>
    <mat-form-field floatLabel="never">
      <input matInput type="text"
        [placeholder]="'secure.products.unit_product_creation.basic_information.form.conversion_factor_placeholder' | translate"
        formControlName="conversionFactor" (blur)="basicInformationForm.get('conversionFactor').markAsDirty()">
      <mat-error *ngIf="basicInformationForm.get('conversionFactor').hasError('required')">
        {{ "errors.error_required" | translate }}
      </mat-error>
      <mat-error *ngIf="basicInformationForm.get('conversionFactor').hasError('pattern')">
        {{ "errors.error_format_invalid" | translate }}
      </mat-error>
    </mat-form-field>
  </div>

  <div class="unit-product-creation__form-field" *ngIf="category.ProductType === 'Clothing'">
    <label class="unit-product-creation__form-label">
      {{ "secure.products.unit_product_creation.basic_information.form.reference_label" | translate }}
    </label>
    <mat-form-field floatLabel="never">
      <input matInput type="text"
        [placeholder]="'secure.products.unit_product_creation.basic_information.form.reference_placeholder' | translate"
        formControlName="parentReference" (blur)="basicInformationForm.get('parentReference').markAsDirty()">
      <mat-error *ngIf="basicInformationForm.get('parentReference').hasError('required')">
        {{ "errors.error_required" | translate }}
      </mat-error>
      <mat-error *ngIf="basicInformationForm.get('parentReference').hasError('pattern')">
        {{ "errors.error_format_invalid" | translate }}
      </mat-error>
    </mat-form-field>
  </div>

  <div class="unit-product-creation__form-field">
    <label class="unit-product-creation__form-label">
      {{ "secure.products.unit_product_creation.basic_information.form.keywords_label" | translate }}
    </label>
    <mat-form-field floatLabel="never">
      <input type="tel" matInput placeholder="..." #keywordInput formControlName="keywordsValue"
        (keydown.enter)="saveKeyword(keywordInput.value)">
      <mat-icon matSuffix class="material-icons add-keyword-icon" (click)="saveKeyword(keywordInput.value)">
        add_circle_outline
      </mat-icon>
      <mat-error
        *ngIf="basicInformationForm.get('keywordsValue').errors && basicInformationForm.get('keywordsValue').dirty">
        {{ "errors.error_required" | translate }}
      </mat-error>
    </mat-form-field>
  </div>
  <div>
    <mat-chip-list>
      <mat-chip *ngFor="let keyword of basicInformationForm.get('keywords').value; let i=index"
        (removed)="deleteKeyword(i)">
        {{ keyword }}
        <mat-icon class="material-icons" matChipRemove>cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
  </div>
  <angular-editor formControlName="description" id="description" [config]="editorConfig" class="angular-editor"
    [ngClass]="{'angular-editor__invalid':basicInformationForm.get('description').hasError('required') && basicInformationForm.get('description').dirty}"></angular-editor>
  <p class="custom-form__error"
    *ngIf="basicInformationForm.get('description').errors && basicInformationForm.get('description').dirty">
    {{ "errors.error_required" | translate }}
  </p>
  <div class="unit-product-creation__form-field">
    <mat-checkbox class="unit-product-creation__checkbox" color="primary" formControlName="isCombo" [hidden]="true">
      {{ "secure.products.unit_product_creation.basic_information.form.product_combo_label" | translate }}
    </mat-checkbox>
    <div *ngIf="!!basicInformationForm.get('isCombo').value" [hidden]="true">
      <mat-form-field floatLabel="never" class="my-2">
        <input matInput [placeholder]="'secure.products.create_product_unit.basic_information.kit_ean'
            | translate" class="input-packing-width" formControlName="eanCombo" (keyup.enter)="addEanCombo()">
        <mat-icon matSuffix class="material-icons" (click)="addEanCombo()" class="add-keyword-icon">
          add_circle_outline
        </mat-icon>
        <mat-error *ngIf="basicInformationForm.get('eanCombo').hasError('pattern')">
          {{'secure.products.create_product_unit.basic_information.invalid_ean'
          | translate}}</mat-error>
        <mat-error *ngIf="basicInformationForm.get('eanCombo').hasError('existBD')">
          {{'secure.products.create_product_unit.basic_information.not_exist_ean'
          | translate}}</mat-error>
        <mat-error *ngIf="basicInformationForm.get('eanCombo').hasError('exist')">
          {{'secure.products.create_product_unit.basic_information.alredy_added_ean'
          | translate}}</mat-error>
      </mat-form-field>
    </div>
  </div>


</form>