<!-- TRANSPORT TEXT DIALOG -->
<ng-template #addTransportBlock>{{'secure.offers.quoting.administrator.dialogs.create.modal_title_new_shipping' | translate}}</ng-template>
<ng-template #updateTransportBlock>{{'secure.offers.quoting.administrator.dialogs.create.modal_title_update_shipping' | translate}}</ng-template>
<ng-template #addTransportParagraphBlock>{{'secure.offers.quoting.administrator.dialogs.create.modal_text_new_shipping' | translate}}</ng-template>
<ng-template #updateTransportParagraphBlock>{{'secure.offers.quoting.administrator.dialogs.create.modal_text_update_shipping' | translate}}</ng-template>

<!-- ZONE TEXT DIALOG -->
<ng-template #addZoneBlock>{{'secure.offers.quoting.administrator.dialogs.create.modal_title_new_zone' | translate}}</ng-template>
<ng-template #updateZoneBlock>{{'secure.offers.quoting.administrator.dialogs.create.modal_title_update_zone' | translate}}</ng-template>
<ng-template #addZoneParagraphBlock>{{'secure.offers.quoting.administrator.dialogs.create.modal_text_new_zone' | translate}}</ng-template>
<ng-template #updateZoneParagraphBlock>{{'secure.offers.quoting.administrator.dialogs.create.modal_text_update_zone' | translate}}</ng-template>

<!-- END TEXT -->

<section class="dialog-component flexbox flex-wrap align-center justify-center">
  <article class="transport-dialog">
    <div fxFlex="100" fxLayoutAlign="center center" *ngIf="!chargeData">
        <mat-spinner></mat-spinner>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center" fxFlex="100" *ngIf="chargeData">
      <div class="container-transport-icon" fxLayout="column" fxLayoutAlign="center center">
        <i class="material-icons" *ngIf="showDialogForm(dialogTransport)">
          local_shipping
        </i>
        <i class="material-icons" *ngIf="showDialogForm(dialogZone)">
          map
        </i>
      </div>

      <h3 *ngIf="showDialogForm(dialogTransport)">
        <div *ngIf="dialogMode; then updateTransportBlock; else addTransportBlock"></div>
      </h3>
      <h3 *ngIf="showDialogForm(dialogZone)">
        <div *ngIf="dialogMode; then updateZoneBlock; else addZoneBlock"></div>
      </h3>

      <!-- Description form -->
      <div fxLayout="row" fxLayoutAlign="center center">
        <p fxFlex="70" class="text-dialog-transporter" *ngIf="showDialogForm(dialogTransport)">
          <span *ngIf="dialogMode; then updateTransportParagraphBlock; else addTransportParagraphBlock"></span>
        </p>
        <p fxFlex="70" class="text-dialog-transporter" *ngIf="showDialogForm(dialogZone)">
          <span *ngIf="dialogMode; then updateZoneParagraphBlock; else addZoneParagraphBlock"></span>
        </p>
      </div>


      <!-- TRANSPORT DIALOG -->
      <div class="form-transporter-container" *ngIf="showDialogForm(dialogTransport)">
        <!-- FORM: to create transport -->
        <form [formGroup]="formTransporter" (ngSubmit)="saveTransport()" fxLayout="column">
         
          <!-- Fields container -->
          <div fxFlex="100" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" fxLayoutAlign.xs="center center">
            <!-- Name input Field -->
            <mat-form-field [ngClass]="{'name-transport': dialogMode, 'name-transport-update': !dialogMode}">
              <mat-icon matPrefix>local_shipping</mat-icon>
              <input matInput formControlName="Name" [placeholder]="'secure.offers.quoting.administrator.dialogs.create.modal_placeholder_transporter_name' | translate" required>
              <mat-error>{{'errors.required' | translate}}</mat-error>
            </mat-form-field>

            <!-- Method transport select Field -->
            <mat-form-field fxFlex="48" class="select-transport" *ngIf="!dialogMode">
              <mat-select [placeholder]="'secure.offers.quoting.administrator.dialogs.create.modal_placeholder_shipping_method' | translate" formControlName="IdShippingMethod">
                <mat-option *ngFor="let type of transportTypeList" [value]="type.Id">
                  {{type.Name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <!-- Buttons SAVE and CANCEL -->
          <div class="buton-create flexbox" fxLayout="row" fxLayout.fx="column" fxLayoutAlign="center center">
            <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between center">
              <div fxFlex="48" fxLayout="row" fxLayoutAlign="end center">
                <button type="button" id="btn-create-transporter" mat-stroked-button color="primary" (click)="closeDialog()">{{'actions.cancel' | translate}}</button>
              </div>
              <div fxFlex="48" fxLayout="row" fxLayoutAlign="start center">
                <button id="btn-create-transporter" type="submit" mat-raised-button color="primary" [disabled]="formTransporter.invalid">{{'actions.accept' | translate}}</button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <!-- END TRANSPORT DIALOG -->
      

      <!-- ZONE DIALOG -->
      <div class="form-transporter-container" *ngIf="showDialogForm(dialogZone)">
        <!-- FORM: to create transport -->
        <form [formGroup]="formZone" (ngSubmit)="saveZone()" fxLayout="column">

          <!-- Fields container -->
          <div fxFlex="100" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" fxLayoutAlign.xs="center center">
            <!-- Name input Field -->
            <mat-form-field fxFill="48" fxFlex="48">
              <mat-icon matPrefix>map</mat-icon>
              <input matInput formControlName="Name" [placeholder]="'secure.offers.quoting.administrator.dialogs.create.modal_placeholder_name_zone' | translate" required>
              <mat-error>{{'errors.required' | translate}}</mat-error>
            </mat-form-field>

            <!-- DANE code input Field -->
            <mat-form-field fxFill="48" fxFlex="48">
              <input matInput formControlName="DaneCode" [placeholder]="'secure.offers.quoting.administrator.dialogs.create.modal_placeholder_postal_code' | translate" required>
              <mat-error *ngIf="formZone.controls.DaneCode.errors && formZone.controls.DaneCode.errors.pattern">{{'errors.quoting_postal_code_invalid' | translate}}</mat-error>
              <mat-error *ngIf="formZone.controls.DaneCode.errors && formZone.controls.DaneCode.errors.required">{{'errors.required' | translate}}</mat-error>
            </mat-form-field>
          </div>

          <!-- Buttons SAVE and CANCEL -->
          <div class="buttons-container flexbox" fxLayout="row" fxLayout.fx="column" fxLayoutAlign="center center">
            <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between center">
              <div fxFlex="48" fxLayout="row" fxLayoutAlign="end center">
                <button type="button" id="btn-create-transporter" mat-stroked-button color="primary" (click)="closeDialog()">{{'actions.cancel' | translate}}</button>
              </div>
              <div fxFlex="48" fxLayout="row" fxLayoutAlign="start center">
                <button id="btn-create-transporter" type="submit" mat-raised-button color="primary" [disabled]="formZone.invalid">{{'actions.accept' | translate}}</button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <!-- END ZONE DIALOG -->
      
    </div>
  </article>
</section>