<!-- Web Desing -->
<mat-toolbar class="tree-toolbar" *ngIf="activeToolbarSearch">
    <div class="normal-theme toolbar-options-table flexbox justify-center align-center">
        <!-- Componente para realizar la busqueda de una tienda -->
        <div class="search-container">
            <app-search-seller id="contet-searc-store" class="search-content"
                *ngIf="getFunctionality(visualize)"></app-search-seller>
        </div>
    </div>
</mat-toolbar>

<div class="list-container">
    <div *ngIf="!sellerData && !agreementsSeller.length" class="empty-state">
        <div class="icon-face">
            <mat-icon>face</mat-icon>
        </div>
        <p class="icon-face-text">{{'secure.seller.manage.txt_serch_seller' | translate}}</p>
    </div>

    <div *ngIf="agreementsSeller && !thereAgreements">
        <div class="icon-face">
            <mat-icon>insert_drive_file</mat-icon>
        </div>
        <p class="icon-face-text">{{'secure.seller.contracts.txt_no_contract_seller' | translate}}</p>
    </div>

    <div *ngIf="agreementsSeller.length" class="agreement">
        <div class="agreement-title">
            <p>{{'shared.name' | translate}}</p>
        </div>
        <div *ngFor="let agreement of agreementsSeller" fxLayout="row" fxLayoutAlign="space-between center"
            class="list-agreement">
            <div fxLayoutAlign="start center" fxLayout="row" class="width-80">
                <mat-icon class="material-icons" class="file-agreement">
                    insert_drive_file
                </mat-icon>
                <p>{{agreement.ContractName}}</p>
            </div>
            <button mat-icon-button (click)="getPDF(agreement)" *ngIf="download">
                <mat-icon class="material-icons">
                    get_app
                </mat-icon>
            </button>
        </div>
    </div>
</div>