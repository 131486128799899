import { Types, ActionsUnion } from './actions';
import { deleteSonById } from './helpers';
import { UnitProductCreationState } from './state';

export const initialState: UnitProductCreationState = {
  images: []
};

export function unitProductCreationReducer(
  state: UnitProductCreationState = initialState,
  action: ActionsUnion
) {
  switch (action.type) {
    case Types.SetVariantImage:
      return {
        ...state,
        images: [...state.images, action.payload]
      };
    case Types.DeleteVariantImagesFromSon:
      return {
        ...state,
        images: deleteSonById(action.payload, state)
      };
    case Types.ResetVariantImages:
      return { ...state, images: [] };
    default:
      return state;
  }
}
