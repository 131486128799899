<div class="main">
  <mat-list>
    <div class="head">
      <p>
        <strong>{{'secure.billing.invoice' | translate}}: </strong>
        <span class="billingNumber">{{ data.billingNumber }}</span>
      </p>
      <p>
        <strong>{{'secure.billing.payment_date' | translate}}: </strong>
        <span class="paymentDate">{{ data.paymentDate | date}}</span>
      </p>
    </div>
    <mat-divider></mat-divider>
    <mat-list-item>
      <mat-icon mat-list-icon>local_shipping</mat-icon>
      <p mat-line>
        <strong>{{'secure.billing.movement_charge' | translate}}: </strong>
        <span class="totalMovement">{{ data.fulfillmentDetail.totalMovement | currency: 'COP' }}</span>
      </p>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item>
      <mat-icon mat-list-icon>store</mat-icon>
      <p mat-line>
        <strong>{{'secure.billing.storage_charge' | translate}}: </strong>
        <span class="totalStorage">{{ data.fulfillmentDetail.totalStorage | currency: 'COP' }}</span>
      </p>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item>
      <mat-icon mat-list-icon>local_atm</mat-icon>
      <p mat-line>
        <strong>{{'secure.billing.total_charged' | translate}}: </strong>
        <span class="totalCharged">
          {{ data.fulfillmentDetail.totalMovement + data.fulfillmentDetail.totalStorage | currency: 'COP' }}
        </span>
      </p>
    </mat-list-item>
  </mat-list>
</div>