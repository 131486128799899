import { UnitProductCreationState } from "./state";

export const deleteSonById = (index: number, state: UnitProductCreationState) => {
    return [
        ...state.images.filter(image => image.sonIndex !== index)
    ].map(image => {
        if (image.sonIndex > index) {
            return {
                ...image,
                sonIndex: image.sonIndex - 1
            }
        }
        return image;
    });
}