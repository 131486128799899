<form #f="ngForm" [formGroup]="myform" class="search-form light-content">
  <mat-form-field id="sinceDate-input-search-order" color="accent">
    <input id="sinceDate-input" [max]="myform.value.dateOrderFinal" formControlName="dateOrderInitial"
      [placeholder]="'secure.orders.in_devolution.in_devolution_page.date_order' | translate" matInput
      [matDatepicker]="dateOrderInitial">
    <mat-datepicker-toggle id="sinceDate-Perfix" matPrefix [for]="dateOrderInitial"></mat-datepicker-toggle>
    <mat-datepicker touchUi="true" startView="year" #dateOrderInitial></mat-datepicker>
  </mat-form-field>

  <mat-form-field id="untilDate-input-search-order" color="accent">
    <input id="untilDate-input" [min]="myform.value.dateOrderInitial" formControlName="dateOrderFinal"
      [placeholder]="'secure.orders.in_devolution.in_devolution_page.date_request' | translate" matInput
      [matDatepicker]="dateOrderFinal">
    <mat-datepicker-toggle id="untilDate-Prefix" matPrefix [for]="dateOrderFinal">
    </mat-datepicker-toggle>
    <mat-datepicker touchUi="true" startView="year" #dateOrderFinal></mat-datepicker>
  </mat-form-field>

  <mat-form-field id="chanelOrder-input-search-order" class="example-full-width" color="accent">
    <mat-icon matPrefix color="accent">device_hub</mat-icon>
    <mat-select id="select-chanelOrder" formControlName="idChannel"
      [placeholder]="'core.shell.search_order_menu.search_envios_exito_form.filter_channel' | translate">
      <mat-option>{{'core.shell.search_order_menu.search_envios_exito_form.none' | translate}}</mat-option>
      <mat-option value="11">{{'core.shell.search_order_menu.search_envios_exito_form.exito' | translate}}</mat-option>
      <mat-option value="13">{{'core.shell.search_order_menu.search_envios_exito_form.carulla' | translate}}</mat-option>
      <mat-option value="16">{{'core.shell.search_order_menu.search_envios_exito_form.digital_catalog' | translate}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="example-full-width" color="accent">
    <mat-icon matPrefix color="accent">format_list_bulleted</mat-icon>
    <input id="orderNumber-input-search-order" formControlName="orderNumber" type="text" pattern="[0-9.]+" matInput
      [placeholder]="'core.shell.search_order_menu.search_envios_exito_form.filter_by_number' | translate">
  </mat-form-field>

  <mat-form-field class="example-full-width" color="accent">
    <mat-icon matPrefix color="accent">person_pin</mat-icon>
    <input id="identificationCard-input-search-order" formControlName="identificationCard" type="text" pattern="[0-9.]+"
      matInput [placeholder]="'core.shell.search_order_menu.search_envios_exito_form.filter_by_id' | translate">
  </mat-form-field>


  <div class="orders-search-option normal-theme">
    <button id="clear-filter-button" class="sc-button" mat-button (click)="clearForm()">
      <mat-icon>clear_all</mat-icon>
      {{'filters.clear' | translate}}
    </button>
    <button id="apply-filter-button" [disabled]="!f.form.valid" class="sc-button" mat-raised-button color="primary"
      type="submit" (click)="filterOrder(myform)">{{'actions.apply' | translate}}
    </button>
  </div>
</form>
