import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ColorService {
  private colorMap: { [key: string]: string } = {
    '722F37': 'Vinotinto',
    'FF0000': 'Rojo',
    '804000': 'Café',
    '763C28': 'Cobre',
    '724625': 'Camel',
    'FF8000': 'Naranja',
    'FF7F50': 'Coral',
    'EFB810': 'Dorado',
    'FFDB58': 'Mostaza',
    'FFFF00': 'Amarillo',
    '008F39': 'Verde',
    '009C8C': 'Verde azul',
    '0000FF': 'Azul',
    '572364': 'Morado',
    'B264D9': 'Lila',
    'FF0080': 'Rosa',
    'E30052': 'Fucsia',
    'E6D690': 'Marfil',
    'C89C7D': 'Terracota',
    '000000': 'Negro',
    '9B9B9B': 'Gris',
    'E3E4E5': 'Plateado',
    'FFFFFF': 'Blanco',
    'F5F5DC': 'Beige',
    'F2D3BC': 'Nude',
    'FF90C8': 'MultiColor'
};


  getColorName(hex: string): string {
    return this.colorMap[hex.toUpperCase()] || 'Unknown Color';
  }
}