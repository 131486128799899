import {Component, ElementRef, Input, ViewChild, Output, EventEmitter} from '@angular/core';

const ALLOWED_FILE_TYPES = [
  'image/jpeg',
];


@Component({
  selector: 'app-upload-product-image-file',
  templateUrl: './upload-product-image-file.component.html',
  styleUrls: ['./upload-product-image-file.component.scss']
})
export class UploadProductImageFileComponent {
  @ViewChild('fileInput', {static: false}) fileInput!: ElementRef;
  @Input() imageData: { id: string; url: string };
  @Output() uploadImage: EventEmitter<{ id: string; image: string }>;
  allowedFileTypes = ALLOWED_FILE_TYPES;

  constructor() {
    this.uploadImage = new EventEmitter<{ id: string; image: string }>();
  }

  onUploadFile(files: File[]) {
    const file = files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const image = reader.result as string;
      const base64data = image.replace(new RegExp('.*' + 'base64,'), '');
      this.uploadImage.emit({
        id: this.imageData.id,
        image: base64data
      });
    };
  }

}
