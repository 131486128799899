<div class="content-table">
  <div fxFlex="100%" class="toolbar-paginate">
    <mat-paginator class="paginator-style orders-paginate" itemsPerPageLabel="Items por pagina" #paginator [pageSize]="5"
      [length]="100" [pageSizeOptions]="[5, 10, 20]"></mat-paginator>
    <button fxShow.xs="false" fxShow.sm="false" fxShow.md="true" fxShow.lg="true" fxShow.xl="true" style="margin-left:20px"
      (click)="bulkLoad.resetVariableUploadFile()" id="clear-guide-button" color="primary" mat-raised-button>
      <span>{{'secure.products.table_load_product.upload_all' | translate}}</span>
    </button>
  </div>

  <mat-expansion-panel class="card-err-process" [expanded]="countErrors > 0">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="title-expansion-panel-table">
          <mat-icon>error</mat-icon>
          <span [innerHTML]="countRowUpload - 1"></span>
          <b>{{'secure.products.table_load_product.records_found' | translate}}</b>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="container-table-errors">

      <table mat-table [dataSource]="bulkLoad.dataSource">

        <!-- EAN Column -->
        <ng-container matColumnDef="EAN" sticky>
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>EAN</p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorEan == true }" *matCellDef="let element" matTooltip="{{element.Ean}}">
            <div class="information" *ngIf="element.Ean != undefined && element.Ean != ''">
              <p class="sc-text-normal">{{element.Ean}}</p>
            </div>
            <div class="information" *ngIf="element.Ean == undefined || element.Ean == ''">
              <p class="text-error">{{'secure.products.table_load_product.mandatory_data' | translate}}
              </p>
            </div>
          </td>
        </ng-container>

        <!-- ParentReference Column -->
        <ng-container matColumnDef="ParentReference">
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>{{'secure.products.table_load_product.parent_reference' | translate}}</p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorParentReference == true }" *matCellDef="let element"
            matTooltip="{{element.ParentReference}}">
            <div class="information" *ngIf="element.ParentReference != undefined && element.ParentReference != '' && element.ParentReference !== null">
              <p class="sc-text-normal">{{element.ParentReference}}</p>
            </div>
            <div class="information" *ngIf="element.isVariant && (element.ParentReference == undefined || element.ParentReference == '' || element.ParentReference == null)">
              <p class="text-error"> {{'secure.products.table_load_product.mandatory_data' | translate}}
              </p>
            </div>
            <div class="information" *ngIf="!element.isVariant && (element.ParentReference == undefined || element.ParentReference == '' || element.ParentReference == null)">
              <p class="text-error"> -
              </p>
            </div>
          </td>
        </ng-container>

        <!-- SonReference Column -->
        <ng-container matColumnDef="SonReference">
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>{{'secure.products.table_load_product.child_reference' | translate}}</p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorSonReference == true }" *matCellDef="let element"
            matTooltip="{{element.SonReference}}">
            <div class="information" *ngIf="element.SonReference != undefined && element.SonReference != '' && element.SonReference !== null">
              <p class="sc-text-normal">{{element.SonReference}}</p>
            </div>
            <div class="information" *ngIf="element.isVariant && (element.SonReference == undefined || element.SonReference == '' || element.SonReference == null)">
              <p class="text-error"> {{'secure.products.table_load_product.mandatory_data' | translate}}
              </p>
            </div>
            <div class="information" *ngIf="!element.isVariant && (element.SonReference == undefined || element.SonReference == '' || element.SonReference == null)">
              <p class="text-error"> -
              </p>
            </div>
          </td>
        </ng-container>

        <!-- Name Colum Column -->
        <ng-container matColumnDef="Name">
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>{{'shared.name' | translate}}</p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorName == true }" *matCellDef="let element" matTooltip="{{element.Name}}">
            <div class="information" *ngIf="element.Name != undefined && element.Name != ''">
              <p class="sc-text-normal">{{element.Name}}</p>
            </div>
            <div class="information" *ngIf="element.Name == undefined || element.Name == ''">
              <p class="text-error"> -
              </p>
            </div>
          </td>
        </ng-container>

        <!-- Category Column -->
        <ng-container matColumnDef="Category">
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>{{'shared.category' | translate}}</p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorCategory == true }" *matCellDef="let element"
            matTooltip="{{element.Category}}">
            <div class="information" *ngIf="element.Category != undefined && element.Category != ''">
              <p class="sc-text-normal">{{element.Category}}</p>
            </div>
            <div class="information" *ngIf="element.Category == undefined || element.Category == ''">
              <p class="text-error"> {{'secure.products.table_load_product.mandatory_data' | translate}}
              </p>
            </div>
          </td>
        </ng-container>

        <!-- Brand Column -->
        <ng-container matColumnDef="Brand">
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>{{'shared.brand' | translate}}</p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorBrand == true }" *matCellDef="let element" matTooltip="{{element.Brand}}">
            <div class="information" *ngIf="element.Brand != undefined && element.Brand != ''">
              <p class="sc-text-normal">{{element.Brand}}</p>
            </div>
            <div class="information" *ngIf="element.Brand == undefined || element.Brand == ''">
              <p class="text-error"> -
              </p>
            </div>
          </td>
        </ng-container>

        <!-- Model Column -->
        <ng-container matColumnDef="Model">
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>{{'shared.model' | translate}}</p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorModel == true }" *matCellDef="let element" matTooltip="{{element.Model}}">
            <div class="information" *ngIf="element.Model != undefined && element.Model != ''">
              <p class="sc-text-normal">{{element.Model}}</p>
            </div>
            <div class="information" *ngIf="element.Model == undefined || element.Model == ''">
              <p class="text-error"> -
              </p>
            </div>
          </td>
        </ng-container>

        <!-- Details Column -->
        <ng-container matColumnDef="Details">
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>{{'shared.details' | translate}}</p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorDetails == true }" *matCellDef="let element"
            matTooltip="{{element.Details}}">
            <div class="information" *ngIf="element.Details != undefined && element.Details != ''">
              <p class="sc-text-normal">{{element.Details}}</p>
            </div>
            <div class="information" *ngIf="element.Details == undefined || element.Details == ''">
              <p class="text-error"> -
              </p>
            </div>
          </td>
        </ng-container>

        <!-- Description Column -->
        <ng-container matColumnDef="Description">
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>{{'shared.descripcion' | translate}}</p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorDescription == true }" *matCellDef="let element"
            matTooltip="{{element.Description}}">
            <div class="information" *ngIf="element.Description != undefined && element.Description != ''">
              <p class="sc-text-normal">{{element.Description}}</p>
            </div>
            <div class="information" *ngIf="element.Description == undefined || element.Description == ''">
              <p class="text-error"> -
              </p>
            </div>
          </td>
        </ng-container>

        <!-- MetaTitle Column -->
        <ng-container matColumnDef="MetaTitle">
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>{{'secure.products.table_load_product.meta_title' | translate}}</p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorMetaTitle == true }" *matCellDef="let element"
            matTooltip="{{element.MetaTitle}}">
            <div class="information" *ngIf="element.MetaTitle != undefined && element.MetaTitle != ''">
              <p class="sc-text-normal">{{element.MetaTitle}}</p>
            </div>
            <div class="information" *ngIf="element.MetaTitle == undefined || element.MetaTitle == ''">
              <p class="text-error"> -
              </p>
            </div>
          </td>
        </ng-container>

        <!-- MetaDescription Column -->
        <ng-container matColumnDef="MetaDescription">
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>{{'secure.products.table_load_product.meta_description' | translate}}</p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorMetaDescription == true }" *matCellDef="let element"
            matTooltip="{{element.MetaDescription}}">
            <div class="information" *ngIf="element.MetaDescription != undefined && element.MetaDescription != ''">
              <p class="sc-text-normal">{{element.MetaDescription}}</p>
            </div>
            <div class="information" *ngIf="element.MetaDescription == undefined || element.MetaDescription == ''">
              <p class="text-error"> -
              </p>
            </div>
          </td>
        </ng-container>

        <!-- KeyWords Column -->
        <ng-container matColumnDef="KeyWords">
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>{{'secure.products.table_load_product.keywords' | translate}}</p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorKeyWords == true }" *matCellDef="let element"
            matTooltip="{{element.KeyWords}}">
            <div class="information" *ngIf="element.KeyWords != undefined && element.KeyWords != ''">
              <p class="sc-text-normal">{{element.KeyWords}}</p>
            </div>
            <div class="information" *ngIf="element.KeyWords == undefined || element.KeyWords == ''">
              <p class="text-error"> -
              </p>
            </div>
          </td>
        </ng-container>

        <!-- PackageHeight Column -->
        <ng-container matColumnDef="PackageHeight">
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>{{'secure.products.table_load_product.package_height' | translate}}</p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorPackageHeight == true }" *matCellDef="let element"
            matTooltip="{{element.PackageHeight}}">
            <div class="information" *ngIf="element.PackageHeight != undefined && element.PackageHeight != ''">
              <p class="sc-text-normal">{{element.PackageHeight}}</p>
            </div>
            <div class="information" *ngIf="element.PackageHeight == undefined || element.PackageHeight == ''">
              <p class="text-error"> -
              </p>
            </div>
          </td>
        </ng-container>

        <!-- PackageLength Column -->
        <ng-container matColumnDef="PackageLength">
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>{{'secure.products.table_load_product.package_lenght' | translate}}</p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorPackageLength == true }" *matCellDef="let element"
            matTooltip="{{element.PackageLength}}">
            <div class="information" *ngIf="element.PackageLength != undefined && element.PackageLength != ''">
              <p class="sc-text-normal">{{element.PackageLength}}</p>
            </div>
            <div class="information" *ngIf="element.PackageLength == undefined || element.PackageLength == ''">
              <p class="text-error"> -
              </p>
            </div>
          </td>
        </ng-container>

        <!-- PackageWidth Column -->
        <ng-container matColumnDef="PackageWidth">
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>{{'secure.products.table_load_product.package_width' | translate}}</p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorPackageWidth == true }" *matCellDef="let element"
            matTooltip="{{element.PackageWidth}}">
            <div class="information" *ngIf="element.PackageWidth != undefined && element.PackageWidth != ''">
              <p class="sc-text-normal">{{element.PackageWidth}}</p>
            </div>
            <div class="information" *ngIf="element.PackageWidth == undefined || element.PackageWidth == ''">
              <p class="text-error"> -
              </p>
            </div>
          </td>
        </ng-container>

        <!-- PackageWeight Column -->
        <ng-container matColumnDef="PackageWeight">
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>{{'secure.products.table_load_product.package_weight' | translate}}</p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorPackageWeight == true }" *matCellDef="let element"
            matTooltip="{{element.PackageWeight}}">
            <div class="information" *ngIf="element.PackageWeight != undefined && element.PackageWeight != ''">
              <p class="sc-text-normal">{{element.PackageWeight}}</p>
            </div>
            <div class="information" *ngIf="element.PackageWeight == undefined || element.PackageWeight == ''">
              <p class="text-error"> -
              </p>
            </div>
          </td>
        </ng-container>

        <!-- SkuShippingSize Column -->
        <ng-container matColumnDef="SkuShippingSize">
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>{{'secure.products.table_load_product.sku_shipping_size' | translate}}</p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorSkuShippingSize == true }" *matCellDef="let element"
            matTooltip="{{element.SkuShippingSize}}">
            <div class="information" *ngIf="element.SkuShippingSize != undefined && element.SkuShippingSize != ''">
              <p class="sc-text-normal">{{element.SkuShippingSize}}</p>
            </div>
            <div class="information" *ngIf="element.SkuShippingSize == undefined || element.SkuShippingSize == ''">
              <p class="text-error"> -
              </p>
            </div>
          </td>
        </ng-container>

        <!-- ProductHeight Column -->
        <ng-container matColumnDef="ProductHeight">
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>{{'secure.products.table_load_product.item_height' | translate}}</p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorProductHeight == true }" *matCellDef="let element"
            matTooltip="{{element.ProductHeight}}">
            <div class="information" *ngIf="element.ProductHeight != undefined && element.ProductHeight != ''">
              <p class="sc-text-normal">{{element.ProductHeight}}</p>
            </div>
            <div class="information" *ngIf="element.ProductHeight == undefined || element.ProductHeight == ''">
              <p class="text-error"> -
              </p>
            </div>
          </td>
        </ng-container>

        <!-- ProductLength Column -->
        <ng-container matColumnDef="ProductLength">
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>{{'secure.products.table_load_product.item_lenght' | translate}}</p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorProductLength == true }" *matCellDef="let element"
            matTooltip="{{element.ProductLength}}">
            <div class="information" *ngIf="element.ProductLength != undefined && element.ProductLength != ''">
              <p class="sc-text-normal">{{element.ProductLength}}</p>
            </div>
            <div class="information" *ngIf="element.ProductLength == undefined || element.ProductLength == ''">
              <p class="text-error"> -
              </p>
            </div>
          </td>
        </ng-container>

        <!-- ProductWidth Column -->
        <ng-container matColumnDef="ProductWidth">
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>{{'secure.products.table_load_product.item_width' | translate}}</p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorProductWidth == true }" *matCellDef="let element"
            matTooltip="{{element.ProductWidth}}">
            <div class="information" *ngIf="element.ProductWidth != undefined && element.ProductWidth != ''">
              <p class="sc-text-normal">{{element.ProductWidth}}</p>
            </div>
            <div class="information" *ngIf="element.ProductWidth == undefined || element.ProductWidth == ''">
              <p class="text-error"> -
              </p>
            </div>
          </td>
        </ng-container>

        <!-- ProductWeight Column -->
        <ng-container matColumnDef="ProductWeight">
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>{{'secure.products.table_load_product.item_weight' | translate}}</p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorProductWeight == true }" *matCellDef="let element"
            matTooltip="{{element.ProductWeight}}">
            <div class="information" *ngIf="element.ProductWeight != undefined && element.ProductWeight != ''">
              <p class="sc-text-normal">{{element.ProductWeight}}</p>
            </div>
            <div class="information" *ngIf="element.ProductWeight == undefined || element.ProductWeight == ''">
              <p class="text-error"> -
              </p>
            </div>
          </td>
        </ng-container>

        <!-- Seller Column -->
        <ng-container matColumnDef="Seller">
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>{{'secure.products.table_load_product.seller' | translate}}</p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorSeller == true }" *matCellDef="let element"
            matTooltip="{{element.Seller}}">
            <div class="information" *ngIf="element.Seller != undefined && element.Seller != ''">
              <p class="sc-text-normal">{{element.Seller}}</p>
            </div>
            <div class="information" *ngIf="element.Seller == undefined || element.Seller == ''">
              <p class="text-error"> -
              </p>
            </div>
          </td>
        </ng-container>

        <!-- ProductType Column -->
        <ng-container matColumnDef="ProductType">
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>{{'secure.products.table_load_product.product_type' | translate}}</p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorProductType == true }" *matCellDef="let element"
            matTooltip="{{element.ProductType}}">
            <div class="information" *ngIf="element.ProductType != undefined && element.ProductType != ''">
              <p class="sc-text-normal">{{element.ProductType}}</p>
            </div>
            <div class="information" *ngIf="element.ProductType == undefined || element.ProductType == ''">
              <p class="text-error"> {{'secure.products.table_load_product.mandatory_data' | translate}}
              </p>
            </div>
          </td>
        </ng-container>

        <!-- ModifyImage Column -->
        <ng-container matColumnDef="ModifyImage">
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>{{'secure.products.table_load_product.image_modification' | translate}}</p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorModifyImage == true }" *matCellDef="let element"
            matTooltip="{{element.ModifyImage}}">
            <div class="information" *ngIf="element.ModifyImage != undefined && element.ModifyImage != ''">
              <p class="sc-text-normal">{{element.ModifyImage}}</p>
            </div>
            <div class="information" *ngIf="element.ModifyImage == undefined || element.ModifyImage == ''">
              <p class="text-error"> -
              </p>
            </div>
          </td>
        </ng-container>

        <!-- ImageUrl1 Column -->
        <ng-container matColumnDef="ImageUrl1">
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>{{'secure.products.table_load_product.image' | translate}} 1</p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorImageUrl1 == true }" *matCellDef="let element"
            matTooltip="{{element.ImageUrl1}}">
            <div class="information" *ngIf="element.ImageUrl1 != undefined && element.ImageUrl1 != ''">
              <p class="sc-text-normal">{{element.ImageUrl1}}</p>
            </div>
            <div class="information" *ngIf="element.ImageUrl1 == undefined || element.ImageUrl1 == ''">
              <p class="text-error"> -
              </p>
            </div>
          </td>
        </ng-container>

        <!-- ImageUrl2 Column -->
        <ng-container matColumnDef="ImageUrl2">
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>{{'secure.products.table_load_product.image' | translate}} 2</p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorImageUrl2 == true }" *matCellDef="let element"
            matTooltip="{{element.ImageUrl2}}">
            <div class="information" *ngIf="element.ImageUrl2 != undefined && element.ImageUrl2 != ''">
              <p class="sc-text-normal">{{element.ImageUrl2}}</p>
            </div>
            <div class="information" *ngIf="element.ImageUrl2 == undefined || element.ImageUrl2 == ''">
              <p class="text-error"> -
              </p>
            </div>
          </td>
        </ng-container>

        <!-- ImageUrl3 Column -->
        <ng-container matColumnDef="ImageUrl3">
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>{{'secure.products.table_load_product.image' | translate}} 3</p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorImageUrl3 == true }" *matCellDef="let element"
            matTooltip="{{element.ImageUrl3}}">
            <div class="information" *ngIf="element.ImageUrl3 != undefined && element.ImageUrl3 != ''">
              <p class="sc-text-normal">{{element.ImageUrl3}}</p>
            </div>
            <div class="information" *ngIf="element.ImageUrl3 == undefined || element.ImageUrl3 == ''">
              <p class="text-error"> -
              </p>
            </div>
          </td>
        </ng-container>

        <!-- ImageUrl4 Column -->
        <ng-container matColumnDef="ImageUrl4">
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>{{'secure.products.table_load_product.image' | translate}} 4</p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorImageUrl4 == true }" *matCellDef="let element"
            matTooltip="{{element.ImageUrl4}}">
            <div class="information" *ngIf="element.ImageUrl4 != undefined && element.ImageUrl4 != ''">
              <p class="sc-text-normal">{{element.ImageUrl4}}</p>
            </div>
            <div class="information" *ngIf="element.ImageUrl4 == undefined || element.ImageUrl4 == ''">
              <p class="text-error"> -
              </p>
            </div>
          </td>
        </ng-container>

        <!-- ImageUrl5 Column -->
        <ng-container matColumnDef="ImageUrl5">
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>{{'secure.products.table_load_product.image' | translate}} 5</p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorImageUrl5 == true }" *matCellDef="let element"
            matTooltip="{{element.ImageUrl5}}">
            <div class="information" *ngIf="element.ImageUrl5 != undefined && element.ImageUrl5 != ''">
              <p class="sc-text-normal">{{element.ImageUrl5}}</p>
            </div>
            <div class="information" *ngIf="element.ImageUrl5 == undefined || element.ImageUrl5 == ''">
              <p class="text-error"> -
              </p>
            </div>
          </td>
        </ng-container>
        
        <!-- videoUrl Column -->
        <ng-container matColumnDef="videoUrl">
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>{{'secure.products.table_load_product.video' | translate}}</p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorvideoUrl == true }" *matCellDef="let element"
            matTooltip="{{element.videoUrl}}">
            <div class="information" *ngIf="element.videoUrl != undefined && element.videoUrl != ''">
              <p class="sc-text-normal">{{element.videoUrl}}</p>
            </div>
            <div class="information" *ngIf="element.videoUrl == undefined || element.videoUrl == ''">
              <p class="text-error"> -
              </p>
            </div>
          </td>
        </ng-container>

        <!-- Size Column -->
        <ng-container matColumnDef="Size">
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>{{'secure.products.table_load_product.size' | translate}}</p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorSize == true }" *matCellDef="let element" matTooltip="{{element.Size}}">
            <div class="information" *ngIf="element.Size != undefined && element.Size != '' && element.Size !== null">
              <p class="sc-text-normal">{{element.Size}}</p>
            </div>
            <div class="information" *ngIf="element.Size == undefined || element.Size == '' || element.Size == null">
              <p class="text-error"> -
              </p>
            </div>
          </td>
        </ng-container>

        <!-- Color Column -->
        <ng-container matColumnDef="Color">
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>{{'secure.products.table_load_product.color' | translate}}</p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorColor == true }" *matCellDef="let element" matTooltip="{{element.Color}}">
            <div class="information" *ngIf="element.Color != undefined && element.Color != '' && element.Color !== null">
              <p class="sc-text-normal">{{element.Color}}</p>
            </div>
            <div class="information" *ngIf="element.Color == undefined || element.Color == '' || element.Color == null">
              <p class="text-error"> -
              </p>
            </div>
          </td>
        </ng-container>
        <!-- HexColourName Column -->
        <ng-container matColumnDef="HexColourName">
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>HexColourName</p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorHexColourName == true }" *matCellDef="let element"
            matTooltip="{{element.HexColourName}}">
            <div class="information" *ngIf="element.HexColourName != undefined && element.HexColourName != '' && element.HexColourName !== null">
              <p class="sc-text-normal">{{element.HexColourName}}</p>
            </div>
            <div class="information" *ngIf="element.HexColourName == undefined || element.HexColourName == '' || element.HexColourName == null">
              <p class="text-error"> -
              </p>
            </div>
          </td>
        </ng-container>

        <!-- IsLogisticsExito Column -->
        <ng-container matColumnDef="IsLogisticsExito">
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>{{'secure.products.table_load_product.exito_logistics' | translate}}</p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorIsLogisticsExito == true }" *matCellDef="let element"
            matTooltip="{{element.IsLogisticsExito}}">
            <div class="information" *ngIf="element.IsLogisticsExito != undefined && element.IsLogisticsExito != '' && element.IsLogisticsExito !== null">
              <p class="sc-text-normal">{{element.IsLogisticsExito}}</p>
            </div>
            <div class="information" *ngIf="element.IsLogisticsExito == undefined || element.IsLogisticsExito == '' || element.IsLogisticsExito == null">
              <p class="text-error"> -
              </p>
            </div>
          </td>
        </ng-container>

        <!-- MeasurementUnit Column -->
        <ng-container matColumnDef="MeasurementUnit">
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>{{'secure.products.table_load_product.measuring_unit_description' | translate}}</p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorMeasurementUnit == true }" *matCellDef="let element"
            matTooltip="{{element.MeasurementUnit}}">
            <div class="information" *ngIf="element.MeasurementUnit != undefined && element.MeasurementUnit != '' && element.MeasurementUnit !== null">
              <p class="sc-text-normal">{{element.MeasurementUnit}}</p>
            </div>
            <div class="information" *ngIf="element.MeasurementUnit == undefined || element.MeasurementUnit == '' || element.MeasurementUnit == null">
              <p class="text-error"> -
              </p>
            </div>
          </td>
        </ng-container>

         <!-- ConversionFactor  Column -->
            <ng-container matColumnDef="ConversionFactor">
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>{{'secure.products.table_load_product.conversion_factor' | translate}}</p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorConversionFactor == true }" *matCellDef="let element" matTooltip="{{element.ConversionFactor}}">
            <div class="information" *ngIf="element.ConversionFactor != undefined && element.ConversionFactor != ''">
              <p class="sc-text-normal">{{element.ConversionFactor}}</p>
            </div>
            <div class="information" *ngIf="element.ConversionFactor == undefined || element.ConversionFactor == ''">
              <p class="text-error"> -
              </p>
            </div>
          </td>
        </ng-container>
        

         <!-- DrainedFactor Column -->
         <ng-container matColumnDef="DrainedFactor">
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>{{'secure.products.table_load_product.drained_factor' | translate}}</p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorDrainedFactor == true }" *matCellDef="let element"
            matTooltip="{{element.DrainedFactor}}">
            <div class="information" *ngIf="element.DrainedFactor != undefined && element.DrainedFactor != ''">
              <p class="sc-text-normal">{{element.DrainedFactor}}</p>
            </div>
            <div class="information" *ngIf="element.DrainedFactor == undefined || element.DrainedFactor == ''">
              <p class="text-error"> -
              </p>
            </div>
          </td>
        </ng-container>

        <!-- EanCombo Column -->
        <ng-container matColumnDef="EanCombo">
          <th mat-header-cell *matHeaderCellDef>
            <div class="information th">
              <p>{{'secure.products.table_load_product.combo_ean_group' | translate}}</p>
            </div>
          </th>
          <td mat-cell [ngClass]="{'selected-error': element.errorEanCombo == true }" *matCellDef="let element"
            matTooltip="{{element.EanCombo}}">
            <div class="information" *ngIf="element.EanCombo != undefined && element.EanCombo != ''">
              <p class="sc-text-normal">{{element.EanCombo}}</p>
            </div>
            <div class="information" *ngIf="element.EanCombo == undefined || element.EanCombo == ''">
              <p class="text-error"> -
              </p>
            </div>
          </td>
        </ng-container>


        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

        <mat-row [ngClass]="{'personal-row': row.errorRow == true}" id="order-{{row.Ean}}" *matRowDef="let index=index;let row; columns: displayedColumns;"
          class="personal-row element-row personal-row-tabl ">
        </mat-row>
      </table>
    </div>

  </mat-expansion-panel>

</div>