<section class="profile-status">
    <p class="floating-header__section-title">
        {{ 'profile.current-status' | translate }}: <span class="current-state">{{ currentState | translate }}</span>
    </p>
    <button class="floating-header__profile-button" *ngIf="!scheduledVacation; else scheduledVacationTemplate"
        (click)="sendToOpenVacationDialog()">
        <span class="material-symbols-rounded button__icon">beach_access</span>
        <span class="button__text">{{ 'secure.seller.list.program_vacation' | translate }}</span>
    </button>
    <ng-template #scheduledVacationTemplate>
        <button class="floating-header__profile-button" (click)="sendToOpenVacationDialog()">
            <span class="material-symbols-rounded button__icon peach-background">beach_access</span>
            <span class="button__text">
                {{ ( currentState === 'profile.status.vacations' ?
                'profile.vacation-to' : 'profile.scheduled-vacation') | translate }}: {{ scheduledVacation }}
            </span>
            <span class="material-symbols-rounded float-right-icon">edit</span>
        </button>
    </ng-template>
</section>



<ng-template #dialogTemplate>
    <form [formGroup]="vacationForm" class="full-width space-between
      margin-top__15">
        <mat-form-field class="half-width">
            <input matInput id="inputInitialDate" [matDatepicker]="intialPicker" [min]="tomorrow" [readonly]="true"
                [placeholder]="'secure.seller.list.start_date' | translate" formControlName="StartDateVacation"
                (click)="openPicker(1)">
            <mat-datepicker-toggle matSuffix [for]="intialPicker"></mat-datepicker-toggle>
            <mat-datepicker #intialPicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="half-width">
            <input matInput id="inputEndDate" [matDatepicker]="endPicker" [readonly]="true" [min]="endvacationStart"
                [placeholder]="'secure.seller.list.end_date' | translate" formControlName="EndDateVacation"
                (click)="openPicker(2)">
            <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
            <mat-datepicker #endPicker></mat-datepicker>
        </mat-form-field>
    </form>
</ng-template>