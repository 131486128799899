<section class="custom-checkbox">
  <input
    type="checkbox"
    [id]="id"
    [formControl]="control"
  >
  <label class="custom-checkbox__label" [for]="id">
    <div class="custom-checkbox__frame">
      <div class="custom-checkbox__background">
        <div class="custom-checkbox__checkmark">
          <span class="custom-checkbox__checkmark__short-line"></span>
          <span class="custom-checkbox__checkmark__long-line"></span>
        </div>
      </div>
    </div>
    <ng-content></ng-content>
  </label>
</section>
