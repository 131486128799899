<h2 class="unit-product-creation__title">
  {{ "secure.products.unit_product_creation.basic_information.title" | translate }}
</h2>
<p class="unit-product-creation__description">
  {{ "secure.products.unit_product_creation.basic_information.description" | translate }}
</p>
<div class="product-basic-information">
  <app-product-basic-information-form *ngIf="basicInformationForm"
    [validationRegularExpresions]="validationRegularExpresions" [BrandsRegex]="BrandsRegex"
    [basicInformationForm]="basicInformationForm" [isEditing]="this.isProductEdition()" [category]="category">
  </app-product-basic-information-form>
  <ng-container *ngIf="basicInformationForm && canAddProductVariants()">
    <button class="add-variants__button" (click)="onAddProductVariant()">
      <span>{{ "secure.products.unit_product_creation.basic_information.add_variant_button_label" | translate }}</span>
      <span class="material-symbols-outlined">add</span>
    </button>
    <app-product-variant-register-form
      *ngFor="let variantForm of basicInformationForm.get('variants').controls; let i = index;"
      [variantForm]="variantForm" [index]="i" [clothingSizes]="clothingSizes" [clothingColors]="clothingColors"
      (deleteVariantForm)="onDeleteVariantForm($event)">
    </app-product-variant-register-form>
    <p class="product-basic-information__variants-required"
      *ngIf="basicInformationForm.get('variants').hasError('minArrayLength') && basicInformationForm.get('variants').dirty">
      {{ "secure.products.unit_product_creation.basic_information.form.variants_min_required" | translate }}
    </p>
  </ng-container>
</div>
<div class="unit-product-creation__actions">
  <button matStepperPrevious class="unit-product-creation__step-button unit-product-creation__button-back">
    {{ "secure.products.unit_product_creation.back_button_label" | translate }}
  </button>
  <button (click)="checkForm()" class="unit-product-creation__step-button unit-product-creation__button-continue">
    {{ "secure.products.unit_product_creation.continue_button_label" | translate }}
  </button>
</div>