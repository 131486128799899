<div class="consult-indicators-padding-20">
    <app-toolbar-search-pagination [informationToForm]="informationToForm"
        #toolbarOptions [lengthOrder]="null" [btnFilter]="btnFilter"
        [showContainerDetail]="showContainerDetail"
        [btnDownload]="btnDownload" [Typeprofile]="_Typeprofile"
        [isFullSearch]="isFullSearch"
        [showBtn]="false" [btnFilter]="false"
        [showContainerDetail]="_showContainerDetail"
        [showSearchSeller]="showSearchSeller">
    </app-toolbar-search-pagination>
    <app-quality-indicators [params]="params"></app-quality-indicators>
</div>