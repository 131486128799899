import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CategoryModel } from '../../categorization/list/category.model';
import { NestedTreeControl } from '@angular/cdk/tree';
import { ArrayDataSource } from '@angular/cdk/collections';

@Component({
  selector: 'app-product-category-tree',
  templateUrl: './product-category-tree.component.html',
  styleUrls: ['./product-category-tree.component.scss']
})
export class ProductCategoryTreeComponent implements OnInit, OnChanges {
  @Input() categories: CategoryModel[];
  @Input() navigationCategoriesSelected: CategoryModel[];
  @Output() categorySelected: EventEmitter<CategoryModel>;
  treeControl: NestedTreeControl<CategoryModel>;
  currentCategoriesTree: ArrayDataSource<CategoryModel>;
  previousCategoriesTrees: ArrayDataSource<CategoryModel>[];
  categoriesSelected: CategoryModel[];
  categoryLeafSelected: CategoryModel;
  loadingTree: boolean;

  constructor() {
    this.treeControl = new NestedTreeControl<CategoryModel>(
      (category: CategoryModel) => category.Son
    );
    this.previousCategoriesTrees = [];
    this.categoriesSelected = [];
    this.categorySelected = new EventEmitter();
  }

  ngOnInit(): void {
    if (!this.currentCategoriesTree) {
      this.currentCategoriesTree = new ArrayDataSource(this.categories);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['navigationCategoriesSelected'].currentValue) {
      this.onSearchChange();
    }
  }

  onSearchChange(): void {
    this.loadingTree = true;
    this.categoriesSelected = this.navigationCategoriesSelected;
    this.categoryLeafSelected = this.navigationCategoriesSelected[this.navigationCategoriesSelected.length - 1];
    this.categorySelected.emit(this.categoryLeafSelected);
    this.previousCategoriesTrees = this.categoriesSelected.map(category => {
      return new ArrayDataSource(category.Son);
    });
    this.currentCategoriesTree = this.previousCategoriesTrees[this.previousCategoriesTrees.length - 2];
    this.loadingTree = false;
  }

  hasNestedChildren(index: number, category: CategoryModel): boolean {
    return !!category.Son && category.Son.length > 0;
  }

  handleNavigateLevel(categoryNode: CategoryModel): void {
    this.categoryLeafSelected = null;
    const categoryNodes = this.treeControl.getChildren(categoryNode);
    this.previousCategoriesTrees.push(this.currentCategoriesTree);
    this.currentCategoriesTree = new ArrayDataSource(categoryNodes);
    if (this.lastCategoryIsLeaf()) {
      this.categoriesSelected[this.categoriesSelected.length - 1] = categoryNode;
    } else {
      this.categoriesSelected.push(categoryNode);
    }
    this.categorySelected.emit(null);
  }

  handleSelectCategory(categoryNode: CategoryModel): void {
    const categoryisAdded = this.categoriesSelected[this.categoriesSelected.length - 1].Id === categoryNode.Id;
    if (!this.lastCategoryIsLeaf() && !categoryisAdded) {
      this.categoriesSelected.push(categoryNode);
    }
    if (!this.lastCategoryIsLeaf() && categoryisAdded) {
      this.categoriesSelected[this.categoriesSelected.length - 1] = categoryNode;
      this.categoriesSelected.push(categoryNode);
    }
    this.categoryLeafSelected = categoryNode;
    this.categorySelected.emit(this.categoryLeafSelected);
  }

  lastCategoryIsLeaf(): boolean {
    return this.categoriesSelected.length > 0 && !this.hasNestedChildren(0, this.categoriesSelected[this.categoriesSelected.length - 1]);
  }

  handleNavigateCategory(categorySelected: CategoryModel): void {
    const categoryIndex = this.categoriesSelected.findIndex(
      (category) => category.Id === categorySelected.Id
    );
    this.categoriesSelected.splice(categoryIndex + 1);
    this.previousCategoriesTrees.splice(categoryIndex);
    this.currentCategoriesTree = new ArrayDataSource(
      this.categoriesSelected[categoryIndex].Son
    );
    this.categoryLeafSelected = null;
    this.categorySelected.emit(this.categoryLeafSelected);
  }

}
