import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CategoryModel } from '../../categorization/list/category.model';

@Component({
  selector: 'app-product-category-breadcrumb',
  templateUrl: './product-category-breadcrumb.component.html',
  styleUrls: ['./product-category-breadcrumb.component.scss']
})
export class ProductCategoryBreadcrumbComponent {
  @Input() navigationCategoriesSelected: CategoryModel[];
  @Input() navigationCategoryNodeLeaf: CategoryModel;
  @Output() navigateCategoryBreadcrumb: EventEmitter<CategoryModel>;

  constructor() {
    this.navigateCategoryBreadcrumb = new EventEmitter<CategoryModel>();
  }

  handleNavigateCategory(category: CategoryModel): void {
    this.navigateCategoryBreadcrumb.emit(category);
  }

  isLastBreadcrumb(index: number): boolean {
    return this.navigationCategoriesSelected.length - 1 === index;
  }
}
