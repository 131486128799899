import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AsignateimageService } from '@secure/products/create-product-unit/assign-images/assign-images.component.service';
import { ProductDetail } from '@secure/products/models/product-detail.model';
import { ProductCategory } from '../../models/product-creation.model';

@Component({
  selector: 'app-product-media',
  templateUrl: './product-media.component.html',
  styleUrls: ['./product-media.component.scss']
})
export class ProductMediaComponent implements OnInit, OnChanges {
  @Input() category: ProductCategory;
  @Input() isValidProduct: boolean;
  @Input() productDetails: ProductDetail;
  @Output() productCreationCompleted: EventEmitter<void> = new EventEmitter();
  @Output() mediaStepCompleted: EventEmitter<any> = new EventEmitter();
  images: { id: string; url: string; }[];
  videoUrl: string;
  productType: string = '';

  constructor(private imagesService: AsignateimageService) {
  }

  ngOnInit(): void {
    this.initProperties();
  }

  initProperties() {
    this.images = [
      {
        id: 'product-image-1',
        url: this.productDetails ? this.productDetails.imageUrl1 : '',
      },
      {
        id: 'product-image-2',
        url: this.productDetails ? this.productDetails.imageUrl2 : ''
      },
      {
        id: 'product-image-3',
        url: this.productDetails ? this.productDetails.imageUrl3 : '',
      },
      {
        id: 'product-image-4',
        url: this.productDetails ? this.productDetails.imageUrl4 : '',
      },
      {
        id: 'product-image-5',
        url: this.productDetails ? this.productDetails.imageUrl5 : '',
      }
    ];
    this.videoUrl = this.productDetails ? this.productDetails.videoUrl : null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['category']) {
      const previousValue = changes['category'].previousValue;
      const currentValue = changes['category'].currentValue;
      const previousType = previousValue && previousValue.ProductType;
      const currentType = currentValue && currentValue.ProductType;
      if (previousType === 'Technology' && currentType === 'Clothing') {
        this.resetProductMedia();
      }
      if (changes['category'].currentValue && this.category.Category) {
        this.productType = this.category.ProductType;
      }
    }
  }


  onUploadImageFile(imageData: { id: string; image: string }) {
    const imageUrl = {
      UrlImage: imageData.image
    };
    this.imagesService.getvalidateImage(imageUrl).subscribe(res => {
      const responseData = JSON.parse(res.body).Data;
      const index = this.images.findIndex(i => i.id === imageData.id);
      this.images[index].url = responseData.Url;
      this.mediaStepCompleted.emit({
        mediaData: {
          images: this.images,
          videoUrl: this.videoUrl,
        },
        validStep: true
      });
    });
  }

  resetProductMedia() {
    this.productDetails = null;
    this.initProperties();
    this.mediaStepCompleted.emit({
      mediaData: {
        images: this.images,
        videoUrl: this.videoUrl,
      },
      validStep: false
    });
  }

  onUploadImageUrl(image: string) {
    const imageUrl = {
      UrlImage: image
    };
    this.imagesService.getvalidateImage(imageUrl).subscribe(res => {
      const responseData = JSON.parse(res.body).Data;
      for (const img of this.images) {
        if (!img.url) {
          img.url = responseData.Url;
          break;
        }
      }
      this.mediaStepCompleted.emit({
        mediaData: {
          images: this.images,
          videoUrl: this.videoUrl,
        },
        validStep: true
      });
    });

  }

  productCreationCompletedEmit() {
    this.productCreationCompleted.emit();
  }

  onUploadVideo(videoUrl: string) {
    this.videoUrl = videoUrl;
    this.mediaStepCompleted.emit({
      mediaData: {
        images: this.images,
        videoUrl: this.videoUrl,
      },
      validStep: true
    });
  }
}
