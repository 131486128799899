import { Component, OnDestroy, OnInit } from '@angular/core';
import { CoreState } from '@app/store';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss']
})
export class NotificationListComponent implements OnInit, OnDestroy {

  showMore: boolean = false;
  sliceLimit: number = 3;
  notification$: Subscription = new Subscription();
  pendingDevolution: any;
  pendingClaims: any;

  notificationList = [];

  constructor(
    private store: Store<CoreState>
  ) { }

  ngOnInit() {
    this.getNotifications();
  }

  toggleShowMore() {
    if (this.showMore) {
      this.showMore = false;
      this.sliceLimit = 2;
      return;
    }
    this.showMore = true;
    this.sliceLimit = this.notificationList.length;
  }

  getNotifications() {
    this.notification$ = this.store
      .pipe(select(state => state.notification))
      .subscribe(
        notificationState => {
          this.pendingDevolution = notificationState.unreadDevolutions;
          this.pendingClaims = notificationState.unreadCases;
          this.setDevolutionAndPendingNotifications();
        }
      );
  }

  setDevolutionAndPendingNotifications() {
    this.notificationList = [];
    // Acá se deben añadir al array las notificaciones que se van a mostrar
  }

  ngOnDestroy(): void {
    this.notification$.unsubscribe();
  }

}
