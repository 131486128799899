<section class="select-language">
  <p class="floating-header__section-title" *ngIf="!hideLabel">
    {{ 'select.language' | translate }}
  </p>
  <div class="language__button-group">
    <button *ngFor="let language of languages" (click)="select(language)" class="floating-header__profile-button"
      [ngClass]="{'active':currentLanguage === language}">
      <span class="material-symbols-rounded button__icon" *ngIf="currentLanguage === language; else noSelected">
        check_circle
      </span>
      <ng-template #noSelected>
        <span class="material-symbols-rounded button__icon">check_circle</span>
      </ng-template>
      <span class="button__text">{{ languagesDictionary[currentLanguage][language] }}</span>
    </button>
  </div>
</section>