import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CategoryModel } from '../categorization/list/category.model';
import { ProductCategorizationTreeBuilderService } from './product-categorization-tree-builder.service';
import { CategoryOption } from './product-category-search/product-category-search.component';
import { LoadingService } from '@app/core';
import { ProductDetail } from '@secure/products/models/product-detail.model';
import { ProductCategory } from '@secure/products/models/product-creation.model';
import { CoreState } from '@app/store';
import { Store } from '@ngrx/store';
import { ResetVariantImages } from '@app/store/unit-product-creation/actions';

@Component({
  selector: 'app-product-categorization',
  templateUrl: './product-categorization.component.html',
  styleUrls: ['./product-categorization.component.scss'],
})
export class ProductCategorizationComponent implements OnInit {
  categories: CategoryModel[];
  navigationCategoriesSelected: CategoryModel[];
  @Input() productDetails: ProductDetail;
  @Output() categoryStepCompleted: EventEmitter<{ categoryData: ProductCategory, validStep: boolean }>;

  constructor(
    private productCategorizationTreeBuilderService: ProductCategorizationTreeBuilderService,
    private loadingService: LoadingService,
    private store: Store<CoreState>
  ) {
    this.categoryStepCompleted = new EventEmitter<{ categoryData: ProductCategory, validStep: boolean }>();
  }

  ngOnInit(): void {
    this.getCategoriesTree();
  }


  getCategoriesTree(): void {
    this.loadingService.viewSpinner();
    this.productCategorizationTreeBuilderService.getCategoriesTree()
      .subscribe((categoryTree: CategoryModel[]) => {
        this.categories = categoryTree;
        if (this.productDetails) {
          this.onCategorySelected({
            id: Number(this.productDetails.categoryId),
            name: this.productDetails.category,
            idParent: 0
          });
        }
        this.loadingService.closeSpinner();
      });
  }

  onCategoryFromTreeSelected(categorySelected: CategoryModel): void {
    if (categorySelected) {
      if (categorySelected.ProductType === 'Technology') {
        this.store.dispatch(new ResetVariantImages());
      }
      this.categoryStepCompleted.emit({
        categoryData: {
          Category: categorySelected.Id,
          CategoryName: categorySelected.Name,
          ProductType: categorySelected.ProductType
        },
        validStep: true
      });
    } else {
      this.categoryStepCompleted.emit({ categoryData: null, validStep: false });
    }
  }

  onCategorySelected(searchedCategory: CategoryOption): void {
    this.navigationCategoriesSelected = this.getAncestorsCategories(this.categories, searchedCategory.id);
  }

  getAncestorsCategories(categories: CategoryModel[], idToSearch: number, lineage: CategoryModel[] = []): CategoryModel[] {
    for (let i = 0; i < categories.length; i++) {
      const category = categories[i];
      const { Id, Son } = category;
      const currentLineage = [...lineage];
      currentLineage.push(category);
      if (Id === idToSearch) {
        return currentLineage;
      }
      if (Son && Son.length > 0) {
        const result = this.getAncestorsCategories(Son, idToSearch, currentLineage);
        if (result.length > 0) {
          return result;
        }
      }
    }
    return [];
  }

}
