<div class="upload-image-url">
  <h3 class="unit-product-creation__subtitle">
    {{ "secure.products.unit_product_creation.images_and_video.upload_images_by_url.title" | translate }}
  </h3>
  <div class="upload-image-url__form">
    <div class="upload-image-url__placeholder">
      <img *ngIf="imageUrl" class="upload-image-url__image" [src]="imageUrl">
    </div>
    <mat-form-field class="unit-product-creation__form-field flex1" floatLabel="never">
      <input matInput type="text"
             [placeholder]="'secure.products.unit_product_creation.images_and_video.upload_images_by_url.url_input_placeholder' | translate"
             #imageUrlInput (change)="onImageUrl()">
    </mat-form-field>
  </div>
  <button class="unit-product-creation__button" (click)="sendImage()">
    <span class="material-symbols-outlined">add</span>
    <span class="unit-product-creation__button-text">
      {{ "secure.products.unit_product_creation.images_and_video.upload_images_by_url.add_image_button_label" | translate }}
    </span>
  </button>
</div>
