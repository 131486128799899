<h2 mat-dialog-title>{{ title | translate}}</h2>
<mat-dialog-content>

  <form #f="ngForm" [formGroup]="myform" class="form-login">
    <div fxLayout="column" fxLayoutGap="20px">
      <div fxLayout="row" fxFlex="100">
        <mat-form-field fxFlex="100" class="input-style">
          <input
            name="email"
            formControlName="email"
            matInput
            [placeholder]="'secure.seller.register_seller.lb_email_form' |
            translate"
            type="email"
            id="emailPago"
            required>
          <button
            disabled="{{!f.form.dirty}}"
            matSuffix
            type="button"
            color="primary"
            mat-icon-button
            (click)="createForm()">
            <mat-icon
              [matTooltip]="'secure.offers.historical_admin.download_historical.tooltip_email'
              | translate" matTooltipPosition="below" matSuffix>
              autorenew
            </mat-icon>
          </button>
          <mat-error>{{'errors.blank_email' | translate}}</mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>

</mat-dialog-content>
<mat-dialog-actions class="dialog-buttons" align="end">
  <button class="sc-button" mat-button [mat-dialog-close]="true">{{'actions.cancel'
    | translate}}</button>

  <button
    class="sc-button"
    [disabled]="!f.form.valid"
    (click)="dowrloadReport(myform)"
    mat-raised-button color="accent">{{ btn |
    translate}}
  </button>

</mat-dialog-actions>
