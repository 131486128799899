<article id="list-zones">
  <h4>{{'secure.offers.quoting.administrator.list_zones.title_component' | translate}}</h4>


  <aside class="title-list-comp" fxHide.xs>
    <p>{{'shared.name' | translate}}</p>
  </aside>

  <div *ngIf="!listZones || (listZones && !listZones.length)" class="no-items" fxLayout="row"
    fxLayoutAlign="center center">
    <p class="title-zones">
        {{'secure.offers.quoting.administrator.list_zones.txt_not_elements' | translate}}
    </p>
  </div>

  <div fxLayout="column">
    <aside class="cont-list-comp" fxLayout="row" *ngFor="let item of listZones; let i = index">
      <div class="info-zones" fxFlex="90" fxLayout.xs="column">
        <p class="title-zones" fxShow.xs="true" fxShow="false">{{'shared.name' | translate}}</p>
        <p>{{item.Name}}</p>
      </div>
      <div class="options-zones" fxFlex="10" fxLayout="row" fxLayoutAlign="end end">
        <button type="button" color="primary" [matTooltip]="'actions.delete' | translate" matTooltipPosition="below" mat-icon-button
          mat-button (click)="deleteZone(item , i)" *ngIf="canDelete">
          <mat-icon class="flexbox align-center justify-center">delete</mat-icon>
        </button>

        <button type="button" color="primary" [matTooltip]="'actions.edit' | translate" matTooltipPosition="below" mat-icon-button mat-button
          (click)="editZone(item.Id)" *ngIf="canEdit">
          <mat-icon class="flexbox align-center justify-center">edit</mat-icon>
        </button>
      </div>
    </aside>

    <aside class="flexbox flex-wrap align-center justify-center" [ngClass]="{'button-create': listZones && listZones.length}">
      <button id="btn-create-zone" type="submit" mat-stroked-button color="primary" *ngIf="canCreate" (click)="createZone()">{{'actions.create' | translate}}</button>
    </aside>
  </div>

</article>
<app-create-dialog *ngIf="openModalCreate" [@enterAnimation] [typeDialog]="typeDialog" [idToEdit]="idToEdit"></app-create-dialog>