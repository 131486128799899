<div class="sh-message">
  <span class="sh-message__small-text sh-message__small-text--bottom-space"
    *ngIf="configuration.theme === _THEMES.BASIC">
    {{ date ? (date | date: "d/MM/y - HH:mm:ss") : '' }}
  </span>
  <div class="sh-message__content">
    <span class="sh-message__small-text sh-message__small-text--bottom-space"
      *ngIf="configuration.theme === _THEMES.ALTER">
      {{ date ? (date | date: "d/MM/y - HH:mm:ss") : '' }}
    </span>
    <p id="conversation-message-{{caseId}}">{{ message ? message : '' }}</p>
    <span class="sh-message__small-text">
      {{ name ? name : '' }}
    </span>
  </div>
  <div class="sh-message__controls sh-message__controls--right" *ngIf="!disableButtonAnswer">
    <button [disabled]="disabled" (click)="replyEvent.emit()" *ngIf="configuration.reply"
      class="mat-raised-button sh-message__button sh-message__button--secondary"
      [ngClass]="disabledClass ? 'sh-message__button--secondary-disabled' : ''"
      id="conversation-message-reply-btn-{{caseId}}">
      {{'secure.parametize.support_claims.reply-btn'| translate}}
    </button>
  </div>
</div>
