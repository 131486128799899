<mat-drawer-container class="example-container" autosize>
  <mat-drawer #drawer [mode]="'over'" [position]="'end'" class="example-sidenav" [disableClose]="true">
    <form class="flexbox flex-wrap justify-center" #createfilterListCases="ngForm" [formGroup]="filterListCases"
      class="seller-filter-sidenav" *ngIf="filterListCases">
      <div class="filter-content">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div class="filter-header">
            <span class="filter-header--title">
              {{'secure.parametize.support_claims-filter.modal-title'| translate}}
            </span>
          </div>
          <button id="closeMenuApplication" type="button" color="primary" [matTooltip]="'actions.close' | translate"
            matTooltipPosition="below" mat-icon-button mat-button (click)="drawer.toggle()">
            <mat-icon>clear</mat-icon>
          </button>
        </div>
        <div fxLayout="column" fxLayoutAlign="space-between" fxLayout.xs="column">
          <mat-form-field color="primary">
            <input matInput [placeholder]="translateService.instant('secure.parametize.support_claims-filter.id')"
              formControlName="CaseNumber" name="caseNumber" [errorStateMatcher]="matcher">
            <mat-hint></mat-hint>
            <mat-error *ngIf="filterListCases.get('CaseNumber').hasError('pattern')">
              {{'secure.parametize.support_claims.list.error.caseNumber' | translate}}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="filter-form--field">
            <mat-select [placeholder]="translateService.instant('secure.parametize.support_claims-filter.last_post')"
              [(ngModel)]="filterLastPost" formControlName="LastPost" name="lastPost">
              <mat-option *ngFor="let post of lastPost" value="{{post.valuePost}}">
                {{post.name | translate}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="classDatapicker">
            <input matInput [matDatepicker]="picker"
              [placeholder]="'secure.parametize.support_claims-filter.start-date' | translate"
              formControlName="DateInit" [(ngModel)]="filterDateInit">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker disabled="false"></mat-datepicker>
          </mat-form-field>

          <mat-form-field class="classDatapicker">
            <input matInput [matDatepicker]="picker1"
              [placeholder]="'secure.parametize.support_claims-filter.end-date' | translate" formControlName="DateEnd"
              [(ngModel)]="filterDateEnd">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1 disabled="false"></mat-datepicker>
          </mat-form-field>

          <mat-form-field class="filter-form--field">
            <mat-select [placeholder]="translateService.instant('secure.parametize.support_claims-filter.status')"
              name="status" formControlName="Status">
              <mat-option *ngFor="let state of options" value="{{state.code}}">
                {{state.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="filter-form--field">
            <mat-select id="suport-modal-input-reason" formControlName="classification"
              [placeholder]="'secure.parametize.support_drop-down-reason-detail' | translate">
              <ng-container *ngFor="
              let item of getClassification;
              let i = index
            ">
                <mat-option [value]="item.idMatrix">
                  <span id="{{ item.category }} - {{ i }}">
                    {{
                  item.category != null
                    ? item.category
                    : "Sin título"
                }}
                  </span>
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="filter-form--field">
            <input matInput
              [placeholder]="translateService.instant('secure.parametize.support_claims-filter.orderNumber')"
              formControlName="OrderNumber" name="orderNumber" />
            <mat-hint></mat-hint>
            <mat-error *ngIf="filterListCases.get('OrderNumber').hasError('pattern')">
              {{'secure.parametize.support_claims.list.error.orderNumber' | translate}}
            </mat-error>
          </mat-form-field>

        </div>
        <div class="filter-control">
          <button mat-stroked-button color="primary" type="button" (click)="cleanFilter()">
            {{'secure.parametize.support_claims-filter.clear'| translate}}
          </button>
          <button mat-flat-button color="primary" type="submit" (click)="drawer.toggle() && filterApply()"
            [disabled]="!createfilterListCases.form.valid || !createfilterListCases.form.touched">
            {{'secure.parametize.support_claims-filter.filter'| translate}}
          </button>
        </div>
      </div>
    </form>
  </mat-drawer>



  <div class="example-sidenav-content borde-line-toolbar">
    <mat-toolbar class="drawer-container">
      <app-case-toolbar [ngClass]="{disabledDiv: idDetail}" class="styleToolbar"
        (sellerDataSearch)="getSellerBySearch($event)" (changePagination)="changePagination($event)" [length]="length"
        [pageIndex]="pageIndex" [pageSize]="pageSize">
      </app-case-toolbar>
      <div class="filterDesktop">
        <button mat-icon-button [matTooltip]="'secure.parametize.support_claims.download' | translate"
          (click)="openModalExportByFilter()">
          <mat-icon>archive</mat-icon>
        </button>
        <button type="button" mat-button (click)="drawer.toggle()">
          <mat-icon>filter_list</mat-icon>
          <small class="sub-title-filter">{{'filters.filter' | translate}}</small>
        </button>
      </div>
      <div [ngClass]="{filterMobile2: isAdmin, filterMobile: !isAdmin}">
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="openModalExportByFilter()">
            <mat-icon>archive</mat-icon>
            <small class="sub-title-filter">{{'permissions.Descargar' | translate}}</small>
          </button>
          <button mat-menu-item type="button" (click)="drawer.toggle()">
            <mat-icon>filter_list</mat-icon>
            <small class="sub-title-filter">{{'filters.filter' | translate}}</small>
          </button>
        </mat-menu>
      </div>
    </mat-toolbar>
  </div>

  <div class="example-sidenav-content borde-line-toolbar-title-time">
    <span class="material-icons">
      alarm_on
    </span>
    <div fxLayout="column" fxLayoutAlign="space-evenly start">
      <h1>{{timeResponse}} {{'secure.parametize.support.hours' | translate}}</h1>
      <p>{{'secure.parametize.support_claims.title.time' | translate }}</p>
    </div>

  </div>

  <ng-template [ngIf]="cases">
    <div *ngIf="!idDetail">
      <!-- Headers para tipo perifl seller -->
      <div matSort (matSortChange)="sortData($event)" class="sh-list-header-header" fxLayout="row" fxLayout.xs="row"
        fxLayoutAlign="center center" fxLayoutGap="10px" fxLayoutGap.xs="0" *ngIf="hideHeader === true">
        <div fxFlex="5%" class="sh-list-header-header--item-header-none">&nbsp;</div>

        <div class="sh-list-header-header--item-header" id="sh-list-header-drop-down-menu-ID"
          [fxFlex]="2*  5 | flexSize" [fxFlex.xs]="5*  5 | flexSize">
          {{"secure.parametize.support_drop-down-menu-ID" | translate}}
        </div>

        <div class="sh-list-header-header--item-header sh-list-header-header--marginleft"
          id="sh-list-header-drop-down-menu-order-numer" [fxFlex]="3*  5 | flexSize" [fxFlex.xs]="10*  5 | flexSize">
          {{"secure.parametize.support_drop-down-order-numer" | translate}}
        </div>

        <div class="sh-list-header-header--item-header sh-list-header-header--item-header-none"
          id="sh-list-header-drop-down-menu-reason-detail" [fxFlex]="6*  5 | flexSize">
          {{"secure.parametize.support_drop-down-reason-detail" | translate}}
        </div>

        <div mat-sort-header="createDate"
          class="sh-list-header-header--item-header sh-list-header-header--item-header-none"
          id="sh-list-header-drop-down-creation-date" [fxFlex]="3*  5 | flexSize">
          {{"secure.parametize.support_drop-down-creation-date" | translate}}
        </div>

        <div class="sh-list-header-header--item-header sh-list-header-header--item-header-none"
          id="sh-list-header-drop-down-status" [fxFlex]="2*  5 | flexSize">
          {{"secure.parametize.support_drop-down-status" | translate}}
        </div>

        <div mat-sort-header="lastReply"
          class="sh-list-header-header--item-header sh-list-header-header--item-header-none"
          id="sh-list-header-drop-down-last-reply" [fxFlex]="4*  5 | flexSize">
          {{"secure.parametize.support_drop-down-last-reply" | translate}}
        </div>

        <div mat-sort-header="timeReply" class="sh-list-header-header--item-header"
          id="sh-list-header-drop-down-time-reply" [fxFlex]="4*  5 | flexSize">
          {{"secure.parametize.support_drop-down-time-reply" | translate}}
        </div>
      </div>

      <!-- Headers para tipo perifl administrator -->
      <div matSort (matSortChange)="sortData($event)" class="sh-list-header-header" fxLayout="row" fxLayout.xs="row"
        fxLayoutAlign="center center" fxLayoutGap="10px" fxLayoutGap.xs="0" *ngIf="hideHeader === false">

        <div fxFlex="5%" class="sh-list-header-header--item-header-none">&nbsp;</div>
        <div class="sh-list-header-header--item-header paddingLeft" id="sh-list-header-drop-down-menu-ID"
          [fxFlex]="2*  5 | flexSize" [fxFlex.xs]="8*  5 | flexSize">
          {{"secure.parametize.support_drop-down-menu-ID" | translate}}
        </div>

        <div class="sh-list-header-header--item-header sh-list-header-header--marginleft-admin"
          id="sh-list-header-drop-down-menu-order-numer" [fxFlex]="3*  5 | flexSize" [fxFlex.xs]="10*  5 | flexSize">
          {{"secure.parametize.support_drop-down-order-numer" | translate}}
        </div>

        <div class="sh-list-header-header--item-header sh-list-header-header--item-header-none"
          id="sh-list-header-drop-down-menu-reason-detail" [fxFlex]="4*  5 | flexSize">
          {{"secure.parametize.support_drop-down-reason-detail" | translate}} 2
        </div>

        <div mat-sort-header="createDate"
          class="sh-list-header-header--item-header sh-list-header-header--item-header-none"
          id="sh-list-header-drop-down-creation-date" [fxFlex]="3*  5 | flexSize">
          {{"secure.parametize.support_drop-down-creation-date" | translate}}
        </div>

        <div class="sh-list-header-header--item-header sh-list-header-header--item-header-none"
          id="sh-list-header-drop-down-status" [fxFlex]="4*  5 | flexSize">
          {{"secure.parametize.support_drop-down-status" | translate}}
        </div>

        <div mat-sort-header="lastReply"
          class="sh-list-header-header--item-header sh-list-header-header--item-header-none"
          id="sh-list-header-drop-down-last-reply" [fxFlex]="4*  5 | flexSize">
          {{"secure.parametize.support_drop-down-last-reply" | translate}}
        </div>

        <div mat-sort-header="timeReply" class="sh-list-header-header--item-header"
          id="sh-list-header-drop-down-time-reply" [fxFlex]="4*  5 | flexSize">
          {{"secure.parametize.support_drop-down-time-reply" | translate}}
        </div>
      </div>

      <app-drop-down-list [data]="cases" [options]="options" [configurations]="headerConfigurations">

        <ng-container *itemDropDownList>
          <app-drop-down-box *ngFor="let item of cases; let i = index" [data]="item" [read]="item.read"
            (opened)="markAsRead($event)" (showDetail)="consultDetails($event, item)" [index]="i">
            <ng-container *itemCaseList>
              <div *ngIf="item.statusLoad" class="text-aling-center">
                <div class="margin-bottom-10">
                  <mat-spinner [diameter]="30" class="margin-loading"></mat-spinner>
                </div>
                <span>
                  {{'secure.products.create_product_unit.list_products.please_wait' | translate}}
                </span>
              </div>
              <div *ngIf="!item.statusLoad">

                <table mat-table [dataSource]="[item]" class="w-100 hide-mobile table-support">

                  <!--- Note that these columns can be defined in any order.
                        The actual rendered columns are set as a property on the row definition" -->

                  <!-- product Column -->
                  <ng-container matColumnDef="product">
                    <th mat-header-cell *matHeaderCellDef> {{'secure.parametize.support.product' | translate}} </th>
                    <td style="width: 35%;" class="p-rel" mat-cell *matCellDef="let element">

                      <span class="name" (click)="showThumbnail(element)">
                        {{element.productName}}
                      </span>

                      <mat-icon class="icon" *ngIf="element.productName">image</mat-icon>

                    </td>
                  </ng-container>

                  <!-- Brand Column -->
                  <ng-container matColumnDef="brand">
                    <th mat-header-cell *matHeaderCellDef> {{'secure.parametize.support.brand' | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.brand}} </td>
                  </ng-container>

                  <!-- Sku Column -->
                  <ng-container matColumnDef="sku">
                    <th mat-header-cell *matHeaderCellDef> SKU </th>
                    <td mat-cell *matCellDef="let element"> {{element.sku}} </td>
                  </ng-container>

                  <!-- Ean Column -->
                  <ng-container matColumnDef="ean">
                    <th mat-header-cell *matHeaderCellDef> EAN </th>
                    <td mat-cell *matCellDef="let element"> {{element.ean}} </td>
                  </ng-container>

                  <!-- Sku vendedor Column -->
                  <ng-container matColumnDef="skuseller">
                    <th mat-header-cell *matHeaderCellDef>
                      {{'secure.orders.order_list.product_order.sku_seller' | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.sellerSku}} </td>
                  </ng-container>

                  <!-- price Column -->
                  <ng-container matColumnDef="price">
                    <th mat-header-cell *matHeaderCellDef> {{'secure.parametize.support.sell_price' | translate}}</th>
                    <td mat-cell *matCellDef="let element"> {{element.price}} </td>
                  </ng-container>

                  <!-- Quantity Column -->
                  <ng-container matColumnDef="quantity">
                    <th mat-header-cell *matHeaderCellDef> {{'secure.parametize.support.quantity' | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.quantity}} </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>

                <app-case-summary (clickResponse)="onEmitResponse($event)" [_case]="item"
                  (idDetail)="redirectToDetailClaims($event)" [disabledClass]="item.status === 'Cerrado'"
                  [indexParent]="i"></app-case-summary>
              </div>
            </ng-container>
          </app-drop-down-box>
        </ng-container>

      </app-drop-down-list>
    </div>

    <div *ngIf="idDetail">
      <app-detail-case [idDetail]="idDetail" (idDetailFalse)="redirectToListClaims($event)"></app-detail-case>
    </div>
  </ng-template>
</mat-drawer-container>
<div *ngIf="length === 0 && isAdmin" class="orders-no-data">
  <mat-icon class="icon-not-found">info</mat-icon>

  <p>{{'secure.parametize.support_claims.search_orders_insert' | translate}}</p>
</div>
