<div class="title-page">
  <div fxFlex="100%" fXLayout="row" fxLayoutAlign="start center"
    class="toolbar-cont">
    <div fxFlex="93">
      <span class="sc-title">{{'secure.orders.order_list.product_detail.product'| translate}}: {{order.reversionRequestDetailViewModel.productName}}
      </span>
    </div>
    <div fxFlex="7">
      <a [matTooltip]="'actions.close' | translate" mat-icon-button matTooltipPosition="below"
        mat-button>
        <mat-icon (click)="onNoClick()">close</mat-icon>
      </a>
    </div>
  </div>
</div>
<div class="container-product-list content-products-pending-detail">
  <mat-accordion fxFlex="100">
    <mat-expansion-panel class="expanded-element" expanded="true">
      <mat-expansion-panel-header>
        <mat-panel-title class="sc-link-text">
          {{'secure.orders.in_devolution.product_devolution_modal.info_title' |
          translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-list class="card-info" fxFlex="100">
        <mat-list-item class="mat-list-item-order">
          <h4 mat-line>{{'secure.orders.order_list.product_detail.product' |
            translate}}
            <p mat-subheader class="sub-text-list">{{order.reversionRequestDetailViewModel.productName}}</p>
          </h4>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item class="mat-list-item-order">
          <h4 mat-line>{{'secure.orders.in_devolution.product_devolution_modal.reference'
            | translate}}
            <p mat-subheader class="sub-text-list">{{order.reversionRequestDetailViewModel.reference}}</p>
          </h4>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item class="mat-list-item-order">
          <h4 mat-line>{{'secure.orders.in_devolution.product_devolution_modal.sku'
            | translate}}
            <p mat-subheader class="sub-text-list">{{order.reversionRequestDetailViewModel.sku}}</p>
          </h4>
        </mat-list-item>
      </mat-list>
    </mat-expansion-panel>
  </mat-accordion>
  <mat-accordion fxFlex="100">
    <mat-expansion-panel class="expanded-element">
      <mat-expansion-panel-header>
        <mat-panel-title class="sc-link-text">
          {{'secure.orders.in_devolution.product_devolution_modal.view_info_client'
          | translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-list class="card-info" fxFlex="100">
        <mat-list-item class="mat-list-item-order">
          <h4 mat-line>{{'shared.name' | translate}}
            <p mat-subheader class="sub-text-list">{{order.clientName}}</p>
          </h4>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item class="mat-list-item-order">
          <h4 mat-line>{{'secure.orders.in_devolution.product_devolution_modal.id'
            | translate}}
            <p mat-subheader class="sub-text-list">{{order.identificationCard}}</p>
          </h4>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item class="mat-list-item-order">
          <h4 mat-line>{{'secure.client_information.address' | translate}}
            <p mat-subheader class="sub-text-list">{{order.clientAddress}}</p>
          </h4>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item class="mat-list-item-order">
          <h4 mat-line>{{'secure.client_information.phone' | translate}}
            <p mat-subheader class="sub-text-list">{{order.clientTelephone}}</p>
          </h4>
        </mat-list-item>
      </mat-list>
    </mat-expansion-panel>
  </mat-accordion>
</div>
<mat-dialog-actions align="end" class="dialog-buttons">
  <a download class="sc-default-button" id="download-file-button"
    (click)="cloudDownload(order.evidenceLists);"
    mat-button>
    <mat-icon class="mat-18">file_download</mat-icon>
    {{'actions.view_attachments' | translate}}
  </a>
  <a download class="sc-default-button" id="download-file-button"
    (click)="onNoClick()" mat-button>
    {{'actions.close' | translate}}
  </a>
</mat-dialog-actions>
<div *ngIf="showMessage" class="text-validate">
  <span class="text">{{'error.pending.not_attachments' | translate}}</span>
</div>
