<div>
  <div class="info-indicators-padding-15">
    <span class="info-indicators-font-w-size">
      {{data.title}}
    </span>
  </div>
  <div class="info-indicators-padding-max">
    {{data.body}}
  </div>
  <div class="info-indicators-width-text">
    <span class="info-indicators-text-decoration-cursor" (click)="close()">{{'quality.indicators.i_understand'| translate}}</span>
  </div>
</div>