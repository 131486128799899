<div class="container-error" *ngIf="countErrors > 0">
  <div class="name-file-upload">
    <p>{{'secure.products.table_errors_product.selected_file' | translate}}: </p>
    <b>{{ fileName}}</b>
  </div>
  <mat-expansion-panel expanded="true" [@detailExpand] class="list-information-table card-err-process"
     *ngIf="listLog.length != 0">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="title-expansion-panel-table">
          <mat-icon>error</mat-icon>
          <span>{{countErrors}} </span>
          <b> {{'errors.errors_detected' | translate}}</b>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="error-list-content">

      <mat-nav-list dense>
        <mat-list-item>
          <p class="wrap" matLine>{{'errors.errors_upload_product_again' | translate}}
          </p>
        </mat-list-item>
        <mat-list-item id="error-list-guide-button" (click)="bulkLoad.selectErrorLog(item)"
          *ngFor="let item of listLog">
          <mat-icon class="icon-color-error">error</mat-icon>


          <p matLine *ngIf="item.type == 'dateNotFound'" style="white-space: normal;overflow: visible;">
            {{'secure.products.table_errors_product.column_data' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.column' | translate}}
              #{{ item.columna }}</span> {{'secure.products.table_errors_product.for' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.row' | translate}} #{{item.fila}}</span>
            {{'secure.products.table_errors_product.mandatory' | translate}}
          </p>

          <p matLine *ngIf="item.type == 'BoleanFormat'" style="white-space: normal;overflow: visible;">
            {{'secure.products.table_errors_product.column_data' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.column' | translate}}
              #{{ item.columna }}</span> {{'secure.products.table_errors_product.for' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.row' | translate}} #{{item.fila}}</span>
            {{'secure.products.table_errors_product.boolean' | translate}}
          </p>

          <p matLine *ngIf="item.type == 'LessThanZero'" style="white-space: normal;overflow: visible;">
            {{'secure.products.table_errors_product.column_data' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.column' | translate}}
              #{{ item.columna }}</span> {{'secure.products.table_errors_product.for' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.row' | translate}} #{{item.fila}}</span>
            {{'secure.products.table_errors_product.number_positive' | translate}}
          </p>

          <p matLine *ngIf="item.type == 'invalidFormatProduct'" style="white-space: normal;overflow: visible;">
            {{'secure.products.table_errors_product.column_data' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.column' | translate}}
              #{{ item.columna }}</span> {{'secure.products.table_errors_product.for' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.row' | translate}} #{{item.fila}}</span>
            {{'secure.products.table_errors_product.tech_cloting' | translate}}
          </p>

          <p matLine *ngIf="item.type == 'invalidFormatUnidad'" style="white-space: normal;overflow: visible;">
            {{'secure.products.table_errors_product.column_data' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.column' | translate}}
              #{{ item.columna }}</span> {{'secure.products.table_errors_product.for' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.row' | translate}} #{{item.fila}}</span>
            {{'secure.products.table_errors_product.gram_milili' | translate}}
          </p>

          <p matLine *ngIf="item.type == 'invalidFortFact'" style="white-space: normal;overflow: visible;">
            {{'secure.products.table_errors_product.column_data' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.column' | translate}}
              #{{ item.columna }}</span> {{'secure.products.table_errors_product.for' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.row' | translate}} #{{item.fila}}</span>
            {{'secure.products.table_errors_product.errorneous_format' | translate}}.
          </p>

          <p matLine *ngIf="item.type == 'invalidFormat'" style="white-space: normal;overflow: visible;">
            {{'secure.products.table_errors_product.column_data' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.column' | translate}}
              #{{ item.columna }}</span> {{'secure.products.table_errors_product.for' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.row' | translate}} #{{item.fila}}</span>
            {{'secure.products.table_errors_product.errorneous_format' | translate}}.
          </p>

          <p matLine *ngIf="item.type == 'invalidFormatImage'" style="white-space: normal;overflow: visible;">
            {{'secure.products.table_errors_product.column_data' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.column' | translate}}
              #{{ item.columna }}</span> {{'secure.products.table_errors_product.for' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.row' | translate}} #{{item.fila}}</span>
            {{'secure.products.table_errors_product.errorneous_format' | translate}}
            <strong>{{'secure.products.table_errors_product.errorneous_formatimage' | translate}}</strong>
          </p>

          <p matLine *ngIf="item.type == 'invalidFormatExtraField'" style="white-space: normal;overflow: visible;">
            {{'secure.products.table_errors_product.input_option' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.column' | translate}}
              #{{ item.columna }}</span> {{'secure.products.table_errors_product.for' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.row' | translate}} #{{item.fila}}</span>
            {{'secure.products.table_errors_product.errorneous_format' | translate}}.
          </p>

          <p matLine *ngIf="item.type == 'EanComboRepeatError'" style="white-space: normal;overflow: visible;">
            {{'secure.products.table_errors_product.column_data' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.column' | translate}}
              #{{ item.columna }}</span> {{'secure.products.table_errors_product.for' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.row' | translate}} #{{item.fila}}</span>
            {{'secure.products.table_errors_product.duplication_ean' | translate}}
          </p>

          <p matLine *ngIf="item.type == 'UrlVIdeoYoutbeError'" style="white-space: normal;overflow: visible;">
            {{'secure.products.table_errors_product.column_data' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.column' | translate}}
              #{{ item.columna }}</span> {{'secure.products.table_errors_product.for' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.row' | translate}} #{{item.fila}}</span>
            {{'secure.products.create_product_unit.assing_video_url.format_invalidate' | translate}}
          </p>

        </mat-list-item>
      </mat-nav-list>
    </div>
  </mat-expansion-panel>
</div>
