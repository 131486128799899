<div class="container-error" *ngIf="countErrors > 0">
  <div class="name-file-upload">
    <p>{{'secure.products.table_errors_product.selected_file' | translate}}:</p>
    <b>{{ fileName}}</b>
  </div>
  <mat-expansion-panel expanded="true" [@detailExpand] class="list-information-table card-err-process"
     *ngIf="listLog.length != 0">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="title-expansion-panel-table">
          <mat-icon>error</mat-icon>
          <span>{{countErrors}} </span>
          <b> {{'errors.errors_detected' | translate}}</b>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="error-list-content">

      <mat-nav-list dense>
        <mat-list-item>
          <p class="wrap" matLine>{{'errors.errors_upload_offer_again' | translate}}
          </p>
        </mat-list-item>
        <mat-list-item id="error-list-guide-button" (click)="bulkLoad.selectErrorLog(item)"
          *ngFor="let item of listLog">
          <mat-icon class="icon-color-error">error</mat-icon>

          <p matLine *ngIf="item.type == 'NumberFormat'" style="white-space: normal;overflow: visible;">
            {{'secure.products.table_errors_product.column_data' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.column' | translate}}
              #{{ item.columna }}</span> {{'secure.products.table_errors_product.for' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.row' | translate}}
              #{{item.fila}}</span> {{'secure.products.table_errors_product.number_positive' | translate}}
          </p>

          <p matLine *ngIf="item.type == 'dateNotFound'" style="white-space: normal;overflow: visible;">
            {{'secure.products.table_errors_product.column_data' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.column' | translate}}
              #{{ item.columna }}</span> {{'secure.products.table_errors_product.for' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.row' | translate}}
              #{{item.fila}}</span> {{'secure.products.table_errors_product.mandatory' | translate}}
          </p>

          <p matLine *ngIf="item.type == 'BoleanFormat'" style="white-space: normal;overflow: visible;">
            {{'secure.products.table_errors_product.column_data' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.column' | translate}}
              #{{ item.columna }}</span> {{'secure.products.table_errors_product.for' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.row' | translate}}
              #{{item.fila}}</span> {{'secure.products.table_errors_product.boolean' | translate}}
          </p>

          <p matLine *ngIf="item.type == 'LessThanZero'" style="white-space: normal;overflow: visible;">
            {{'secure.products.table_errors_product.column_data' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.column' | translate}}
              #{{ item.columna }}</span> {{'secure.products.table_errors_product.for' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.row' | translate}}
              #{{item.fila}}</span> {{'secure.products.table_errors_product.number_positive' | translate}}
          </p>

          <p matLine *ngIf="item.type == 'ThanZero'" style="white-space: normal;overflow: visible;">
            {{'secure.products.table_errors_product.column_data' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.column' | translate}}
              #{{ item.columna }}</span> {{'secure.products.table_errors_product.for' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.row' | translate}}
              #{{item.fila}}</span> {{'secure.products.table_errors_product.must_positive_decimal' | translate}}
          </p>

          <p matLine *ngIf="item.type == 'InvalidFormatPromEntrega'" style="white-space: normal;overflow: visible;">
            {{'secure.products.table_errors_product.column_data' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.column' | translate}}
              #{{ item.columna }}</span> {{'secure.products.table_errors_product.for' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.row' | translate}}
              #{{item.fila}}</span> {{'secure.offers.bulk_upload.table_error.error_promise_delivery' | translate}}
          </p>

          <p matLine *ngIf="item.type == 'InvalidFormatCurrency'" style="white-space: normal;overflow: visible;">
            {{'secure.products.table_errors_product.column_data' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.column' | translate}}
              #{{ item.columna }}</span> {{'secure.products.table_errors_product.for' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.row' | translate}}
              #{{item.fila}}</span> {{'secure.offers.bulk_upload.table_error.error_currency' | translate}}
          </p>

          <p matLine *ngIf="item.type == 'InvalidEanCombo'" style="white-space: normal;overflow: visible;">
            {{'secure.products.table_errors_product.column_data' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.column' | translate}}
              #{{ item.columna }}</span> {{'secure.products.table_errors_product.for' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.row' | translate}}
              #{{item.fila}}</span> {{'secure.offers.bulk_upload.table_error.diferent_ean' | translate}}
          </p>

          <p matLine *ngIf="item.type == 'InvalidExistEan'" style="white-space: normal;overflow: visible;">
            {{'secure.products.table_errors_product.column_data' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.column' | translate}}
              #{{ item.columna }}</span> {{'secure.products.table_errors_product.for' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.row' | translate}}
              #{{item.fila}}</span> {{'secure.offers.bulk_upload.table_error.incomplete_info' | translate}}
            [{{item.msg}}].
          </p>

          <p matLine *ngIf="item.type == 'InvalidPriceCombo'" style="white-space: normal;overflow: visible;">
            {{'secure.products.table_errors_product.column_data' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.column' | translate}}
              #{{ item.columna }}</span> {{'secure.products.table_errors_product.for' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.row' | translate}}
              #{{item.fila}}</span> {{'secure.offers.bulk_upload.table_error.sum_price' | translate}}
          </p>

          <p matLine *ngIf="item.type == 'InvalidSellerSku'" style="white-space: normal;overflow: visible;">
            {{'secure.products.table_errors_product.column_data' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.column' | translate}}
              #{{ item.columna }}</span> {{'secure.products.table_errors_product.for' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.row' | translate}}
              #{{item.fila}}</span> {{'secure.offers.bulk_upload.table_error.sellerSku' | translate}}
          </p>

          <p matLine *ngIf="item.type == 'InvalidPriceOfferCombo'" style="white-space: normal;overflow: visible;">
            {{'secure.products.table_errors_product.column_data' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.column' | translate}}
              #{{ item.columna }}</span> {{'secure.products.table_errors_product.for' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.row' | translate}}
              #{{item.fila}}</span> {{'secure.offers.bulk_upload.table_error.sum_price_component' | translate}}
          </p>
          <!-- Error de Dirección de Recogida -->
          <p matLine *ngIf="item.type == 'InvalidFormatAddress'" style="white-space: normal;overflow: visible;">
            {{'secure.products.table_errors_product.column_data' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.column' | translate}}
              #{{ item.columna }}</span> {{'secure.products.table_errors_product.for' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.row' | translate}}
              #{{item.fila}}</span> {{'secure.offers.bulk_upload.table_error.address' | translate}}
          </p>

          <!-- Error de Ciudad de Recogida -->
          <p matLine *ngIf="item.type == 'InvalidFormatDaneCode'" style="white-space: normal;overflow: visible;">
            {{'secure.products.table_errors_product.column_data' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.column' | translate}}
              #{{ item.columna }}</span> {{'secure.products.table_errors_product.for' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.row' | translate}}
              #{{item.fila}}</span> {{'secure.offers.bulk_upload.table_error.dane_code' | translate}}
          </p>

          <!-- Error de periodicidad -->
          <p matLine *ngIf="item.type == 'InvalidFormatPeriodicity'" class="overflex-style">
            {{'secure.products.table_errors_product.column_data' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.column' | translate}}
              #{{ item.columna }}</span> {{'secure.products.table_errors_product.for' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.row' | translate}}
              #{{item.fila}}</span> {{'secure.offers.bulk_upload.table_error.periodicity' | translate}}
          </p>
          <!-- Error de referencia padre -->
          <p matLine *ngIf="item.type == 'InvalidReference'" class="overflex-style">
            {{'secure.products.table_errors_product.column_data' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.column' | translate}}
              #{{ item.columna }}</span> {{'secure.products.table_errors_product.for' | translate}}
            <span class="error-string">{{'secure.products.table_errors_product.row' | translate}}
              #{{item.fila}}</span> {{'secure.offers.bulk_upload.table_error.reference' | translate}}
          </p>


        </mat-list-item>
      </mat-nav-list>
    </div>
  </mat-expansion-panel>
</div>
