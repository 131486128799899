<section class="validate-ean">
  <h2 class="unit-product-creation__title">
    {{ "secure.products.unit_product_creation.ean.title" | translate }}
  </h2>
  <form class="validate-ean__form" *ngIf="eanControl">
    <mat-form-field class="validate-ean__form-field">
      <input matInput [placeholder]="'secure.products.unit_product_creation.ean.input_placeholder' | translate"
        [formControl]="eanControl" (keyup)="validateEanServices()" autocomplete="off" maxlength="16" minlength="7"
        (blur)="eanControl.markAsDirty()" />
      <mat-error *ngIf="eanControl.touched && eanControl.errors?.required">
        {{ "errors.error_required"| translate }}
      </mat-error>
      <mat-error *ngIf="eanControl.errors?.pattern">
        {{ "secure.products.unit_product_creation.ean.invalid.format"| translate }}
      </mat-error>
      <mat-error *ngIf="
          eanControl.errors?.validExistEanDB
        ">
        {{ "secure.products.unit_product_creation.ean.invalid.multioffer" | translate }}
      </mat-error>
    </mat-form-field>
    <mat-checkbox #asignatedEanCheckBox [checked]="asignatedEan"
      (change)="onAsignatedEanChanged(asignatedEanCheckBox.checked)" [formControl]="associateEanControl">
      <span class="validate-ean__checkbox-text" [class.validate-ean__checkbox-text--checked]="asignatedEan">
        {{ "secure.products.unit_product_creation.ean.checkbox_label" | translate }}
      </span>
    </mat-checkbox>
    <button class="unit-product-creation__step-button unit-product-creation__button-continue" matStepperNext
      (click)="nextStepEan()">
      {{ "secure.products.unit_product_creation.continue_button_label" | translate }}
    </button>
  </form>
  <ul class="unit-product-creation__list">
    <li class="unit-product-creation__list-item">
      <span class="unit-product-creation__list-icon material-symbols-outlined">
        check_circle
      </span>
      <span [innerHTML]="'secure.products.unit_product_creation.ean.list_item_1' | translate"></span>
    </li>
    <li class="unit-product-creation__list-item">
      <span class="unit-product-creation__list-icon material-symbols-outlined">
        check_circle
      </span>
      <span [innerHTML]="'secure.products.unit_product_creation.ean.list_item_2' | translate"></span>
    </li>
    <li class="unit-product-creation__list-item">
      <span class="unit-product-creation__list-icon material-symbols-outlined">
        check_circle
      </span>
      <span [innerHTML]="'secure.products.unit_product_creation.ean.list_item_3' | translate"></span>
    </li>
    <li class="unit-product-creation__list-item">
      <span class="unit-product-creation__list-icon material-symbols-outlined">
        check_circle
      </span>
      <span [innerHTML]="'secure.products.unit_product_creation.ean.list_item_4' | translate"></span>
    </li>
  </ul>
</section>