<div *ngIf="myform">
  <h2 mat-dialog-title>Te enviaremos el reporte actualizado de ofertas al siguiente correo electrónico.</h2>
  <mat-dialog-content>
    <form #f="ngForm" [formGroup]="myform" class="form-login">
      <div fxLayout="column" fxLayoutGap="20px">
        <div fxLayout="row" fxFlex="100">
          <mat-form-field fxFlex="100" class="input-style">
            <input name="email" formControlName="email" matInput placeholder="Correo electrónico" type="email" required>

            <!-- dirty: si el campo cambia -->
            <button disabled="{{!f.form.dirty}}" matSuffix type="button" color="primary" mat-icon-button (click)="createForm()">
              <mat-icon matTooltip="Volver a seleccionar mi corre electrónico" matTooltipPosition="below" matSuffix>
                autorenew
              </mat-icon>
            </button>
            <mat-error>El correo electrónico no puede estar en blanco</mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions class="dialog-buttons">
    <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
    <button class="sc-button" mat-button [mat-dialog-close]="true">Cancelar</button>

    <button class="sc-button" [disabled]="!f.form.valid" (click)="downloadReportOffertADmin(myform)" mat-raised-button color="accent">
      <span>Enviar reporte de ofertas </span>
    </button>
  </mat-dialog-actions>
</div>
