<form #f="ngForm" [formGroup]="myform" class="search-form light-content">

  <mat-form-field id="fileName-input-search-order" class="example-full-width" color="accent" class="filename">
    <input id="fileName-input-search-order" formControlName="fileName" type="text" matInput
           [placeholder]="'core.shell.search_order_menu.search_fraud_notification_nameFile' | translate">
  </mat-form-field>

  <div fxLayout="row" fxLayoutGap="10px">

  <mat-form-field id="sinceDate-input-search-order" color="accent" class="inputw">
    <input id="sinceDate-input" [max]="myform.value.dateOrderFinal" formControlName="dateOrderInitial"
      [placeholder]="'core.shell.search_order_menu.search_order_form.from' | translate" matInput
      [matDatepicker]="dateOrderInitial">
    <mat-datepicker-toggle id="sinceDate-Perfix" matPrefix [for]="dateOrderInitial"></mat-datepicker-toggle>
    <mat-datepicker touchUi="true" startView="year" #dateOrderInitial disabled="false"></mat-datepicker>
  </mat-form-field>

  <mat-form-field id="untilDate-input-search-order" color="accent" class="inputw">
    <input id="untilDate-input" [min]="myform.value.dateOrderInitial" formControlName="dateOrderFinal"
      [placeholder]="'core.shell.search_order_menu.search_order_form.to' | translate" matInput
      [matDatepicker]="dateOrderFinal">
    <mat-datepicker-toggle id="untilDate-Prefix" matPrefix [for]="dateOrderFinal">
    </mat-datepicker-toggle>
    <mat-datepicker touchUi="true" startView="year" #dateOrderFinal disabled="false"></mat-datepicker>
  </mat-form-field>

</div>

  <div class="orders-search-option normal-theme fraud-search" fxLayoutGap="10px" fxLayout="row" fxLayoutAlign="space-between center">
    
    <button  id="clear-filter-button" class="sc-button w-100 shadow radius" mat-stroked-button color="primary" (click)="clearForm()">
      {{'filters.clear' | translate}}
    </button>

    <button  id="apply-filter-button" [disabled]="!f.form.valid || !f.form.touched" class="sc-button radius w-100" mat-raised-button
      color="primary" type="submit" (click)="filterOrder(myform)">{{'filters.filter' | translate}}
    </button>
  </div>

</form>
