<div class="category-search">
  <div class="category-search__icon-container">
    <span class="category-search__icon material-symbols-outlined">search</span>
  </div>
  <input id="input-search" type="text" [matAutocomplete]="auto"
    [placeholder]="'secure.products.unit_product_creation.categorization.input_placeholder'| translate"
    class="category-search__input" [formControl]="searchCategory">
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" autoActiveFirstOption
    class="category-search__autocomplete" (optionSelected)="handleOptionSelected($event)">
    <mat-option class="autocomplete-list" *ngFor="let option of filteredOptions | async" [value]="option">
      {{ option.name }}
    </mat-option>
  </mat-autocomplete>
</div>