<div class="tree-content">
  <div class="header-tree-categories">
    <h1>{{capitalizeName(currentSellerSelect.Name)}}</h1>
  </div>

  <!-- Componente empleado cuando no se posee una tienda seleccionada -->
  <div *ngIf="currentSellerSelect.Name === ''" class="no-content-component">
    <div class="icon-face">
      <mat-icon>face</mat-icon>
    </div>
    <p>{{'secure.seller.manage.txt_serch_seller' | translate}}</p>
  </div>

  <mat-tab-group dynamicHeight mat-stretch-tabs>
    <mat-tab [label]="'secure.seller.register_seller.tap_title_update_seller' | translate" *ngIf="showUpdate === true">
      <div class="row" fxLayoutAlign="center" id="containerH3">
      </div>


      <mat-divider> </mat-divider>

      <!-- Componente que se visualiza cuando se ha indicado una tienda para consultar -->
      <div *ngIf="currentSellerSelect.Name != ''">
        <form #updateSeller="ngForm" [formGroup]="validateFormRegister" autocomplete="off" id="register-form-seller"
          (ngSubmit)="submitUpdateSeller()">

          <h4>{{'secure.seller.register_seller.lb_select_profile_type' | translate}}</h4>
          <mat-form-field>
            <mat-select [placeholder]="'secure.seller.register_seller.lb_profile_type_form' | translate"
              [(ngModel)]="selectedValue" name="food" formControlName="Profile">
              <mat-option *ngFor="let Seller of profileSeller" [value]="Seller">
                {{Seller}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <p>{{'secure.seller.register_seller.txt_select_profile_type' | translate}}: {{selectedValue}} </p>

          <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutAlign="space-between"
            fxLayoutGap.md="10%" fxLayoutGap.lg="10%" fxLayoutGap.xs="0" fxLayoutGap.sm="0%">
            <div fxFlex="50%">
              <mat-form-field class="register full-width">
                <mat-label>País</mat-label>
                <mat-select formControlName="Country" required>
                  <mat-option *ngFor="let country of countries" [value]="country.CountryName">
                    {{country.CountryName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div *ngIf="isColombiaSelect; else stateInput" fxFlex="50%">
              <app-states-data (idStateEvent)="receiveDataState($event)" [elementLoad]="department"></app-states-data>
            </div>
            <ng-template #stateInput>
              <div fxFlex="50%">
                <mat-form-field class="register full-width">
                  <input matInput [placeholder]="isColombiaSelect?
                    'Departamento':'Región'" formControlName="State" autocomplete="off" [errorStateMatcher]="matcher"
                    id="register-state">
                  <mat-hint></mat-hint>
                  <mat-error id="error-postal-code-invalid" *ngIf="State.hasError('maxlength') ||
                    State.hasError('minlength') || State.hasError('pattern')">
                    <p></p>
                    {{departamento}} no es válido
                  </mat-error>
                  <mat-error id="error-postal-code-invalid" *ngIf="State.hasError('required')">
                    {{departamento}} es requerido
                  </mat-error>
                </mat-form-field>
              </div>
            </ng-template>
          </div>

          <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutAlign="space-between"
            fxLayoutGap.md="10%" fxLayoutGap.lg="10%" fxLayoutGap.xs="0" fxLayoutGap.sm="0%">
            <div *ngIf="isColombiaSelect; else cityInput" fxFlex="50%">
              <app-cities *ngIf="elementCityLoad" [elementLoad]="elementCityLoad" [idState]="idState"
                (daneCodeEvent)="receiveDataCitie($event)" [disabledComponent]='disabledComponent'></app-cities>
            </div>
            <ng-template #cityInput>
              <div fxFlex="50%">
                <mat-form-field class="register full-width">
                  <input matInput placeholder="Ciudad" formControlName="City" autocomplete="off"
                    [errorStateMatcher]="matcher" id="register-state" required>
                  <mat-hint></mat-hint>
                  <mat-error id="error-postal-code-invalid" *ngIf="City.hasError('maxlength') ||
                    City.hasError('minlength') || City.hasError('pattern')">
                    La ciudad no es válida
                  </mat-error>
                  <mat-error id="error-postal-code-invalid" *ngIf="City.hasError('required')">
                    La ciudad es requerida
                  </mat-error>
                </mat-form-field>
              </div>
            </ng-template>
            <div fxFlex="50%">
              <mat-form-field class="register" style="width:100% !important;">
                <input matInput [placeholder]="isColombiaSelect? 'Código
                  DANE':'Código postal'" [readonly]="isColombiaSelect" formControlName="DaneCode"
                  id="register-dane-code" (keypress)="keyPress($event, 'daneCode')" required>
                <mat-hint></mat-hint>
                <mat-error id="error-postal-code-invalid" *ngIf="PostalCode.hasError('maxlength') ||
                  PostalCode.hasError('minlength') ||
                  PostalCode.hasError('pattern')">
                  El código no es válido
                </mat-error>
                <mat-error id="error-postal-code-invalid" *ngIf="PostalCode.hasError('required')">
                  El código es requerido
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutAlign="space-between"
            fxLayoutGap.md="10%" fxLayoutGap.lg="10%" fxLayoutGap.xs="0" fxLayoutGap.sm="0%">
            <div *ngIf="!isColombiaSelect" [fxFlex]="!isColombiaSelect ? '45%' : '50%'" >
              <app-ports (portItemEmmited)="receivePortItem($event)" [countryName]="Country.value" [disabledComponent]='disabledComponent' [elementLoad]="elementIdDispatchPortLoad"></app-ports>
            </div>
            <!-- Campo para registro de idselleroctopia -->
            <div *ngIf="!isColombiaSelect" fxFlex="50%">
              <mat-form-field class="register" style="width:100% !important;">
                <input matInput [placeholder]="'secure.seller.register_seller.lb_Id Seller Octopia' | translate"
                  [pattern]="sellerRegex.IdSellerOctopia" maxlength="20" 
                  [errorStateMatcher]="matcher" (keypress)="keyPress($event, 'IdSellerOctopia')" id="register-phone-number"
                  formControlName="IdSellerOctopia" >
                <mat-hint></mat-hint>            
              </mat-form-field>
            </div>
          </div>

         

          <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutAlign="space-between"
            fxLayoutGap.md="10%" fxLayoutGap.lg="10%" fxLayoutGap.xl="10%" fxLayoutGap.xs="0" fxLayoutGap.sm="0%">
            <div fxFlex="50%" [ngStyle]="{'padding-right': (isColombiaSelect ?
              '5%' : '0')}">
              <mat-form-field class="register" style="width:100% !important;">
                <input matInput placeholder="Tel&eacute;fono" [maxlength]="isColombiaSelect? 10 : 20" minlength="7"
                  formControlName="PhoneNumber" [errorStateMatcher]="matcher"
                  (keypress)="keyPress($event, 'phoneNumber')" id="register-phone-number" required>
                <mat-hint></mat-hint>
                <mat-error id="error-phone-number-required"
                  *ngIf="validateFormRegister.get('PhoneNumber').hasError('required')"
                  [innerHTML]="'Este campo es requerido.'"></mat-error>
                <mat-error id="error-phone-number-invalid" *ngIf=" validateFormRegister.get('PhoneNumber').hasError('pattern')
                  ||
                  validateFormRegister.get('PhoneNumber').hasError('minlength')"
                  [innerHTML]="'El tel&eacute;fono no es v&aacute;lido.'"></mat-error>
              </mat-form-field>
            </div>
            <div *ngIf="!isColombiaSelect" fxFlex="50%">
              <mat-form-field class="register" style="width:100% !important;">
                <input matInput placeholder="Payoneer" maxlength="50" (blur)="validateExitPayoneerUser($event)"
                  [errorStateMatcher]="matcher" (keypress)="keyPress($event, 'payoneer')" id="register-phone-number"
                  formControlName="Payoneer">
                <mat-hint></mat-hint>
                <mat-error id="error-phone-number-invalid"
                  *ngIf="validateFormRegister.get('Payoneer').hasError('payoneer')"
                  [innerHTML]="'El usuario no es valido.'"></mat-error>
                <mat-error id="error-phone-number-invalid"
                  *ngIf="validateFormRegister.get('Payoneer').hasError('required')"
                  [innerHTML]="'El campo es requerido.'"></mat-error>
              </mat-form-field>
            </div>
          </div>



          <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutAlign="space-between"
            fxLayoutGap.md="10%" fxLayoutGap.lg="10%" fxLayoutGap.xs="0" fxLayoutGap.sm="0%">

            <div fxFlex="50%">
              <mat-form-field class="register" style="width:100% !important">
                <input matInput [placeholder]="'secure.seller.register_seller.lb_nit_form' | translate"
                  formControlName="Nit" id="register-nit" (ngModelChange)="activeButton = false" [readonly]="true"
                  required>
              </mat-form-field>
            </div>

            <div fxFlex="50%">
              <mat-form-field class="register" style="width:100% !important;">
                <input matInput [placeholder]="('secure.seller.register_seller.lb_rut_form' | translate)"
                  (keypress)="keyPress($event)" maxlength="20" formControlName="Rut" [errorStateMatcher]="matcher"
                  id="register-rut" [readonly]="true" required>
                <mat-hint></mat-hint>

                <mat-error id="error-rut-required" *ngIf="validateFormRegister.get('Rut').hasError('required')">
                  {{'errors.required' | translate}}</mat-error>

                <mat-error id="error-rut-invalid"
                  *ngIf="validateFormRegister.get('Rut').hasError('maxlength') || validateFormRegister.get('Rut').hasError('pattern')">
                  {{'errors.rut_invalid' | translate}}</mat-error>
              </mat-form-field>
            </div>

          </div>
          <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutAlign="space-between"
            fxLayoutGap.md="10%" fxLayoutGap.lg="10%" fxLayoutGap.xs="0" fxLayoutGap.sm="0%">

            <div fxFlex="50%">
              <mat-form-field class="register" style="width:100% !important;">
                <input matInput appNoSpaces formControlName="ContactName"
                  [placeholder]="'secure.seller.register_seller.lb_contact_form' | translate"
                  [errorStateMatcher]="matcher" id="register-contact-name" required>
                <mat-hint></mat-hint>

                <mat-error id="error-contact-name-required"
                  *ngIf="validateFormRegister.get('ContactName').hasError('required'); else invalidContactName">
                  {{'errors.required' | translate}}</mat-error>

                <ng-template #invalidContactName>
                  <mat-error id="error-contact-name-invalid"
                    *ngIf="validateFormRegister.get('ContactName').hasError('pattern') || !validateFormRegister.get('ContactName').pristine || !validateFormRegister.get('ContactName').valid">
                    {{'errors.contact_invalid' | translate}}</mat-error>
                </ng-template>
              </mat-form-field>
            </div>

            <div fxFlex="50%">
              <mat-form-field class="register" style="width:100% !important;">
                <input matInput [placeholder]="'secure.seller.register_seller.lb_email_form' | translate"
                  formControlName="Email" [errorStateMatcher]="matcher" id="register-email"
                  (ngModelChange)="activeButton = false" (blur)="validateExist($event, 'Email')" required>
                <mat-hint></mat-hint>

                <mat-error id="error-email-required" *ngIf="validateFormRegister.get('Email').hasError('required')">
                  {{'errors.required' | translate}}</mat-error>
                <mat-error id="error-email-invalid" *ngIf="validateFormRegister.get('Email').hasError('pattern')">
                  {{'errors.email_invalid' | translate}}</mat-error>
                <mat-error id="error-email-exist"
                  *ngIf="validateFormRegister.get('Email').hasError('validExistEmailDB')">
                  {{'errors.email_exist' | translate}}</mat-error>
              </mat-form-field>
            </div>

          </div>
          <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutAlign="space-between"
            fxLayoutGap.md="10%" fxLayoutGap.lg="10%" fxLayoutGap.xl="10%" fxLayoutGap.xs="0" fxLayoutGap.sm="0%">

            <!-- <div fxFlex="50%">
              <mat-form-field class="register" style="width:100% !important;">
                <input matInput [placeholder]="'secure.seller.register_seller.lb_phone_form' | translate"
                  pattern="^[0-9]*$" maxlength="10" minlength="7" formControlName="PhoneNumber"
                  [errorStateMatcher]="matcher" (keypress)="keyPress($event)" id="register-phone-number" required>
                <mat-hint></mat-hint>

                <mat-error id="error-phone-number-required"
                  *ngIf="validateFormRegister.get('PhoneNumber').hasError('required')">
                  {{'errors.error_required' | translate}}</mat-error>
                <mat-error id="error-phone-number-invalid"
                  *ngIf="validateFormRegister.get('PhoneNumber').hasError('maxlength') || validateFormRegister.get('PhoneNumber').hasError('pattern') || validateFormRegister.get('PhoneNumber').hasError('minlength')">
                  {{'errors.phone_invalid' | translate}}</mat-error>
              </mat-form-field>
            </div> -->

            <div fxFlex="50%">
              <mat-form-field class="register" style="width:100% !important;">
                <input matInput appNoSpaces [placeholder]="'secure.seller.register_seller.lb_address_form' | translate"
                  formControlName="Address" [errorStateMatcher]="matcher" id="register-address" required>
                <mat-hint></mat-hint>
                <mat-error id="error-address-required"
                  *ngIf="validateFormRegister.get('Address').hasError('required') || (!validateFormRegister.get('Address').valid && !validateFormRegister.get('Address').pristine)">
                  {{'errors.required' | translate}}</mat-error>
              </mat-form-field>
            </div>
            <!-- </div> -->

            <!-- <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutAlign="space-between"
            fxLayoutGap.md="10%" fxLayoutGap.lg="10%" fxLayoutGap.xs="0" fxLayoutGap.sm="0%"> -->

            <div fxFlex="50%">
              <mat-form-field class="register" style="width:100% !important;">
                <input matInput [placeholder]="'secure.seller.register_seller.lb_store_name_form' | translate"
                  (blur)="validateExist($event, 'Name')" formControlName="Name" [errorStateMatcher]="matcher"
                  id="register-name-store" (ngModelChange)="activeButton= true" required>
                <mat-hint></mat-hint>
                <mat-error id="error-name-store-required" *ngIf="validateFormRegister.get('Name').hasError('required')">
                  {{'errors.required' | translate}}
                </mat-error>
                <mat-error id="error-name-store-invalid" *ngIf="validateFormRegister.get('Name').hasError('pattern')">
                  {{'errors.store_name_invalid' | translate}}</mat-error>
                <mat-error id="error-name-store-exist"
                  *ngIf="validateFormRegister.get('Name').hasError('validExistNameDB')">
                  {{'errors.store_name_exist' | translate}}</mat-error>
              </mat-form-field>
            </div>
            <!-- </div> -->
          </div>
          <!--Campo politicas y devoluciones-->
          <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutAlign="space-between"
            fxLayoutGap.md="10%" fxLayoutGap.lg="10%" fxLayoutGap.xs="0" fxLayoutGap.sm="0%">
            <div fxFlex="100%">
              <mat-form-field class="register" style="width:100% !important;">
                <textarea matInput [placeholder]="'secure.seller.register_seller.lb_warranty' | translate"
                  formControlName="Policy" [errorStateMatcher]="matcher" id="register-warranty-store" required rows="5"
                  cols="80%"></textarea>
                <mat-hint></mat-hint>
                <mat-error id="error-name-store-required"
                  *ngIf="validateFormRegister.get('Policy').hasError('required')">{{'errors.required' | translate}}
                </mat-error>
                <mat-error id="error-name-store-invalid" *ngIf="validateFormRegister.get('Policy').hasError('pattern')">
                  {{'errors.error_format_invalid' | translate}}</mat-error>
              </mat-form-field>
            </div>

          </div>
          <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutAlign="space-between"
            fxLayoutGap.md="10%" fxLayoutGap.lg="10%" fxLayoutGap.xs="0" fxLayoutGap.sm="0%">
            <div fxFlex="100%">
              <div fxLayout="column" fxLayout.xs="column" fxLayout.sm="column" fxLayoutGap.md="20px"
                fxLayoutGap.lg="20px" fxLayoutGap.xs="2%" fxLayoutGap.sm="2%">
                <mat-slide-toggle id="register-is-logistc-exito" formControlName="IsLogisticsExito">
                  {{'secure.seller.register_seller.lb_logistic_form' | translate}}
                </mat-slide-toggle>
                <mat-slide-toggle id="register-is-shipping-exito" formControlName="IsShippingExito">
                  {{'secure.seller.register_seller.lb_envios_form' | translate}}
                </mat-slide-toggle>
                <mat-slide-toggle id="register-is-channel-advisor" formControlName="IsChannelAdvisor" *ngIf="toggleChannel">
                  {{'secure.seller.register_seller.lb_channel_form' | translate}}
                </mat-slide-toggle>
              </div>

            </div>

            <div fxFlex="50%">
              <h4>{{('secure.seller.register_seller.lb_view_form' | translate)}}</h4>

              <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutGap.md="20px" fxLayoutGap.lg="20px"
                fxLayoutGap.xs="2%" fxLayoutGap.sm="2%">
                <mat-card class="imageRegisterSeller">
                  <img mat-card-image [src]="validateFormRegister.value.GotoExito ?
                    imagesRegister[0].checked : imagesRegister[0].unchecked">
                  <mat-card-actions class="register-actions-right">
                    <mat-checkbox id="register-go-exito" formControlName="GotoExito"></mat-checkbox>
                  </mat-card-actions>
                </mat-card>
                <mat-card class="imageRegisterSeller">
                  <img mat-card-image [src]="validateFormRegister.value.GotoCarrulla ?
                    imagesRegister[1].checked : imagesRegister[1].unchecked">
                  <mat-card-actions class="register-actions-right">
                    <mat-checkbox id="register-go-carulla" formControlName="GotoCarrulla"></mat-checkbox>
                  </mat-card-actions>
                </mat-card>
                <mat-card class="imageRegisterSeller">
                  <img mat-card-image [src]="validateFormRegister.value.GotoCatalogo ?
                    imagesRegister[2].checked : imagesRegister[2].unchecked">
                  <mat-card-actions class="register-actions-right">
                    <mat-checkbox id="register-go-catalogo" formControlName="GotoCatalogo"></mat-checkbox>
                  </mat-card-actions>
                </mat-card>
              </div>
            </div>
          </div>
          <div class="container" fxLayoutAlign="center" fxLayoutGap="10px" fxLayoutGap.xs="0">
            <button id="register-send-form" class="button-seller-register" type="submit" mat-raised-button
              color="primary"
              [disabled]="!updateSeller.form.valid || disabledForService || !activeButton || !updateSeller.form.touched">{{'actions.update' | translate}}</button>
          </div>
        </form>
      </div>
    </mat-tab>
    <mat-tab [label]="'secure.seller.register_seller.tap_title_update_admin' | translate" *ngIf="showUpdate === false">
      <div class="row" fxLayoutAlign="center" id="containerH3">
      </div>
      <mat-divider> </mat-divider>

      <div *ngIf="currentSellerSelect.Name != ''">
        <form #updateAdmin="ngForm" [formGroup]="validateFormRegisterAdmin" autocomplete="off" id="register-form-seller"
          (ngSubmit)="submitUpdateAdmin()">

          <h4>{{'secure.seller.register_seller.lb_select_profile_type' | translate}}</h4>
          <mat-form-field>
            <mat-select [placeholder]="'secure.seller.register_seller.lb_profile_type_form' | translate"
              [(ngModel)]="selectedValue" name="adminList" formControlName="Profile">
              <mat-option *ngFor="let Admin of profileAdmin" [value]="Admin">
                {{Admin}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <p> {{'secure.seller.register_seller.txt_select_profile_type' | translate}}: {{selectedValue}} </p>
          <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutAlign="space-between"
            fxLayoutGap.md="10%" fxLayoutGap.lg="10%" fxLayoutGap.xs="0" fxLayoutGap.sm="0%">

            <div fxFlex="50%">
              <mat-form-field class="register" style="width:100% !important">
                <input matInput placeholder="NIT" formControlName="Nit" id="register-nit"
                  (ngModelChange)="activeButton= true" [readonly]="true" required>
              </mat-form-field>
            </div>
            <div fxFlex="50%">
              <mat-form-field class="register" style="width:100% !important;">
                <input matInput [placeholder]="'secure.seller.register_seller.lb_email_form' | translate"
                  formControlName="Email" [errorStateMatcher]="matcher" id="register-email"
                  (ngModelChange)="activeButton = false" (blur)="validateExist($event, 'Email')" required>
                <mat-hint></mat-hint>

                <mat-error id="error-email-required"
                  *ngIf="validateFormRegisterAdmin.get('Email').hasError('required')">
                  {{'errors.required' | translate}}</mat-error>
                <mat-error id="error-email-invalid" *ngIf="validateFormRegisterAdmin.get('Email').hasError('pattern')">
                  {{'errors.email_invalid' | translate}}</mat-error>
                <mat-error id="error-email-exist"
                  *ngIf="validateFormRegisterAdmin.get('Email').hasError('validExistEmailDB')">
                  {{'errors.email_exist' | translate}}</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutAlign="space-between"
            fxLayoutGap.md="10%" fxLayoutGap.lg="10%" fxLayoutGap.xs="0" fxLayoutGap.sm="0%">
            <div fxFlex="45%">
              <mat-form-field class="register" style="width:100% !important;">
                <input matInput [placeholder]="'secure.seller.register_seller.lb_name_form' | translate"
                  (blur)="validateExist($event, 'Name')" formControlName="Name" [errorStateMatcher]="matcher"
                  id="register-name-store" (ngModelChange)="activeButton= true" required>
                <mat-hint></mat-hint>
                <mat-error id="error-name-store-required"
                  *ngIf="validateFormRegisterAdmin.get('Name').hasError('required')">
                  {{'errors.required' | translate}}</mat-error>
                <mat-error id="error-name-store-invalid"
                  *ngIf="validateFormRegisterAdmin.get('Name').hasError('pattern')">
                  {{'errors.name_invalid' | translate}}</mat-error>
                <mat-error id="error-name-store-exist"
                  *ngIf="validateFormRegisterAdmin.get('Name').hasError('validExistNameDB')">
                  {{'errors.name_exist' | translate}}</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="container" fxLayoutAlign="center" fxLayoutGap="10px" fxLayoutGap.xs="0">
            <button id="register-send-form" class="button-seller-register" type="submit" mat-raised-button
              color="primary"
              [disabled]="!updateAdmin.form.valid || disabledForService || !activeButton || !updateAdmin.form.touched">{{'actions.update' | translate}}</button>
          </div>
        </form>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>