<h2 mat-dialog-title>{{'secure.load_guide_page.download_format.title' | translate}}</h2>
<mat-dialog-content>
  <form #f="ngForm" [formGroup]="myform" class="form-login">
    <div fxLayout="column" fxLayoutGap="20px">
      <div fxLayout="row" fxFlex="100">
        <mat-form-field fxFlex="100" class="input-style">
          <input name="limit" formControlName="limit" matInput
            [placeholder]="'secure.load_guide_page.download_format.ph_record_limit' | translate" type="number"
            required>
          <mat-error>{{'errors.error_limit_format' | translate}}</mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="dialog-buttons">


  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button class="sc-button" mat-button [mat-dialog-close]="true">{{'actions.cancel_min' | translate}}</button>

  <button class="sc-button" [disabled]="!f.form.valid" (click)="downloadInformationForGuide(myform)" mat-raised-button
    color="accent">{{'actions.download_orders' | translate}}
  </button>
</mat-dialog-actions>
