<section class="sidebar">
  <button class="menu-button" (click)="closeMatDialogRef()">
    <span class="material-symbols-rounded modules-options__icon">menu</span>
  </button>
  <a routerLink="/" (click)="closeMatDialogRef()">
    <img class="sidebar__seller-center-logo" src="assets/side-menu/header-side-menu.svg" alt="">
  </a>
  <div class="modules-options" *ngIf="user">
    <a routerLink="/securehome/seller-center/dashboard" routerLinkActive="active" class="module-options__single-option"
      (click)="closeMatDialogRef()">
      <span class="material-symbols-rounded modules-options__icon">home</span>
      <span class="modules-options__title">
        {{ "menu.Home" | translate}}
      </span>
    </a>
    <mat-accordion [multi]="false">
      <!-- <mat-expansion-panel id="ver-fulfillment" class="expansion-module" expanded="false" routerLinkActive="active">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="material-symbols-rounded modules-options__icon">rocket_launch</span>
            <span class="modules-options__title">
              Fulfillment
            </span>
          </mat-panel-title>
          <span class="material-symbols-rounded toggle-arrow">keyboard_arrow_down</span>
        </mat-expansion-panel-header>
  
        <mat-list-item class="option-list-item" id="ver-inscripción" [routerLink]="['/securehome/seller-center/fulfillment/inscripcion']"
          routerLinkActive="active" (click)="closeMatDialogRef()">
          Inscripción productos
        </mat-list-item>
      </mat-expansion-panel> -->
      <ng-container *ngFor="let module of modules">
        <mat-expansion-panel [id]="'ver-'+(('module.' + getPersonalityName(module.NameModule)) | translate)"
          class="expansion-module" expanded="false" *ngIf="showModule(module) && module.NameModule !== 'Escuela Exito'"
          routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="material-symbols-rounded modules-options__icon">{{getIconByName(module)}}</span>
              <span class="modules-options__title">
                {{('module.' + getPersonalityName(module.NameModule)) | translate}}
              </span>
              <span class="notification-point" *ngIf="showNotificationPoint(module)"></span>
            </mat-panel-title>
            <span class="material-symbols-rounded toggle-arrow">keyboard_arrow_down</span>
          </mat-expansion-panel-header>

          <ng-container *ngFor="let menu of module.Menus">
            <mat-list-item class="option-list-item" [id]="'ver-'+(('menu.' + menu.NameMenu) | translate)"
              [routerLink]="['/' + menu.UrlRedirect]" *ngIf="showMenu(menu)" routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }" (click)="closeMatDialogRef()">
              {{('menu.' + menu.NameMenu) | translate}}
              <span class="notification-count" *ngIf="showUnreadCases(menu.NameMenu)">{{unreadCases}}</span>
              <span class="notification-count" *ngIf="showDevolution(menu.NameMenu)">{{devolution}}</span>
            </mat-list-item>
            <a mat-list-item class="option-list-item"
              [id]="'ver-'+(('module.' + getPersonalityName(module.NameModule)) | translate)"
              href="{{menu.UrlRedirect}}" [id]="('menu.' + menu.NameMenu) | translate" *ngIf="showMenu(menu, true)"
              target="_blank" (click)="closeMatDialogRef()">
              {{('menu.' + menu.NameMenu) | translate}}
            </a>
          </ng-container>
        </mat-expansion-panel>
      </ng-container>
    </mat-accordion>
  </div>
  <img class="sidebar__grupo-exito-logo" src="assets/side-menu/grupo-exito.svg" alt="">

</section>