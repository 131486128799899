<div fxFlex fxLayout="column" fxFlex="100" class="content-product-detail">
  <div class="title-product-list">
    <div fxFlex="56px" [fxFlex.sm]="order.allowShipping === true ? '44px' : '16px'"
      [fxFlex.xs]="order.allowShipping === true ? '44px' : '16px'" class="sc-text-normal">
      <div class="center-product-list" *ngIf="Typeprofile === 0">
        <mat-checkbox class="sc-check-personal"
          *ngIf="order.allowShipping == true && order.idStatusOrder == const.OrderAsignado"
          [checked]="order.sendAllProduct" (change)="checkAllProductInOrder(order)"></mat-checkbox>
      </div>
    </div>
    <div fxFlex.sm="220px" fxFlex.md="220px" fxFlex.lg="220px" fxFlex.xs="220px" class="sc-text-normal">
      <div class="title-table-product">
        <p>{{'secure.orders.order_list.product_detail.product' | translate}}</p>
      </div>
    </div>
    <div fxFlex.sm="12" fxFlex.md="12" fxFlex.lg="12" fxFlex.xs="12" fxShow fxHide.xs="true" fxHide.sm="false"
      fxHide.md="false" fxHide.lg="false" fxHide.xl="false" class="sc-text-normal">
      <div class="title-table-product">
        <p>{{'shared.status' | translate}}</p>
      </div>
    </div>
    <div fxFlex.sm="12" fxFlex.md="12" fxFlex.lg="12" fxFlex.xs="12" fxShow fxHide.xs="true" fxHide.sm="true"
      fxHide.lt-md="true" fxHide.lg="false" fxHide.xl="false" class=" sc-text-normal">
      <div class="title-table-product">
        <p>{{'secure.orders.order_list.product_detail.guide' | translate}}</p>
      </div>
    </div>
    <div fxFlex.sm="12" fxFlex.md="12" fxFlex.lg="12" fxFlex.xs="12" fxShow fxHide.xs="true" fxHide.sm="true"
      fxHide.lt-md="true" fxHide.lg="false" fxHide.xl="false" class=" sc-text-normal">
      <div class="title-table-product">
        <p class="text-center">{{'secure.orders.order_list.product_order.conveyor' | translate}}</p>
      </div>
    </div>
    <div fxFlex.sm="10" fxFlex.md="10" fxFlex.lg="10" fxFlex.xs="10" fxShow fxHide.xs="true" fxHide.sm="true"
      fxHide.lt-md="true" fxHide.lg="false" fxHide.xl="false" class=" sc-text-normal text-center" >
      <div class="title-table-product">
        <p>{{'secure.orders.order_list.product_order.delivery_type' | translate}}</p>
      </div>
    </div>
    <div fxFlex.sm="10" fxFlex.md="10" fxFlex.lg="10" fxFlex.xs="10" fxShow fxHide.xs="true" fxHide.sm="false"
      fxHide.md="false" fxHide.lg="false" fxHide.xl="false" class="sc-text-normal text-center" >
      <div class="title-table-product">
        <p>{{'secure.orders.order_list.product_detail.cost' | translate}}</p>
      </div>
    </div>
    <div fxFlex.sm="10" fxFlex.md="10" fxFlex.lg="10" fxFlex.xs="10" fxShow fxHide.xs="true" fxHide.sm="false"
      fxHide.md="false" fxHide.lg="false" fxHide.xl="false" class="sc-text-normal text-center" >
      <div class="title-table-product">
        <p>{{'secure.orders.order_list.product_detail.freight' | translate}}</p>
      </div>
    </div>
    <div fxFlex.sm="10" fxFlex.md="10" fxFlex.lg="10" fxFlex.xs="10" fxShow fxHide.xs="true" fxHide.sm="true"
      fxHide.md="false" fxHide.lg="false" fxHide.xl="false" class=" sc-text-normal">
      <div class="title-table-product">
        <p class="padding-left-22" *ngIf="Typeprofile === 0">{{'shared.actions' | translate}}</p>
      </div>
    </div>
  </div>

  <div *ngFor="let product of order.products;let index=index" class="content-product-list">
    <div fxFlex="56px" [fxFlex.sm]="order.allowShipping === true ? '44px' : '16px'"
      [fxFlex.xs]="order.allowShipping === true ? '44px' : '16px'">
      <div class="center-product-list" *ngIf="Typeprofile === 0">
        <mat-checkbox id="check-product-detail-{{index}}" class="sc-check-personal"
          *ngIf="order.allowShipping == true && product.idStatusProduct == const.ProductAsignado"
          [disabled]="product.tracking != null" (change)="validateCheckProductForSendAll(product, order)"
          [(ngModel)]="product.checkProductToSend"></mat-checkbox>
      </div>
    </div>
    <div fxFlex.sm="220px" fxFlex.md="220px" fxFlex.lg="220px" fxFlex.xs="0">
      <div class="productName-table-option">
        <div class="product-name n-margin">
          {{product.nameProduct}}
          <p class="sc-text-bold-order n-margin"><span class="font-weight">{{'secure.orders.order_list.product_order.ref' | translate}}</span>:
            {{product.reference}}</p>
          <p class="sc-text-bold-order n-margin"><span class="font-weight">{{'secure.orders.order_list.product_order.sku' | translate}}:</span>
            {{product.sku}}</p>
          <p class="sc-text-bold-order n-margin"><span class="font-weight">{{'shared.ean' | translate}}</span>:
            {{product.ean}}</p>
          <p class="sc-text-bold-order n-margin" *ngIf="product.sellerSku"><span class="font-weight">{{'secure.orders.order_list.product_order.sku_seller' | translate}}:</span>
            {{product.sellerSku}}</p>
        </div>
      </div>
    </div>

    <div fxFlex.sm="12" fxFlex.md="12" fxFlex.lg="12" fxFlex.xs="12" fxShow fxHide.xs="true" fxHide.sm="false"
      fxHide.md="false" fxHide.lg="false" fxHide.xl="false">
      <div class=" mat-icon-status-order">

        <p class="sc-status-order-table" *ngIf="product.statusProduct == const.NameProductAsignado">
          <mat-icon [ngClass]="{'status-orange': product.statusProduct === const.NameProductAsignado}">notifications
          </mat-icon>
          {{'secure.orders.assigned.product.' + const.AppProductAsignado | translate}}
        </p>

        <p class="sc-status-order-table" *ngIf="product.statusProduct == const.NameProductEntregado">
          <mat-icon [ngClass]="{'status-orange': product.statusProduct === const.NameProductEntregado}">notifications
          </mat-icon>
          {{'secure.orders.delivered.product.' + const.AppProductEntregado | translate}}
        </p>

        <p class="sc-status-order-table" *ngIf="product.statusProduct == const.NameProductEnProcesoDeEnvio">
          <mat-icon [ngClass]="{'status-green': product.statusProduct === const.NameProductEnProcesoDeEnvio}">
            notifications
          </mat-icon>
          {{'secure.orders.sent.product.' + const.AppProductEnviado | translate}}
        </p>

        <p class="sc-status-order-table" *ngIf="product.statusProduct == const.NameOrderEnFacturadoEnviado">
          <mat-icon [ngClass]="{'status-green': product.statusProduct === const.NameOrderEnFacturadoEnviado}">
            notifications
          </mat-icon>
          {{'secure.orders.sent.' + const.AppOrderFacturadoEnviado | translate}}
        </p>
        <p class="sc-status-order-table" *ngIf="product.statusProduct == const.NameOrderCancelado">
          <mat-icon [ngClass]="{'status-green': product.statusProduct === const.NameOrderCancelado}">
            notifications
          </mat-icon>
          {{'secure.orders.sent.' + const.AppOrderCancelado | translate}}
        </p>
        <p class="sc-status-order-table" *ngIf="product.statusProduct == const.NameOrderPagadoCanceldo">
          <mat-icon [ngClass]="{'status-green': product.statusProduct === const.NameOrderPagadoCanceldo}">
            notifications
          </mat-icon>
          {{'secure.orders.sent.' + const.AppOrderPagadoCanceldo | translate}}
        </p>
        <p class="sc-status-order-table" *ngIf="product.statusProduct == const.NameOrderDevolucionPendienteValidacion">
          <mat-icon [ngClass]="{'status-green': product.statusProduct === const.NameOrderDevolucionPendienteValidacion}">
            notifications
          </mat-icon>
          {{'secure.orders.sent.' + const.AppOrderDevolucionPendienteValidacion | translate}}
        </p>
        <p class="sc-status-order-table" *ngIf="product.statusProduct == const.NameOrderDespachoInt">
          <mat-icon [ngClass]="{'status-green': product.statusProduct === const.NameOrderDespachoInt}">
            notifications
          </mat-icon>
          {{'secure.orders.sent.' + const.AppOrderDespachoInt | translate}}
        </p>
      </div>
    </div>
    <div fxFlex.sm="12" fxFlex.md="12" fxFlex.lg="12" fxFlex.xs="12" fxShow fxHide.xs="true" fxHide.sm="true"
      fxHide.lt-md="true" fxHide.lg="false" fxHide.xl="false">
      <div class=" margin-top-13">
        <span class="sc-text-order-international" *ngIf="!!product.tracking">
          Loc: <strong>{{product.tracking}}</strong>
        </span>
        <span class="sc-text-order-international" *ngIf="!!product.internationalTracking">
          Int: <strong>{{product.internationalTracking}}</strong>
        </span>
        <br>
        <span class="sc-text-order-international cursor-pointer" [matTooltip]="'secure.orders.order_list.order_page.date_tracking_guide' | translate" *ngIf="!!product.dateDelivery">
          <strong>{{product.dateDelivery | date}}</strong>
        </span>
      </div>
    </div>
    <div fxFlex.sm="12" fxFlex.md="12" fxFlex.lg="12" fxFlex.xs="12" fxShow fxHide.xs="true" fxHide.sm="true"
      fxHide.lt-md="true" fxHide.lg="false" fxHide.xl="false" class="text-center">
      <div>
      <button mat-raised-button *ngIf="canShowShippingButton(product)"
              id="redirect-to-genesis-order-{{order.orderNumber}}-product-{{product.id}}"
              (click)="redirectToGenesis()"
              class="sc-default-button"
              color="primary"
              >{{ getScheduleShippingButtonLabel() | translate }}
      </button>
        <p class="sc-text-order-carrier" *ngIf="canShowShippingCompany(product)">Loc: {{product.carrier}}</p>
        <p class="sc-text-order-carrier" *ngIf="!product.carrier && product.tracking && isInternational===true">Loc:
          INTERNATIONAL SHIPPING</p>
        <p class="sc-text-order-carrier" *ngIf="!!product.internationalCarrier">Int: {{product.internationalCarrier}}
        </p>
      </div>
    </div>
    <div fxFlex.sm="11" fxFlex.md="11" fxFlex.lg="11" fxFlex.xs="11" fxShow fxHide.xs="true" fxHide.sm="true"
      fxHide.lt-md="true" fxHide.lg="false" fxHide.xl="false">
      <div class="text-center">
        <p class="fulfillment" *ngIf="product.fulfillment == true">
          {{'secure.orders.order_list.product_order.fulfillment' | translate}}</p>
        <p class="sc-text-order" *ngIf="product.enviosExito == true">
          {{'secure.offers.historical_admin.historical_admin.shiping_exito' | translate}}</p>
        <p class="sc-text-order" *ngIf="product.fulfillment == false && product.enviosExito == false">
          {{'secure.orders.order_list.product_order.standar' | translate}}</p>
      </div>
    </div>
    <div fxFlex.sm="10" fxFlex.md="10" fxFlex.lg="10" fxFlex.xs="10" fxShow fxHide.xs="true" fxHide.sm="false"
      fxHide.md="false" fxHide.lg="false" fxHide.xl="false">
      <div class="text-center">
        <p class="sc-text-normal">${{product.costProduct | number}}</p>
      </div>
    </div>
    <div fxFlex.sm="10" fxFlex.md="10" fxFlex.lg="10" fxFlex.xs="10" fxShow fxHide.xs="true" fxHide.sm="false"
      fxHide.md="false" fxHide.lg="false" fxHide.xl="false">
      <div class="text-center">
        <p class="sc-text-normal">${{product.costShippingProduct | number}}</p>
      </div>
    </div>
    <div class="item-action-table" *ngIf="Typeprofile === 0">
      <button id="dropdown-product-order-{{index}}" color="primary" class="button-toolbar" mat-button
        [matMenuTriggerFor]="orderOptions">
        <mat-icon>arrow_drop_down</mat-icon>
        <span>{{'shared.actions' | translate}}</span>
      </button>
      <mat-menu #orderOptions="matMenu">
        <button id="send-product-inDetail-{{index}}"
          *ngIf="order.allowShipping == true && product.idStatusProduct == const.ProductAsignado"
          (click)="openDialogSendOrder(order,true)" mat-menu-item>
          <mat-icon >local_shipping</mat-icon>
          <span>{{'actions.send' | translate}}</span>
        </button>
        <button id="dropdown-product-viewDetail-{{index}}" (click)="openModalDetailProduct(product)" fxShow.xs="true"
          fxShow.sm="true" fxShow.gt-lg="false" mat-menu-item>
          <mat-icon>more_vert</mat-icon>
          <span>{{'actions.show_detail' | translate}}</span>
        </button>
      </mat-menu>
    </div>
  </div>

  <div fxLayout="row wrap" fxLayoutAlign.xs="center space-evenly" fxLayoutGap.xs="17px">
    <div fxFlex.xs="initial" fxFlex.sm="96px" fxFlex.md="230px" fxFlex.lg="873px" fxFlexOffset.sm="34px">
      <div class="total-product-text container-text-total" >
        <p class="sc-text-bold-order total-container" >
          {{'secure.orders.order_list.product_order.total' | translate}}:</p>
      </div>
    </div>
    <div fxFlex.xs="initial" fxFlex.sm="127px" fxFlex.md="230px" fxFlex.lg="127px">
      <div class="text-center">
        <p>
          <span class="cost-item-table" fxShow fxHide.xs="false" fxHide.sm="false" fxHide.lt-md="true" fxHide.lg="true"
            fxHide.xl="true">{{'secure.orders.order_list.product_detail.cost' | translate}}:</span>
          <strong class="sc-text-normal">${{order.costTotalOrder | number}}</strong>
        </p>
      </div>
    </div>
    <div fxFlex.xs="initial" fxFlex.sm="127px" fxFlex.md="230px" fxFlex.lg="127px">
      <div class="text-center">
        <p>
          <span class="cost-item-table" fxShow fxHide.xs="false" fxHide.sm="false" fxHide.lt-md="true" fxHide.lg="true"
            fxHide.xl="true">
            {{'secure.orders.order_list.product_detail.freight' | translate}}:</span>
          <strong class="sc-text-normal">${{order.costTotalShipping | number}}</strong>
        </p>
      </div>
    </div>
    <div fxFlex.xs="initial" fxFlex.sm="127px" fxFlex.md="230px" fxFlex.lg="127px">
      <div *ngIf="order.allowShipping == true && order.idStatusOrder == const.OrderAsignado" class="left-Button"
        fxFlexOffset.lg="40%" fxFlexOffset.xl="80%" fxFlex.xl="140px" fxFlex.lg="140px" fxFlex.sm="140px"
        fxFlex.md="140px" fxFlex.xs="initial">
        <div *ngIf="Typeprofile === 0">
          <button id="send-from-product-order-{{order.orderNumber}}" (click)="openDialogSendOrder(order)"
            [disabled]="!order.sendAllProduct" class="full-button sc-default-button sc-button" mat-raised-button
            color="accent">{{'actions.send_all' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
