export const menuIcons = {
    'ordenes': 'package_2',
    'ordenes2': 'package_2',
    'ofertas': 'sell',
    'productos': 'sell',
    'devoluciones': 'contact_support',
    'documentacion': 'article',
    'parametrizacion': 'settings',
    'vendedores': 'person',
    'reclamaciones': 'contact_support',
    'reportes': 'description',
    'calidad': 'person',
    'dispersion': 'attach_money',
    'productos-y-ofertas': 'sell',
    'reclamaciones-y-devoluciones': 'contact_support',
    'finanzas': 'attach_money',
    'vendedor': 'person',
    'fulfillment': 'rocket_launch',
};