import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CoreState } from '@app/store';
import { SetHeaderTitle } from '@app/store/configuration/actions';
import { Store } from '@ngrx/store';

@Component({
    selector: 'app-manage',
    templateUrl: './manage.component.html',
    styleUrls: ['./manage.component.scss']
})
export class ManageComponent implements OnInit {

    parameters: any;
    chargueView = false;

    constructor(
        private route: ActivatedRoute,
        private store: Store<CoreState>
    ) { }

    ngOnInit(): void {
        this.setConfigurationTitle();
        this.route.params.subscribe(data => {
            if (data && data.id) {
                this.parameters = { IdSeller: data.id };
            }
            this.chargueView = true;
        });
    }

    setConfigurationTitle() {
        this.store.dispatch(new SetHeaderTitle({
            title: 'module.Vendedor',
            subtitle: 'menu.Administrar',
            description: ''
        }));
    }
}
