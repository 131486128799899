<article id="shipping-methods">
  <h4>{{'secure.offers.quoting.administrator.shipping_methods.title' | translate}}</h4>

  <aside fxLayout="row" fxLayoutAlign="space-between center">

    <!-- List with shiping methods -->
    <div class="shipping-methods" fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="start start" fxLayoutAlign="center center"
      *ngFor="let shippingMethod of shippingMethodsList">
      <mat-icon>{{shippingMethod.Icon}}</mat-icon>
      <p [id]="shippingMethod.Id">{{shippingMethod.Name}}</p>
    </div>

  </aside>
</article>