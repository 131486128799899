<mat-toolbar class="ticket-toolbar">
  <mat-toolbar-row class="toolbarIpad">
    <div *ngIf="isAdmin" class="widthToolbar">
      <app-search-seller id="contet-searc-store" class="search-content">
      </app-search-seller>
    </div>
    <div class="toolbar-content">
      <mat-sidenav-container>
        <mat-sidenav-content class="toolbar-right">
          <div class="toolbar-right--item toolbar-item--vertical-center">
            <mat-paginator #paginator [length]="length" [pageIndex]="pageIndex" [pageSize]="pageSize"
              [pageSizeOptions]="[5, 10, 25, 50, 100]">
            </mat-paginator>
          </div>
        </mat-sidenav-content>
      </mat-sidenav-container>
    </div>
  </mat-toolbar-row>
</mat-toolbar>