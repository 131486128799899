<section class="uploaded-images">
  <p class="uploaded-images__title">
    {{ 'secure.products.unit_product_creation.upload_images.title' | translate }}
  </p>
  <p class="upload-images__description">
    {{ 'secure.products.unit_product_creation.upload_images.description' | translate }}
  </p>
  <div class="uploaded-images__images-wrapper">
    <article class="image-element" *ngFor="let image of uploadedImages | slice:0:showNImages;index as idx">
      <span class="image-element__order-number">{{idx + 1}}.</span>
      <img [src]="image.url" alt="" class="image-element__image">
      <div class="image-element__information">
        <span class="image-element__name">
          {{ getImageName(image.url)}}
        </span>
        <span class="image-element__son">
          {{ 'Hijo ' + image.sonIndex}}
        </span>
      </div>
    </article>
  </div>
  <button class="uploaded-images__see-more" *ngIf="nImages < uploadedImages.length">
    <span class="material-symbols-rounded uploaded-images__see-more-icon">
      {{ showMore ? 'add' : 'remove' }}
    </span>
    <span class="uploaded-images__see-more-text" (click)="toggleShowMore()">
      {{ (showMore ? 'profile.notifications.show-more' : 'profile.notifications.show-less') | translate}}
    </span>
  </button>
</section>