import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';

const YOUTUBE_URL_REGEXP = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;

@Component({
  selector: 'app-upload-product-video-url',
  templateUrl: './upload-product-video-url.component.html',
  styleUrls: ['./upload-product-video-url.component.scss']
})
export class UploadProductVideoUrlComponent implements OnInit {
  @Input() videoUrl: string;
  @Output() uploadVideo: EventEmitter<string> = new EventEmitter();
  videoId: string;
  videoIdInput: FormControl;

  constructor() {
    this.videoIdInput = new FormControl(null,
      [Validators.pattern(YOUTUBE_URL_REGEXP)]);
  }

  ngOnInit(): void {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    document.body.appendChild(tag);
    this.handleInputChange();
    if (this.videoUrl) {
      this.videoIdInput.setValue(this.videoUrl);
    }
  }


  handleInputChange(): void {
    this.videoIdInput.valueChanges.subscribe((value: string) => {
      if (this.videoIdInput.valid) {
        const match = value.match(YOUTUBE_URL_REGEXP);
        this.videoId = (match && match[7].length === 11) ? match[7] : '';
        this.uploadVideo.emit(value);
      } else {
        this.videoId = null;
      }
    });
  }

}
