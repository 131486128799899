<mat-drawer-container class="drawer-container allContainer" [hasBackdrop]="true">
  <mat-drawer #drawer [mode]="'over'" [position]="'end'" class="example-sidenav" [disableClose]="false">
    <form class="basic-info" #createfilterProduts="ngForm" [formGroup]="filterProduts" class="seller-filter-sidenav"
      *ngIf="filterProduts">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <h3>{{'secure.products.create_product_unit.list_products.product_filter' | translate}}</h3>
        <button id="closeMenuApplication" type="button" color="primary" [matTooltip]="'actions.close' | translate"
          matTooltipPosition="below" mat-icon-button mat-button (click)="drawer.toggle() && closeFilter()">
          <mat-icon>clear</mat-icon>
        </button>
      </div>

      <div fxLayout="column" fxLayoutAlign="space-between" fxLayout.xs="column">
        <mat-form-field color="primary">
          <input matInput [placeholder]="'secure.products.create_product_unit.list_products.product_name' | translate"
            formControlName="productName" [errorStateMatcher]="matcher">
          <mat-hint></mat-hint>
          <mat-error *ngIf="filterProduts.get('productName').hasError('pattern')">
            {{'secure.products.create_product_unit.list_products.product_name_invalid' |translate}}
          </mat-error>
          <mat-error *ngIf="filterProduts.get('productName').hasError('maxlength')">
            {{'errors.error_product_length' | translate}}
          </mat-error>
        </mat-form-field>
        <mat-form-field color="primary">
          <input matInput [placeholder]="'shared.ean' | translate" formControlName="ean" [errorStateMatcher]="matcher">
          <mat-hint></mat-hint>
        </mat-form-field>
        <!-- Nuevo campo PLU VTEXT -->
        <mat-form-field color="primary">
          <input matInput [placeholder]="'secure.products.bulk_upload.filter.plu' | translate" formControlName="pluVtex"
            (keyup.enter)="saveSomePlus()" [errorStateMatcher]="matcher">
          <mat-icon matSuffix [matTooltip]="'secure.products.create_product_unit.list_products.add_plus' | translate"
            class="material-icons add-keyword-icon vertical-align-top" (click)="saveSomePlus()">
            add_circle_outline
          </mat-icon>
        </mat-form-field>
        <div [ngClass]="keyPlus.length > 0 ? 'example-chip-list-filters': example-chip-list">
          <mat-chip-list #chipList>
            <mat-chip *ngFor="let key of keyPlus; let i = index" (removed)="deleteKeyPlus(i)">
              {{key}}
              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
          </mat-chip-list>
        </div>
        <!-- Nuevo campo SellerSKU -->
        <mat-form-field color="primary">
          <input matInput [placeholder]="'secure.offers.bulk_upload.table_load.sellerSku' | translate"
            formControlName="sellerSku" [errorStateMatcher]="matcher">
          <mat-hint></mat-hint>
          <mat-error *ngIf="filterProduts.get('sellerSku').hasError('pattern')">
            {{'secure.products.create_product_unit.list_products.product_name_invalid' | translate}}
          </mat-error>
        </mat-form-field>

        <!-- Nuevo campo Referencia padre -->
        <mat-form-field color="primary">
          <input matInput [placeholder]="'secure.products.table_load_product.parent_reference' | translate"
            formControlName="reference" [errorStateMatcher]="matcher">
          <mat-hint></mat-hint>
          <mat-error *ngIf="filterProduts.get('reference').hasError('pattern')">
            {{'errors.format_invalidate' | translate}}
          </mat-error>
        </mat-form-field>

        <div>
          <mat-radio-group formControlName="creationDate" [(ngModel)]="optionDates" fxLayout="row"
            fxLayoutAlign="space-between center" fxLayout.xs="column" fxLayoutAlign.xs="space-between" class="radioDate"
            (change)="changeDates()">
            <mat-radio-button value="createDate">
              {{'secure.products.create_product_unit.list_products.creation_date' | translate}}</mat-radio-button>
            <mat-radio-button value="updateDate">
              {{'secure.products.create_product_unit.list_products.modification_date' | translate}}</mat-radio-button>
          </mat-radio-group>
          <mat-error *ngIf="filterProduts.controls.creationDate.hasError('date')">
            {{'secure.products.create_product_unit.list_products.ending_date' | translate}}
          </mat-error>
        </div>
        <div *ngIf="optionDates">
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <mat-form-field class="classDatapicker">
              <input matInput [matDatepicker]="picker"
                [placeholder]="'core.shell.search_order_menu.search_order_form.from' | translate" disabled
                formControlName="initialDate">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker disabled="false"></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="classDatapicker">
              <input matInput [matDatepicker]="picker1"
                [placeholder]="'core.shell.search_order_menu.search_order_form.to' | translate" disabled
                formControlName="finalDate">
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1 disabled="false"></mat-datepicker>
            </mat-form-field>
          </div>
        </div>

        <!-- Nuevo campo CATEGORIA-->
        <mat-form-field color="primary">
          <input matInput [placeholder]="'secure.parametize.dialog.modal_placeholder_category' | translate"
            [matAutocomplete]="auto" formControlName="category" (keyup.enter)="saveKeyword()">
          <mat-hint></mat-hint>
          <mat-error *ngIf="filterProduts.get('category').hasError('pattern')">
            {{'secure.parametize.dialog.category_invalid' | translate}}
          </mat-error>
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let category of listCategories2" [value]="category.Name">
              {{category.Name}}
            </mat-option>
          </mat-autocomplete>
          <mat-icon matSuffix [matTooltip]="'secure.products.list_products.download.tooltip_category' | translate"
            [ngClass]="invalidCategory ? 'disabledIconAddKeyCategoryFilter': ''"
            class="material-icons add-keyword-icon vertical-align-top" (click)="saveKeyword()">
            add_circle_outline
          </mat-icon>

        </mat-form-field>
        <div [ngClass]="keywords.length > 0 ? 'example-chip-list-filters': example-chip-list">
          <mat-chip-list #chipList>
            <mat-chip *ngFor="let key of keywords; let i = index" (removed)="deleteKeywork(i)">
              {{key}}
              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
          </mat-chip-list>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between center" class="buttonsSend">
          <button mat-stroked-button color="primary" type="button" (click)="cleanAllFilter()">{{'filters.clear' |
            translate}} </button>
          <!-- <button mat-flat-button color="primary" type="submit" [innerHTML]="'Filtrar'" [disabled]="!filterSeller.form.valid || (!product.value && !ean.value && !stock.value)"></button> -->
          <button mat-flat-button color="primary" type="submit" (click)="filterApply(drawer)"
            [disabled]="!createfilterProduts.form.valid || !createfilterProduts.form.touched">{{'filters.filter' |
            translate}}</button>
        </div>
      </div>
    </form>
  </mat-drawer>

  <div class="height-full">
    <mat-toolbar id="matToolbar" class="tree-toolbar">
      <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
        (page)="pageEvent = changePaginatorProducts($event)"></mat-paginator>

      <!--  Descargar productos admin -->
      <button mat-button (click)="openDialogDownloadProducts()" *ngIf="isAdmin">
        <mat-icon>archive</mat-icon>
        <small>{{'permissions.Descargar' | translate}}</small>
      </button>

      <!--  Descargar productos vendedor -->
      <button mat-button (click)="openDialogDownloadProductsSeller()" *ngIf="!isAdmin && showButtonDownload === true">
        <mat-icon>archive</mat-icon>
        <small>{{'permissions.Descargar' | translate}}</small>
      </button>

      <!--  Filtro -->
      <button type="button" mat-button (click)="toggle(showFilter)">
        <mat-icon>filter_list</mat-icon>
        <small class="sub-title-filter">{{'filters.filter' | translate}}</small>
      </button>
    </mat-toolbar>
  </div>
  <div fxLayout="column">
    <div class="example-chip-list">
      <mat-chip-list #chipList>
        <mat-chip *ngFor="let productsFilter of listFilterProducts" [selectable]="selectable" [removable]="removable"
          (removed)="remove(productsFilter)">
          {{productsFilter.name}}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <input [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" disabled>
      </mat-chip-list>
    </div>

    <div class="prodList">
      <div *ngIf="myProduct && !activeCheck && !hideButtonDelete" class="divDelete">
        <button mat-stroked-button color="primary" [matMenuTriggerFor]="menu" class="buttonDelete">
          {{'secure.products.create_product_unit.list_products.delete_products' | translate}}</button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item color="primary" class="buttonSelected"
            (click)="activeMultipleOffer()">{{'secure.products.create_product_unit.list_products.delete_products_some' |
            translate}}</button>
          <button mat-menu-item color="primary" class="buttonSelected"
            (click)="allProductsSelected()">{{'secure.products.create_product_unit.list_products.delete_products_all' |
            translate}}</button>
        </mat-menu>
      </div>

      <div *ngIf="activeCheck" class="divDelete">
        <button mat-stroked-button color="primary" class="buttonSelectedSome" (click)="someProductsSelected()">
          <span>{{'secure.products.create_product_unit.list_products.delete_products_some_checked' |
            translate}}</span></button>
      </div>

      <app-combo-product [offerPermission]="offerPermission" [editPermission]="editPermission"
        (viewDetailProduct)="viewDetailProduct($event)" (countPlu)="countPlu($event)" [showProducts]="showProducts"
        [productsList]="productsList" class="listCombo" (reloadData)="reloadData();"
        [deletePermission]="deletePermission" [isAdmin]="isAdmin" [activeCheck]="activeCheck"></app-combo-product>
      <div *ngIf="!productsList.length" fxLayout="column" class="no-products">
        <div class="icon-face">
          <mat-icon class="mat-18">layers_clear</mat-icon>
        </div>
        <label class="icon-face-text">
          {{'secure.products.create_product_unit.list_products.are_no_uploades_products' | translate}}
        </label>
      </div>
    </div>
  </div>
</mat-drawer-container>