<form #createOfertExpandetProduct="ngForm" [formGroup]="ofertProduct" *ngIf="ofertProduct" fxLayout.xs="column" class="form-ofert">
  <div fxLayout="row" fxLayoutAlign="space-around center" fxLayout.xs="column">
    <div fxFlex="28%" class="ofert-information">
      <mat-form-field color="primary" fxFlex="80%">
        <mat-icon matPrefix>list_alt</mat-icon>
        <input matInput [placeholder]="'secure.offers.historical_admin.historical_admin.stock' | translate" formControlName="Stock" [errorStateMatcher]="matcher">
        <mat-hint></mat-hint>
        <mat-error *ngIf="ofertProduct.get('Stock').hasError('pattern')">{{'secure.products.create_product_unit.list_products.ofert_product.stock_invalid' | translate}}</mat-error>
        <mat-error *ngIf="ofertProduct.get('Stock').hasError('required')">{{'secure.products.create_product_unit.list_products.ofert_product.stock_mandatory' | translate}}</mat-error>
      </mat-form-field>
    </div>
    <div fxFlex="28%" class="ofert-information">
      <mat-form-field color="primary" fxFlex="80%">
        <mat-icon matPrefix>attach_money</mat-icon>
        <input matInput [placeholder]="'secure.offers.historical_admin.historical_admin.price' | translate" formControlName="Price" [errorStateMatcher]="matcher" (blur)="getVerifyPrice()">
        <mat-hint></mat-hint>
        <mat-error *ngIf="ofertProduct.get('Price').hasError('pattern')">{{'errors.format_invalidate' | translate}}</mat-error>
        <mat-error *ngIf="ofertProduct.get('Price').hasError('required')">{{'secure.products.create_product_unit.list_products.ofert_product.price_mandatoy' | translate}}</mat-error>
        <mat-error *ngIf="ofertProduct.controls.Price.hasError('priceReal')">
            {{'secure.products.create_product_unit.list_products.ofert_product.price_higher_product' | translate}} {{sellerMinPrice}}
        </mat-error>
        <mat-error *ngIf="ofertProduct.get('Price').hasError('notCero')">
          {{'errors.price_cannot_be_0' | translate}}
      </mat-error>
      </mat-form-field>
    </div>
    <div fxFlex="28%" class="ofert-information">
      <mat-form-field color="primary" fxFlex="80%">
        <mat-icon matPrefix>attach_money</mat-icon>
        <input matInput [placeholder]="'secure.offers.historical_admin.historical_admin.discount_price' | translate" formControlName="DiscountPrice" [errorStateMatcher]="matcher" (blur)="getVerifyPrice()">
        <mat-hint></mat-hint>
        <mat-error *ngIf="ofertProduct.get('DiscountPrice').hasError('pattern')">{{'errors.format_invalidate' | translate}}</mat-error>
        <mat-error *ngIf="ofertProduct.controls.DiscountPrice.hasError('price')">
            {{'secure.products.create_product_unit.list_products.ofert_product.discount_price_higher' | translate}} {{sellerMinPrice}}
        </mat-error>
        <mat-error *ngIf="ofertProduct.get('DiscountPrice').hasError('notCero')">
          {{'errors.discounted_price_cannot_be_0' | translate}}
      </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-around center" fxLayout.xs="column">
    <div fxFlex="28%" class="ofert-information">
      <mat-form-field color="primary" fxFlex="80%">
        <mat-icon matPrefix>attach_money</mat-icon>
        <input matInput [placeholder]="'secure.products.create_product_unit.list_products.ofert_product.shipping_cost' | translate" formControlName="IsFreightCalculator" [errorStateMatcher]="matcher">
        <mat-hint></mat-hint>
        <mat-error *ngIf="ofertProduct.get('IsFreightCalculator').hasError('pattern')">{{'errors.format_invalidate' | translate}}</mat-error>
        <mat-error *ngIf="ofertProduct.get('IsFreightCalculator').hasError('required')">{{'secure.products.create_product_unit.list_products.ofert_product.shipping_cost_mandatory' | translate}}</mat-error>
      </mat-form-field>
    </div>
    <div fxFlex="28%" class="ofert-information">
      <mat-form-field color="primary" fxFlex="80%">
        <mat-icon matPrefix>local_shipping</mat-icon>
        <input matInput [placeholder]="'secure.offers.historical_admin.historical_admin.promise' | translate" formControlName="PromiseDelivery" [errorStateMatcher]="matcher" (keyup)="validatePromiseDeliveri()">
        <mat-hint></mat-hint>
        <mat-error *ngIf="ofertProduct.get('PromiseDelivery').hasError('pattern')">{{'secure.products.create_product_unit.list_products.ofert_product.promise_invalid' | translate}}</mat-error>
        <mat-error *ngIf="ofertProduct.get('PromiseDelivery').hasError('required')">{{'secure.products.create_product_unit.list_products.ofert_product.promise_mandatoy' | translate}}</mat-error>
        <mat-error *ngIf="ofertProduct.get('PromiseDelivery').hasError('startIsGreaterThanEnd')">{{'secure.products.create_product_unit.list_products.ofert_product.promise_higher' | translate}}</mat-error>
      </mat-form-field>
    </div>
    <div fxFlex="28%" class="ofert-information">
      <mat-form-field color="primary" fxFlex="80%">
        <mat-select formControlName="Periodicity" class="color-select-periodicity" [disabled]="IsInternational">
          <mat-option value="1">{{'secure.offers.historical_admin.historical_admin.day' |
            translate}}</mat-option>
          <mat-option value="2">{{'secure.offers.historical_admin.historical_admin.hours'|
            translate}}</mat-option>
        </mat-select>
        <mat-hint></mat-hint>
        <mat-error *ngIf="ofertProduct.get('PromiseDelivery').hasError('required')">{{'secure.products.create_product_unit.list_products.ofert_product.promise_mandatoy' | translate}}</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-around center" fxLayout.xs="column">
    <div fxFlex="28%" class="ofert-information">
      <mat-form-field color="primary" fxFlex="80%">
        <mat-icon matPrefix>beenhere</mat-icon>
        <input matInput [placeholder]="'secure.offers.historical_admin.historical_admin.warranty' | translate" formControlName="Warranty" [errorStateMatcher]="matcher">
        <mat-hint></mat-hint>
        <mat-error *ngIf="ofertProduct.get('Warranty').hasError('pattern')">{{'secure.products.create_product_unit.list_products.ofert_product.invalid_warranty' | translate}}</mat-error>
        <mat-error *ngIf="ofertProduct.get('Warranty').hasError('required')">{{'secure.products.create_product_unit.list_products.ofert_product.mandatory_warranty' | translate}}</mat-error>
      </mat-form-field>
    </div>
    <div fxFlex="28%" class="ofert-information">
      <mat-form-field color="primary" fxFlex="80%">
        <mat-label>{{'secure.offers.historical_admin.historical_admin.currency' | translate}}</mat-label>
        <mat-select name="Currency" formControlName="Currency">
              <mat-option value="USD">USD</mat-option>
              <mat-option value="COP">COP</mat-option>
        </mat-select>
    </mat-form-field>
    </div>
    <div fxFlex="28%" class="ofert-information">
      <mat-form-field color="primary" fxFlex="80%">
        <mat-icon matPrefix>dehaze</mat-icon>
        <input matInput [placeholder]="'secure.offers.bulk_upload.table_load.sellerSku' | translate" formControlName="SellerSku" [errorStateMatcher]="matcher">
        <mat-hint></mat-hint>
        <mat-error *ngIf="ofertProduct.get('SellerSku').hasError('pattern')">{{'secure.offers.bulk_upload.table_error.sellerSku' | translate}}</mat-error>
      </mat-form-field>
    </div>
  </div>


  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-evenly center" class="ofert-option">
    <div class="optionsSend">
      <mat-radio-group formControlName="ofertOption">
        <mat-radio-button value="IsFreeShipping" class="radio-ofert">{{'secure.offers.historical_admin.historical_admin.free_shipping' | translate}}</mat-radio-button>
        <mat-radio-button value="IsEnviosExito" class="radio-ofert">{{'secure.products.create_product_unit.list_products.ofert_product.envios_exito_indicator' | translate}}</mat-radio-button>
        <mat-radio-button value="IsFreightCalculator" class="radio-ofert">{{'secure.offers.historical_admin.historical_admin.freight_calculator' | translate}}</mat-radio-button>
        <mat-radio-button value="IsLogisticsExito" class="radio-ofert">{{'secure.offers.historical_admin.historical_admin.exito_logistic' | translate}}</mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="optionsUpdate">
      <mat-slide-toggle formControlName="IsUpdatedStock" (change)="alertSellerByReference($event)">
          {{changeCopyUpdateStock}}
      </mat-slide-toggle>
      <mat-slide-toggle formControlName="OfferByReference" (change)="alertSellerByReference($event)" *ngIf="applyOffer.eanesCombos.length === 0 && applyOffer.reference">
        {{'secure.products.create_product_unit.list_products.ofert_product.reference_update' | translate}}
    </mat-slide-toggle>
    </div>
  </div>

  <!--Aqui van los componentes del producto si es Combo-->
  <div class="container" fxLayout.xs="column" fxLayoutAlign.xs="space-around center" *ngIf="applyOffer.eanesCombos.length !== 0">
    <div class="ofert-component">
      <ng-container class="container-sub" *ngFor="let comp of Combos.controls">
        <div class="info-product" fxShow.xs="true">
          <ng-container [formGroup]="comp">
            <div  class="style-text">
              <h3>{{comp.get('nameCombo').value}}</h3>
            </div>
            <div class="ofert-information">
              <mat-form-field color="primary">
                <mat-icon matPrefix>attach_money</mat-icon>
                <input (change)="getPriceDescount()" matInput [placeholder]="'secure.offers.historical_admin.historical_admin.price' | translate" formControlName="ofertPriceComponet" [errorStateMatcher]="matcher">
                <mat-hint></mat-hint>
                <mat-error *ngIf="comp.get('ofertPriceComponet').hasError('required')">{{'secure.products.create_product_unit.list_products.ofert_product.mandatory_price_ean_combo' | translate}}</mat-error>
                <mat-error *ngIf="comp.get('ofertPriceComponet').hasError('pattern')">{{'secure.products.create_product_unit.list_products.ofert_product.invalid_price_ean_combo' | translate}}</mat-error>
              </mat-form-field>
            </div>
            <div class="ofert-information">
              <mat-form-field color="primary">
                <mat-icon matPrefix>add_shopping_cart</mat-icon>
                <input (change)="getPriceDescount()" matInput [placeholder]="'secure.offers.bulk_upload.table_load.quantity_combo' | translate" (blur)="getVerifyPrice()" formControlName="ComboQuantity" [errorStateMatcher]="matcher">
                <mat-hint></mat-hint>
                <mat-error *ngIf="comp.get('ComboQuantity').hasError('required')">{{'secure.products.create_product_unit.list_products.ofert_product.mandatory_amount_ean_combo' | translate}}}</mat-error>
                <mat-error *ngIf="comp.get('ComboQuantity').hasError('pattern')">{{'secure.products.create_product_unit.list_products.ofert_product.invalid_amount_ean_combo' | translate}}</mat-error>
              </mat-form-field>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="center" class="buttonsSend">
    <button mat-stroked-button color="primary" type="button" [innerHTML]="'actions.cancel_min' | translate" (click)="cleanFilter()"></button>
    <button mat-flat-button color="primary" type="submit" [innerHTML]="'permissions.Ofertar' | translate"  [disabled]="!createOfertExpandetProduct.form.valid || showButton === true" (click)="sendDataToService(0)"></button>
  </div>
</form>