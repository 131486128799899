<button class="bleft" mat-icon-button *ngIf="productsExpanded && showProducts"  (click)="backTolist()">
  <mat-icon class="material-icons">
    arrow_back
  </mat-icon>
  {{'secure.products.create_product_unit.list_products.combo_product.back' | translate}}
</button>


<div class="cardImageAling" fxLayout.xs="column" fxLayoutAlign.xs="space-between center" *ngFor="let products of _listProduct; let i = index ">
  <div class="checkSelected" *ngIf="activeCheck">
    <mat-checkbox type="checkbox" [checked]="products.checked" (change)="onvalueCheckdesactiveProducts(products)">{{'secure.offers.list.list.select_me' | translate}}
    </mat-checkbox>
  </div>
  <div (click)="openInformation(products.ean)" *ngIf="showImage === false">
    <mat-card class="example-card">
      <div class="container-card-img flexbox align-center justify-center">
        <img src="{{products.urlImage}}">
        <!--<img mat-card-image src="https://d2dctyer3ngjhx.cloudfront.net/images/products/002/MP02730000000002/SKUMP02730000002_bg_a.jpg">-->
      </div>
      <mat-card-content>
        <div class="container-desc-card flexbox flex-wrap align-center">
          <h3>EAN: {{products.ean}}</h3>
          <h3>PLU: {{products.pluVtex}}</h3>
          <p class="name">{{products.name}}</p>
        </div>
        <mat-card-footer class="mfooter">
            <p>{{'secure.products.create_product_unit.list_products.combo_product.creation_date' | translate}}: {{products.createdDate}}</p>
            <p>{{'secure.products.create_product_unit.list_products.combo_product.modification_date' | translate}}: {{products.updatedDate}}</p>
        </mat-card-footer>
      </mat-card-content>
      <div class="best-offer" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="products.bestOffer !== '0.00'">
        <p>{{'secure.products.create_product_unit.list_products.combo_product.best_offer' | translate}}: </p>
        <p [innerHTML]="products.bestOffer | currency: '$' : 'symbol' : '1.2-2'"> </p>
      </div>
    </mat-card>
  </div>
</div>


<div class="full-width">
  <app-expanded-product [offerPermission]="offerPermission" [editPermission]="editPermission" *ngIf="productsExpanded && showProducts" [productsExpanded]="productsExpanded" (reloadDataListProduct)="reloadDataListProduct();" [deletePermission]="deletePermission" [isAdmin] ="isAdmin"></app-expanded-product>
</div>
