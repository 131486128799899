import { Component, EventEmitter, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { DialogWithFormComponent } from '@app/shared/components/dialog-with-form/dialog-with-form.component';
import { DateFormat } from '@app/shared/util/date-format';
import { CoreState } from '@app/store';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { DateService } from '@app/shared/util/date.service';
import { LoadingService, ModalService } from '@app/core/global';
import { StoresService } from '@app/secure/offers/stores/stores.service';
import { UserLoginService } from '@app/core/aws-cognito';
import { Router } from '@angular/router';
import { RoutesConst } from '@app/shared';
import { MyProfileService } from '@app/secure/aws-cognito/profile/myprofile.service';
import moment from 'moment';

@Component({
  selector: 'app-profile-status',
  templateUrl: './profile-status.component.html',
  styleUrls: ['./profile-status.component.scss']
})
export class ProfileStatusComponent implements OnInit, OnDestroy {

  currentState: string = '';
  scheduledVacation: string = '';
  today = DateService.getToday();
  @ViewChild('dialogTemplate', { static: false }) content: TemplateRef<any>;
  @ViewChild('intialPicker', { static: false }) initialPicker;
  @ViewChild('endPicker', { static: false }) endPicker;
  tomorrow = DateService.getTomorrowDate();
  enableEndVacation: boolean = true;
  vacationForm: FormGroup;
  public user: any;
  private userData$: Subscription = new Subscription();
  isAdmin: boolean;
  isInVacation: boolean;
  endvacationStart: any;
  @Output() closeMenu: EventEmitter<boolean> = new EventEmitter();

  constructor(
    public router: Router,
    private store: Store<CoreState>,
    private languageService: TranslateService,
    private matDialog: MatDialog,
    private formBuilder: FormBuilder,
    private loading: LoadingService,
    private sotreService: StoresService,
    public userService: UserLoginService,
    private profileService: MyProfileService,
    private modalService: ModalService,
  ) { }

  ngOnInit() {
    this.getDataUser();
    this.initVacationForm();
    this.userService.isAuthenticated(this);
  }

  getDataUser() {
    this.userData$ = this.store
      .pipe(select(state => state.configuration.userData))
      .subscribe(
        userData => {
          if (userData) {
            this.getCurrentState(userData);
            const startDate = new Date(userData.StartVacations);
            const endDate = new Date(userData.EndVacations);
            if (startDate.getFullYear() === 1 || endDate.getFullYear() === 1) {
              this.scheduledVacation = '';
            } else {
              this.setVacationDate(userData);
            }
          }
        }
      );
  }

  setVacationDate(userData: any) {
    const startDate = new Date(userData.StartVacations);
    const endDate = new Date(userData.EndVacations);
    if (userData.Status === 'Enable') {
      this.scheduledVacation = DateFormat.format(startDate, 'DD/MM/YY');
    }
    if (userData.Status === 'Vacations') {
      this.scheduledVacation = DateFormat.format(endDate, 'DD/MM/YY');
    }
  }

  async isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate([`/${RoutesConst.homeLogin}`]);
    } else {
      const user = await this.profileService.getUser().toPromise().then(res => {
        const body: any = res.body;
        const response = JSON.parse(body.body);
        const userData = response.Data;
        return userData;
      });
      this.setUserData(user);
    }
  }

  setUserData(user: any) {
    const startDate = new Date(user.StartVacations);
    const endDate = new Date(user.EndVacations);
    if (startDate.getFullYear() === 1 || endDate.getFullYear() === 1) {
      user.StartVacations = null;
      user.EndVacations = null;
    }
    this.user = Object.assign({}, user);
    this.isAdmin = !this.user.City;
    this.isInVacation = (!!this.user.StartVacations && !!this.user.EndVacations);
    if (this.isInVacation) {
      this.setVacationForm();
      this.setVacationDate(user);
      this.user.StartVacations = DateService.getDateFormatToShow(new Date(this.user.StartVacations));
      this.user.EndVacations = DateService.getDateFormatToShow(new Date(this.user.EndVacations));
    }
  }

  setVacationForm() {
    const endDate = DateService.getDateFormatToShow(this.user.EndVacations);
    const startDateVacationsSeller = DateService.stringToDate(DateService.getDateFormatToShow(this.user.StartVacations));
    if (moment(this.today).diff(moment(startDateVacationsSeller)) > 0) {
      this.vacationForm.controls.StartDateVacation.setValue(this.today);
    } else {
      this.vacationForm.controls.StartDateVacation.setValue(startDateVacationsSeller);
    }
    this.vacationForm.controls.EndDateVacation.setValue(DateService.stringToDate(endDate));
    this.startDate();
  }

  private initVacationForm() {
    this.vacationForm = this.formBuilder.group({
      StartDateVacation: ['', Validators.compose([Validators.required])],
      EndDateVacation: [{ value: '', disabled: true }, Validators.compose([Validators.required])]
    });
    this.vacationForm.controls.StartDateVacation.valueChanges.subscribe(val => {
      if (val) {
        this.startDate();
        this.vacationForm.controls.EndDateVacation.reset(null);
        this.vacationForm.controls.EndDateVacation.enable();
      } else {
        this.vacationForm.controls.EndDateVacation.disable();
      }
    });
  }

  getCurrentState(userData: any) {
    const statesDictionary = {
      enable: 'profile.status.enabled',
      disable: 'profile.status.disabled',
      vacations: 'profile.status.vacations'
    }
    this.currentState = statesDictionary[userData.Status.toLocaleLowerCase()];
  }

  sendToOpenVacationDialog() {
    this.emitClose();
    const data = this.setDataVacationsDialog();
    const dialogInstance = this.openDialogVacation(data);
    this.setConfigVacationDialog(dialogInstance);
  }

  setDataVacationsDialog() {
    const title = this.languageService.instant('secure.seller.list.vacation_title_modal');
    const message = this.languageService.instant('secure.seller.list.vacation_message_modal');
    const icon = 'local_airport';
    const form = this.vacationForm;
    const showButtons = true;
    const btnConfirmationText = null;
    const keepFormValue = true;
    return { title, message, icon, form, showButtons, btnConfirmationText, keepFormValue };
  }

  openDialogVacation(data: any) {
    const dialogRef = this.matDialog.open(DialogWithFormComponent, {
      data: data,
      width: '55%',
      minWidth: '280px'
    });
    return dialogRef.componentInstance;
  }

  setConfigVacationDialog(dialogInstance: DialogWithFormComponent) {
    dialogInstance.content = this.content;
    dialogInstance.confirmation = () => {
      const vacationForm = this.vacationForm.value;
      if (vacationForm.StartDateVacation && vacationForm.EndDateVacation) {
        vacationForm.StartDateVacation = DateService.getDateFormatToRequest(vacationForm.StartDateVacation);
        vacationForm.EndDateVacation = DateService.getDateFormatToRequest(vacationForm.EndDateVacation);
      }
      this.loading.viewSpinner();
      this.sotreService.changeStateSeller(vacationForm).subscribe(response => {
        const body = response.body;
        if (body && body.statusCode && body.statusCode === 201) {
          const resultData = JSON.parse(body.body);
          if (resultData && resultData.Message && resultData.Message === 'El usuario ha sido actualizado éxitosamente.') {
            const user = {
              ...this.user,
              StartVacations: this.vacationForm.controls.StartDateVacation.value,
              EndVacations: this.vacationForm.controls.EndDateVacation.value
            }
            this.setUserData(user);
          } else {
            this.profileService.getUser().toPromise().then(res => {
              const body: any = res.body;
              const response = JSON.parse(body.body);
              const userData = response.Data;
              this.setUserData(userData);
            });
          }
        } else {
          this.modalService.showModal('errorService');
        }
        dialogInstance.onNoClick();
        this.loading.closeSpinner();
      });
    };
  }

  startDate() {
    let date = moment(this.vacationForm.controls.StartDateVacation.value).add(1, 'days').utc();
    this.endvacationStart = date['_d'];
  }

  openPicker(pos: number) {
    switch (pos) {
      case 1:
        this.initialPicker.open();
        break;
      case 2:
        this.endPicker.open();
        break;
    }
  }

  emitClose() {
    this.closeMenu.emit(true);
  }

  ngOnDestroy(): void {
    this.userData$.unsubscribe();
  }

}
