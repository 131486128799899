 <h4 mat-dialog-title class="process-text" *ngIf="typeDialog.Success ===
  response.type" fxLayout="row" fxLayoutAlign="start center">
  <mat-icon class="process-success">check</mat-icon>
  {{'secure.products.Finish_upload_product_information.successful_upload' | translate}}
</h4>

<h4 mat-dialog-title class="process-text" fxLayout="row" mat-subheader
  *ngIf="typeDialog.Process ===
  response.type">
  <mat-spinner class="process-charge" [diameter]="60"></mat-spinner>
  {{response.processing}} {{'secure.products.Finish_upload_product_information.upload_progress' | translate}} ...
</h4>

<h4 mat-dialog-title *ngIf="typeDialog.Error === response.type"> {{'secure.products.create_product_unit.specifications.dialog.there' | translate}}
  {{response.errors.length > 1 ? 'han': 'ha' }}
  presentado
  {{response.errors.length}} {{response.errors.length > 1 ? 'errores': 'error'
  }} al momento de crear
  la solicitud. Intentelo nuevamente... Gracias
</h4>

<mat-divider *ngIf="typeDialog.Error === response.type"></mat-divider>
<mat-nav-list *ngIf="typeDialog.Error === response.type" mat-dialog-content>
  <mat-list-item *ngFor="let item of response.errors">
    <mat-icon mat-list-icon class="icon-color-error">warning</mat-icon>
    <h4 matLine>EAN:{{item.Name}}</h4>
    <p matLine>Error presentado:{{item.Description}}</p>
  </mat-list-item>
</mat-nav-list>


<div fxLayout="row" fxLayoutAlign="space-between center" mat-dialog-actions>
  <div *ngIf="response.type !== typeDialog.Process"></div>
  <button *ngIf="typeDialog.Process === response.type" mat-raised-button
    color="primary" (click)="chargeDialogAgain()">Refrescar</button>
  <button *ngIf="typeDialog.Process === response.type" mat-raised-button
    color="primary" mat-dialog-close>Home Seller
    Center</button>
  <button mat-raised-button color="primary" *ngIf="typeDialog.Success ===
    response.type" (click)="onNoClick()">Inicio</button>
  <button mat-raised-button color="primary" *ngIf="typeDialog.Error ===
    response.type" (click)="onNoClick()">Reintentar</button>
</div>