<app-product-category-breadcrumb
[navigationCategoriesSelected]="categoriesSelected"
[navigationCategoryNodeLeaf]="categoryLeafSelected"
(navigateCategoryBreadcrumb)="handleNavigateCategory($event)"
></app-product-category-breadcrumb>

<div class="product-category__tree">
  <cdk-tree *ngIf="!loadingTree" [dataSource]="currentCategoriesTree" [treeControl]="treeControl">
    <cdk-nested-tree-node *cdkTreeNodeDef="let category">
      <div
        class="product-category__item"
        (click)="handleSelectCategory(category)"
      >
        {{ category.Name }}
      </div>
    </cdk-nested-tree-node>
    <cdk-nested-tree-node *cdkTreeNodeDef="let category; when: hasNestedChildren">
      <div
        class="product-category__item"
        (click)="handleNavigateLevel(category)"
      >
        {{ category.Name }}
        <span class="material-symbols-outlined">chevron_right</span>
      </div>
    </cdk-nested-tree-node>
  </cdk-tree>
</div>
