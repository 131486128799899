<div>
  <div>
    <div *ngIf="inProcess && data.processText">
      <h3 class="processing-string" mat-subheader>
        <mat-spinner class="processCharge" [diameter]="60"></mat-spinner>
        {{data.processText}}
      </h3>
    </div>
    <div *ngIf="Success && !inProcess && data.successText">
      <h3 class="succes-string" mat-subheader>
        <mat-icon>check</mat-icon>
        <!-- {{response.successful}} {{'secure.products.Finish_upload_product_information.successful_upload' | translate}} -->
        {{response.successful}} {{data.successText}}
      </h3>
    </div>
    <div *ngIf="!Success && !inProcess && data.failText">
      <h4 class="error-string" mat-subheader>
        <!-- <mat-icon>warning</mat-icon> -->
        {{'secure.offers.bulk_upload.finish_upload_information.error_upload' | translate}} {{countError}}
        {{'secure.offers.bulk_upload.finish_upload_information.error_upload_2' | translate}}
      </h4>
    </div>
    <mat-divider></mat-divider>
  </div>
  <div *ngIf="!Success && !inProcess && data.failText">
    <mat-dialog-content class="mat-typography finish-upload-information-list-height">
      <mat-nav-list>
        <mat-list-item *ngFor="let error of listError">
          <mat-icon mat-list-icon class="icon-color-error" *ngIf="error.Code === 'PEX'">warning</mat-icon>
          <mat-icon mat-list-icon class="icon-color-error" *ngIf="error.Code !== 'PEX'">error</mat-icon>
          <h4 matLine *ngIf="error.Ean">{{'secure.offers.historical_admin.components.filter.EAN' | translate}}:
            {{error.Ean}}</h4>
          <h4 matLine *ngIf="error.Sku">{{'secure.orders.order_list.product_order.sku' | translate}}: {{error.Sku}}</h4>
          <p matLine *ngIf="error.Code !== 'PEX' && error.Code !== 'ResponseDiferent'">
            {{'secure.products.Finish_upload_product_information.error_ocurred' | translate}}:
            {{error.Message}}</p>
          <p matLine *ngIf="error.Code === 'PEX'">{{error.Message}}</p>
          <p matLine [matTooltip]="error.Message" *ngIf="error.Code === 'ResponseDiferent'">{{error.Message}}</p>
        </mat-list-item>
      </mat-nav-list>
    </mat-dialog-content>
    <mat-divider></mat-divider>
  </div>
  <div>
    <mat-dialog-actions align="end">
      <button id="export-to-excel-button" *ngIf="countError > 0 && showExport" mat-raised-button color="primary"
        (click)="exportAsExcelFile(listError,'Log')">{{'actions.export_excel' | translate}}
      </button>
      <button id="btn-confirmation" type="submit" *ngIf="countError > 0 && pex" mat-raised-button color="primary"
        (click)="confirmation()">
        {{'actions.confirm_offer' | translate}}
      </button>
      <button id="close-finish-upload-button" *ngIf="inProcess" mat-raised-button color="primary"
        [routerLink]="['/securehome/seller-center']" mat-dialog-close>{{'actions.go_to_start' | translate}}</button>
      <button id="close-finish-upload-button" [routerLink]="['/securehome']" *ngIf="enableCloseButton && !data.goList" [disabled]="inProcess" mat-button
        mat-dialog-close>{{'actions.close' | translate}}</button>
      <button id="close-finish-upload-button" *ngIf="!enableCloseButton && !data.goList" [disabled]="inProcess" mat-button
        mat-dialog-close>{{'actions.close' | translate}}</button>
        <button id="close-finish-upload-button"  *ngIf="data.goList" [disabled]="inProcess" mat-button (click)="onClickOk()"
        mat-dialog-close>{{'actions.close' | translate}}</button>
    </mat-dialog-actions>
  </div>
</div>
