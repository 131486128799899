import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { SaveProcessDialogComponent } from '@secure/products/create-product-unit/component-process/dialogSave/dialogSave.component';
import { LoadingService } from '@app/core';
import { ProcessService } from '@secure/products/create-product-unit/component-process/component-process.service';
import { MatDialog } from '@angular/material';
import { ListProductService } from '@secure/products/list-products/list-products.service';
import { EanServicesService } from '@secure/products/create-product-unit/validate-ean/ean-services.service';
import { ProductDetail } from '@secure/products/models/product-detail.model';
import { ProductBasicInformation, ProductCategory, ProductCreation, ProductEan, ProductMediaListener, ProductSpecifications } from '@secure/products/models/product-creation.model';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { CoreState } from '@app/store';
import { SetVariantImage } from '@app/store/unit-product-creation/actions';
import { ResetVariantImages } from '@app/store/unit-product-creation/actions';
import {UserParametersService} from '@app/core';
import { PendingProductsService } from '../../pending-products/pending-products.service';


@Component({
  selector: 'app-unit-product-creation-flow',
  templateUrl: './unit-product-creation-flow.component.html',
})
export class UnitProductCreationFlowComponent implements OnInit {
  @Input() ean: string;
  @Input() reference: string;
  @Input() pendingProduct: boolean;
  @ViewChild('stepper', { static: false }) stepper: ElementRef<HTMLInputElement>;
  productDetails: ProductDetail;
  nameControl: FormControl = new FormControl(null, [Validators.required, Validators.minLength(1)]);
  eanForm: FormGroup;
  basicInformationForm: FormGroup;
  productCategorizationForm: FormGroup;
  loading: boolean;
  productData: ProductCreation = {
    Ean: null,
    AssignEan: false,
    Category: null,
    CategoryName: null,
    ProductType: null,
    HasEAN: false,
    Name: null,
    Brand: null,
    Seller: 'Marketplace',
    SkuShippingSize: null,
    PackageWidth: null,
    PackageHeight: null,
    PackageLength: null,
    PackageWeight: null,
    ProductWidth: null,
    ProductHeight: null,
    ProductLength: null,
    ProductWeight: null,
    Description: null,
    KeyWords: null,
    Children: null,
    ConversionFactor: null,
    MeasurementUnit: null,
    Features: null,
    ImageUrl1: null,
    ImageUrl2: null,
    ImageUrl3: null,
    ImageUrl4: null,
    ImageUrl5: null,
    videoUrl: null,
    MetaTitle: null,
    MetaDescription: null,
    IsCombo: null,
    EanCombo: null,
    ModifyImage: 0,
    ParentReference:""
  };

  eanStep: { eanData: ProductEan, validStep: boolean } = {
    eanData: null,
    validStep: false,
  };
  categoryStep: { categoryData: ProductCategory, validStep: boolean } = {
    categoryData: {} as any,
    validStep: false,
  };
  basicInformationStep: { basicInformationData: ProductBasicInformation, validStep: boolean } = {
    basicInformationData: null,
    validStep: false,
  };
  specificationsStep: { specificationsData: ProductSpecifications, validStep: boolean } = {
    specificationsData: null,
    validStep: false,
  };
  mediaStep: { mediaData: ProductMediaListener, validStep: boolean } = {
    mediaData: null,
    validStep: false,
  };
  saving = false;
  Isadmin=false;
  pendingProductProcessId: string = null;

  constructor(private loadingService: LoadingService,
    private process: ProcessService,
    public dialog: MatDialog,
    private productsService: ListProductService,
    private service: EanServicesService,
    private formBuilder: FormBuilder,
    private store: Store<CoreState>,
    public userParams: UserParametersService,
    private pendingProductsService: PendingProductsService
  ) {
  }

  ngOnInit(): void {
    if (this.ean) {
      if(this.pendingProduct){
        this.getPendingProductDetails();
      }else{
        this.getProductDetails();
      }
    }
    this.productCategorizationFormInit();
  }

  getProductDetails(): void {
    this.loading = true;
    this.service.validateEan(this.ean).subscribe(res => {
      if (this.reference === '' || this.reference === null || this.reference === ' ') {
        this.reference = 'null';
      }
      this.productsService.getProductsDetails(`${this.ean}/${this.reference}`).subscribe((result: any) => {
        if (result && result.data.brand) {
          this.productDetails = result.data;
          this.productDetails.reference = this.reference;
          this.loadProductDetails();
        }
        this.loading = false;
      });
    });
  }

  getPendingProductDetails(): void {
    this.loading = true;
    if (this.reference === '' || this.reference === null || this.reference === ' ') {
      this.reference = 'null';
    }
    const params = `${localStorage.getItem('userId')}/${this.ean}/${this.reference}`;
    this.pendingProductsService.getEANProductsModify(params).subscribe((result: any) => {
      if (result && result.data.brand) {
        this.productDetails = result.data;
        this.productDetails.reference = this.reference;
        this.loadProductDetails();
        this.pendingProductProcessId = result.data.idProductProcess;
      }
      this.loading = false;
    });
  }

  productCategorizationFormInit() {
    this.productCategorizationForm = this.formBuilder.group({
      category: new FormControl(null, Validators.required)
    })
  }

  loadProductDetails(): void {
    this.onEanFormChange({
      eanData: {
        Ean: this.productDetails.ean,
        HasEAN: this.productDetails.hasEan,
        AssignEan: !this.productDetails.hasEan
      },
      validStep: true
    });
    this.onCategoryStepChange({
      categoryData: {
        Category: Number(this.productDetails.categoryId),
        CategoryName: this.productDetails.category,
        ProductType: this.productDetails.productType
      },
      validStep: true
    });    
    this.onBasicInformationStepChange({
      basicInformationData: {
        Name: this.productDetails.name,
        Brand: this.productDetails.brand,
        SkuShippingSize: this.productDetails.skuShippingSize,
        PackageWidth: Number(this.productDetails.packageWidth),
        PackageHeight: Number(this.productDetails.packageHeight),
        PackageLength: Number(this.productDetails.packageLength),
        PackageWeight: Number(this.productDetails.packageWeight),
        ProductWidth: Number(this.productDetails.productWidth),
        ProductHeight: Number(this.productDetails.productHeight),
        ProductLength: Number(this.productDetails.productLength),
        ProductWeight: Number(this.productDetails.productWeight),
        ConversionFactor: this.productDetails.conversionFactor,
        MeasurementUnit: this.productDetails.measurementUnit,
        ParentReference: this.productDetails.parentReference,
        KeyWords: this.productDetails.keyWords,
        Description: this.productDetails.description,
        IsCombo: this.productDetails.isCombo,
        EanCombo: this.productDetails.eanCombo,
      },
      validStep: true,
    });
    if (this.productDetails.productType === 'Clothing') {
      basicInformationData: {
        Children: this.productDetails.children.map(variant => ({
          Ean: variant.ean,
          HasEAN: variant.hasEAN,
          Size: variant.size,
          Color: variant.color,
          HexColourName: variant.hexColourName,
          idProductProcess: variant.idProductProcess,
          ImageUrl1: variant.imageUrl1,
          ImageUrl2: variant.imageUrl2,
          ImageUrl3: variant.imageUrl3,
          ImageUrl4: variant.imageUrl4,
          ImageUrl5: variant.imageUrl5,
        })),
        this.loadChildrenImages();
      }
      validStep: true
    }
    this.onSpecificationsStepChange({
      specificationsData: {
        Features: this.productDetails.features.map(feature => ({
          Key: feature.key,
          Name: feature.name,
          Value: feature.value,
          ExistId: feature.existId,
        }))
      },
      validStep: true,
    });
    this.onMediaStepChange({
      mediaData: {
        images: [
          { id: 'product-image-1', url: this.productDetails.imageUrl1 },
          { id: 'product-image-2', url: this.productDetails.imageUrl2 },
          { id: 'product-image-3', url: this.productDetails.imageUrl3 },
          { id: 'product-image-4', url: this.productDetails.imageUrl4 },
          { id: 'product-image-5', url: this.productDetails.imageUrl5 },
        ],
        videoUrl: this.productDetails.videoUrl
      },
      validStep: true
    });   
  }

  loadChildrenImages() {
    const children = this.productDetails.children;
    this.store.dispatch(new ResetVariantImages())
    children.forEach((kid, index) => {
      if (kid.imageUrl1) {       
        this.store.dispatch(new SetVariantImage({
          sonIndex: index + 1,
          url: kid.imageUrl1
        }));
      }
      if (kid.imageUrl2) {
        this.store.dispatch(new SetVariantImage({
          sonIndex: index + 1,
          url: kid.imageUrl2
        }));
      }
      if (kid.imageUrl3) {
        this.store.dispatch(new SetVariantImage({
          sonIndex: index + 1,
          url: kid.imageUrl3
        }));
      }
      if (kid.imageUrl4) {
        this.store.dispatch(new SetVariantImage({
          sonIndex: index + 1,
          url: kid.imageUrl4
        }));
      }
      if (kid.imageUrl5) {
        this.store.dispatch(new SetVariantImage({
          sonIndex: index + 1,
          url: kid.imageUrl5
        }));
      }
    })  
  }

  eanFormEmit(event: FormGroup) {
    this.eanForm = event;
  }

  basicInformationFormEmit(event: FormGroup) {
    this.basicInformationForm = event;
  }

  onEanFormChange(eanStep: { eanData: ProductEan, validStep: boolean }): void {
    this.eanStep = eanStep;
    if (eanStep.validStep) {
      this.productData.Ean = this.eanStep.eanData.Ean;
      this.productData.AssignEan = this.eanStep.eanData.AssignEan;
      this.productData.HasEAN = this.eanStep.eanData.HasEAN;
    }
  }

  onCategoryStepChange(categoryStep: { categoryData: ProductCategory, validStep: boolean }): void {
    if (categoryStep.categoryData) {
      this.categoryStep = categoryStep;
      if (this.categoryStep.validStep) {
        this.productData.Category = this.categoryStep.categoryData.Category;
        this.productData.CategoryName = this.categoryStep.categoryData.CategoryName;
        this.productData.ProductType = this.categoryStep.categoryData.ProductType;
      }
    }
    this.productData.Features = [];
    this.productCategorizationForm.controls.category.setValue(categoryStep.categoryData);
  }

  onBasicInformationStepChange(basicInformationStep: { basicInformationData: ProductBasicInformation, validStep: boolean }): void {
    this.basicInformationStep = basicInformationStep;
    if (this.basicInformationStep.validStep) {
      this.productData.Name = this.basicInformationStep.basicInformationData.Name;
      this.productData.Brand = this.basicInformationStep.basicInformationData.Brand;
      this.productData.SkuShippingSize = this.basicInformationStep.basicInformationData.SkuShippingSize;
      this.productData.PackageWidth = this.basicInformationStep.basicInformationData.PackageWidth;
      this.productData.PackageHeight = this.basicInformationStep.basicInformationData.PackageHeight;
      this.productData.PackageLength = this.basicInformationStep.basicInformationData.PackageLength;
      this.productData.PackageWeight = this.basicInformationStep.basicInformationData.PackageWeight;
      this.productData.ProductWidth = this.basicInformationStep.basicInformationData.ProductWidth;
      this.productData.ProductHeight = this.basicInformationStep.basicInformationData.ProductHeight;
      this.productData.ProductLength = this.basicInformationStep.basicInformationData.ProductLength;
      this.productData.ProductWeight = this.basicInformationStep.basicInformationData.ProductWeight;
      this.productData.ParentReference = this.basicInformationStep.basicInformationData.ParentReference;
      this.productData.Description = this.basicInformationStep.basicInformationData.Description;
      this.productData.KeyWords = this.basicInformationStep.basicInformationData.KeyWords;
      this.productData.Children = this.basicInformationStep.basicInformationData.Children;
      this.productData.MeasurementUnit = this.basicInformationStep.basicInformationData.MeasurementUnit;
      this.productData.ConversionFactor = this.basicInformationStep.basicInformationData.ConversionFactor;
      this.productData.IsCombo = this.basicInformationStep.basicInformationData.IsCombo;
      this.productData.EanCombo = this.basicInformationStep.basicInformationData.EanCombo;
    }
  }

  onSpecificationsStepChange(specificationsStep: { specificationsData: ProductSpecifications, validStep: boolean }): void {
    this.specificationsStep = specificationsStep;
    if (this.specificationsStep.validStep) {
      this.productData.Features = this.specificationsStep.specificationsData as any;
    }
  }

  onMediaStepChange(mediaStep: { mediaData: ProductMediaListener, validStep: boolean }): void {
    this.mediaStep = mediaStep;
    const images = mediaStep.mediaData.images
    if (this.mediaStep.validStep) {
      this.productData.ImageUrl1 = images.find(img => img.id === 'product-image-1').url;
      this.productData.ImageUrl2 = images.find(img => img.id === 'product-image-2').url;
      this.productData.ImageUrl3 = images.find(img => img.id === 'product-image-3').url;
      this.productData.ImageUrl4 = images.find(img => img.id === 'product-image-4').url;
      this.productData.ImageUrl5 = images.find(img => img.id === 'product-image-5').url;
      this.productData.videoUrl = mediaStep.mediaData.videoUrl;
    } else {
      this.productData.ImageUrl1 = null;
      this.productData.ImageUrl2 = null;
      this.productData.ImageUrl3 = null;
      this.productData.ImageUrl4 = null;
      this.productData.ImageUrl5 = null;
    }
  }

  isValidProductData(): boolean {
    return this.eanStep.validStep &&
      this.categoryStep.validStep &&
      this.basicInformationStep.validStep &&
      this.specificationsStep.validStep &&
      this.mediaStep.validStep;
  }

  saveInformationCreation() {
    this.userParams.getUserData().then(data => {
    if (data.sellerProfile=="administrator") {this.Isadmin=true;}});
    this.loadingService.viewSpinner();
    if (!this.saving) {
      this.saving = true;
      this.process.saveInformationUnitCreation(this.ean, this.productData, this.pendingProductProcessId).subscribe(result => {
        if (result && result['body'] && result['body'].data && result['body'].data.productNotify) {
          if (result['body'].data.error === 0) {
            this.process.resetProduct();
          }
          this.openConfirmationModal(result['body'].data.productNotify, result['body'].data.error, this.productData.Ean,this.Isadmin);          
        } else if (result && result['data']) {
          if (result['data'].error === 0) {
            this.process.resetProduct();
          }
              this.openConfirmationModal(result['data'].productNotify, result['data'].error, this.productData.Ean,this.Isadmin);
        }

        this.loadingService.closeSpinner();
        this.saving = false;
      }, error => {
        this.saving = false;
        this.loadingService.closeSpinner();
      });
    }
  }

  openConfirmationModal(res: any, error: any, ean: any,admin:any): void {
    const sendModal = {
      productNotifyViewModel: res,
      error: error,
      ean: ean,
      admin:admin
    };
    this.dialog.open(SaveProcessDialogComponent, {
      width: '95%',
      disableClose: true,
      data: {
        response: sendModal
      },
    });
  }

}
