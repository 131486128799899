<div fxLayout="row" class="load-file-container">
  <div fxFlex="100" class="text-aling-center-modal-load">
    <p>{{ manualName }}</p>
    <div class="inline-block">
      <div
        ngfDrop
        selectable="1"
        [(validDrag)]="validComboDrag"
        [(files)]="files"
        [(file)]="file"
        [maxSize]="maxSize"
        accept="application/pdf"
        [(lastInvalids)]="lastInvalids"
        [(dragFiles)]="dragFiles"
        class="well my-drop-zone"
        fxLayout="row-reverse"
        [id]="id"
        fxLayoutAlign="end center"
        [class.invalid-drag]="validComboDrag === false"
        [class.valid-drag]="validComboDrag"
        (filesChange)="lastFileAt = getDate(); validateOneFile(); sendFile()"
      >
        <strong *ngIf="!files.length && !lastInvalids?.length">{{
          "school.exito.uploadfile.dragfile" | translate
        }}</strong>
        <strong *ngIf="files?.length && !lastInvalids?.length"
          >{{ files[files.length - 1].name }} -
          {{ files[files.length - 1].size / 1024 / 1024 | number: ".2" }}
          MB</strong
        >
        <strong
          *ngIf="
            lastInvalids?.length && lastInvalids.length
              ? validateFormatInvalidate(lastInvalids)
              : null
          "
          >{{ "shared.components.load_file.text2" | translate }}</strong
        >
        <strong *ngIf="lastInvalids?.length && lastInvalids[0].type === 'accept'">{{
          "school.exito.uploadfile.pdf" | translate
        }}
      </strong>
     
      <strong  *ngIf="lastInvalids?.length && lastInvalids[0].type === 'fileSize'">{{
        "school.exito.uploadfile.maxSize.pdf" | translate
      }}
    </strong>
      
        
        <mat-icon
          class="material-icons"
          *ngIf="!files.length && !lastInvalids?.length"
        >
          note_add
        </mat-icon>
        <mat-icon
          class="material-icons check"
          *ngIf="files.length && !lastInvalids?.length"
        >
          check_circle
        </mat-icon>
        <mat-icon class="material-icons error" *ngIf="lastInvalids?.length">
          not_interested
        </mat-icon>
      </div>
    </div>
  </div>
</div>

