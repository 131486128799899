<div class="content-order my-content">

  <div class="container" fxLayout="row wrap" fxLayoutAlign="center start">

    <div fxFlex="100" class="light-content">
      <!-- Toolbar para los dropdown de filtro de estado para las órdenes -->
      <!-- Toolbar de las opciones para la orden -->
      <app-toolbar-options [informationToForm]="informationToForm" #toolbarOptions [idSeller]="idSeller" [_lengthOrder]="lengthOrder"
        (OnGetOrdersList)="getOrdersList($event)" (OnChangeSizeOrderTable)="changeSizeOrderTable($event); paginations($event)"
        [Typeprofile]="typeProfile" [searchComponent]="searchHistorical" [hideOptionsHistorical]="searchHistorical">
      </app-toolbar-options>
      <!-- Toolbar de las opciones para la orden -->
    </div>

    <!-- Tabla de órdenes -->
    <div fxFlex.xs="93%" fxFlex.sm="95" fxFlex.md="95" fxFlex.lg="95" fxFlex.xl="95" class="content-table-orders">

      <!-- matSort: Permite filtrar la tabla por los titulos de las columnas -->
      <mat-table #table [dataSource]="dataSource" matSort>

        <!-- orderNumber Column -->
        <ng-container matColumnDef="orderNumber">
          <mat-header-cell fxShow.xs="true" fxShow.sm="true"  fxShow.md="true"   *matHeaderCellDef
            mat-sort-header> {{'secure.orders.in_devolution.in_devolution_page.order' | translate}}
          </mat-header-cell>
          <mat-cell fxShow.xs="true" fxShow.sm="true"  fxShow.md="true" *matCellDef="let element">
            <p class="sc-text-order">{{element.orderNumber}}</p>
          </mat-cell>
        </ng-container>

        <!-- orderDate Column -->
        <ng-container matColumnDef="orderDate">
          <mat-header-cell fxShow.xs="true" fxShow.sm="true"  fxShow.md="true" *matHeaderCellDef mat-sort-header >
            {{'secure.orders.in_devolution.in_devolution_page.date_order' | translate}}
          </mat-header-cell>
          <mat-cell fxShow.xs="true" fxShow.sm="true" fxShow.md="true" *matCellDef="let element">
            <p class="sc-date-content-table">{{element.orderDate | date:'dd/MM/yyyy'}} </p>
          </mat-cell>
        </ng-container>

        <!-- creationDate Column -->
        <ng-container matColumnDef="creationDate">
          <mat-header-cell fxShow.xs="true"  fxShow.sm="true" fxShow.md="true"  *matHeaderCellDef mat-sort-header>
            {{'secure.orders.in_devolution.in_devolution_page.date_request' | translate}}
          </mat-header-cell>
          <mat-cell fxShow.xs="true" fxShow.sm="true" fxShow.md="true"  *matCellDef="let element">
            <p class="sc-date-content-table">{{element.creationDate | date:'dd/MM/yyyy'}} </p>
          </mat-cell>
        </ng-container>

        <!-- Fecha de resolutionDate  - Column -->
        <ng-container matColumnDef="resolutionDate">
          <mat-header-cell fxShow.xs="false" fxShow.sm="false" fxShow.md="false" *matHeaderCellDef mat-sort-header>
            {{'secure.orders.historical_devolutions.historical_devolutions_page.date_resolution' | translate}}
          </mat-header-cell>
          <mat-cell fxShow.xs="false" fxShow.sm="false" fxShow.md="false" *matCellDef="let element">
            <p class="sc-date-content-table" *ngIf="element.resolutionDate !== '0001-01-01T00:00:00'">{{element.resolutionDate | date: 'dd/MM/yyyy hh:mm a'}} </p>
          </mat-cell>
        </ng-container>

        <!-- reversionRequestStatus Column -->
        <ng-container matColumnDef="reversionRequestStatus">
          <mat-header-cell  fxShow.xs="false" fxShow.sm="true" fxShow.md="false" *matHeaderCellDef mat-sort-header>
            {{'secure.orders.historical_devolutions.historical_devolutions_page.resolution' | translate}}
          </mat-header-cell>
          <mat-cell fxShow.xs="false" fxShow.sm="true" fxShow.md="false" *matCellDef="let element">
            <p class="sc-date-content-table" *ngIf="element.reversionRequestStatusId === 2">
              {{'secure.orders.historical_devolutions.historical_devolutions_page.resolution.approved' | translate}}
            </p>
            <p class="sc-date-content-table" *ngIf="element.reversionRequestStatusId === 3">
              {{'secure.orders.historical_devolutions.historical_devolutions_page.resolution.rejected' | translate}}
            </p>
            <p class="sc-date-content-table" *ngIf="element.reversionRequestStatusId === 4 || element.reversionRequestStatusId === 10">
              {{'secure.orders.historical_devolutions.historical_devolutions_page.resolution.rejected_validation' | translate}}
            </p>
          </mat-cell>
        </ng-container>

        <!-- reversionRequestReason Column -->
        <ng-container matColumnDef="reversionRequestReason">
          <mat-header-cell fxShow.xs="false" fxShow.sm="true" fxShow.md="true"  *matHeaderCellDef mat-sort-header
            >
            {{'secure.seller.list.reason' | translate}}
          </mat-header-cell>
          <mat-cell  fxShow.xs="false" fxShow.sm="true" fxShow.md="true" *matCellDef="let element">
            <p class="sc-text-normal center-product-list">{{element.reversionRequestReason}} </p>
          </mat-cell>
        </ng-container>

        <!-- comment Column -->
        <ng-container matColumnDef="comment">
          <mat-header-cell fxShow.xs="false" fxShow.sm="true" fxShow.md="true" *matHeaderCellDef mat-sort-header
            >
            {{'secure.orders.in_devolution.in_devolution_page.commnet' | translate}}
          </mat-header-cell>
          <mat-cell fxShow.xs="false" fxShow.sm="true" fxShow.md="true" *matCellDef="let element">
            <div >
              <button (click)="openModalCommentOrder(element)" id="button-order-{{element.orderNumber}}-comment"
                color="primary" class="sc-button-toolbar no-button button-toolbar"
                mat-button>{{'secure.orders.in_devolution.in_devolution_page.view_commnet' | translate}}
              </button>
            </div>
          </mat-cell>
        </ng-container>

        <!-- detailOrder Column -->
        <ng-container matColumnDef="detailOrder">
          <mat-header-cell fxShow.xs="true" fxShow.sm="true"  fxShow.md="true" *matHeaderCellDef mat-sort-header>
            {{'shared.actions' | translate}}
          </mat-header-cell>
          <mat-cell fxShow.xs="true" fxShow.sm="true"  fxShow.md="true" *matCellDef="let element">
            <div>
              <button id="dropdown-button-order-{{element.orderNumber}}" color="primary"
                class="sc-button-toolbar no-button button-toolbar" mat-button [matMenuTriggerFor]="orderOptions">
                <mat-icon>arrow_drop_down</mat-icon>
                <span>{{'shared.actions' | translate}}</span>
              </button>
              <mat-menu #orderOptions="matMenu">
                <button (click)="openModalDetailHistorical(element)"
                  id="viewDetail-button-order-{{element.orderNumber}}" mat-menu-item>
                  <mat-icon>more_vert</mat-icon>
                  <span>{{'actions.show_detail' | translate}}</span>
                </button>
              </mat-menu>
            </div>
          </mat-cell>
        </ng-container>


        <mat-header-row *matHeaderRowDef="displayedColumns "></mat-header-row>

        <mat-row id="order-{{row.orderNumber}}" *matRowDef="let row; columns: displayedColumns;"
          class="element-row personal-row-table">
        </mat-row>
      </mat-table>

      <div [@detailExpand] class="orders-no-data " *ngIf="orderListLength ">
        <mat-icon class="icon-not-found" color="primary">info</mat-icon>
        <p>{{'secure.orders.in_devolution.in_devolution_page.no_found_orders' | translate}}
        </p>
        <button id="load-all-orders-button" mat-button color="primary">
          {{'secure.orders.in_devolution.in_devolution_page.charge_orders' | translate}}
        </button>
      </div>
    </div>

  </div>
</div>
