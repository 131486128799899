<!-- <app-case-toolbar (toggleFilter)="toggleFilter($event)" > </app-case-toolbar> -->

<!-- <mat-toolbar>
  <div class="prev-view"> -->
    <!-- <a [routerLink]="['/securehome/seller-center/support-center']">
      <mat-icon>keyboard_arrow_left</mat-icon>
      {{'secure.parametize.support_claims.back-to-claims' | translate}}
    </a> -->
    <!-- <a (click)="redirecToListClaims()">
      <mat-icon>keyboard_arrow_left</mat-icon>
      {{'secure.parametize.support_claims.back-to-claims' | translate}}
    </a>
  </div>
</mat-toolbar> -->

<button class="prev-view" mat-icon-button (click)="redirecToListClaims()">
  <mat-icon>keyboard_arrow_left</mat-icon>
  {{'secure.parametize.support_claims.back-to-claims' | translate}}
</button>

<div *ngIf="case$ | async as case" class="detail-content">
  <app-drop-down-list [data]="[case]" [configurations]="headerConfigurations">
    <ng-container *itemDropDownList>
      <app-drop-down-box [data]="case" [isOpen]="true">
        <ng-container *itemCaseList>

          <table mat-table [dataSource]="[case]" class="w-100 hide-mobile table-support">

            <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->
          
           <!-- product Column -->
           <ng-container matColumnDef="product">
            <th mat-header-cell *matHeaderCellDef>  {{'secure.parametize.support.product' | translate}} </th>
            <td style="width: 35%;" class="p-rel" mat-cell *matCellDef="let element"> 
               
              <span class="name" (click)="showThumbnail(element)">
                {{element.productName}} 
              </span>

              <mat-icon class="icon" *ngIf="element.productName">image</mat-icon>

            </td>
          </ng-container>
          
          <!-- Brand Column -->
          <ng-container  matColumnDef="brand">
            <th mat-header-cell *matHeaderCellDef> {{'secure.parametize.support.brand' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.brand}} </td>
          </ng-container>

            <!-- Sku Column -->
            <ng-container  matColumnDef="sku">
              <th mat-header-cell *matHeaderCellDef> SKU </th>
              <td mat-cell *matCellDef="let element"> {{element.sku}} </td>
            </ng-container>

          <!-- Ean Column -->
          <ng-container  matColumnDef="ean">
            <th mat-header-cell *matHeaderCellDef> EAN </th>
            <td mat-cell *matCellDef="let element"> {{element.ean}} </td>
          </ng-container>

          <!-- Sku vendedor Column -->
          <ng-container  matColumnDef="skuseller">
            <th mat-header-cell *matHeaderCellDef> {{'secure.orders.order_list.product_order.sku_seller' | translate}}  </th>
            <td mat-cell *matCellDef="let element"> {{element.sellerSku}} </td>
          </ng-container>

          <!-- price Column -->
          <ng-container  matColumnDef="price">
            <th mat-header-cell *matHeaderCellDef> {{'secure.parametize.support.sell_price' | translate}}</th>
            <td mat-cell *matCellDef="let element"> {{element.price}} </td>
          </ng-container>

          <!-- Quantity Column -->
          <ng-container  matColumnDef="quantity">
            <th mat-header-cell *matHeaderCellDef> {{'secure.parametize.support.quantity' | translate}}  </th>
            <td mat-cell *matCellDef="let element"> {{element.quantity}} </td>
          </ng-container>
          
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <app-case-detail (reply)="onEmitResponse($event)" [case]="case"></app-case-detail>
        </ng-container>
      </app-drop-down-box>
    </ng-container>
  </app-drop-down-list>
</div>

<div [ngClass]="
    filter ? 'support-center-case__filter-container' : 'hidden-content'
  ">
  <div [@slideInOut]="menuState" [ngClass]="filter ? 'support-filter' : 'hidden-content'">
    <app-case-filter (eventFilter)="toggleFilter($event)" [stateFilter]="filter" [options]="options"></app-case-filter>
  </div>
</div>
