<div class="seller-rating-component">
  <div class="example-container-rating" (scroll)="eventScrollFilter($event)">
    <div fxLayout="column">
      <div fxLayout="row" class="seller-rating-component-container-header-align">
        <div fxFlex="40" fxFlex.sm="100" fxFlex.xs="100"
          class="seller-rating-component-container-position-title-orders">
          <mat-icon class="mat-18
          seller-rating-component-container-icon-color-process-send"><span class="material-icons">
              assignment_turned_in
            </span>
          </mat-icon>
          {{'secure.dashboard.seller-rating.title' | translate}}
        </div>
        <div fxFlex="60" fxFlex.sm="100" fxFlex.xs="100">
          <div class="filter_rating">
            <form class="example-container" #createfilterSellerRating="ngForm" [formGroup]="filterSellerRating"
              class="seller-filter-sidenav">
              <h5>{{'secure.dashboard.seller-rating.title_filter' | translate}}:</h5>
              <div class="filterResponsive">

                <mat-form-field>
                  <input matInput
                    [placeholder]="'secure.dashboard.seller-rating.date_issued_initial_filter' | translate"
                    formControlName="datequalificationinitial" [errorStateMatcher]="matcher">
                  <mat-error *ngIf="filterSellerRating.get('datequalificationinitial').hasError('pattern')">
                    {{'secure.dashboard.seller-rating.date_issued_final_filter_error' | translate}}</mat-error>
                </mat-form-field>
                <mat-form-field>
                  <input matInput [placeholder]="'secure.dashboard.seller-rating.date_issued_final_filter' | translate"
                    formControlName="dateQualificationFinal" [errorStateMatcher]="matcher" (keypress)="compareDate()">
                  <mat-error *ngIf="filterSellerRating.get('dateQualificationFinal').hasError('pattern')">
                    {{'secure.dashboard.seller-rating.date_issued_final_filter_error' | translate}}</mat-error>
                </mat-form-field>

                <button type="button" mat-button class="btn_filter_rating" (click)="getFilterSellerRating()"
                  [disabled]="!createfilterSellerRating.form.valid || !createfilterSellerRating.form.touched">
                  <mat-icon>filter_list</mat-icon>
                  <small class="sub-title-filter">{{'filters.filter' | translate}}</small>
                </button>

                <button type="button" mat-button class="btn_filter_rating" (click)="cleanAllFilter()">
                  <mat-icon>clear</mat-icon>
                  <small class="sub-title-filter">{{'filters.clear' | translate}}</small>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="marginTable">
        <table mat-table [dataSource]="dataSource">

          <ng-container matColumnDef="calification">
            <th mat-header-cell *matHeaderCellDef class="position_fixed_header seller-aling-center">
              {{'secure.dashboard.seller-rating.header_rating' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.qualitative}} </td>
          </ng-container>


          <ng-container matColumnDef="date_calificate">
            <th mat-header-cell *matHeaderCellDef class="position_fixed_header seller-aling-center">
              {{'secure.dashboard.seller-rating.date_calificated' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.qualificationDate}} </td>
          </ng-container>


          <ng-container matColumnDef="date_issued">
            <th mat-header-cell *matHeaderCellDef class="position_fixed_header seller-aling-center">
              {{'secure.dashboard.seller-rating.date_issued' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.generatedDate}} </td>
          </ng-container>

          <ng-container matColumnDef="Actions">
            <th mat-header-cell *matHeaderCellDef class="position_fixed_header seller-aling-center">
              {{'shared.actions' | translate}} </th>
            <td mat-cell *matCellDef="let element" class="pointer">
              <mat-icon color="primary" [matTooltip]="'secure.dashboard.seller-rating.getPdf' | translate"
                (click)="getPDF(element)">picture_as_pdf</mat-icon>
              <mat-icon color="accent" [matTooltip]="'secure.dashboard.seller-rating.appeal' | translate"
                (click)="openDialogSupport()">event_note</mat-icon>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div class="seller-no-data" *ngIf="arraySellerRating === null && activeFilter === false">
          <mat-icon class="icon-not-found" color="primary">info</mat-icon>
          <p>{{'secure.dashboard.seller-rating.date_issued_final_not_data' | translate}}</p>
        </div>
        <div class="seller-no-data" *ngIf="activeFilter === true">
          <mat-icon class="icon-not-found" color="primary">info</mat-icon>
          <p>{{'secure.dashboard.seller-rating.date_issued_final_not_data_filter' | translate}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
