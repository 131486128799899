import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { RoutesConst } from '@app/shared/util/routes.constants';
import { UserInformation } from '@app/shared';
import { UserParametersService } from '@core/aws-cognito';
import { MatDialog } from '@angular/material';
import { SupportModalComponent } from '@app/secure/support-modal/support-modal.component';
import { LoadingService } from '@app/core/global';
import { Subscription } from 'rxjs';
import { UtilsService } from '@app/shared/services/utils.service';
import { Store, select } from '@ngrx/store';
import { CoreState } from '@app/store';

@Component({
  selector: 'app-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProfileMenuComponent implements OnInit, OnDestroy {

  public routesConst = RoutesConst;
  user: any = {};
  cognitoProfile: string = '';
  cognitoUser: UserInformation = {} as any;
  shortName: string = 'NN';
  supportModalComponent$: Subscription = new Subscription();
  userData$: Subscription = new Subscription();
  @Output() closeMenu: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private matDialog: MatDialog,
    private loadingService: LoadingService,
    private utilsService: UtilsService,
    private store: Store<CoreState>,
    public userParams: UserParametersService,
  ) { }

  ngOnInit() {
    this.getDataUser();
    this.getCognitoProfile();
  }

  async getCognitoProfile() {
    const profileDictionary = {
      administrator: 'profile.administrator',
      seller: 'profile.seller'
    };
    this.cognitoUser = await this.userParams.getUserData();
    this.cognitoProfile = profileDictionary[this.cognitoUser.sellerProfile.toLocaleLowerCase()];
  }

  getDataUser() {
    this.userData$ = this.store
      .pipe(select(state => state.configuration.userData))
      .subscribe(
        userData => {
          if (userData) {
            this.user = userData;
            this.shortName = this.utilsService.getShortName(userData.Name);
          }
        }
      );
  }

  openDialogSupport(): void {
    this.loadingService.viewProgressBar();
    this.supportModalComponent$ = this.matDialog.open(SupportModalComponent, {
      width: '90%',
      panelClass: 'full-width-dialog'
    }).afterClosed().subscribe(() => {
      this.loadingService.closeProgressBar();
    });
  }

  emitClose() {
    this.closeMenu.emit(true);
  }

  ngOnDestroy(): void {
    this.supportModalComponent$.unsubscribe();
    this.userData$.unsubscribe();
  }

}
