<div class="info-modal-support">

    <div fxLayout="row" fxLayoutAlign="end end"><mat-icon class="material-icons close" (click)="close()"> close </mat-icon></div>
  
    <div fxLayout="column" fxLayoutAlign="start start">
        <img [src]="dataInfoSupport.dataProduct.imageUrl" alt="dataInfoSupport.dataProduct.productName">
        <p class="ean">EAN {{dataInfoSupport.dataProduct.ean}}</p>
        <p class="name">{{dataInfoSupport.dataProduct.productName}}</p>
        <span class="desc" fxHide.gt-xs="true">
        <p>SKU: {{dataInfoSupport.dataProduct.sku}}</p>
        <p>{{'secure.parametize.support.brand' | translate}}: {{dataInfoSupport.dataProduct.brand}}</p>
        <p>{{'secure.orders.order_list.product_order.sku_seller' | translate}}: {{dataInfoSupport.dataProduct.sellerSku}}</p>
        <p>{{'secure.parametize.support.sell_price' | translate}}: {{dataInfoSupport.dataProduct.price}}</p>
        <p>{{'secure.billing.amount' | translate}}: {{dataInfoSupport.dataProduct.quantity}}</p>
        </span>
    </div>
  
  </div>