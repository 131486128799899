<ng-container *ngIf="canRead">
  <div class="header__wrapper padding-exception">
    <div></div>
    <div *ngIf="canUpdate" fxShow="true" [fxShow.xs]="false">
      <button mat-stroked-button color="primary"
        (click)="openDialog('create')">{{'secure.parametize.commission.add' | translate}}</button>
    </div>
    <div fxShow="false" [fxShow.xs]="true" *ngIf="canUpdate">
      <button mat-stroked-button color="primary" (click)="openDialog('create')">{{'actions.add'| translate}}</button>
    </div>
  </div>

  <div class="table-wrapper" *ngIf="!showMessageNoData">
    <div class="example-container">
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="Type">
          <th mat-header-cell *matHeaderCellDef> {{'secure.parametize.commission.type-exception' | translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element.TypeName}} </td>
        </ng-container>

        <ng-container matColumnDef="Description">
          <th mat-header-cell *matHeaderCellDef> {{'shared.descripcion' | translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element.Brand}} </td>
        </ng-container>
        <ng-container matColumnDef="InitialDate">
          <th mat-header-cell *matHeaderCellDef>
            {{'secure.dashboard.seller-rating.date_issued_initial_filter' | translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element.InitialDate | date : 'dd/MM/yyyy HH:mm'}} </td>
        </ng-container>

        <ng-container matColumnDef="FinalDate">
          <th mat-header-cell *matHeaderCellDef>
            {{'secure.dashboard.seller-rating.date_issued_final_filter' | translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element.FinalDate | date : 'dd/MM/yyyy HH:mm'}} </td>
        </ng-container>

        <ng-container matColumnDef="Commission">
          <th mat-header-cell *matHeaderCellDef> {{'shared.commission' | translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element.Commission}} </td>
        </ng-container>

        <ng-container matColumnDef="options">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <div class="options">
              <button mat-icon-button [matTooltip]="'actions.edit' | translate" (click)="openDialog('edit', element)">
                <mat-icon>
                  edit
                </mat-icon>
              </button>
              <button mat-icon-button [matTooltip]="'actions.delete' | translate" (click)="deleteElement(element)">
                <mat-icon>
                  delete
                </mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>

  <div class="orders-no-data" *ngIf="showMessageNoData">
    <mat-icon class="icon-not-found" color="primary">info</mat-icon>
    <div>
      <p>{{'secure.parametize.commission.not-foun-data-exception' | translate}}</p>
    </div>
  </div>

  <ng-template #dialogContent>

    <div class="full-width">
      <form [formGroup]="typeForm" class="full-width justify-content-center dis-flex">
        <mat-form-field class="half-width" [ngClass]="{disabledDiv: disableInputType}">
          <mat-label>{{'secure.parametize.commission.type-exception' | translate}}</mat-label>
          <mat-select formControlName="type" [(ngModel)]="typeValue" (change)="disableType()">
            <mat-option *ngFor="let type of typeException" value="{{type.value}}">
              {{type.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>

    </div>

    <form class="full-width space-between" [formGroup]="form" *ngIf="typeForm.get('type').value" #f="ngForm">
      <div fxLayout="column" fxLayoutAlign="space-around center" class="widthBrand">
        <div fxLayout="row" fxLayoutAlign="space-evenly center" class="widthBrand">
          <mat-form-field class="half-width" *ngIf="typeValue === 'MARCA'">
            <input matInput [placeholder]="'shared.brand' | translate" [matAutocomplete]="auto" formControlName="Brand"
              id="brandProduct">
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let brand of filterBrands" [value]="brand.Name">
                {{brand.Name}}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="form.get('Brand').hasError('required')">
              {{'secure.products.create_product_unit.basic_information.input_mandatory' | translate}}</mat-error>
            <mat-error *ngIf="form.get('Brand').hasError('pattern')">
              {{'secure.products.create_product_unit.basic_information.brand_invalidate' | translate}}</mat-error>
            <mat-error *ngIf="form.get('Brand').hasError('minLength')">
              {{'secure.products.create_product_unit.basic_information.brand_invalidate' | translate}}</mat-error>
          </mat-form-field>

          <mat-form-field class="half-width" *ngIf="typeValue === 'PLU'" [ngClass]="{disabledDiv: disableInputType}">
            <input matInput [placeholder]="'secure.products.bulk_upload.filter.plu' | translate" formControlName="Plu"
              id="pluProduct">
            <mat-error *ngIf="form.get('Plu').hasError('pattern')">
              {{'errors.invalid_plu' | translate}}</mat-error>
          </mat-form-field>

          <mat-form-field class="half-width">
            <input matInput id="exception" [placeholder]="'shared.commission' | translate" autocomplete="false"
              formControlName="Commission">
            <mat-error *ngIf="form.get('Commission').hasError('pattern') || form.get('Commission').hasError('trim')">
              {{'error.exception_brand.number_int' | translate}}</mat-error>
            <mat-error *ngIf="form.get('Commission').hasError('max')">{{'error.exception_brand.max_error' | translate}}
            </mat-error>
            <mat-error *ngIf="form.get('Commission').hasError('min')">{{'error.exception_brand.min_error' | translate}}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="dateException" fxLayout="row" fxLayoutAlign="space-evenly center">
          <mat-form-field fxFlex="40%">
            <input matInput type="datetime-local"
              [placeholder]="'secure.dashboard.seller-rating.date_issued_initial_filter' | translate"
              autocomplete="false" formControlName="InitialDate" (change)="minorDate()">
            <mat-error *ngIf="form.get('InitialDate').hasError('minorDate')">{{'secure.parametize.commission.error-date-1' | translate}}
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="40%">
            <input matInput type="datetime-local"
              [placeholder]="'secure.dashboard.seller-rating.date_issued_final_filter' | translate" autocomplete="false"
              formControlName="FinalDate" (change)="compareDate()">
            <mat-error *ngIf="form.get('FinalDate').hasError('minorDate2')">
              {{'secure.products.create_product_unit.list_products.ending_date' | translate}}
            </mat-error>
            <mat-error *ngIf="form.get('FinalDate').hasError('required')">
              {{'secure.parametize.commission.date-init-required' | translate}}
            </mat-error>
          </mat-form-field>

          <div>
            <button mat-button mat-icon-button matSuffix *ngIf="!form.value.Id"
              [matTooltip]="'secure.parametize.commission.addTariffs' | translate" matTooltipPosition="below"
              [disabled]="!f.form.valid" (click)="addBrand()">
              <mat-icon>
                add_circle_outline
              </mat-icon>
            </button>
          </div>
        </div>

        <div *ngIf="actionsEdit" fxLayout="row" fxLayoutAlign="space-between center" class="buttonsSend">
          <button mat-flat-button color="default" type="button"
            (click)="close()">{{'actions.cancel' | translate}}</button>
          <button mat-flat-button color="primary" type="submit" [disabled]="!form.touched"
            (click)="confirmationEdit()">{{'actions.edit' | translate}}</button>
        </div>
      </div>

    </form>
    <ng-container *ngIf="selectedBrands.length > 0">
      <table mat-table [dataSource]="selectedBrandsSources">
        <ng-container matColumnDef="Brand">
          <th mat-header-cell *matHeaderCellDef> {{'secure.parametize.commission.exception' | translate}} </th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element.Brand">
              {{element.Brand}}
            </span>
            <span *ngIf="element.Plu">
              {{element.Plu}}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="InitialDate">
          <th mat-header-cell *matHeaderCellDef> Fecha y hora inicial </th>
          <td mat-cell *matCellDef="let element"> {{element.InitialDate | date : 'dd/MM/yyyy HH:mm'}} </td>
        </ng-container>

        <ng-container matColumnDef="FinalDate">
          <th mat-header-cell *matHeaderCellDef> Fecha y hora final </th>
          <td mat-cell *matCellDef="let element"> {{element.FinalDate | date : 'dd/MM/yyyy HH:mm'}} </td>
        </ng-container>

        <ng-container matColumnDef="Commission">
          <th mat-header-cell *matHeaderCellDef> {{'shared.commission' | translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element.Commission}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumnsInModal;"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsInModal;"></tr>
      </table>
    </ng-container>
  </ng-template>
</ng-container>
