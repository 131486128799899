import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { RoutesConst } from '@shared/util';
import { UserInformation } from '@shared/models';
import { environment } from '@env/environment';

import { UserLoginService, UserParametersService } from '@core/aws-cognito';
import { ShellComponent } from '@core/shell/shell.component';
import { MenuModel, ProfileTypes, ModuleModel } from '@app/secure/auth/auth.consts';
import { AuthService } from '@app/secure/auth/auth.routing';
import { Store, select } from '@ngrx/store';
import { CoreState } from '@app/store';
import { menuIcons } from './icons';
import { MatDialogRef } from '@angular/material';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class SidebarComponent implements OnInit, OnDestroy {
  user: UserInformation;
  // Define si la app esta en un entorno de producción.
  isProductionEnv = environment.production;
  // Lista de categorías de las órdenes
  categoryList: any;
  public routes = RoutesConst;
  prueba = 'solicitudes-pendientes';
  modules: ModuleModel[] = null;

  getModules$: Subscription = new Subscription();
  getNotifications$: Subscription = new Subscription();
  unreadCases: number;
  sumadevolution: number;
  devolution: number;
  pending: number;

  constructor(
    public shellComponent: ShellComponent,
    public userService: UserLoginService,
    public userParams: UserParametersService,
    public authService: AuthService,
    private store: Store<CoreState>,
    private matDialogRef: MatDialogRef<SidebarComponent>,
  ) { }

  ngOnInit() {
    this.categoryList = this.routes.CATEGORYLIST;
    this.getUser();
    this.getModules();
    this.getNotifications();
  }

  getIconByName(module: ModuleModel) {
    const noAccentinLowerCase = module.NameModule.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLocaleLowerCase();
    const name = noAccentinLowerCase.replace(/\s+/g, '-');
    return `${menuIcons[name] || 'settings'}`;
  }

  getModules() {
    this.authService.getModules().then(data => {
      this.modules = data;
    }, error => {
      console.error(error);
    });
  }

  getNotifications() {
    this.getNotifications$ = this.store
      .pipe(select(state => state.notification))
      .subscribe(
        notificationState => {
          this.unreadCases = notificationState.unreadCases;
          this.sumadevolution = notificationState.sumaUnreadDevolutions;
          this.devolution = notificationState.unreadDevolutions;
          this.pending = notificationState.unreadPendings;
        }
      );
  }

  async getUser() {
    this.user = await this.userParams.getUserData();
  }
  public getPersonalityName(name: string): string {
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  }

  /**
   * Funcion que se encarga de verificar que menus se debe de mostrar y cuales no, aqui debe ir la enumeracion que envia back con los menus pertenecientes al usuario.
   *
   * @param {MenuModel} menu
   * @returns {boolean}
   * @memberof SidebarComponent
   */
  public showMenu(menu: MenuModel, showUrlRedirect: boolean = false): boolean {
    // return menu.ShowMenu && menu.ShowMenuProduction;
    if (showUrlRedirect) {
      return menu.ShowMenu && ((this.isProductionEnv && menu.ShowMenuProduction) || !this.isProductionEnv) && showUrlRedirect && !this.showOnlyLocalMenus(menu.UrlRedirect);
    } else {
      return menu.ShowMenu && ((this.isProductionEnv && menu.ShowMenuProduction) || !this.isProductionEnv) && this.showOnlyLocalMenus(menu.UrlRedirect);
    }
  }

  /**
   * Solo abre nuevas pestañas de rutas que no poseen http en la cabecera.
   *
   * @param {string} url
   * @returns {boolean}
   * @memberof SidebarComponent
   */
  public showOnlyLocalMenus(url: string): boolean {
    return url.search('http') === -1;
  }

  /**
   * Verifica si debe mostrar el modulo.
   *
   * @param {ModuleModel} module
   * @returns {boolean}
   * @memberof SidebarComponent
   */
  public showModule(moduleR: ModuleModel): boolean {
    // const menu = moduleR.Menus.find(result => (result.ShowMenu === true && this.validateUserType(result.ProfileType)));
    const menu = moduleR.Menus.find(result => (result.ShowMenu === true));
    return menu !== undefined;
  }

  /**
   * Verifica que debe de mostrar.
   *
   * @param {number} profileType
   * @returns {boolean}
   * @memberof SidebarComponent
   */
  public validateUserType(profileType: number): boolean {
    return this.user.sellerProfile === 'administrator' ? profileType === ProfileTypes.Administrador : profileType === ProfileTypes.Vendedor;
  }

  showNotificationPoint(module: ModuleModel) {
    return (
      module.NameModule.toLocaleLowerCase() === 'reclamaciones y devoluciones' && (this.devolution || this.unreadCases)
    );
  }

  closeMatDialogRef() {
    this.matDialogRef.close && this.matDialogRef.close();
  }

  showUnreadCases(menu: string) {
    const availableMenus = [
      'lista de reclamaciones',
      'solicitudes pendientes'
    ];
    return (availableMenus.includes(menu.toLocaleLowerCase()) && this.unreadCases);
  }

  showDevolution(menu: string) {
    const availableMenus = [
      'cancelaciones',
      'listado de cancelaciones'
    ];
    return (availableMenus.includes(menu.toLocaleLowerCase()) && this.devolution);
  }

  ngOnDestroy(): void {
    this.getModules$.unsubscribe();
    this.getNotifications$.unsubscribe();
  }
}
