<section class="product-list">
  <ng-container *ngIf="products && products.length; else noProducts">
    <app-filters
      [gridView]="gridView"
      [limit]="limit"
      [selectAll]="selectAll"
      [enableConfirm]="enableConfirm()"
      [fulfillmentView]="fulfillmentView"
      (viewModeEmitter)="changeViewMode($event)"
      (confirmProductsEmitter)="confirmProducts()"
    ></app-filters>
    <article
      *ngIf="productCheckArr?.controls?.length"
      class="product-list__grid"
      [class.product-list__grid--details-mode]="!gridView"
    >
      <app-product-card
        *ngFor="let product of products; let i = index"
        [checkControl]="getProdCheckFormControl(i)"
        [product]="product"
        [gridView]="gridView"
      ></app-product-card>
    </article>
    <app-pager
      [currentPage]="currentPage"
      [pagesNumber]="pagesNumber"
      (pageEmitter)="changePage($event)"
    ></app-pager>
  </ng-container>
</section>

<ng-template #noProducts>
  <section *ngIf="fulfillmentView; else noFulfillmentView" class="no-products">
    <span class="material-symbols-rounded">rocket_launch</span>
    <h3>En el momento no cuentas con productos <b>fulfillment.</b></h3>
    <p>Regresa a <b>Mis productos</b> y selecciónalos.</p>
  </section>

  <ng-template #noFulfillmentView>
    <section class="no-products">
      <h3>En el momento no cuentas con productos.</h3>
    </section>
  </ng-template>

</ng-template>

<app-modal></app-modal>
