import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Route } from '@app/core';
import { RoutesConst } from '@app/shared';
import { EnrollmentComponent } from './enrollment/enrollment.component';
import { TermsService } from '../seller/agreement/terms/terms.component.service';
import { AuthService } from '../auth/auth.routing';

const routes: Routes = [
  Route.withShell([
    {
      path: `${RoutesConst.sellerCenterFulfillment}`,
      component: EnrollmentComponent,
      canActivate: [TermsService, AuthService]
    },
    {
      path: `${RoutesConst.sellerCenterFulfillmentEnrollment}`,
      component: EnrollmentComponent,
      canActivate: [TermsService, AuthService]
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FulfillmentRoutingModule { }
