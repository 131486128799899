<div class="content-order my-content">
<div class="container" fxLayout="row wrap" fxLayoutAlign="center start">
    <div fxFlex="100" class="light-content">
    <!-- Toolbar para los dropdown de filtro de notificación de fraudes -->
    <!-- Toolbar de las opciones para notificación de fraudes -->
    <app-toolbar-search-pagination [isClear]="isClear" [informationToForm]="informationToForm" [state]="lastState"
        [idSeller]="idSeller" #toolbarOptions (OnGetOrdersList)="getOrdersList($event)"
        (paginationListOrdens)="paginations($event)" [isFullSearch]="isFullSearch" [pageIndexChange]="pageIndexChange"
        (OnChangeSizeOrderTable)="changeSizeOrderTable($event)" [downloadPermission]="downloadPermission"
        [Typeprofile]="typeProfile" [allFilter]="allFilter" [showLoading]="false" [limitPaginate]="[50, 100, 150]"
        [lengthOrder]="length" [limitSizeList]='pageSize' [showBtn]="false" [btnFilter]="false">
  </app-toolbar-search-pagination >
    <!-- Toolbar de las opciones para notificación de fraudes -->
  </div>

  <div fxLayout="row" fxLayoutAlign="end end" class="button-pos">
    <button class="btn-transparent" mat-stroked-button color="primary" (click)="chargeFraud()">{{'secure.reports.notification.fraud.charge-fraud' | translate}}</button>
  </div>

  <div fxFlex.xs="93%" fxFlex.sm="95" fxFlex.md="95" fxFlex.lg="95" fxFlex.xl="95" class="content-table-orders">
    <table mat-table [dataSource]="dataSource">

        <!-- name Column -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>{{'core.shell.search_order_menu.search_fraud_notification_nameFile'| translate}}  </th>
            <td mat-cell *matCellDef="let element"> <a [href]="element.url" target="_blank"> {{element.nameFile}} </a></td>
        </ng-container>
        
        <!-- creation data Column -->
        <ng-container matColumnDef="creationDate">
            <th mat-header-cell *matHeaderCellDef>{{'secure.parametize.support_drop-down-creation-date' | translate}}  </th>
            <td mat-cell *matCellDef="let element"> {{element.creationDate | date: 'dd/MM/yyyy'}} </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
  </div>
  
</div>
</div>
