<div class="content-page">
  <div class="content-tab" fxFlexAlign="center" fxLayout="row" fxFlex="450px" fxFlex.xs="90" fxFlex.sm="450px" fxFlex.md="450px"
    fxFlex.lg="450px" xFlex.xl="35">
    <div [@scaleEfect] fxFlex="100" class="tab-style forgot-password-content">
      <div class="title-forgot-password">
        <div class="logo-application"></div>
      </div>
      <mat-card class="card-forgot-password">
        <form #f="ngForm"
              [formGroup]="confirmNewPassword"
              class="form-forgot-password"
              method="POST"
              action="#"
              role="form">

          <div fxLayout="column" fxLayoutGap="20px">
            <div fxFlex="100">
              <mat-form-field class="full-width input-style">
                <mat-icon matPrefix>dialpad</mat-icon>
                <input id="verificationCode"
                       name="verificationCode"
                       formControlName="verificationCode"
                       maxlength="25"
                       class="form-control"
                       [placeholder]="'public.auth.forgot.placeholder_code' | translate"
                       matInput>
                <mat-error>{{'public.auth.forgot.code_error_required' | translate}}</mat-error>
              </mat-form-field>
            </div>
            <div fxFlex="100">
                <mat-form-field class="full-width">
                    <mat-icon matPrefix>https</mat-icon>
                    <input id="newPassword"
                           name="newPassword"
                           formControlName="newPassword"
                           type="password"
                           maxlength="25"
                           class="form-control"
                           length="40"
                           [placeholder]="'public.auth.forgot.new_psw' | translate"
                           matInput>
                    <mat-error>{{'public.auth.forgot.new_psw_required' | translate}}</mat-error>
                </mat-form-field>
            </div>
            <div fxFlex="100">
                <mat-form-field class="full-width">
                    <mat-icon matPrefix>https</mat-icon>
                    <input id="newPassword2"
                           name="newPassword2"
                           formControlName="newPassword2"
                           type="password"
                           maxlength="25"
                           class="form-control"
                           length="40"
                           [placeholder]="'public.auth.forgot.confirm_new_psw' | translate"
                           matInput
                           (blur)="confirmNewPass('newPassword2')">
                    <mat-error *ngIf="confirmNewPassword.get('newPassword2').hasError('passNoMatch')">{{'public.auth.forgot.error_psw' | translate}}</mat-error>
                    <mat-error *ngIf="confirmNewPassword.get('newPassword2').hasError('required')">{{'ublic.auth.forgot.confirm_new_psw_required' | translate}}</mat-error>
                </mat-form-field>
            </div>
            <div fxFlex="100" fxLayout="row">
                <button id="signupSubmit"
                        [disabled]="!f.form.valid"
                        (click)="onNext()"
                        type="submit"
                        mat-raised-button
                        fxFlex="100"
                        color="primary">{{'public.auth.forgot.psw' | translate}}</button>
            </div>
            <div *ngIf="errorMessage!=null">
              <mat-error>{{ errorMessage }}</mat-error>
            </div>
          </div>
        </form>
      </mat-card>
    </div>
  </div>
</div>
