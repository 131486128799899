<mat-toolbar class="toolbar-orders-options-table" color="primary">
  <mat-toolbar-row class="toolbar-options">
    <div class="normal-theme toolbar-options-table">
      <div fxFlex="100%" fxLayout="row" fxLayoutAlign="end center" class="toolbar-cont">
        <div *ngIf="Typeprofile == 1">
          <form id="search-store-form" class="search-store mat-typography" (keydown)="keyDownFunction($event)">
            <mat-form-field class="input-search">
              <!-- Icon search -->
              <mat-icon id="icon-search-store" class="icon-search" matPrefix>search</mat-icon>

              <!-- Input search -->
              <input id="input-search-store" class="search-store-input input-search-black" type="search"
                (ngModelChange)="whatchValueInput($event)" matInput [formControl]="textForSearch"
                [matAutocomplete]="auto">
            </mat-form-field>

            <!-- Autocomplete -->
            <mat-autocomplete autoActiveFirstOption class="auto-complete" #auto="matAutocomplete">
              <mat-option id="option-search-store-number-{{position}}" (click)="viewStoreInformation(option)"
                class="autocomplete-list" *ngFor="let option of filteredOptions | async; let position = index"
                [value]="option.Name">
                <mat-icon>store</mat-icon>
                {{option.Name}}
              </mat-option>
            </mat-autocomplete>
          </form>
        </div>

        <div fxLayout="row" class="toolbar-paginate">

          <mat-paginator [ngClass]="{'hidden-pagination': orderListLength == true}" class="order-pagination"
            fxFlex.xs="250px" fxFlex.sm="250px" fxFlex.md="auto" fxFlex.lg="325px" fxFlex.xl="325px"
            class="paginator-style orders-paginate" itemsPerPageLabel="Items por pagina" #paginator
            [pageSize]="pageSizeOrder" [length]="lengthOrder" [pageSizeOptions]="[50, 100, 200]"
            (page)="pageEvent = changeSizeOrderTable($event)">
          </mat-paginator>

          <div fxLayout="row" class="center-product-list" fxFlex="20px" fxShow.xs="true" fxShow.sm="true"
            fxShow.md="false" fxShow.lg="false" fxShow.xl="false"
            *ngIf="hideOptionsHistorical || hideOptionsListCancel">
            <button id="more-options-for-search-orden-historical" class="button-toolbar" mat-icon-button
              [matMenuTriggerFor]="toolbarOptionsHistorical">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #toolbarOptionsHistorical="matMenu">
              <div class="filterMobileFlex">
                <button id="search-order-button" (click)="toggleMenuOrderSearch()" mat-menu-item>
                  <mat-icon>filter_list</mat-icon>
                  <span class="acction-text">Filtrar</span>
                </button>
                <div class="options-dropdwon">
                  <button id="close-more-options-for-search-orden" color="primary" mat-icon-button matSuffix>
                    <mat-icon>close</mat-icon>
                  </button>
                </div>
              </div>

              <button [disabled]="orderListLength" (click)="openModalDownloadListCancels()" class="acction-text"
                *ngIf="hideOptionsListCancel" id="download-order-button" mat-menu-item>
                <mat-icon>file_download</mat-icon>
                <span>{{'actions.download' | translate}}</span>
              </button>
              <button [disabled]="orderListLength" (click)="openModalDownloadHistCancels()" class="acction-text"
                *ngIf="hideOptionsHistorical" id="download-order-button" mat-menu-item>
                <mat-icon>file_download</mat-icon>
                <span>{{'actions.download' | translate}}</span>
              </button>
            </mat-menu>
          </div>

          <div fxLayout="row" class="center-product-list" fxFlex="20px" fxShow.xs="true" fxShow.sm="true"
            fxShow.md="true" fxShow.lg="false" fxShow.xl="false"
            *ngIf="!hideOptionsHistorical && !hideOptionsListCancel">
            <button id="more-options-for-search-orden" class="button-toolbar" mat-icon-button
              [matMenuTriggerFor]="toolbarOptions">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #toolbarOptions="matMenu">
              <div class="options-dropdwon">
                <button id="close-more-options-for-search-orden" color="primary" mat-icon-button matSuffix>
                  <mat-icon>close</mat-icon>
                </button>
              </div>
              <button id="search-order-button" (click)="toggleMenuOrderSearch()" mat-menu-item>
                <mat-icon>filter_list</mat-icon>
                <span class="acction-text">Filtrar</span>
              </button>
              <button [disabled]="orderListLength" (click)="openModalDownloadOrder()" class="acction-text"
                id="download-order-button" mat-menu-item>
                <mat-icon>file_download</mat-icon>
                <span>{{'actions.download' | translate}}</span>
              </button>
              <mat-form-field class="selectPageSize">
                <mat-select id="select-number-page-size" (click)="stopPropagation($event)"
                  placeholder="Items por pagina" [(ngModel)]="pageSizeOrder"
                  (ngModelChange)="changeSizeOrderTable($event)">
                  <mat-option value="5">5</mat-option>
                  <mat-option value="10">10</mat-option>
                  <mat-option value="20">20</mat-option>
                </mat-select>
              </mat-form-field>

              <div class="options-dropdwon">
                <button id="close-more-options-for-search-orden" color="primary" mat-button matSuffix>
                  Cerrar
                </button>
              </div>
            </mat-menu>
          </div>
        </div>
        <button [disabled]="orderListLength" (click)="openModalDownloadOrder()" fxShow.xs="false" fxShow.sm="false"
          fxShow.md="false" fxShow="true" class="sc-default-button btn-download" id="download-order-button" mat-button
          color="primary" *ngIf="!billingType && downloadPermission">
          <mat-icon>file_download</mat-icon>
          {{'actions.download' | translate}}
        </button>

        <button [disabled]="orderListLength" (click)="openModalDownloadBillPay()" fxShow.xs="false" fxShow.sm="false"
          fxShow.md="false" fxShow="true" class="sc-default-button btn-download" id="download-pay-button" mat-button
          color="primary" *ngIf="downloadBillingPay">
          <mat-icon>file_download</mat-icon>
          {{'actions.download' | translate}}
        </button>

        <button [disabled]="orderListLength" (click)="openModalDownloadHistCancels()" fxShow.xs="false"
          fxShow.sm="false" fxShow.md="false" fxShow="true" class="sc-default-button btn-download"
          id="download-order-button" mat-button color="primary" *ngIf="hideOptionsHistorical">
          <mat-icon>file_download</mat-icon>
          {{'actions.download' | translate}}
        </button>

        <button [disabled]="orderListLength" (click)="openModalDownloadListCancels()" fxShow.xs="false"
          fxShow.sm="false" fxShow.md="false" fxShow="true" class="sc-default-button btn-download"
          id="download-order-button" mat-button color="primary" *ngIf="hideOptionsListCancel">
          <mat-icon>file_download</mat-icon>
          {{'actions.download' | translate}}
        </button>

        <button fxShow.xs="false" fxShow.sm="false" fxShow.md="false" fxShow="true" class="sc-default-button btn-filter"
          id="search-order-button" (click)="toggleMenuOrderSearch()" mat-button color="primary">
          <mat-icon>filter_list</mat-icon>
          {{'filters.filter' | translate}}
        </button>
      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>