<mat-sidenav-container class="example-container">

  <!-- Sidebar Busqueda de órdenes -->
  <mat-sidenav #sidenavSearchOrder mode="over" position="end" opened="{{stateSideNavOrder}}">
    <app-search-order-menu [paginator]="paginator" [informationToForm]="informationToForm" [state]="state"
      [idSeller]="idSeller" [typeProfiel]="typeProfiel"
      [sidenavSearchOrder]="sidenavSearchOrder"></app-search-order-menu>
  </mat-sidenav>

  <mat-sidenav-content class="view-content">
    <aside class="aside-menu">
      <app-sidebar></app-sidebar>
    </aside>
    <div class="main-content">
      <app-header [user]="user"></app-header>
      <section class="router-container">
        <router-outlet></router-outlet>
      </section>
    </div>

  </mat-sidenav-content>
</mat-sidenav-container>