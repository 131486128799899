import {Component, EventEmitter, Output} from '@angular/core';

const SHIPPING_SIZES: { value: number; icon: string }[] = [
  {
    value: 1,
    icon: 'smartphone'
  },
  {
    value: 2,
    icon: 'blender'
  },
  {
    value: 3,
    icon: 'desktop_windows'
  },
  {
    value: 4,
    icon: 'local_laundry_service'
  },
  {
    value: 5,
    icon: 'bed'
  }
];

@Component({
  selector: 'app-product-size-slider',
  templateUrl: './product-size-slider.component.html',
  styleUrls: ['./product-size-slider.component.scss']
})
export class ProductSizeSliderComponent {
  @Output() selectOption: EventEmitter<number>;
  sizeOptionSelected: { value: number; icon: string };
  sizeOptionIndex: number;
  shippingSizeOptions: { value: number; icon: string }[] = SHIPPING_SIZES;

  constructor() {
    this.selectOption = new EventEmitter<number>();
  }

  isOptionSelected(index: number): boolean {
    return this.sizeOptionSelected && index <= this.sizeOptionIndex;
  }

  isRangeSelected(index: number): boolean {
    return this.sizeOptionSelected && index < this.sizeOptionIndex;
  }

  onSelectOption(sizeOption: { value: number; icon: string }, index: number): void {
    this.sizeOptionSelected = sizeOption;
    this.sizeOptionIndex = index;
    this.selectOption.emit(this.sizeOptionSelected.value);
  }
}

