
<div *ngIf="false" class="dashboard-component-container-general" fxFlex="row">
  
  <iframe
    src="https://vignette.wikia.nocookie.net/gemas/images/b/b1/Gear1.gif/revision/latest?cb=20151201164022&path-prefix=es"
    width="200" height="146" frameBorder="0"></iframe>
  <p>
    "Estamos trabajando para mostrarte una información mas detallada, muchas
    gracias por tu paciencia.
    Puedes ingresar al resto de los m&oacute;dulos a trabajar. "</p>
</div>
<div class="dashboard">

  <div class="dashboard__content">

    <div class="dashboard__greetings">
      <span>{{'shared.hi' | translate}}</span>
      <p *ngIf="user">
        {{user.sellerName}}
      </p>
    </div>
   
    <div class="dashboard__advertisements-orders">
      <app-advertisements class="dashboard__advertisements"></app-advertisements>
      <app-dash-orders class="dashboard__orders"></app-dash-orders>
    </div>

    <div class="dashboard__sales">
      <app-dash-sales></app-dash-sales>
    </div>

    <div class="dashboard__indicators" *ngIf="false">
      <app-quality-indicators [params]="paramsIndicators"></app-quality-indicators>
    </div>

    <div class="dashboard__quality">
      <app-seller-rating></app-seller-rating>
    </div>
  </div>
</div>
