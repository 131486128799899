import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material';
import { CategoryModel } from '../../categorization/list/category.model';

export interface CategoryOption {
  id: number;
  idParent: number;
  name: string;
}

@Component({
  selector: 'app-product-category-search',
  templateUrl: './product-category-search.component.html',
  styleUrls: ['./product-category-search.component.scss']
})
export class ProductCategorySearchComponent implements OnInit {
  @Input() categories: CategoryModel[];
  @Output() categorySelected: EventEmitter<CategoryOption>;
  options: CategoryOption[];
  searchCategory: FormControl;
  filteredOptions: Observable<CategoryOption[]>;

  constructor() {
    this.categorySelected = new EventEmitter<CategoryOption>();
    this.searchCategory = new FormControl(null);
  }

  ngOnInit(): void {
    this.options = this.flattenCategories(this.categories);
    this.filteredOptions = this.searchCategory.valueChanges.pipe(
      startWith(''),
      map((value: string | CategoryOption) => {
        const name = typeof value === 'string' ? value : value.name;
        return name ? this.filter(name as string) : this.options.slice();
      }),
    );
  }

  displayFn(category: CategoryOption): string {
    return category && category.name ? category.name : '';
  }

  private filter(value: string): CategoryOption[] {
    const filterValue = value.toLowerCase();
    const filteredOptions = this.options.filter(option => option.name.toLowerCase().includes(filterValue));
    return filteredOptions;
  }

  handleOptionSelected(event: MatAutocompleteSelectedEvent): void {
    const optionSelected = event.option.value;
    this.categorySelected.emit(optionSelected);
  }

  flattenCategories(categories: CategoryModel[], flattenedArray: CategoryOption[] = [], parentCategoryName: string = ""): CategoryOption[] {
    categories.forEach(category => {
      const { Name, Son, Id, IdParent } = category;
      const currentString = parentCategoryName.length === 0 ? Name : parentCategoryName + " - " + Name;
      if (Son && Son.length > 0) {
        this.flattenCategories(Son, flattenedArray, currentString);
      }
      else {
        flattenedArray.push({ id: Id, idParent: IdParent, name: currentString });
      }
    })
    return flattenedArray;
  }

}
