import { Action } from '@ngrx/store';
import { UnitProductCreationImage } from './state';

const NAMESPACE = 'UNIT-PRODUCT-CREATION';
const withNamespace = (action: any) => `${NAMESPACE} - ${action}`;

export const Types = {
  SetVariantImage: withNamespace('Set variant image'),
  ResetVariantImages: withNamespace('Reset variant images'),
  DeleteVariantImagesFromSon: withNamespace('Delete variant images from son')
};

export class SetVariantImage implements Action {
  readonly type = Types.SetVariantImage;
  constructor(public payload: UnitProductCreationImage) { }
}

export class DeleteVariantImagesFromSon implements Action {
  readonly type = Types.DeleteVariantImagesFromSon;
  constructor(public payload: number) { }
}

export class ResetVariantImages implements Action {
  readonly type = Types.ResetVariantImages;
}

class GenericAction implements Action {
  readonly type = withNamespace('GENERIC');
  constructor(public payload: any) { }
}

export type ActionsUnion = GenericAction;
