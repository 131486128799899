<div *ngIf="!showSuccessful && !showReject">
  <div>
    <div fxLayout="row wrap">
      <div fxFlex="100" class="modal-generic-text-align-end">
        <mat-icon (click)="close()" [matTooltip]="'actions.close' | translate" class="modal-generic-color-cursor-font-size">close</mat-icon>
      </div>
    </div>
    <div fxLayout="row wrap" class="modal-generic-text-align-center">
      <div fxFlex="100" class="modal-generic-padding-top-bottom-20">
        <span class="modal-generic-font-weight-size-15">
          {{data.title}}
        </span>
      </div>
    </div>
    <div fxLayout="row wrap" class="modal-generic-text-align-center">
      <div fxFlex="100" class="modal-generic-padding-btn-30">
        <span class="modal-generic-color-font-size-14">
          {{data.subtitle}}
        </span>
      </div>
    </div>
  </div>
  <div>
    <div fxLayout="row wrap" *ngIf="data && data.type === 'approved'">
      <div fxFlex="50" class="modal-generic-text-align-end">
        <button mat-stroked-button color="primary" mat-button
          (click)="close()">{{'actions.cancel' | translate}}</button>
      </div>
      <div fxFlex="40" class="modal-generic-padding-left-10">
        <button mat-raised-button color="primary" mat-button
          (click)="approvedProduct()">{{'actions.accept' | translate}}</button>
      </div>
    </div>
    <div fxLayout="row wrap" *ngIf="data && data.type === 'reject'">
      <div fxFlex="50" class="modal-generic-text-align-end">
        <button mat-stroked-button color="primary" mat-button
          (click)="close()">{{'actions.cancel' | translate}}</button>
      </div>
      <div fxFlex="40" class="modal-generic-padding-left-10">
        <button mat-raised-button color="primary" mat-button
          (click)="rejectProduct()">
          {{'secure.products.create_product_unit.list_products.expanded_product.multiOfert.btn_reject_changes'| translate}}
        </button>
      </div>
    </div>
  </div>
</div>


<div *ngIf="showSuccessful || showReject"><div fxLayout="row wrap">
    <div fxFlex="100" class="modal-generic-text-align-end">
      <mat-icon (click)="close()" [matTooltip]="'actions.close' | translate" class="modal-generic-color-cursor-font-size">close</mat-icon>
    </div>
  </div>
  <div class="modal-generic-text-align-center">
    <div class="modal-generic-container-icon-check">
      <mat-icon class="material-icons modal-generic-icons-white-margin">
        check
      </mat-icon>
    </div>
  </div>
  <div class="modal-generic-text-align-center-top-btn">
    <span [innerHTML]="status"></span>
  </div>
  <div class="modal-generic-text-align-center">
    <button mat-raised-button color="primary" mat-button
      (click)="close()">{{'actions.close_mayus' | translate}}</button>
  </div>
</div>