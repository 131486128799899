<section class="fulfillment-filters">
  <aside class="fulfillment-filters__left-aside">
    <app-input-checkbox
      [id]="'selectAllCheckbox'"
      [control]="selectAll"
    >
      {{ (selectAll.value ? 'Des' : 'S') + 'eleccionar todo' }}
    </app-input-checkbox>
    <button *ngIf="enableConfirm" class="fulfillment-filters__left-aside__confirm" (click)="confirmProducts()">
      <span class="material-symbols-rounded">{{fulfillmentView ? 'block' : 'rocket_launch'}}</span>
      {{fulfillmentView ? 'Desactivar' : 'Confirmar'}}
    </button>
  </aside>
  <aside class="fulfillment-filters__right-aside">
    <div class="fulfillment-filters__right-aside__products-by-page">
      <p>Productos por página</p>
      <mat-select
        id="select-number-page-size"
        [formControl]="limit"
      >
        <mat-option value="30">30</mat-option>
        <mat-option value="60">60</mat-option>
        <mat-option value="120">120</mat-option>
        <mat-option value="600">600</mat-option>
      </mat-select>
    </div>
    <div class="fulfillment-filters__right-aside__view">
      <p>Vista:</p>
      <button  [attr.status]="gridView" (click)="emitViewMode(true)">
        <span class="material-symbols-rounded">
          grid_view
        </span>
      </button>
      <button [attr.status]="!gridView" (click)="emitViewMode(false)">
        <span class="material-symbols-rounded">
          view_list
        </span>
      </button>
    </div>
    <div class="fulfillment-filters__right-aside__order">
      <p>Ordenar por:</p>
      <button>
        <span class="material-symbols-outlined">
          page_info
        </span>
      </button>
    </div>
  </aside>
</section>