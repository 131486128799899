
<div class="detail-offer__container">
  <div fxLayout="row">
    <div fxFlex="40">
      <button class="btn-back container-main-width-auto-color" mat-icon-button
        (click)="goToListOffers()">
        <mat-icon>arrow_back</mat-icon>
        <small class="sub-title-back font-size-14">{{'secure.products.pending_products.Schedule.modal.back_the_list_offers' | translate}}</small>
      </button>
    </div>
    <div fxFlex="60">
    </div>
  </div>
  <div fxLayout="row">
    <div fxFlex="40" class="max-height">
      <div class="position-relative-height">
        <img [src]="dataOffer.imageUrl" alt=""
          class="container-detail-ofert-img">
        <div class="sold-out flexbox justify-center align-center"
          *ngIf="dataOffer.stock === 0 || !dataOffer.stock">
          <div class="flexbox justify-center align-center">
            <p>{{'secure.offers.list.list.with_out_stock' | translate}}</p>
          </div>
        </div>
      </div>
    </div>
    <div fxFlex="60">
      <div class="padding-14">
        <div>
          <div fxLayout="row">
            <div fxFlex="80">
              <span class="font-size-color-grey-img">
                <mat-icon class="mat-18" *ngIf="dataOffer.reference === null ||
                  dataOffer.reference === 'null' || dataOffer.reference === ''"
                  class="color-icon-detail">phonelink</mat-icon>
                <mat-icon class="mat-18" *ngIf="dataOffer.reference != null &&
                  dataOffer.reference != 'null' && dataOffer.reference != ''"
                  class="color-icon-detail">loyalty</mat-icon>
                {{dataOffer.name}}
              </span>
            </div>
            <div fxFlex="20" class="text-align-end">
              <div class="width-100-and-text-align-end">
                <button type="button" color="primary"
                  [matTooltip]="'actions.edit' |
                  translate" matTooltipPosition="below"
                  mat-icon-button mat-button (click)="editOffer()"
                  *ngIf="!isUpdateOffer
                  && isUpdateOffer === false && canEdit">
                  <mat-icon class="flexbox align-center justify-center
                    color-grey-text-icon">edit</mat-icon>
                </button>
                <button type="button" color="primary"
                  [matTooltip]="'secure.products.pending_products.Schedule.modal.ofert_scheduled' | translate" matTooltipPosition="below"
                  mat-icon-button mat-button  (click)="offersPrograms()"
                  *ngIf="!isUpdateOffer
                  && isUpdateOffer === false && canEdit && !isDisabledAddScheduleOfert">
                  <mat-icon class="flexbox align-center justify-center
                    color-grey-text-icon">alarm_add</mat-icon>
                </button>
                <button type="button" color="primary"
                  [matTooltip]="'actions.cancel' |
                  translate" matTooltipPosition="below"
                  mat-icon-button mat-button (click)="cancelEditOffer()"
                  *ngIf="isUpdateOffer && isUpdateOffer === true">
                  <mat-icon class="flexbox align-center justify-center">close</mat-icon>
                </button>
              </div>
            </div>
          </div>

        </div>
        <div class="detail-ofert-info-down">
          <span>
            <span>
              <span class="color-grey-light-text">
                {{'shared.ean' | translate}}:
              </span>
              {{dataOffer.ean}}
            </span>
            <span *ngIf="dataOffer.pluVtex"> |
              <span class="color-grey-light-text">
                {{'secure.dashboard.table.top.title.SKU' | translate}}:
              </span>
              {{dataOffer.pluVtex}}
            </span>
            <span *ngIf="dataOffer.reference"> |
              <span class="color-grey-light-text">
                {{'secure.products.table_load_product.parent_reference' |
                translate}}:
              </span>
              {{dataOffer.reference}}
            </span>
          </span>
        </div>
        <div class="detail-ofert-info-down-two">
          <span class="color-grey-light-text">
            {{'secure.offers.bulk_upload.table_load.sellerSku' | translate}}:
          </span>
          {{dataOffer.sellerSku}}
        </div>
        <div *ngIf="!isUpdateOffer && isUpdateOffer === false">
          <div fxLayout="row wrap">
            <div fxFlex="50">
              <div class="container-price-info">
                <div class="aling-container-price-info"
                  *ngIf="dataOffer.scheduleOffer !== null"></div>
                <div *ngIf="dataOffer.discountPrice> 0">
                  <span class="info-text-price">
                    {{dataOffer.price | currency: '$' : 'symbol' : '1.2-2'}}
                  </span>
                </div>
                <div>
                  <span>
                    <span *ngIf="dataOffer.discountPrice> 0"
                      class="font-size-20" [ngClass]="dataOffer.scheduleOffer
                      !== null ? 'font-size-20' : 'color-red font-size-20'">
                      {{dataOffer.discountPrice | currency: '$' : 'symbol' :
                      '1.2-2'}}
                    </span>
                    <span *ngIf="dataOffer.discountPrice === 0" class="color-red
                      font-size-20">
                      {{dataOffer.price | currency: '$' : 'symbol' : '1.2-2'}}
                    </span>
                  </span>
                </div>
              </div>
              <div fxLayout="row" class="padding-bottom-10">
                <div fxFlex="100" class="detail-ofert-info-down-three">
                  <span>
                    <span class="color-grey-light-text">
                      {{'secure.offers.list.components.detail_offer.id_offer' |
                      translate}}
                    </span>
                    {{dataOffer.idOffer}}
                  </span> |
                  <span>
                    <span *ngIf="dataOffer.stock> 0 && dataOffer.stock">
                      <span class="color-grey-light-text">
                        {{'secure.offers.list.components.detail_offer.in_stock'
                        |
                        translate}}
                      </span>
                      {{dataOffer.stock | number}}
                    </span>
                  </span>
                  <span>
                    <span *ngIf="dataOffer.stock <0 && dataOffer.stock">
                      <span class="color-grey-light-text">
                        {{'secure.offers.list.list.stock' | translate}}
                      </span>
                      {{dataOffer.stock | number}}
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div fxFlex="50" *ngIf="dataOffer.scheduleOffer &&
              dataOffer.scheduleOffer !== null"
              class="border-container-solid-left">
              <div class="container-ofert-program">
                <div fxLayout="row wrap">
                  <div fxFlex="80" class="text-aling-center">
                    <span class="font-size-13">{{'secure.products.pending_products.Schedule.modal.scheduled_offer' | translate}}</span>
                  </div>
                  <div fxFlex="20" class="text-align-end">
                    <mat-icon class="mat-18 icon-color-ofert-program"
                      (click)="offersPrograms(dataOffer.scheduleOffer, false,
                      true)">edit</mat-icon>
                    <mat-icon class="mat-18 icon-color-ofert-program"
                      (click)="offersPrograms(null, true, false)">delete</mat-icon>
                  </div>
                </div>
                <div>
                  <div *ngIf="dataOffer && dataOffer.scheduleOffer &&
                    dataOffer.scheduleOffer.discountPrice> 0">
                    <span class="text-decoration-line">
                      {{dataOffer.scheduleOffer.price | currency: '$' : 'symbol'
                      : '1.2-2'}}
                    </span>
                  </div>
                </div>
                <div>
                  <span>
                    <span *ngIf="dataOffer && dataOffer.scheduleOffer &&
                      dataOffer.scheduleOffer.discountPrice> 0"
                      class="font-size-color-red">
                      {{dataOffer.scheduleOffer.discountPrice | currency: '$' :
                      'symbol' :
                      '1.2-2'}}
                    </span>
                    <span *ngIf="dataOffer && dataOffer.scheduleOffer &&
                      dataOffer.scheduleOffer.discountPrice === 0"
                      class="font-size-color-red">
                      {{dataOffer.scheduleOffer.price | currency: '$' : 'symbol'
                      : '1.2-2'}}
                    </span>
                  </span>
                </div>
                <div fxLayout="row wrap" class="font-size-13-color">
                  <div fxFlex="50">
                    <span class="color-grey-light-text">
                      {{'filter-InitialDate' | translate}}
                    </span>
                    <span>{{scheduleOfferDateStart}} |</span>
                  </div>
                  <div fxFlex="50">
                    <span class="color-grey-light-text">
                      {{'filter-FinalDate' | translate}}
                    </span>
                    <span>{{scheduleOfferDateEnd}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="margin-left-55">
            <mat-tab-group>
              <mat-tab [label]="'module.Ofertas' | translate">
                <div fxLayout="row" class="margin-top-10">
                  <div fxFlex="100" class="padding-left-font-size-13">
                    <div fxLayout="row" class="widht-100">
                      <div fxFlex="50" *ngIf="dataOffer.size">
                        <mat-icon class="vertical-align-color-grey">tab_unselected</mat-icon>
                        <span>
                          <span class="color-grey-light-text">
                            {{'secure.products.bulk_upload.size' | translate}}
                          </span>
                          ({{dataOffer.size}})
                        </span>
                      </div>
                      <div fxFlex="50" *ngIf="dataOffer.hexColourCodePDP">
                        <mat-icon class="vertical-align-color-grey">color_lens</mat-icon>
                        <span>
                          <span class="color-grey-light-text">
                            {{'secure.products.bulk_upload.color' | translate}}
                          </span>
                          <mat-icon [ngStyle]="{'color':'#' +
                            dataOffer.hexColourCodePDP}" class="vertical-align">brightness_1</mat-icon>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div fxLayout="row" class="padding-top-font-size">
                  <div fxFlex="100" class="padding-left-50">
                    <div fxLayout="row" class="widht-100">
                      <div fxFlex="50">
                        <mat-icon class="vertical-align-color-grey">attach_money</mat-icon>
                        <span>
                          <span class="color-grey-light-text">
                            {{'secure.offers.list.list.currency' | translate}}
                          </span>
                          {{dataOffer.currency}}
                        </span>
                      </div>
                      <div fxFlex="50">
                        <mat-icon class="vertical-align-color-grey">local_shipping</mat-icon>
                        <span>
                          <span class="color-grey-light-text">
                            {{'secure.offers.list.list.averageFreightCost' |
                            translate}}
                          </span>
                          {{dataOffer.shippingCost | currency: '$' : 'symbol' :
                          '1.2-2'}}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div fxLayout="row" class="padding-top-font-size">
                  <div fxFlex="100" class="padding-left-50">
                    <div fxLayout="row" class="widht-100">
                      <div fxFlex="50">
                        <mat-icon class="color-grey-light-text">beenhere</mat-icon>
                        <span>
                          <span class="color-grey-light-text">
                            {{'secure.offers.list.list.warranty' | translate}}
                          </span>
                          {{dataOffer.warranty}}
                        </span>
                      </div>
                      <div fxFlex="50">
                        <mat-icon class="color-grey-light-text">date_range</mat-icon>
                        <span>
                          <span class="color-grey-light-text">
                            {{'secure.offers.list.list.promise' | translate}}
                          </span>
                          {{promiseFirts}}
                          {{'secure.offers.list.components.detail_offer.' + to |
                          translate}} {{promiseSeconds}} {{periodicityHtml}}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div fxLayout="row wrap" class="padding-top-font-size-color">
                  <div fxFlex="23" class="freshippingText">
                    <div class="flexbox align-center justify-center sep-right"
                      [ngClass]="{'check' : dataOffer.isFreeShipping}">
                      <mat-icon *ngIf="dataOffer.isFreeShipping &&
                        dataOffer.isFreeShipping === true" class="color-green">check</mat-icon>
                      <mat-icon *ngIf="!dataOffer.isFreeShipping ||
                        dataOffer.isFreeShipping === false">clear</mat-icon>
                      <p
                        [innerHTML]="['secure.offers.historical_admin.historical_admin.free_shipping'
                        | translate]"></p>
                    </div>
                  </div>
                  <div fxFlex="23" class="container-element-detail-ofert">
                    <div class="flexbox align-center justify-center"
                      [ngClass]="{'check' : dataOffer.isEnviosExito}">
                      <mat-icon *ngIf="dataOffer.isEnviosExito &&
                        dataOffer.isEnviosExito === true" class="color-green">check</mat-icon>
                      <mat-icon *ngIf="!dataOffer.isEnviosExito ||
                        dataOffer.isEnviosExito === false">clear</mat-icon>
                      <p
                        [innerHTML]="['secure.offers.historical_admin.historical_admin.shiping_exito'
                        | translate]"></p>
                    </div>
                  </div>
                  <div fxFlex="23" class="container-element-detail-ofert">
                    <div class="flexbox align-center justify-center sep-right
                      sep-top"
                      [ngClass]="{'check' : dataOffer.isFreightCalculator}">
                      <mat-icon *ngIf="dataOffer.isFreightCalculator &&
                        dataOffer.isFreightCalculator === true"
                        class="color-green">check</mat-icon>
                      <mat-icon *ngIf="!dataOffer.isFreightCalculator ||
                        dataOffer.isFreightCalculator === false">clear</mat-icon>
                      <p
                        [innerHTML]="['secure.offers.historical_admin.historical_admin.freight_calculator'
                        | translate]"></p>
                    </div>
                  </div>
                  <div fxFlex="23" class="container-element-detail-ofert">
                    <div class="flexbox align-center justify-center sep-top"
                      [ngClass]="{'check' : dataOffer.isLogisticsExito}">
                      <mat-icon *ngIf="dataOffer.isLogisticsExito &&
                        dataOffer.isLogisticsExito === true"
                        class="color-green">check</mat-icon>
                      <mat-icon *ngIf="!dataOffer.isLogisticsExito ||
                        dataOffer.isLogisticsExito === false">clear</mat-icon>
                      <p
                        [innerHTML]="['secure.offers.historical_admin.historical_admin.exito_logistic'
                        | translate]"></p>
                    </div>
                  </div>
                </div>
                <div fxLayout="row wrap" class="padding-top-font-size-color">
                  <div fxFlex="47" class="freshippingText">
                    <div class="flexbox align-center justify-center sep-right"
                      [ngClass]="{'check' : dataOffer.isUpdatedStock}">
                      <mat-icon *ngIf="dataOffer.isUpdatedStock &&
                        dataOffer.isUpdatedStock === true" class="color-green">check</mat-icon>
                      <mat-icon *ngIf="!dataOffer.isUpdatedStock ||
                        dataOffer.isUpdatedStock === false">clear</mat-icon>
                      <p
                        [innerHTML]="changeCopyUpdateStock"></p>
                    </div>
                  </div>
                </div>
              </mat-tab>
              <mat-tab [label]="'module.Combo' | translate"
                *ngIf="dataOffer.offerComponents.length>
                0">
                <div fxLayout="row wrap">
                  <div fxLayout="row wrap" class="beforeNameCombo" fxFlex="100">
                    <div fxFlex="100" id="idListComponent" *ngFor="let
                      detailOfers of dataOffer.offerComponents">
                      <div class="nameCombo">
                        <mat-icon matPrefix class="alingIcon move-to-inbox">move_to_inbox</mat-icon>
                        <span class="colorTitleCombo">{{detailOfers.productName}}</span>
                        <div fxLayout="row">
                          <div fxFlex="50" id="idPrice">
                            {{'secure.offers.historical_admin.historical_admin.price'
                            | translate}}</div>
                          <div fxFlex="50" id="idQuantyCombo">
                            {{'secure.offers.bulk_upload.table_load.quantity_combo'
                            | translate}}</div>
                        </div>
                        <div fxLayout="row">
                          <div fxFlex="50" id="Price2">
                            {{detailOfers.price}}</div>
                          <div fxFlex="50" id="idQuantyCombo2">
                            {{detailOfers.quantity}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
        <div *ngIf="isUpdateOffer && isUpdateOffer === true"
          class="padding-10-20-30">
          <form #updateOffer="ngForm" [formGroup]="formUpdateOffer"
            autocomplete="off">
            <mat-tab-group [(selectedIndex)]="activeTabIndex">
              <mat-tab [label]="'module.Ofertas' | translate" class="font-size-12">
                <div fxLayout="row wrap" class="padding-top-20">
                  <div fxFlex="20" class="margin-right-15">
                    <mat-form-field class="width-100-font-size-13">
                      <input matInput
                        [placeholder]="['secure.offers.bulk_upload.table_load.sellerSku'
                        | translate]"
                        (change)="unLessPrice()" id="detail-offer-stock"
                        formControlName="SellerSku">
                      <mat-hint></mat-hint>
                      <mat-error
                        *ngIf="formUpdateOffer.get('SellerSku').hasError('pattern')"
                        [innerHTML]="['secure.offers.bulk_upload.table_error.sellerSku'
                        | translate]">
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div fxFlex="20" class="margin-right-15">
                    <mat-form-field class="width-100-font-size-13">
                      <input matInput
                        [placeholder]="['secure.offers.historical_admin.historical_admin.stock'
                        | translate]"
                        id="detail-offer-stock" formControlName="Stock"
                        (keypress)="onlyNumber($event)" (change)="unLessPrice()"
                        required>
                      <mat-hint></mat-hint>
                      <mat-error
                        *ngIf="formUpdateOffer.get('Stock').hasError('required')"
                        [innerHTML]="['errors.error_required' | translate]">
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div fxFlex="20" class="margin-right-15">
                    <mat-form-field class="width-100-font-size-13">
                      <input matInput
                        [placeholder]="['secure.offers.historical_admin.historical_admin.price'
                        | translate]" (change)="unLessDiscountPrice()"
                        id="detail-offer-Price" formControlName="Price"
                        [errorStateMatcher]="matcher"
                        (blur)="valiteInput(); validInput('Price');" required>
                      <mat-hint></mat-hint>
                      <mat-error
                        *ngIf="formUpdateOffer.get('Price').hasError('required')"
                        [innerHTML]="['errors.error_required' | translate]">
                      </mat-error>
                      <mat-error
                        *ngIf="formUpdateOffer.get('Price').hasError('pattern')"
                        [innerHTML]="['errors.format_invalidate' | translate]">
                      </mat-error>
                      <mat-error
                        *ngIf="!formUpdateOffer.get('Price').hasError('required')
                        &&
                        formUpdateOffer.get('Price').hasError('isLessThanEightThousand')"
                        [innerHTML]="['errors.error_price' | translate] +
                        sellerMinPrice"></mat-error>
                      <mat-error *ngIf="formUpdateOffer.controls.Price.errors &&
                        formUpdateOffer.controls.Price.errors.unLess"
                        [innerHTML]="['errors.error_price' | translate] +
                        sellerMinPrice"></mat-error>
                      <mat-error
                        *ngIf="!formUpdateOffer.get('Price').hasError('required')
                        &&
                        formUpdateOffer.get('Price').hasError('isLessThanDiscPrice')"
                        [innerHTML]="['errors.error_price_dscto' | translate]"></mat-error>
                      <mat-error
                        *ngIf="!formUpdateOffer.get('Price').hasError('required')
                        && formUpdateOffer.get('Price').hasError('notCero')">
                        {{'errors.price_cannot_be_0' | translate}}</mat-error>
                    </mat-form-field>
                  </div>
                  <div fxFlex="20" class="margin-right-15">
                    <mat-form-field class="width-100-font-size-13">
                      <input matInput
                        [placeholder]="'secure.offers.historical_admin.historical_admin.discount_price'
                        | translate" (change)="unLessDiscountPrice()"
                        [errorStateMatcher]="matcher"
                        formControlName="DiscountPrice" (blur)="valiteInput()">
                      <mat-hint></mat-hint>
                      <mat-error
                        *ngIf="formUpdateOffer.get('DiscountPrice').hasError('pattern')"
                        [innerHTML]="['errors.format_invalidate' | translate]">
                      </mat-error>
                      <mat-error
                        *ngIf="formUpdateOffer.get('DiscountPrice').hasError('isLessThanEightThousand')"
                        [innerHTML]="['errors.error_price_discount' | translate]
                        + sellerMinPrice"></mat-error>
                      <mat-error
                        *ngIf="formUpdateOffer.get('DiscountPrice').hasError('isgreaterThanPrice')"
                        [innerHTML]="['errors.error_price_discount-2' |
                        translate]"></mat-error>
                      <mat-error
                        *ngIf="!formUpdateOffer.get('DiscountPrice').hasError('required')
                        &&
                        formUpdateOffer.get('DiscountPrice').hasError('isLessThanDiscPrice')"
                        [innerHTML]="['errors.error_price_dscto' | translate]"></mat-error>
                      <mat-error
                        *ngIf="!formUpdateOffer.get('Price').hasError('required')
                        &&
                        formUpdateOffer.get('DiscountPrice').hasError('notCero')">
                        {{'errors.discounted_price_cannot_be_0' | translate}}</mat-error>
                      <mat-error
                        *ngIf="formUpdateOffer.controls.DiscountPrice.errors &&
                        formUpdateOffer.controls.DiscountPrice.errors.unLess"
                        [innerHTML]="['errors.error_price_discount' | translate]
                        + sellerMinPrice"></mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div fxLayout="row wrap" class="padding-top-40">
                  <div fxFlex="20" class="margin-right-15">
                    <mat-form-field class="width-100-font-size-13">
                      <input matInput
                        [placeholder]="['secure.offers.list.components.detail_offer.averageFreightCost'
                        | translate]"
                        (change)="unLessPrice()"
                        formControlName="AverageFreightCost"
                        [errorStateMatcher]="matcher" required>
                      <mat-hint></mat-hint>
                      <mat-error
                        *ngIf="formUpdateOffer.get('AverageFreightCost').hasError('pattern')"
                        [innerHTML]="['errors.format_invalidate' | translate]"></mat-error>
                      <mat-error
                        *ngIf="formUpdateOffer.get('AverageFreightCost').hasError('required')"
                        [innerHTML]="['errors.error_required' | translate]"></mat-error>
                    </mat-form-field>
                  </div>
                  <div fxFlex="20" class="margin-right-15">
                    <mat-form-field class="width-100-font-size-13">
                      <input matInput
                        [placeholder]="'secure.offers.historical_admin.historical_admin.promise'
                        | translate"
                        (change)="unLessPrice()"
                        formControlName="PromiseDelivery"
                        (keyup)="validatePromiseDeliveriOffert()"
                        required>
                      <mat-hint></mat-hint>
                      <mat-error
                        *ngIf="formUpdateOffer.get('PromiseDelivery').hasError('required')"
                        [innerHTML]="'errors.error_required' | translate"></mat-error>
                      <mat-error
                        *ngIf="formUpdateOffer.get('PromiseDelivery').hasError('pattern')"
                        [innerHTML]="'secure.products.create_product_unit.list_products.ofert_product.promise_invalid'
                        | translate">
                      </mat-error>
                      <mat-error
                        *ngIf="formUpdateOffer.get('PromiseDelivery').hasError('startIsGreaterThanEnd')"
                        [innerHTML]="'secure.products.create_product_unit.list_products.ofert_product.promise_higher'
                        | translate">
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div fxFlex="20" class="margin-right-15">
                    <mat-form-field class="width-100-font-size-13">
                      <mat-select formControlName="Periodicity"
                        class="color-select-periodicity"
                        [disabled]="isInternational"
                        (change)="unLessPrice()">
                        <mat-option value=1>{{'secure.offers.historical_admin.historical_admin.day'
                          |
                          translate}}</mat-option>
                        <mat-option value=2>{{'secure.offers.historical_admin.historical_admin.hours'|
                          translate}}</mat-option>
                      </mat-select>
                      <mat-hint></mat-hint>
                      <mat-error
                        *ngIf="formUpdateOffer.get('Periodicity').hasError('required')"
                        [innerHTML]="'errors.error_required' | translate"></mat-error>
                    </mat-form-field>
                  </div>
                  <div fxFlex="20" class="margin-right-15">
                    <mat-form-field class="width-100-font-size-13">
                      <input matInput
                        [placeholder]="['secure.offers.historical_admin.historical_admin.warranty'
                        | translate]"
                        formControlName="Warranty" required
                        (keypress)="onlyNumber($event)"
                        (change)="unLessPrice()">
                      <mat-hint></mat-hint>
                      <mat-error
                        *ngIf="formUpdateOffer.get('Warranty').hasError('required')"
                        [innerHTML]="['errors.error_required' | translate]"></mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <div fxLayout="row wrap" class="padding-top-font-size-13">
                  <div fxFlex="30">
                    <mat-slide-toggle color="primary"
                      formControlName="IsFreeShipping" id="freeShippingdWidth"
                      (change)="checkSlide($event, 'isFreeShipping')">
                      {{'secure.offers.historical_admin.historical_admin.free_shipping'
                      | translate}}
                    </mat-slide-toggle>
                  </div>
                  <div fxFlex="30">
                    <mat-slide-toggle color="primary"
                      formControlName="IsEnviosExito"
                      (change)="checkSlide($event, 'isEnviosExito')">
                      {{'secure.products.create_product_unit.list_products.ofert_product.envios_exito_indicator'
                      | translate}}
                    </mat-slide-toggle>
                  </div>
                  <div fxFlex="40">
                    <mat-slide-toggle color="primary"
                      formControlName="IsFreightCalculator"
                      (change)="checkSlide($event, 'isFreightCalculator')">
                      {{'secure.offers.historical_admin.historical_admin.freight_calculator'
                      | translate}}
                    </mat-slide-toggle>
                  </div>
                  <div fxFlex="40">
                    <mat-slide-toggle color="primary"
                      formControlName="IsLogisticsExito"
                      (change)="checkSlide($event, 'isLogisticsExito')">
                      {{'secure.offers.historical_admin.historical_admin.exito_logistic'
                      | translate}}
                    </mat-slide-toggle>
                  </div>
                </div>

                <div fxLayout="row wrap"  class="margin-top-10 padding-top-font-size-13">
                  <div fxFlex="40">
                    <mat-slide-toggle color="primary"
                      formControlName="IsUpdatedStock" id="updateStockWidth"
                      (change)="checkSlide($event, 'IsUpdatedStock');
                      alertSellerByReference($event)">
                      {{changeCopyUpdateStock}}
                    </mat-slide-toggle>
                  </div>
                  <div fxFlex="40" *ngIf="dataOffer.reference &&
                  dataOffer.isCombo === false">
                    <div class="font-size-13">
                      <mat-slide-toggle color="primary"
                        formControlName="OfferByReference"
                        class="apply_variants_toogle"
                        (change)="alertSellerByReference($event)">
                        {{'secure.products.create_product_unit.list_products.ofert_product.reference_update'
                        | translate}}
                      </mat-slide-toggle>
                    </div>
                  </div>
                </div>
                <div fxLayout="row wrap" class="border-color-margin-top">
                  <div class="text-aling-center-width-100-margin"
                    *ngIf="dataOffer.offerComponents.length === 0">
                    <button [disabled]="!updateOffer.form.valid || showButton
                      === true || !updateOffer.form.touched" type="submit"
                      mat-raised-button color="primary"
                      [innerHTML]="'permissions.Editar' | translate"
                      (click)="validateRulesOfert()"></button>
                  </div>
                  <div class="text-aling-center-width-100-margin"
                    *ngIf="dataOffer.offerComponents.length> 0">
                    <button class="sc-default-button" (click)="btnTabActive(1)"
                      mat-stroked-button color="primary">
                      <mat-icon class="color-grey-light-text">arrow_forward</mat-icon>
                      {{'secure.products.pending_products.Schedule.modal.scheduled_next' | translate}}
                    </button>
                  </div>
                </div>
              </mat-tab>
              <mat-tab [label]="'module.Combo' | translate"
                *ngIf="dataOffer.offerComponents.length>
                0">
                <div fxLayout="row wrap" class="sizeContainer">
                  <div fxLayout="row wrap" class="formBeforeNameCombo"
                    fxFlex="100">
                    <div fxFlex="100" id="idListComponent" *ngFor="let comp of
                      Combos.controls">
                      <ng-container [formGroup]="comp">
                        <div class="nameCombo">
                          <mat-icon matPrefix class="move-to-inbox">move_to_inbox</mat-icon>
                          <span class="colorTitleCombo">{{comp.get('nameCombo').value}}</span>
                        </div>
                        <div fxLayout="row wrap">
                          <div fxFlex="50">
                            <mat-form-field color="primary"
                              class="font-margin-left-width">
                              <input (change)="getPriceDescount()" matInput
                                [placeholder]="'secure.offers.historical_admin.historical_admin.price'
                                | translate"
                                formControlName="ofertPriceComponet"
                                (blur)="validInput('Price'); valiteInput()"
                                [errorStateMatcher]="matcher">
                              <mat-hint></mat-hint>
                              <mat-error
                                *ngIf="comp.get('ofertPriceComponet').hasError('required')">
                                {{'secure.products.create_product_unit.list_products.ofert_product.mandatory_price_ean_combo'
                                | translate}}
                              </mat-error>
                              <mat-error
                                *ngIf="comp.get('ofertPriceComponet').hasError('pattern')">
                                {{'secure.products.create_product_unit.list_products.ofert_product.invalid_price_ean_combo'
                                | translate}}
                              </mat-error>
                            </mat-form-field>
                          </div>
                          <div fxFlex="50">
                            <mat-form-field color="primary"
                              class="font-size-width">
                              <input (change)="getPriceDescount()" matInput
                                [placeholder]="'secure.offers.bulk_upload.table_load.quantity_combo'
                                | translate"
                                (blur)="validInput('Price'); valiteInput()"
                                formControlName="ComboQuantity"
                                [errorStateMatcher]="matcher">
                              <mat-hint></mat-hint>
                              <mat-error
                                *ngIf="comp.get('ComboQuantity').hasError('required')">
                                {{'secure.products.create_product_unit.list_products.ofert_product.mandatory_amount_ean_combo'
                                | translate}}}
                              </mat-error>
                              <mat-error
                                *ngIf="comp.get('ComboQuantity').hasError('pattern')">
                                {{'secure.products.create_product_unit.list_products.ofert_product.invalid_amount_ean_combo'
                                | translate}}
                              </mat-error>
                            </mat-form-field>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
                <div class="button-update-offert flexbox justify-center
                  align-center">
                  <button class="sc-default-button margin-right-20"
                    (click)="btnTabActive(0)" mat-stroked-button
                    color="primary">
                    <mat-icon class="color-grey-generic">arrow_back</mat-icon>
                    {{'secure.products.pending_products.Schedule.modal.scheduled_previous'| translate}}
                  </button>
                  <button [disabled]="!updateOffer.form.valid || showButton ===
                    true || !updateOffer.form.touched" type="submit"
                    mat-raised-button color="primary"
                    [innerHTML]="'permissions.Editar' | translate"
                    (click)="validateRulesOfert()"></button>
                </div>
              </mat-tab>
            </mat-tab-group>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
