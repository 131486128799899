<mat-expansion-panel class="product-variant-register" [formGroup]="variantForm">
  <mat-expansion-panel-header>
    <h3 class="unit-product-creation__subtitle">
      {{ "secure.products.unit_product_creation.basic_information.variant.title" | translate }}
      {{ index + 1 }}
    </h3>
  </mat-expansion-panel-header>
  <hr>

  <div class="product-variant-register__form">
    <div class="unit-product-creation__form-field">
      <mat-form-field floatLabel="never">
        <mat-label>
          {{ "secure.products.unit_product_creation.basic_information.variant.ean_placeholder" | translate }}
        </mat-label>
        <input matInput type="text"
          [placeholder]="'secure.products.unit_product_creation.basic_information.variant.ean_placeholder' | translate"
          formControlName="ean" required>
        <mat-error *ngIf="variantForm.get('ean').hasError('required')">
          {{ "errors.error_required" | translate }}
        </mat-error>
      </mat-form-field>
      <mat-checkbox class="unit-product-creation__checkbox" color="primary" formControlName="asignatedEan">
        {{ "secure.products.unit_product_creation.basic_information.variant.ean_checkbox_label" | translate }}
      </mat-checkbox>
    </div>

    <div class="unit-product-creation__form-field">
      <mat-form-field floatLabel="never">
        <mat-label>
          {{ "secure.products.unit_product_creation.basic_information.variant.size_placeholder" | translate }}
        </mat-label>
        <mat-select
          [placeholder]="'secure.products.unit_product_creation.basic_information.variant.size_placeholder' | translate"
          formControlName="size" required>
          <mat-option *ngFor="let clothingSize of clothingSizes" [value]="clothingSize.size">
            {{ clothingSize.label }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="variantForm.get('size').hasError('required')">
          {{ "errors.error_required" | translate }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <app-product-color-picker [colorNameControl]="colorNameControl" [colorSelectControl]="colorSelectControl"  [clothingColors]="clothingColors"
    (colorSelected)="onColorSelected($event)">
  </app-product-color-picker>

  <h3 class="unit-product-creation__subtitle">
    {{ "secure.products.unit_product_creation.basic_information.variant.upload_images_title" | translate }}
  </h3>
  <p class="unit-product-creation__description">
    {{ "secure.products.unit_product_creation.basic_information.variant.upload_images_description" | translate }}
  </p>
  <h3 class="unit-product-creation__subtitle">
    {{ "secure.products.unit_product_creation.images_and_video.upload_images.recommendations" | translate }}
  </h3>
  <ul class="unit-product-creation__list">
    <li class="unit-product-creation__list-item">
      <span class="unit-product-creation__list-icon material-symbols-outlined">check_circle</span>
      <span
        [innerHTML]="'secure.products.unit_product_creation.images_and_video.upload_images.recommendations.list_item_1' | translate : {imagesCount:4}"></span>
    </li>
    <li class="unit-product-creation__list-item">
      <span class="unit-product-creation__list-icon material-symbols-outlined">check_circle</span>
      <span
        [innerHTML]="'secure.products.unit_product_creation.images_and_video.upload_images.recommendations.list_item_3' | translate"></span>
    </li>
    <li class="unit-product-creation__list-item">
      <span class="unit-product-creation__list-icon material-symbols-outlined">check_circle</span>
      <span
        [innerHTML]="'secure.products.unit_product_creation.images_and_video.upload_images.recommendations.list_item_4' | translate"></span>
    </li>
  </ul>
  <div class="product-variant-register__upload-images">
    <app-upload-product-image-file *ngFor="let image of images" [imageData]="image"
      (uploadImage)="sendImage($event)"></app-upload-product-image-file>
  </div>
  <p class="product-variant-register__images-required" *ngIf="checkImagesCount()">
    {{ "secure.products.unit_product_creation.basic_information.form.variants_image_required" | translate }}
  </p>

  <button class="unit-product-creation__button" (click)="onDeleteVarianteForm(index)">
    <span class="material-symbols-outlined">delete</span>
    <span class="unit-product-creation__button-text">
      {{ "secure.products.unit_product_creation.basic_information.variant.delete_variant_button_label" | translate }}
    </span>
  </button>
</mat-expansion-panel>