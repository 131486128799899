import { Injectable } from '@angular/core';

/**
 * Injectable
 */
@Injectable()
/**
 * Clase OrderService
 */
export class PendingService {

}
