<section id="section-filter-offers" class="flexbox flex-wrap">

  <form novalidate *ngIf="filterForm" #filterOffers="ngForm" [formGroup]="filterForm" (ngSubmit)="list.filterOffers(filterForm.value)"
    class="flexbox flex-wrap justify-center">

    <article class="flexbox align-center justify-between">
      <span>{{'secure.offers.list.components.filter.title_filter_offer' | translate}}</span>
      <button id="closeMenuApplication" type="button" color="primary"
        [matTooltip]="'secure.offers.historical_admin.components.filter.tooltip_close' | translate"
        matTooltipPosition="below" mat-icon-button mat-button (click)="toggleMenu()">
        <mat-icon>clear</mat-icon>
      </button>
    </article>

    <mat-form-field color="primary">
      <input matInput [placeholder]="'secure.offers.list.components.filter.placeholder_products' | translate"
        formControlName="product" [errorStateMatcher]="matcher">
      <mat-hint></mat-hint>
      <mat-error *ngIf="filterForm.get('product').hasError('pattern')">{{'errors.error_product' | translate}}
      </mat-error>
      <mat-error *ngIf="filterForm.controls.product.errors && filterForm.controls.product.errors.maxlength"> {{'errors.error_product_length' | translate}}
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <input matInput [placeholder]="'secure.offers.historical_admin.components.filter.EAN' | translate"
        formControlName="ean" [errorStateMatcher]="matcher">
      <mat-hint></mat-hint>
      <mat-error *ngIf="filterForm.get('ean').hasError('pattern')">{{'errors.invalid_EAN' | translate}}</mat-error>
    </mat-form-field>
    <!-- Campo nuevo PLU -->
    <mat-form-field>
      <input matInput (keypress)="onlyNumber($event)"
        [placeholder]="'secure.products.bulk_upload.filter.plu' | translate" formControlName="pluVtex"
        [errorStateMatcher]="matcher">
      <mat-hint></mat-hint>
      <mat-error *ngIf="filterForm.get('pluVtex').hasError('pattern')">{{'errors.invalid_plu' | translate}}</mat-error>
    </mat-form-field>
    <!-- Campo nuevo SellerSKU -->
    <mat-form-field>
      <input matInput 
        [placeholder]="'secure.offers.bulk_upload.table_load.sellerSku' | translate" formControlName="sellerSku"
        [errorStateMatcher]="matcher">
      <mat-hint></mat-hint>
      <mat-error *ngIf="filterForm.get('sellerSku').hasError('pattern')">{{'secure.offers.bulk_upload.table_error.sellerSku' | translate}}</mat-error>
    </mat-form-field>
    <!-- Campo nuevo Referencia Padre -->
    <mat-form-field>
      <input matInput 
        [placeholder]="'secure.products.table_load_product.parent_reference' | translate" formControlName="reference"
        [errorStateMatcher]="matcher">
      <mat-hint></mat-hint>
      <mat-error *ngIf="filterForm.get('reference').hasError('pattern')">{{'errors.format_invalidate' | translate}}</mat-error>
    </mat-form-field>

    <article class="container-radio-buttons flexbox align-center justify-between">
      <div class="inputGroup flexbox align-center justify-center">
        <input id="stock-sold-out" name="stock" type="radio" value="0" formControlName="stock" />
        <label class="flexbox align-center"
          for="stock-sold-out">{{'secure.offers.list.list.with_out_stock' | translate}}</label>
      </div>
      <div class="inputGroup flexbox align-center justify-center">
        <input id="stock" name="stock" type="radio" value="1" formControlName="stock" />
        <label class="flexbox align-center" for="stock">{{'secure.offers.list.list.with_stock' | translate}}</label>
      </div>
    </article>

    <article class="container-buttons flexbox align-center justify-between">
      <button mat-stroked-button color="primary" type="button" (click)="cleanFilter()"
        [innerHTML]="['filters.clear' | translate]">{{'filters.clear' | translate}}</button>
      <button mat-flat-button color="primary" type="submit" [innerHTML]="['filters.filter' | translate]"
        [disabled]="!filterOffers.form.valid || (!product.value && !ean.value && !stock.value && !pluVtex.value && !sellerSku.value && !reference.value)">{{'filters.filter' | translate}}</button>
    </article>

  </form>

</section>
